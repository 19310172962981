import {StackNavigationProp} from '@react-navigation/stack'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {completeUra} from 'src/cassandra'
import {logErrorAndShowException} from 'src/lib/errors'
import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'
import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import {usePfSelector} from 'src/store/utils'

type Props = URAProps & {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}

const InterchangeAccountNumberLightBox: React.FC<Props> = (props) => {
  const [isBusy, setIsBusy] = useState(false)
  const {t} = useTranslation('Common')

  const {ura_id, close, navigation} = props

  const ura = usePfSelector((state) => get_ura_by_id_from_redux(ura_id, state))

  const onComplete = async (): Promise<void> => {
    try {
      setIsBusy(true)

      await completeUra(ura_id)

      close()
    } catch (e) {
      void logErrorAndShowException(e, 'InterchangeAccountNumberLightBox, onComplete:')
    } finally {
      setIsBusy(false)
    }
  }

  const onCollectBankInfo = (): void => {
    navigation.push('CollectDebitCardNumbersForLoansProduct', {
      associatedLinkedAccountId: ura?.actionData.accountId ? ura?.actionData.accountId : undefined,
      onCompleteFromURA: () => void onComplete(),
    })
  }

  const primary = {
    text: t('Continue'),
    disabled: isBusy,
    onPress: (): void => {
      onCollectBankInfo()
    },
  }

  return <URAModalContent uraId={ura_id} ura={ura} primary={primary} close={close} />
}

export {InterchangeAccountNumberLightBox}
