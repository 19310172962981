/*
    LoggingUtilScoped is a wrapper around the LoggingUtil that adds a scope to the log message.
    This is intended to work with the Log.macro.js to add a scope to the log message.
*/

import Log, {argsToMessage} from 'src/lib/loggingUtil'
export {argsToMessage}

type ScopeKey = 'NOT_SET' | 'PLATFORM' | 'GROWTH' | 'ADVANCE'

type LoggingIFace = {
  info: (...args: unknown[]) => void
  warnOnce: (...args: unknown[]) => void
  warn: (...args: unknown[]) => void
  error: (error: unknown, msg?: string) => void
}

const LoggingUtilScoped = (app_scope: ScopeKey, app_file_path: string): LoggingIFace => {
  return {
    info: (...args: unknown[]): void => {
      Log.infoWithContext({app_scope, app_file_path}, ...args)
    },
    warnOnce: (...args: unknown[]): void => {
      Log.warnOnceWithContext({app_scope, app_file_path}, ...args)
    },
    warn: (...args: unknown[]): void => {
      Log.warnWithContext({app_scope, app_file_path}, ...args)
    },
    error: (error: unknown, msg = ''): void => {
      Log.errorWithContext({app_scope, app_file_path}, error, msg)
    },
  }
}

// eslint-disable-next-line import/no-default-export
export default LoggingUtilScoped
