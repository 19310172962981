import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box from 'src/designSystem/components/atoms/Box/Box'
import LoanProgressBar from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressBar'
import {NamedColors} from 'src/designSystem/colors'
import Button from 'src/designSystem/components/atoms/Button/Button'

import {
  useLoanPaymentsPaidAmount,
  useLoanPaymentsRemainingAmount,
  useLoanPaymentsTotalAmount,
  useLoanPaymentsFirstMissedPayment,
} from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressTile.utils'
import {
  LoanProgressTileLoanPaymentFields,
  LoanProgressTileTestIds,
} from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressTile.types'
import {LoanProgressTileProblemNotice} from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressTileProblemNotice'

export type LoanProgressTileProps = {
  payments: LoanProgressTileLoanPaymentFields[]
  amountProcessing: string
  isChargedOff?: boolean
  isInDefault?: boolean
  isSuspended?: boolean
  fundingFailed?: boolean
  noAccountLinked?: boolean
  onShowDefaultExplanation: () => void
  onShowChargedOffExplanation: () => void
  onReschedule: () => void
  onContactUs: () => void
  onRelinkBankAccount: () => void
  testID?: string
  isAdvance?: boolean
}

/**
 * A tile to show payment progress for a loan with a progress bar.
 * @example <LoanProgressTile payments={[]}  onShowDefaultExplanation={()=>{}} onShowChargedOffExplanation={()=>{}}/>
 */
const LoanProgressTile: React.FC<LoanProgressTileProps> = ({
  payments,
  amountProcessing,
  fundingFailed = false,
  noAccountLinked = false,
  isChargedOff = false,
  isInDefault = false,
  isSuspended = false,
  onShowDefaultExplanation: handleOnShowDefaultExplanation,
  onShowChargedOffExplanation: handleOnShowChargedOffExplanation,
  onReschedule: handleOnReschedule,
  onContactUs: handleOnContactUs,
  onRelinkBankAccount: handleOnRelinkBankAccount,
  testID,
  isAdvance,
}: LoanProgressTileProps) => {
  const {t} = useTranslation('DashboardLoanProgressTile')

  const paidAmount: number = useLoanPaymentsPaidAmount(payments)

  const totalAmount: number = useLoanPaymentsTotalAmount(payments)

  const remainingAmount: number = useLoanPaymentsRemainingAmount(payments)

  const firstMissedPayment = useLoanPaymentsFirstMissedPayment(payments)

  const totalAmountInProgress: number = parseFloat(amountProcessing)
  const totalAmountInProgressToDisplay: string = amountProcessing

  const amountPaid = useMemo(() => {
    if (isAdvance === true && remainingAmount === 0) {
      return (
        <Box direction="row" align="center" justify="center">
          <PFText variant="h3" textAlign="center">
            {t('YourAdvanceIsPaidOff')}
            &nbsp; &#127881; {/* <- party popper emoji */}
          </PFText>
        </Box>
      )
    }
    return (
      <Box direction="row" align="center" justify="center">
        <Box marginRight="tiny">
          {totalAmountInProgress > 0 ? (
            <PFText variant="label_lg">${totalAmountInProgressToDisplay}</PFText>
          ) : (
            <PFText variant="label_lg">${paidAmount.toFixed(2)}</PFText>
          )}
        </Box>
        {totalAmountInProgress > 0 ? (
          <PFText variant="p_sm">
            {t('PendingOfTotal', {
              totalAmount: totalAmount.toFixed(2),
            })}
          </PFText>
        ) : (
          <PFText variant="p_sm">
            {t('PaidOfTotal', {
              totalAmount: totalAmount.toFixed(2),
            })}
          </PFText>
        )}
      </Box>
    )
  }, [
    isAdvance,
    paidAmount,
    remainingAmount,
    t,
    totalAmount,
    totalAmountInProgress,
    totalAmountInProgressToDisplay,
  ])

  return (
    <BasicTile padding="medium" testID={testID}>
      {/* amount paid */}
      {amountPaid}
      {/* notices for any problems */}
      <LoanProgressTileProblemNotice
        firstMissedPayment={firstMissedPayment}
        isChargedOff={isChargedOff}
        isInDefault={isInDefault}
        fundingFailed={fundingFailed}
        noAccountLinked={noAccountLinked}
        onShowDefaultExplanation={handleOnShowDefaultExplanation}
        onShowChargedOffExplanation={handleOnShowChargedOffExplanation}
      />

      {/* progress bar */}
      <Box marginBottom="small" marginTop="medium">
        <LoanProgressBar
          payments={payments}
          isInDefault={isInDefault}
          isChargedOff={isChargedOff}
          hasFundingIssue={fundingFailed || noAccountLinked || isSuspended}
        />
      </Box>

      {/* summary numbers */}
      <Box>
        <Box direction="row" justify="between" marginBottom="little">
          {totalAmountInProgress > 0 ? (
            <>
              <PFText variant="p" color={NamedColors.SILVER}>
                {t('PaymentPending')}
              </PFText>
              <PFText variant="p_semibold" color={NamedColors.SILVER}>
                -${totalAmountInProgressToDisplay}
              </PFText>
            </>
          ) : (
            <>
              <PFText variant="p">{t('Paid')}</PFText>
              <PFText variant="p_semibold">${paidAmount.toFixed(2)}</PFText>
            </>
          )}
        </Box>
        {isAdvance === true && remainingAmount === 0 ? null : (
          <Box direction="row" justify="between">
            <PFText variant="p">{t('AmountRemaining')}</PFText>
            <PFText variant="p_semibold">${remainingAmount.toFixed(2)}</PFText>
          </Box>
        )}
      </Box>

      {/* reschedule button if missed payment and not charged off */}
      {isInDefault || (firstMissedPayment && !isChargedOff) ? (
        <Box marginTop="small">
          <Button
            size="medium"
            mode="primary"
            width="100%"
            onPress={handleOnReschedule}
            testID={LoanProgressTileTestIds.RescheduleBtn}
          >
            {t('RescheduleBtn')}
          </Button>
        </Box>
      ) : null}

      {/* contact us button if no account linked, or if charged off */}
      {noAccountLinked || isChargedOff ? (
        <Box marginTop="small">
          <Button
            size="medium"
            mode="primary"
            width="100%"
            onPress={handleOnContactUs}
            testID={LoanProgressTileTestIds.ContactUsBtn}
          >
            {t('ContactUsBtn')}
          </Button>
        </Box>
      ) : null}

      {/* relink bank account button if funding failed */}
      {fundingFailed ? (
        <Box marginTop="small">
          <Button
            size="medium"
            mode="primary"
            width="100%"
            onPress={handleOnRelinkBankAccount}
            testID={LoanProgressTileTestIds.RelinkBankAccountBtn}
          >
            {t('RelinkBankAccountBtn')}
          </Button>
        </Box>
      ) : null}
    </BasicTile>
  )
}

export {LoanProgressTile}
