import React, {useState} from 'react'

import {
  OnCloseAnimationCompleteCallback,
  OverlayContextValue,
  OverlayVariantBaseProps,
} from 'src/designSystem/components/organisms/Overlay/Overlay.types'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = (): void => {
  // noop
}

/**
 * Context used to maintain global state about all overlays in the app that were created with <Overlay/>.
 */
export const OverlayContext = React.createContext<OverlayContextValue>({
  overlays: {},
  showOverlay: noop,
  updateOverlay: noop,
  hideOverlay: noop,
  completedHideOverlay: noop,
  removeOverlay: noop,
})

// each overlay gets its own ascending index so that newer overlays are displayed over older ones
let currentOverlayIndex = 0

// if multiple modals are on a screen and multiple are hidden/shown/removed during the same render cycle
// we need to know the most recent overlays state, so we keep a non-reactive cache of the latest state. this avoids
// a race condition where we don't know the updated state until the next render cycle
// because react state changes with setState() doesn't get reflected until the next render cycle
let overlaysAfterMostRecentUpdate: OverlayContextValue['overlays'] | undefined = undefined

/**
 * Provides access to the Overlay context
 */
export const useOverlayContextValue = (): OverlayContextValue => {
  const [overlays, setOverlays] = useState<OverlayContextValue['overlays']>({})

  const ensureOverlayConfigExists = (args: {
    overlayProps: OverlayVariantBaseProps
    overlayContextValueOverlays: OverlayContextValue['overlays']
  }): void => {
    const {overlayProps, overlayContextValueOverlays} = args
    if (!overlayContextValueOverlays[overlayProps.testID]) {
      overlayContextValueOverlays[overlayProps.testID] = {
        overlayProps,
        index: currentOverlayIndex,
        hasInitiatedAnimatedClose: false,
      }
      if (currentOverlayIndex > Number.MAX_SAFE_INTEGER - 10) {
        // avoid integer overflow
        currentOverlayIndex = 0
      }
      currentOverlayIndex++
    }
  }

  /**
   * Create a copy of the overlays config to modify
   */
  const getCopyOfOverlays = (): OverlayContextValue['overlays'] => {
    const overlaysCopy = {}

    // use cached version if it's available. it's guaranteed to be up to date when updating
    // multiple overlays at once, whereas reactive state "overlays" won't be updated until end of this
    // render cycel
    let overlaysToUse = overlaysAfterMostRecentUpdate ?? overlays
    if (overlaysAfterMostRecentUpdate) {
      overlaysToUse = overlaysAfterMostRecentUpdate
    }
    for (const thisOverlayId in overlaysToUse) {
      overlaysCopy[thisOverlayId] = {
        ...overlaysToUse[thisOverlayId],
      }
    }
    return overlaysCopy
  }
  /**
   * Show an overlay
   */
  const showOverlay = (args: {overlayProps: OverlayVariantBaseProps}): void => {
    const {overlayProps} = args
    const updatedOverlays = getCopyOfOverlays()
    ensureOverlayConfigExists({
      overlayProps,
      overlayContextValueOverlays: updatedOverlays,
    })
    // update this overlay's visiblity and props in case they changed
    updatedOverlays[overlayProps.testID] = {
      ...updatedOverlays[overlayProps.testID],
      overlayProps: {
        ...overlayProps,
        visible: true,
      },
    }

    overlaysAfterMostRecentUpdate = {...updatedOverlays}

    setOverlays(updatedOverlays)
  }

  /**
   * Update an overlay, triggering a re-render whenever children or props change.
   */
  const updateOverlay = (args: {overlayProps: OverlayVariantBaseProps}): void => {
    const {overlayProps} = args
    const updatedOverlays = getCopyOfOverlays()
    ensureOverlayConfigExists({
      overlayProps,
      overlayContextValueOverlays: updatedOverlays,
    })
    if (updatedOverlays[overlayProps.testID]?.hasInitiatedAnimatedClose) {
      // if this overlay was closed and is currently animating itself to opacity: 0 don't update it
      return
    }
    const newOverlayProps: OverlayVariantBaseProps = {
      ...updatedOverlays[overlayProps.testID].overlayProps,
    }
    // update this overlay's props in case they changed to allow it to re-render
    for (const thisKey in overlayProps) {
      if (thisKey !== 'visible') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        newOverlayProps[thisKey] = overlayProps[thisKey]
      }
    }
    updatedOverlays[overlayProps.testID].overlayProps = newOverlayProps
    overlaysAfterMostRecentUpdate = {...updatedOverlays}
    setOverlays(updatedOverlays)
  }

  /**
   * Hide an overlay, beginning its close animation.
   */
  const hideOverlay = (args: {
    overlayProps: OverlayVariantBaseProps
    onCloseAnimationComplete?: OnCloseAnimationCompleteCallback
  }): void => {
    const {overlayProps, onCloseAnimationComplete} = args

    const updatedOverlays = getCopyOfOverlays()
    ensureOverlayConfigExists({
      overlayProps,
      overlayContextValueOverlays: updatedOverlays,
    })
    // provide the onCloseAnimationComplete callback to this overlay's context state so that OverlayModal
    // internally call the callback once animation is complete
    updatedOverlays[overlayProps.testID].onCloseAnimationComplete = onCloseAnimationComplete
    updatedOverlays[overlayProps.testID].hasInitiatedAnimatedClose = true
    overlaysAfterMostRecentUpdate = {...updatedOverlays}
    setOverlays(updatedOverlays)
  }
  /**
   * Finish hiding an overlay in the OverlayContext state after its animation has completed.
   */
  const completedHideOverlay = (args: {overlayProps: OverlayVariantBaseProps}): void => {
    const {overlayProps} = args
    const updatedOverlays = getCopyOfOverlays()

    ensureOverlayConfigExists({
      overlayProps,
      overlayContextValueOverlays: updatedOverlays,
    })
    const newOverlayProps: OverlayVariantBaseProps = {
      ...updatedOverlays[overlayProps.testID].overlayProps,
      visible: false,
    }
    updatedOverlays[overlayProps.testID].overlayProps = newOverlayProps
    updatedOverlays[overlayProps.testID].hasInitiatedAnimatedClose = false

    overlaysAfterMostRecentUpdate = {...updatedOverlays}
    setOverlays(updatedOverlays)
  }

  /**
   * Remove an overlay entirely when it's done being used
   */
  const removeOverlay = (args: {overlayProps: OverlayVariantBaseProps}): void => {
    const {overlayProps} = args

    const updatedOverlays = getCopyOfOverlays()
    delete updatedOverlays[overlayProps.testID]
    overlaysAfterMostRecentUpdate = {...updatedOverlays}
    setOverlays(updatedOverlays)
  }

  return {
    overlays,
    showOverlay,
    updateOverlay,
    hideOverlay,
    completedHideOverlay,
    removeOverlay,
  }
}
