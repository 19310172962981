import React, {useCallback, useState} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'
import {StyleSheet} from 'react-native'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {GetCurrentRouteName} from 'src/workflows/navigation'
import {WORKFLOWS_DEBUG_PANEL} from 'src/config'

type Props = {
  title: string
}

export const WorkflowDebugPanel: React.FC<React.PropsWithChildren<Props>> = ({title, children}) => {
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  const [isVisible, setIsVisible] = useState(__DEV__)

  const handleOnPress = useCallback(() => {
    setIsVisible((prev) => !prev)
  }, [])

  const navState = navigation.getState()

  if (!__DEV__ || !WORKFLOWS_DEBUG_PANEL) {
    return null
  }

  return (
    <Box
      align="center"
      justify="center"
      gap="little"
      boxStyle={{...styles.box, ...(isVisible ? styles.boxFocus : styles.boxBlur)}}
      background="black"
      radius="small"
      padding="little"
    >
      <PFText variant="label_sm" color="inverse">
        {title}
      </PFText>
      <PFText variant="p_sm" color="inverse">
        Visible Route: {GetCurrentRouteName(navState)}
      </PFText>
      {children}
      <Button mode="progressive" size="small" onPress={handleOnPress}>
        {isVisible ? 'Hide' : 'Show More'}
      </Button>
    </Box>
  )
}

const styles = StyleSheet.create({
  box: {
    position: 'absolute',
    top: 40,
    right: 10,
  },
  boxFocus: {
    opacity: 0.8,
  },
  boxBlur: {
    opacity: 0.3,
  },
})
