import React from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {NameLogo} from 'src/designSystem/components/atoms/NameLogo/NameLogo'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Animated from 'src/lib/ReactNativeReanimated'
import {FixedSizeArray} from 'src/lib/utils/typesUtil'
import {LandingScreenContent} from 'src/products/MCU/Landing/LandingScreen.types'
import {LandingScreenTile} from 'src/products/MCU/Landing/LandingScreenTile'

type LandingScreenTemplateProps = {
  title: JSX.Element
  tilesContent: FixedSizeArray<LandingScreenContent, 4>
  handleNavigateToLogin: () => void
  handleNavigateToCreateAccount: () => void
}

const LandingScreenTemplate: React.FC<LandingScreenTemplateProps> = (props): JSX.Element => {
  const {
    handleNavigateToLogin: onHandleNavigateToLogin,
    handleNavigateToCreateAccount: onHandleNavigateToCreateAccount,
    title,
    tilesContent,
  } = props
  const {t} = useTranslation('OpeningLandingPage')

  const buttonProps: ButtonLockupProps = {
    type: 'doubleButton',
    primary: {
      text: t('CheckMyEligibility'),
      onPress: onHandleNavigateToCreateAccount,
      testID: 'Loan-Landing-Check-Eligibility-Button',
      mode: 'landingScreenPrimary',
    },
    secondary: {
      buttonText: t('AlreadyAMember'),
      onPress: onHandleNavigateToLogin,
      testID: 'Loan-Landing-Already-A-Member-Button',
      buttonLinkVariant: 'white',
    },
  }

  const renderContent = (
    <>
      <Animated.View>
        <LandingScreenTile {...tilesContent[0]} />
      </Animated.View>
      <Box direction="row" justify="between" gap={'small'}>
        <LandingScreenTile {...tilesContent[1]} />
        <LandingScreenTile {...tilesContent[2]} />
      </Box>
      <Box>
        <LandingScreenTile {...tilesContent[3]} />
      </Box>
    </>
  )

  return (
    <Page
      title={<NameLogo isInverted />}
      variant={'generic'}
      backgroundColor="primary"
      smallTopGap
      noHeaderSpacer
      buttonProps={buttonProps}
      contentBackgroundColor="black"
      safeAreaBackground="black"
    >
      <Box align="center">{title}</Box>
      {renderContent}
    </Page>
  )
}

export {LandingScreenTemplate}
