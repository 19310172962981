import {useCallback, useState} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useMutationFactory} from '@possible/cassandra/src/utils'
import {
  UserRegisterDocument,
  UserRegisterInput,
} from '@possible/cassandra/src/types/types.public.generated'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {getDeviceDetails} from 'src/lib/getDeviceDetails'
import {getIsAppRevampFunctionalUpdatesEnabled} from 'src/lib/experimentation/appRevampFeatureFlag'
import {logErrorAndShowException} from 'src/lib/errors'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {usePfDispatch} from 'src/store/utils'
import {verifyAuthentication} from 'src/api/MobileGatewayAPI/actions'

export type UseUserRegisterMutationReturn = {
  userSignUp: (data: UserRegisterInput) => Promise<void>
  isSubmittingForm: boolean
  isSocialBusy: boolean
  showLoginFromRegistrationModal: boolean
}

type UseUserRegisterMutationArgs = {
  formContext: UseFormReturn<UserRegisterInput>
  userLogin: (args: {data: UserRegisterInput; loginFromRegistration: boolean}) => Promise<boolean>
}

export const useUserRegisterMutation = (
  args: UseUserRegisterMutationArgs,
): UseUserRegisterMutationReturn => {
  const {formContext, userLogin} = args
  const [userRegister, {loading: isLoadingRegisterMutation}] = useMutationFactory(
    UserRegisterDocument,
  )({}, {isPublic: true})
  const {t} = useTranslation(['RegistrationOrLogin'])

  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [showLoginFromRegistrationModal, setShowLoginFromRegistrationModal] = useState(false)

  const isAppRevampFunctionalUpdatesEnabled = getIsAppRevampFunctionalUpdatesEnabled()

  const dispatch = usePfDispatch()

  const userSignUp = useCallback(
    async (data: UserRegisterInput): Promise<void> => {
      const {email, password, stateSelected} = data

      const deviceDetails = await getDeviceDetails()

      setIsAuthenticating(true)
      try {
        const {data: result, errors} = await userRegister({
          variables: {
            input: {
              stateSelected,
              email,
              password,
              deviceDetails,
            },
          },
        })

        if (errors) {
          if (errors[0].extensions?.errorSubCode === 'register_email_exists') {
            TrackAppEvent(AppEvents.Name.registration_existing_log_in, AppEvents.Category.Login)
            const hasLoggedIn = await userLogin({
              data,
              loginFromRegistration: isAppRevampFunctionalUpdatesEnabled,
            })

            if (isAppRevampFunctionalUpdatesEnabled) {
              if (hasLoggedIn) {
                setShowLoginFromRegistrationModal(true)
              } else {
                formContext.setError('password', {
                  type: 'manual',
                  message: t('AccountAlreadyExistsError'),
                })
              }
            }

            setIsAuthenticating(false)
            return
          }
          throw new Error(errors[0].message)
        }

        if (result?.userRegister) {
          await verifyAuthentication({
            response: result.userRegister,
            dispatch: dispatch,
            analyticsData: 'signup',
            method: 'emailpwd',
          })
          TrackAppEvent(AppEvents.Name.registration_get_started, AppEvents.Category.Login)
        }
        setIsAuthenticating(false)
      } catch (e) {
        void logErrorAndShowException(e, 'useUserRegisterMutation, userSignUp:')
        setIsAuthenticating(false)
      }
    },
    [userRegister, userLogin, isAppRevampFunctionalUpdatesEnabled, formContext, t, dispatch],
  )

  return {
    userSignUp,
    isSubmittingForm: isLoadingRegisterMutation || isAuthenticating,
    isSocialBusy: false,
    showLoginFromRegistrationModal,
  }
}
