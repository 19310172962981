import React, {ReactElement, ReactNode, useCallback, useEffect, useState} from 'react'
import {StyleSheet, View, Keyboard} from 'react-native'

import Button, {
  LARGE_BUTTON_MAX_WIDTH,
  ButtonProps,
} from 'src/designSystem/components/atoms/Button/Button'
import {gapSizeMap, smallGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {ButtonLinkProps, ButtonLink} from 'src/designSystem/components/atoms/ButtonLink/ButtonLink'
import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {NamedColors} from 'src/designSystem/colors'

export type ButtonLockupPropsPrimary = Partial<ButtonProps> & {
  text: string
  disabled?: boolean
}

export type ButtonLockupPropsSecondary = Omit<ButtonLinkProps, 'containerStyle' | 'color'> & {
  buttonText: string
  color?: 'textPrimary' | 'link' | NamedColors
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
  hideIcon?: boolean
}

export type ButtonLockupPropsInline = Omit<ButtonLinkProps, 'containerStyle' | 'color'> & {
  buttonText: string
  text: string
  disabled?: boolean
  loading?: boolean
  hideIcon?: boolean
}

export type ButtonLockupPropSingleButton = {
  type: 'singleButton'
  primary: ButtonLockupPropsPrimary
}

export type ButtonLockupPropDoubleButton = {
  type: 'doubleButton'
  primary: ButtonLockupPropsPrimary
  secondary: ButtonLockupPropsSecondary
}

export type ButtonLockupPropInlineLink = {
  type: 'inlineLink'
  primary: ButtonLockupPropsPrimary
  inline: ButtonLockupPropsInline
}

export type CheckBoxProps = {
  text: string | ReactElement
  onCheck?: (checkValue: boolean) => void
  checked?: boolean
  disabled?: boolean
  testID?: string
}

export type ButtonLockupPropInterstitial = {
  type: 'interstitial'
  primary: ButtonLockupPropsPrimary
  checkBox: CheckBoxProps
  extraCheckBox?: CheckBoxProps
}

export type ButtonLockupProps =
  | ButtonLockupPropSingleButton
  | ButtonLockupPropDoubleButton
  | ButtonLockupPropInlineLink
  | ButtonLockupPropInterstitial

const alternativeViewHeight = 50

const primaryButton = (props: ButtonLockupProps, disabled = false): ReactNode => {
  const {primary} = props
  const {text, ...rest} = primary

  return (
    <Button mode="primary" disabled={disabled} width={'100%'} size={'large'} {...rest}>
      {text}
    </Button>
  )
}

const ButtonOnTop: React.FC<ButtonLockupProps> = (props) => {
  const {primary, type: buttonLockupType} = props
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false)
  useEffect(() => {
    const subscriptionDidShow = Keyboard.addListener('keyboardDidShow', keyboardDidShow)
    const subscriptionDidHide = Keyboard.addListener('keyboardDidHide', keyboardDidHide)

    return (): void => {
      subscriptionDidShow.remove()
      subscriptionDidHide.remove()
    }
  }, [])

  const keyboardDidShow = (): void => {
    setIsKeyboardOpen(true)
  }

  const keyboardDidHide = (): void => {
    setIsKeyboardOpen(false)
  }

  const alternativeActionViewHeight =
    isKeyboardOpen && buttonLockupType === 'singleButton' ? smallGap : alternativeViewHeight

  let auxiliary: JSX.Element | undefined = undefined

  if (buttonLockupType === 'doubleButton') {
    const {secondary} = props
    const buttonLinkSize = 'medium'
    const {buttonText, iconName: icon, hideIcon, ...rest} = secondary
    const buttonLink = (
      <ButtonLink
        size={buttonLinkSize ?? 'small'}
        iconName={hideIcon === true ? undefined : (icon ?? 'arrowRight')}
        {...rest}
      >
        {buttonText}
      </ButtonLink>
    )
    auxiliary = <View style={styles.auxiliaryView}>{buttonLink}</View>
  }

  if (buttonLockupType === 'inlineLink') {
    const {inline} = props
    const buttonLinkSize = inline?.size
    const {buttonText, iconName: icon, hideIcon, ...rest} = inline
    const buttonLink = (
      <ButtonLink
        size={buttonLinkSize ?? 'small'}
        iconName={hideIcon === true ? undefined : (icon ?? 'arrowRight')}
        {...rest}
      >
        {buttonText}
      </ButtonLink>
    )
    auxiliary = (
      <Box direction="row" gap="tiny" wrap="wrap" justify="center" marginTop="little">
        <PFText textAlign={'center'} textProps={{numberOfLines: 1}} variant="p_sm">
          {inline.text}
        </PFText>
        {buttonLink}
      </Box>
    )
  }

  return (
    <>
      <View style={styles.topPrimaryButtonView}>{primaryButton(props, primary.disabled)}</View>
      <View style={{...styles.buttonOnTop, height: alternativeActionViewHeight}}>{auxiliary}</View>
    </>
  )
}

const alternativeCheckboxHeight = 82
const alternativeCheckboxHeightDouble = 121
const ButtonWithCheckbox: React.FC<ButtonLockupPropInterstitial> = (props) => {
  const {checkBox, extraCheckBox, type: buttonLockupType} = props

  const [isInternalCheck, setIsInternalCheck] = useState<boolean>(false)
  const [isInternalExtraCheckboxChecked, setIsInternalExtraCheckboxChecked] =
    useState<boolean>(false)

  const onPress = useCallback(
    (newCheckedValue: boolean) => {
      checkBox.onCheck?.(newCheckedValue)
      setIsInternalCheck(newCheckedValue)
    },
    [checkBox],
  )
  const onPressExtraCheckBox = useCallback(
    (newCheckedValue: boolean) => {
      extraCheckBox?.onCheck?.(newCheckedValue)
      setIsInternalExtraCheckboxChecked(newCheckedValue)
    },
    [extraCheckBox],
  )

  if (buttonLockupType !== 'interstitial') {
    return null
  }

  type CheckBoxRenderProps = {
    selectedCheckBox: CheckBoxProps
    isChecked: boolean
    handleOnPress: (value: boolean) => void
    testID?: string
  }

  const renderCheckbox = ({
    selectedCheckBox,
    isChecked,
    handleOnPress,
    testID,
  }: CheckBoxRenderProps): ReactNode => {
    const isDisabled: boolean = selectedCheckBox.disabled ?? false

    return (
      <View style={{marginTop: smallGap}}>
        <PFCheckbox
          size={'Small'}
          checked={isChecked}
          onPress={handleOnPress}
          disabled={isDisabled}
          testID={testID}
        >
          {selectedCheckBox.text}
        </PFCheckbox>
      </View>
    )
  }

  const isChecked = checkBox.checked ?? isInternalCheck

  const isExtraCheckBoxChecked = extraCheckBox?.checked ?? isInternalExtraCheckboxChecked

  const isPrimaryButtonDisabled = !isChecked || (extraCheckBox && !isExtraCheckBoxChecked)

  return (
    <View style={styles.contentView}>
      <View style={extraCheckBox ? styles.checkboxViewDouble : styles.checkboxView}>
        {renderCheckbox({
          selectedCheckBox: checkBox,
          isChecked,
          handleOnPress: onPress,
          testID: checkBox.testID,
        })}
        {extraCheckBox &&
          renderCheckbox({
            selectedCheckBox: extraCheckBox,
            isChecked: isExtraCheckBoxChecked,
            handleOnPress: onPressExtraCheckBox,
            testID: extraCheckBox.testID,
          })}
      </View>
      <View style={styles.bottomPrimaryButtonView}>
        {primaryButton(props, isPrimaryButtonDisabled)}
      </View>
    </View>
  )
}

const ButtonLockup: React.FC<ButtonLockupProps> = (props) => {
  const {type: buttonLockupType} = props
  return (
    <View style={styles.containerView}>
      {buttonLockupType === 'interstitial' ? (
        <ButtonWithCheckbox {...props} />
      ) : (
        <ButtonOnTop {...props} />
      )}
    </View>
  )
}

export default ButtonLockup

const styles = StyleSheet.create({
  auxiliaryView: {
    marginTop: smallGap,
  },
  bottomPrimaryButtonView: {
    alignItems: 'center',
    marginBottom: smallGap,
  },
  buttonOnTop: {
    alignItems: 'center',
  },
  checkboxView: {
    height: alternativeCheckboxHeight,
    paddingTop: smallGap,
  },
  checkboxViewDouble: {
    height: alternativeCheckboxHeightDouble,
    paddingTop: smallGap,
  },
  containerView: {
    paddingHorizontal: gapSizeMap['large'],
  },
  contentView: {
    alignSelf: 'center',
    maxWidth: LARGE_BUTTON_MAX_WIDTH,
    width: '100%',
  },
  topPrimaryButtonView: {
    alignItems: 'center',
    marginTop: smallGap,
  },
})
