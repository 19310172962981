import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Button from 'src/designSystem/components/atoms/Button/Button'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {NamedColors} from 'src/designSystem/colors'
import {LargeArrowUpRight} from 'src/designSystem/components/atoms/SvgIcon/assets'
import {
  InterstitialList,
  InterstitialListItem,
} from 'src/designSystem/components/molecules/InterstitialList/InterstitialList'
import {largeGap, littleGap, smallGap} from 'src/designSystem/layout'
import {Alert} from 'src/designSystem/components/molecules/Alert/Alert'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, CashAdvanceEvents} from 'src/lib/Analytics/app_events'

export type NextAdvanceTileProps = {
  amount?: number
  testID: string
  onApplyForNextAdvance: () => Promise<void>
}

/**
 * A tile to inform the user that they are prequalified for an advance.
 * Includes an action button to accept the offer.
 * @example <NextAdvanceTile amount={250} onApplyForNextAdvance={() => foo()} testID="NextAdvanceTile" />
 */
export const NextAdvanceTile: React.FC<NextAdvanceTileProps> = ({
  amount,
  testID,
  onApplyForNextAdvance: handleOnApplyForNextAdvance,
}: NextAdvanceTileProps) => {
  const {t} = useTranslation('DashboardNextAdvanceTile')

  usePageViewedAnalytics({
    eventName: CashAdvanceEvents.cash_next_advance_viewed,
    eventCategory: AppEvents.Category.CashAdvance,
    eventArgs: {
      contUWAmount: amount,
      source: 'NextAdvanceTile',
    },
  })

  const commonIllustrationStyle = {
    backgroundColor: NamedColors.WHITESMOKE,
    borderRadius: smallGap,
    width: largeGap,
    height: largeGap,
    alignItems: 'center' as const,
  }

  const commonStepProperties = {
    subtext: t('NoAddedCost'),
    subTextColor: NamedColors.SILVER,
    titleSubTextGap: 'none' as const,
    illustration: LargeArrowUpRight,
    illustrationColor: NamedColors.BLACK,
    illustrationStyle: commonIllustrationStyle,
  }

  const stepsItems: InterstitialListItem[] = [
    {id: 1, title: t('InstantTransfer'), ...commonStepProperties},
    {id: 2, title: t('ReschedulePayments'), ...commonStepProperties},
    {id: 3, title: t('PayInFourInstallments'), ...commonStepProperties},
  ]

  return (
    <Box gap={'small'}>
      <Alert level={'success'} title={t('YourPreviousAdvanceHasBeenPaidOff')} />
      <BasicTile
        padding="medium"
        styles={{paddingHorizontal: 'little'}}
        align="center"
        testID={testID}
      >
        <Box
          padding="tiny"
          align="center"
          radius={'little'}
          background={NamedColors.GOLD}
          marginBottom={littleGap}
        >
          <PFText variant={'label_sm'}>{t('YourNextAdvance')}</PFText>
        </Box>
        <Box align="center">
          <PFText variant={'d2'}>${amount}.00</PFText>
        </Box>
        <Box paddingHorizontal="tiny" marginHorizontal={'tiny'} gap="tiny">
          <Box marginTop={'small'}>
            <InterstitialList items={stepsItems} illustrationSize={'small'} itemGap={'small'} />
          </Box>

          <Box align="center" gap={'small'}>
            <Button
              mode="primary"
              size="large"
              onPress={async () => {
                await handleOnApplyForNextAdvance()
              }}
              width="100%"
              testID="AcceptOfferBtn"
            >
              {t('GetMyAdvance')}
            </Button>
            <PFText variant={'p_sm'} color={NamedColors.SILVER} textAlign={'center'}>
              {t('AdvancesMayUpdateBasedOnLinkedAccounts')}
            </PFText>
          </Box>
        </Box>
      </BasicTile>
    </Box>
  )
}
