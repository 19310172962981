import {Consumer} from '@possible/cassandra'
import React, {useCallback, useEffect, useRef, useState} from 'react'

import {Loading} from 'src/designSystem/components/atoms/Loading/Loading'
import {ContextualizedLogException, ShowException} from 'src/lib/errors'
import {getPlaidLinkTokenInput} from 'src/products/loans/BankOAuth/BankOAuth.utils'

import {isDeviceWeb} from 'src/lib/utils/platform'
import {
  AggregatorPlaidError,
  AggregatorPlaidGQLContainerProps,
  onExitType,
  onSuccessEventType,
  onSuccessType,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid.types'
import AggregatorPlaidErrorPage from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaidErrorPage'
import AggregatorPlaidValidationContainer from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaidValidationContainer'
import {PlaidOAuthRedirectUri} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid.consts'

const AggregatorPlaidGQLContainer: React.FC<AggregatorPlaidGQLContainerProps> = (props) => {
  const {accountId, onSuccess, onExit, onInstitutionSelected, onEarlyExit, ...rest} = props

  const [token, setToken] = useState<string>()

  // we store our most recent success data so that we
  // can send an `onRetry` method to our error tile
  // in the event the token exchange fails
  const mostRecentSuccessData = useRef<onSuccessEventType>()

  const [refetchPlaidLink, {selectedData, error: getPlaidLinkError}] =
    Consumer.hooks.useBankLinkPlaidQuery()

  const [exchangeToken] = Consumer.hooks.useExchangeBankLinkTokenPlaidMutation()

  const [exchangeError, setExchangeError] = useState<Error>()

  const handleRefetchToken = useCallback(() => {
    // this is only here temporarily:
    // it should live in `getPlaidLinkTokenInput`
    // MOVE IT THERE WHEN THE FEATURE FLAG IS REMOVED
    const linkInput = getPlaidLinkTokenInput()
    if (isDeviceWeb()) {
      linkInput.redirectUri = PlaidOAuthRedirectUri
    }

    refetchPlaidLink({
      variables: {
        input: {
          ...linkInput,
          linkedAccountId: accountId,
        },
      },
    })
  }, [accountId, refetchPlaidLink])

  useEffect(() => {
    handleRefetchToken()
  }, [handleRefetchToken])

  // our token has been updated so re-set it
  useEffect(() => {
    if (selectedData?.linkToken) {
      setToken(selectedData.linkToken)
    }
  }, [selectedData?.linkToken])

  const handleExchange = useCallback(async () => {
    try {
      // this shouldn't actually happen
      if (!mostRecentSuccessData.current) {
        throw new AggregatorPlaidError('handleExchange called with no mostRecentSuccessData')
      }

      const {publicToken} = mostRecentSuccessData.current

      const result = await exchangeToken({
        variables: {
          input: {
            publicToken,
          },
        },
      })

      if (result.errors) {
        throw result.errors[0]
      }

      if (result.data?.bankExchangePlaidPublicToken) {
        await onSuccess(mostRecentSuccessData.current)
      } else {
        throw new AggregatorPlaidError('Failed to exchange token')
      }
    } catch (e) {
      ContextualizedLogException('Failed to handle Plaid onSuccess')(e)
      ShowException(e)
      setExchangeError(e as Error)
    }

    // for whatever reason apollo creates a new exchangeToken function
    // after it is called the first time. This causes the entire linking
    // process to start over after an error is encountered. To prevent
    // this we need to ignore `exchangeToken` as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEarlyExit, onSuccess])

  const handleSuccess = useCallback<onSuccessType>(
    async (data) => {
      mostRecentSuccessData.current = data
      await handleExchange()
    },
    [handleExchange],
  )

  const handleExit = useCallback<onExitType>(
    async (data) => {
      onExit(data)
      return Promise.resolve()
    },
    [onExit],
  )

  if (getPlaidLinkError) {
    return <AggregatorPlaidErrorPage onRetry={handleRefetchToken} />
  }

  if (exchangeError) {
    return <AggregatorPlaidErrorPage onRetry={handleExchange} />
  }

  if (!token) {
    return <Loading type="loader0" size="large" />
  }

  return (
    <AggregatorPlaidValidationContainer
      token={token}
      onExit={handleExit}
      onSuccess={handleSuccess}
      onInstitutionSelected={onInstitutionSelected}
      onEarlyExit={onEarlyExit}
      {...rest}
    />
  )
}

export default AggregatorPlaidGQLContainer
