import React, {FC, useState, useEffect, useCallback} from 'react'
import {Image, ImageStyle, StyleSheet} from 'react-native'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {maxBy} from 'lodash'

import {ContextualizedLogException, logErrorAndShowException} from 'src/lib/errors'
import {userInfoUpdate} from 'src/api/actions/user/userActions'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import ActivityIndicator from 'src/products/general/components/atoms/ActivityIndicator/ActivityIndicator'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {formattedPI} from 'src/lib/user/selector'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import HookForm, {FieldVariants} from 'src/designSystem/components/atoms/HookForm/HookForm'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {getHasErrorsOrMissingValues} from 'src/lib/utils/formValidationUtil'
import {defaultActivityIndicatorSize} from 'src/designSystem/guide'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {userIdSelector} from 'src/api/selectors/selectors'
import {getUserDocumentCollection} from '@possible/cassandra/src/user/methods'
import {
  UserDocumentSubType,
  UserDocumentType,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {UserDocQueryDocument} from 'src/cassandra/src/query_types/query_types'
import {StandardIDRatio} from 'src/products/general/components/organisms/CameraPage/CameraPage.consts'
import Log from 'src/lib/loggingUtil'

type NameMatchesIDFormData = {
  firstName: string
  lastName: string
}

type Props = StackScreenProps<MainStackParamList, 'NameMatchesIDConfirm'>

const NameMatchesIDConfirm: FC<Props> = (props) => {
  const {navigation, route} = props

  const {onComplete, setBusy: setParentBusy} = route.params
  const userInfo = usePfSelector(formattedPI)
  const userId = usePfSelector(userIdSelector)

  const dispatch = usePfDispatch()

  const [isBusy, setIsBusy] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)
  const [latestIdPhoto, setLatestIdPhoto] = useState<UserDocQueryDocument>()
  const {t} = useTranslation(['PersonalInformation', 'Common'])

  // we have no control over the typing of the `watch` method

  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm<NameMatchesIDFormData>({
    mode: 'all',
    defaultValues: {
      firstName: userInfo.first_name ?? undefined,
      lastName: userInfo.last_name ?? undefined,
    },
  })

  const refresh = useCallback(async () => {
    setIsBusy(true)
    try {
      if (!userId) {
        return
      }

      const documentType: UserDocumentType = UserDocumentType.Identity
      const docs = await getUserDocumentCollection(documentType)
      if (!docs) {
        throw new Error('Unable to get user document colection')
      }

      const userDocuments = docs?.all

      if (userDocuments) {
        const idPhotos = userDocuments.filter(
          (image) => image.document.subType === UserDocumentSubType.DriversLicenseFront,
        )
        const mostRecentIdPhoto = maxBy(idPhotos, (image) =>
          new Date(image.document.createdAt).valueOf(),
        )
        setLatestIdPhoto(mostRecentIdPhoto)
      }
    } catch (e) {
      if (e instanceof Error) {
        Log.log(`No ID images found: ${e.message}`)
      }
    } finally {
      setIsBusy(false)
    }
  }, [userId])

  useEffect(() => {
    refresh().catch(ContextualizedLogException('Failed to refresh ID photo'))
  }, [refresh])

  const onSubmit = async (data: NameMatchesIDFormData): Promise<void> => {
    try {
      setIsBusy(true)
      setParentBusy(true)
      const {firstName, lastName} = data

      const res = await dispatch(userInfoUpdate({firstName, lastName}))

      if (!res) {
        throw new Error('Failed to submit')
      }

      complete()
    } catch (e) {
      void logErrorAndShowException(e, 'NameMatchesIDConfirm, onSubmit:')
    } finally {
      setIsBusy(false)
      setParentBusy(false)
    }
  }

  const complete = (): void => {
    navigation.goBack()
    onComplete?.()
  }

  const primaryAction = {
    text: t('ResubmitPersonalDetails'),
    onPress: handleSubmit(onSubmit),
    disabled: getHasErrorsOrMissingValues(errors, watch) || !hasChanged || isBusy,
    testID: 'Resubmit-Personal-Details-Btn',
  }

  const formProps = {
    firstName: {
      name: 'firstName',
      field: FieldVariants.TextField,
      rules: {
        required: t('Common:LabelIsRequired', {label: t('Common:FirstName')}),
      },
    },
    lastName: {
      name: 'lastName',
      field: FieldVariants.TextField,
      rules: {
        required: t('Common:LabelIsRequired', {label: t('Common:LastName')}),
      },
    },
  }

  return (
    <GenericNonModalTemplate
      actionBlock={buttonLockupProperties(primaryAction)}
      title={t('ReconfirmYourDetails')}
      description={t('PleaseUpdateYourPersonalDetails')}
      subPage={true}
    >
      <Box fill={true} justify={'between'}>
        <Image
          testID="ID-Image"
          source={{uri: latestIdPhoto?.presignedUrl.url}}
          style={styles.image}
        />
        <HookForm control={control} errors={errors}>
          <PFTextInput
            label={t('Common:FirstName')}
            onFocus={(): void => setHasChanged(true)}
            autoCorrect={false}
            errorOnEmpty={true}
            formProps={formProps.firstName}
            testID="First-Name-Input-URA"
          />
          <PFTextInput
            label={t('Common:LastName')}
            onFocus={(): void => setHasChanged(true)}
            autoCorrect={false}
            errorOnEmpty={true}
            formProps={formProps.lastName}
            testID="Last-Name-Input-URA"
          />
        </HookForm>
        <ActivityIndicator animating={isBusy} size="large" style={styles.loader} />
      </Box>
    </GenericNonModalTemplate>
  )
}

export default NameMatchesIDConfirm

const imageStyle: ImageStyle = {
  resizeMode: 'contain',
  width: '100%',
  aspectRatio: StandardIDRatio,
}
const styles = StyleSheet.create({
  image: imageStyle,
  loader: {
    margin: defaultActivityIndicatorSize,
  },
})
