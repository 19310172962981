import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import React, {useState} from 'react'

import {MainStackPages, MainStackParamList} from 'src/nav/MainStackParamsList'
import {UseBankAddAchPaymentMethodResultPaymentMethod} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/mutations/BankAddAchPaymentMethod/useBankAddAchPaymentMethod'
import {PaymentMethodsAlertModalErrorReason} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'
import {BankRoutingAccountFormData} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountForm.types'
import {
  BankRoutingAndAccountFormPageTemplate,
  BankRoutingAndAccountFormPageTemplateProps,
} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountFormPageTemplate'
import {LoanPayNavigatorStack} from 'src/products/loans/MakePayment/LoanPayNavigatorStack'
import {useBankRoutingAndAccountFormSubmission} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountFormPageContainer.utils'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'

export type BankRoutingAndAccountFormPageContainerProps = Pick<
  BankRoutingAndAccountFormPageTemplateProps,
  'title' | 'description' | 'accountNumberMask'
> & {
  successRouteDestination?: MainStackPages | keyof LoanPayNavigatorStack
  onSuccess?: (args: {
    newAchPaymentMethod: UseBankAddAchPaymentMethodResultPaymentMethod
  }) => void | Promise<void>
  onFailure?: (args: {errorReason: PaymentMethodsAlertModalErrorReason}) => void | Promise<void>
  /**
   * If provided we will create a new AchPaymentMethod associated with an existing linked account (added with plaid).
   * If empty we will create a new AchPaymentMethod that is not associated with a linked account (added without plaid)
   */
  linkedAccountIdToAssociateWith?: string
  onErrorBoundary?: (error: Error) => void | Promise<void>
}
/**
 * Screen to verify an existing bank account's routing + account numbers.
 */
export const BankRoutingAndAccountFormPageContainer: React.FC<
  BankRoutingAndAccountFormPageContainerProps
> = (props: BankRoutingAndAccountFormPageContainerProps) => {
  const {
    title,
    description,
    successRouteDestination,
    linkedAccountIdToAssociateWith = undefined,
    onSuccess,
    onFailure,
    onErrorBoundary,
    accountNumberMask,
  } = props

  const navigation =
    useNavigation<StackNavigationProp<MainStackParamList & LoanPayNavigatorStack>>()

  const {isSubmitting, handleSubmitRoutingAndAccountForm} = useBankRoutingAndAccountFormSubmission()

  const [errorReason, setErrorReason] = useState<PaymentMethodsAlertModalErrorReason | undefined>()

  const handleOnDismissErrorModal = (): void => {
    setErrorReason(undefined)
  }
  const handleOnSubmit = async (data: BankRoutingAccountFormData): Promise<void> => {
    try {
      const {errorReason, achPaymentMethod} = await handleSubmitRoutingAndAccountForm({
        formData: data,
        linkedAccountIdToAssociateWith,
      })

      setErrorReason(errorReason)
      if (errorReason) {
        void onFailure?.({
          errorReason,
        })
        return
      }

      if (achPaymentMethod) {
        // if route props onSuccess we use that. we should prefer a successRouteDestination
        // if possible to avoid passing functions as route params which aren't serializable and
        // don't play nicely with web
        void onSuccess?.({
          newAchPaymentMethod: achPaymentMethod,
        })
        if (successRouteDestination) {
          // if success route is provided we will navigate there
          // @ts-expect-error UPDATE NOTE: we need to reduce the number of potential routes this
          // type is capable of representing
          navigation.navigate(successRouteDestination, {
            newSelectedPaymentInstrumentId: achPaymentMethod?.id ?? null,
          })
        }
      }
    } catch (e) {
      setErrorReason('GENERIC_ERROR')
      void onFailure?.({
        errorReason: 'GENERIC_ERROR',
      })
      logAddPaymentMethodError(
        e,
        `BankRoutingAndAccountFormPageContainer handleSubmitRoutingAndAccountForm() failed`,
      )
    }
  }
  const handleOnErrorBoundary = (error: Error): void => {
    logAddPaymentMethodError(
      error,
      'BankRoutingAndAccountFormPageContainer rendering failed and was caught by ErrorBoundary',
    )
    void onErrorBoundary?.(error)
  }

  return (
    <BankRoutingAndAccountFormPageTemplate
      title={title}
      description={description}
      onSubmit={(data: BankRoutingAccountFormData): void => {
        void handleOnSubmit(data)
      }}
      errorModalReason={errorReason}
      onDismissErrorModal={handleOnDismissErrorModal}
      onErrorBoundary={handleOnErrorBoundary}
      isSubmitting={isSubmitting}
      accountNumberMask={accountNumberMask}
    />
  )
}
