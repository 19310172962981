import {CommonActions, StackActions, DrawerActions} from '@react-navigation/native'

import Log from 'src/lib/loggingUtil'
import {MainStackNavigationProp, MainStackParamList} from 'src/nav/MainStackParamsList'
import {rootNavigationRef} from 'src/nav/RootStackNavigation'

export type MainStackPushArgs = Parameters<MainStackNavigationProp['push']>

function pushToMainStack(...args: MainStackPushArgs) {
  // TODO: don't know why this errors...
  // @ts-expect-error
  rootNavigationRef.current?.navigate('MainStack', {screen: args[0], params: args?.[1]})
}

function popToTop(navigation: MainStackNavigationProp) {
  navigation.popToTop()
}

function PopToName(navigation: MainStackNavigationProp, popToName: string) {
  const state = navigation.getState()
  const routes = [...state.routes].reverse()
  const popCount = routes.findIndex((route) => route.name === popToName)
  if (popCount === -1) {
    Log.warn('could not find route to pop to.')
    return
  }
  navigation.pop(popCount)
}

function pop(navigation: MainStackNavigationProp) {
  navigation.pop()
}

function push(navigation: MainStackNavigationProp, ...args: MainStackPushArgs) {
  if (!navigation) {
    Log.warn('AppNavActions.push called with navigation undefined')
  }

  if (!navigation?.push) {
    navigation?.navigate?.(args[0], args?.[1])
  } else {
    navigation?.push?.(args[0], args?.[1])
  }
}

function replace(
  navigation: MainStackNavigationProp,
  key: keyof MainStackParamList,
  params?: Record<string, unknown>,
) {
  if (!navigation) {
    Log.warn('AppNavActions.replace called with navigation undefined')
  }
  navigation?.replace(key, params)
}

function toggleDrawer() {
  rootNavigationRef.current?.dispatch(DrawerActions.toggleDrawer())
}

function openDrawer() {
  rootNavigationRef.current?.dispatch(DrawerActions.openDrawer())
}

function closeDrawer() {
  rootNavigationRef.current?.dispatch(DrawerActions.closeDrawer())
}

function newModal(screen: string, params) {
  const pushAction = StackActions.push('ModalStack', {
    screen,
    params: {isModal: true, ...params},
  })

  rootNavigationRef.current?.dispatch(pushAction)
}

function dismissModal(key: string) {
  if (!key) {
    Log.debug('dismissModal key is not set')
    return
  }

  const state = rootNavigationRef.current?.getRootState()
  if (!state) return

  const routes = state.routes.filter((r) => r.state?.key !== key)

  const action = CommonActions.reset({
    ...state,
    routes,
    index: routes.length - 1,
  })

  rootNavigationRef.current?.dispatch(action)
}

function resetMainNavigation(routeStack: {routeName: string; params?: Record<string, unknown>}[]) {
  if (rootNavigationRef.current?.getRootState() !== undefined) {
    const routes: {name: string; params?: Record<string, unknown>}[] = []

    routeStack.forEach(({routeName, params}) => routes.push({name: routeName, params}))

    const resetAction = CommonActions.reset({
      index: routes.length - 1,
      key: 'MainStack',
      routes,
    })

    rootNavigationRef.current?.dispatch(resetAction)
  } else {
    Log.log('Navigating too early')
  }
}

function isInModalStack(navigation: MainStackNavigationProp): boolean {
  const state = navigation.getState()
  return !!state.routes?.[0]?.params?.isModal
}

export default {
  toggleDrawer,
  openDrawer,
  closeDrawer,
  newModal,
  dismissModal,
  resetMainNavigation,
  pushToMainStack,
  push,
  pop,
  PopToName,
  popToTop,
  isInModalStack,
  replace,
}
