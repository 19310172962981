import React from 'react'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {formatDate, friendlyDateWithDOW} from 'src/lib/utils/date'
import {UnlinkedBankPaymentMethodLink} from 'src/products/general/GeneralPaymentMethods/PaymentMethodLink/UnlinkedBankPaymentMethodLink'
import {PaymentMethodLink} from 'src/products/general/GeneralPaymentMethods/PaymentMethodLink/PaymentMethodLink'
import {ActionListItem} from 'src/products/general/components/molecules/ActionListItem/ActionListItem'
import {DefaultVariantsColor, NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {PFStatusPillProps} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {PaymentMethodLinkSubsets} from 'src/products/general/GeneralPaymentMethods/PaymentMethodLink/PaymentMethodLink.types'

export type MakePaymentConfirmTemplateProps = {
  totalOwed?: string
  isLoading: boolean
  isSubmittingPayment: boolean
  onPressPaymentAmount: () => void
  onPressSubmitPayment: () => Promise<void>
  onPressViewPaymentMethods: () => void
  overrideTitle?: string
  paymentAmount: string
  selectedButtonLabel?: string
  selectedPaymentMethod?: PaymentMethodLinkSubsets
  infoCapsuleElement?: React.ReactElement
  paymentMethodLinkAnalyticEvent: () => void
}

const MakePaymentConfirmTemplate: React.FC<MakePaymentConfirmTemplateProps> = (
  props,
): JSX.Element => {
  const {
    totalOwed,
    isLoading,
    isSubmittingPayment,
    onPressPaymentAmount: handleOnPressPaymentAmount,
    onPressSubmitPayment: handleOnPressSubmitPayment,
    onPressViewPaymentMethods: handleOnPressViewPaymentMethods,
    overrideTitle,
    paymentAmount,
    selectedButtonLabel,
    selectedPaymentMethod,
    infoCapsuleElement,
    paymentMethodLinkAnalyticEvent,
  } = props

  const {t} = useTranslation('MakePayment')

  const getPrimaryButtonText = (): string => {
    let buttonText = t('PayNow')

    if (!selectedPaymentMethod) {
      buttonText = t('AddAPaymentMethod')
    }
    return buttonText
  }

  const editStatusPill: PFStatusPillProps = {
    text: 'Edit',
    color: NamedColors.ASH,
    fontColor: DefaultVariantsColor.textPrimary,
    shape: 'rounded',
    icon: {
      name: 'arrowRight',
      colorVariant: 'default',
    },
  }

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: getPrimaryButtonText(),
          onPress: selectedPaymentMethod
            ? handleOnPressSubmitPayment
            : handleOnPressViewPaymentMethods,
          disabled: Number(totalOwed) <= 0 || isLoading || isSubmittingPayment,
          loading: isLoading || isSubmittingPayment,
          testID: 'make-payment-review-on-submit',
        },
      }}
      variant={'generic'}
      title={overrideTitle ?? t('ConfirmPayment')}
      banner={(): null => null}
      smallTopGap
      noHorizontalPadding
      noHeaderSpacer
    >
      <ActionListItem
        isDisabled={isLoading}
        isPadded
        onPress={(): void => handleOnPressPaymentAmount()}
        rightStatusPill={editStatusPill}
        subTitle={selectedButtonLabel ?? ''}
        testID={'make-payment-review-change-amount-btn'}
        title={convertToDollarAmt(paymentAmount)}
      />
      {selectedPaymentMethod ? (
        <PaymentMethodLink
          isDisabled={isLoading}
          isFirstChild={false}
          isPadded
          onPressViewPaymentMethods={handleOnPressViewPaymentMethods}
          paymentMethod={selectedPaymentMethod}
          rightStatusPill={editStatusPill}
          testID={'make-payment-view-payment-methods'}
          analyticEvent={paymentMethodLinkAnalyticEvent}
        />
      ) : (
        <UnlinkedBankPaymentMethodLink
          isFirstChild={false}
          isPadded
          onPress={handleOnPressViewPaymentMethods}
          rightStatusPill={editStatusPill}
          testID={'make-payment-unlinked-bank'}
        />
      )}

      <ActionListItem
        subTitle={t('SoonestDate')}
        title={formatDate(moment().add(30, 'm'), friendlyDateWithDOW)}
        isPadded
        testID="Make-Payment-Soonest-Date"
        moreInfoText={t('ToUpdateFuturePaymentDates')}
      />

      <Box gap={'medium'} paddingHorizontal={'medium'} paddingTop={'medium'} grow>
        {infoCapsuleElement}
        <Box flex={1} justify={'end'}>
          <PFText variant="p_sm" color={NamedColors.SILVER}>
            {t('WhenYouClick')}
          </PFText>
        </Box>
      </Box>
    </Page>
  )
}

export {MakePaymentConfirmTemplate}
