import React, {Component} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'

import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {logErrorAndShowException} from 'src/lib/errors'
import {PushPage} from 'src/navigation/NavHelper'
import withNav, {InjectedNavProps} from 'src/nav/withNav'
import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import {completeUra} from 'src/cassandra'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'

type State = {
  busy: boolean
}

class URAVerifyNameMatchesID extends Component<
  PropsFromRedux & URAProps & InjectedNavProps & WithTranslation,
  State
> {
  constructor(props) {
    super(props)

    this.state = {
      busy: false,
    }
  }

  onContinue() {
    PushPage(this.props.navigation, 'NameMatchesIDConfirm', {
      onComplete: () => this.onComplete(),
      setBusy: (value) => this.setState({busy: value}),
    })
  }

  async onComplete() {
    const {close, ura_id} = this.props
    this.setState({busy: true})
    try {
      await completeUra(ura_id)

      close()
    } catch (e) {
      void logErrorAndShowException(e, 'URAVerifyNameMatchesID, onComplete:')
    } finally {
      this.setState({busy: false})
    }
  }

  render() {
    const {ura, ura_id, close, t} = this.props
    const primary = {
      text: t('Continue'),
      disabled: this.state.busy,
      onPress: () => this.onContinue(),
    }
    return <URAModalContent uraId={ura_id} ura={ura} primary={primary} close={close} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ura: get_ura_by_id_from_redux(ownProps.ura_id, state),
  }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withTranslation('Common')(withNav(URAVerifyNameMatchesID)))
