import {Dispatch, SetStateAction} from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {MainStackNavigationProp} from 'src/nav/MainStackParamsList'
import {logErrorAndShowException} from 'src/lib/errors'
import {PushPage} from 'src/navigation/NavHelper'
import {AppEvent} from 'src/lib/Analytics/app_events'
import AppNavActions from 'src/nav/AppNavActions'
import {UserVerifyEmailSendCodeMutation} from '@possible/cassandra/src/user/authPrivateMethods'
import {ModifyEmailSendCodeMedium} from '@possible/cassandra/src/types/types.mobile.generated'

export type BusyHook = Dispatch<SetStateAction<boolean>>

export const onSendEmailVerificationCode = async (
  event: AppEvent,
  onSuccess: (navigation: MainStackNavigationProp) => void,
  navigation: MainStackNavigationProp,
  busyHook?: BusyHook,
  editEmail = false,
  // eslint-disable-next-line max-params
): Promise<void> => {
  TrackAppEvent(event.name, event.category)
  busyHook?.(true)
  try {
    const isSuccess: boolean | undefined = await UserVerifyEmailSendCodeMutation({
      verificationCodeMedium: ModifyEmailSendCodeMedium.EmailCode,
    })
    if (!isSuccess) {
      throw Error('Could not send email code verification')
    }
  } catch (e) {
    void logErrorAndShowException(e, 'Error on onSendEmailVerificationCode')
  } finally {
    //navigate to the screen with code input even if there is an error because they can use the Resend button there
    PushPage(navigation, 'EmailVerification', {onSuccess, editEmail})
    busyHook?.(false)
  }
}

export const onEditEmailVerificationSuccess = (navigation: MainStackNavigationProp): void => {
  //when we go through email verification after editing the email we should return to the contact info screen
  AppNavActions.PopToName(navigation, 'Profile')
}
