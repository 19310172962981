import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {ButtonAction} from 'src/designSystem/types'

export enum LinkAccountTileVariant {
  Unlinked = 'unlinked',
  InsufficientBalance = 'insufficientBalance',
  NoIncomeDetected = 'noIncomeDetected',
}

export type LinkAccountTileProps = {
  handleOnLinkAccount: ButtonAction
  variant: LinkAccountTileVariant
  testID: string
  isAdvance: boolean
}

type TileContent = {
  title: string
  message: string
}

const advanceBalanceAmount = '-$250' //TODO: get this value from the backend

/**
 * A component that displays a tile for linking bank accounts with different states and actions.
 * It provides a button to link a bank account The content varies based on the variant prop
 * and whether it's for an advance or loan product.
 *
 * @param props - Component props
 * @param props.handleOnLinkAccount - Function called when user clicks to link an account
 * @param props.variant - Determines the content and behavior of the tile
 * @param props.testID - Test ID for component testing
 * @param props.isAdvance - Flag to determine if this is for an advance product
 */
export const LinkAccountTile: React.FC<LinkAccountTileProps> = ({
  handleOnLinkAccount,
  variant = LinkAccountTileVariant.Unlinked,
  testID,
  isAdvance,
}) => {
  const {t} = useTranslation('LinkAccountTile')

  const {title, message} = useMemo((): TileContent => {
    const product = isAdvance ? 'advance' : 'loan'
    const tileContent: TileContent = {title: '', message: ''}

    switch (variant) {
      case LinkAccountTileVariant.Unlinked:
        tileContent.title = t('LinkAccountTitle', {product})
        tileContent.message = t('UnlinkedAccountMessage', {product})
        break
      case LinkAccountTileVariant.InsufficientBalance:
        tileContent.title = t('LinkNewAccountTitle', {product})
        tileContent.message = t('InsufficientBalanceMessage', {
          product,
          amount: advanceBalanceAmount,
        })
        break
      case LinkAccountTileVariant.NoIncomeDetected:
        tileContent.title = t('LinkNewIncomeTitle', {product})
        tileContent.message = t('NoIncomeDetectedMessage', {product})
        break
    }

    return tileContent
  }, [variant, isAdvance, t])

  return (
    <BasicTile padding="medium" align="center" testID={testID}>
      <Box width="auto" gap="medium">
        <PFText variant="h3">{title}</PFText>
        <PFText variant="p">{message}</PFText>

        <Box gap="small">
          <Button
            mode="primary"
            size="medium"
            width="100%"
            onPress={handleOnLinkAccount}
            testID={`${testID}-LinkAccountBtn`}
          >
            {t('LinkNewAccountButton')}
          </Button>
        </Box>
      </Box>
    </BasicTile>
  )
}
