import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, AppEventCategory} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

import {UpgradeToInstallmentPlanGQLContainer} from 'src/products/loans/UpgradeToInstallmentPlan/UpgradeToInstallmentPlanGQLContainer'

type UpgradeToInstallmentPlanNavPageStateContainerProps = StackScreenProps<
  MainStackParamList,
  'UpgradeToInstallment'
>

export const UpgradeToInstallmentPlanNavPageStateContainer: React.FC<
  UpgradeToInstallmentPlanNavPageStateContainerProps
> = (props) => {
  const {route, navigation} = props
  const {isFromNavPageStateAcceptance, isFromDashboard, isFromWorkflowsActivation} = route.params

  // I know this is gross. we will rip this apart when we get rid of NavPageState
  const navContainerType = isFromWorkflowsActivation ? 'Workflows' : 'NavPageState'
  usePageViewedAnalytics({
    eventName: AppEvents.Name.installment_upgrade_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
    eventArgs: {
      navContainerType,
    },
  })

  const handleOnConvertLoanSuccess = async (): Promise<void> => {
    let eventCategory: AppEventCategory = AppEvents.Category.Activation
    if (isFromNavPageStateAcceptance || isFromWorkflowsActivation) {
      eventCategory = AppEvents.Category.Activation
    } else if (isFromDashboard) {
      eventCategory = AppEvents.Category.ManageActiveLoan
    }
    TrackAppEvent(AppEvents.Name.installment_upgrade_accepted, eventCategory, {
      navContainerType,
    })
    await goToNextPage()
  }

  const goToNextPage = async (): Promise<void> => {
    if (isFromWorkflowsActivation || isFromNavPageStateAcceptance) {
      navigation.navigate('Dashboard', {
        showMoneyIsOnTheWayMessage: true,
      })
    } else if (isFromDashboard) {
      navigation.pop()
    }
  }

  const handleOnDeclineConversion = async (): Promise<void> => {
    await goToNextPage()
  }

  const handleOnOpenDeclineModal = (): void => {
    TrackAppEvent(AppEvents.Name.installment_upgrade_declined, AppEvents.Category.Activation, {
      navContainerType,
    })
  }

  const handleOnDismissDeclineModal = (): void => {
    TrackAppEvent(
      AppEvents.Name.installment_upgrade_declined_modal_back,
      AppEvents.Category.Activation,
      {
        navContainerType,
      },
    )
  }

  return (
    <UpgradeToInstallmentPlanGQLContainer
      onConvertLoanSuccess={handleOnConvertLoanSuccess}
      onDecline={handleOnDeclineConversion}
      onOpenDeclineModal={handleOnOpenDeclineModal}
      onDismissDeclineModal={handleOnDismissDeclineModal}
    />
  )
}
