import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'
import {useSelector} from 'react-redux'

import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import {completeUra} from 'src/cassandra'
import {logErrorAndShowException} from 'src/lib/errors'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'
import {PfReduxState} from 'src/reducers/types'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {AchAccountNumberAccountsDocument} from 'src/products/general/UserRequestedActions/AchAccountNumber/AchAccountNumber.gqls'

type Props = URAProps & {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}

const AchAccountNumber: React.FunctionComponent<Props> = (props: Props) => {
  const {ura_id, close, navigation} = props
  const [isCompleting, setIsCompleting] = useState(false)
  const {t} = useTranslation('UserRequestedAction')

  const {data} = useCassandraQuery(AchAccountNumberAccountsDocument)

  const ura = useSelector((state: PfReduxState) => get_ura_by_id_from_redux(ura_id, state))
  const onComplete = async (): Promise<void> => {
    try {
      navigation.pop()
      setIsCompleting(true)
      await completeUra(ura_id)
      close()
    } catch (e) {
      void logErrorAndShowException(e, 'AchAccountNumber, onComplete:')
    }
  }

  const onCollectBankInfo = (): void => {
    const uraAccountId: string | undefined = ura?.actionData.accountId ?? undefined

    const selectedAccount = data?.me.bankAccounts.all?.find(
      (acct) => acct.id === ura?.actionData.accountId,
    )
    const mask = selectedAccount?.mask ?? ''

    if (!selectedAccount || !uraAccountId) {
      void logErrorAndShowException(
        `AchAccountNumber URA matching account not found, accountId=${uraAccountId}`,
      )
      return
    }

    navigation.push('BankVerifyRoutingAndAccount', {
      linkedAccountIdToAssociateWith: selectedAccount.id,
      accountNumberMask: mask,
      onSuccess: () => void onComplete(),
    })
  }

  const primary = {
    text: t('EnterBankDetails'),
    disabled: isCompleting,
    onPress: onCollectBankInfo,
  }

  return <URAModalContent uraId={ura_id} ura={ura} primary={primary} close={close} />
}

export {AchAccountNumber}
