import React, {useCallback, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Button from 'src/designSystem/components/atoms/Button/Button'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {NamedColors} from 'src/designSystem/colors'
import {LargeArrowUpRight} from 'src/designSystem/components/atoms/SvgIcon/assets'
import {
  InterstitialList,
  InterstitialListItem,
} from 'src/designSystem/components/molecules/InterstitialList/InterstitialList'
import {largeGap, littleGap, smallGap} from 'src/designSystem/layout'
import {BottomSheet} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'
import {SubscriptionLegalize} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptSubscriptionAgreement/SubscriptionLegalize'
import {Alert} from 'src/designSystem/components/molecules/Alert/Alert'

export type MembershipApprovedTileProps = {
  amount: number
  testID?: string
  loanId: string
  onAcceptAutoPay: () => Promise<void>
}

/**
 * A tile to inform the user that their membership has been approved.
 * Includes an action button to accept the offer.
 * @example <MembershipApprovedTile amount={250} onAcceptOffer={() => foo()} />
 */
export const MembershipApprovedTile: React.FC<MembershipApprovedTileProps> = ({
  amount,
  testID,
  loanId,
  onAcceptAutoPay: handleOnAcceptAutoPay,
}: MembershipApprovedTileProps) => {
  const {t} = useTranslation('DashboardMembershipApprovedTile')
  const [shouldViewAgreementBottomSheet, setShouldViewAgreementBottomSheet] =
    useState<boolean>(false)
  const [isSelected, setIsSelected] = useState<boolean>(false)

  const handleSetSelected = useCallback((value: boolean) => {
    setIsSelected(value)
  }, [])

  const stepsItems: InterstitialListItem[] = [
    {
      subtext: t('TransferYourPossibleFinanceToActivate'),
      illustration: LargeArrowUpRight,
      illustrationColor: NamedColors.BLACK,
      id: 1,
      illustrationStyle: {
        backgroundColor: NamedColors.WHITESMOKE,
        borderRadius: smallGap,
        width: largeGap,
        height: largeGap,
        alignItems: 'center',
      },
    },
    {
      subtext: t('MembershipDueMonthly'),
      illustration: LargeArrowUpRight,
      illustrationColor: NamedColors.BLACK,
      id: 2,
      illustrationStyle: {
        backgroundColor: NamedColors.WHITESMOKE,
        borderRadius: smallGap,
        width: largeGap,
        height: largeGap,
        alignItems: 'center',
      },
    },
    {
      subtext: t('CancelAnytime'),
      illustration: LargeArrowUpRight,
      illustrationColor: NamedColors.BLACK,
      id: 3,
      illustrationStyle: {
        backgroundColor: NamedColors.WHITESMOKE,
        borderRadius: smallGap,
        width: largeGap,
        height: largeGap,
        alignItems: 'center',
      },
    },
  ]

  return (
    <Box gap={'small'}>
      <Alert level={'success'} title={t('YouAreEligibleForAMembership')} />
      <BasicTile
        padding="medium"
        styles={{paddingHorizontal: 'little'}}
        align="center"
        testID={testID}
      >
        <Box
          padding="tiny"
          align="center"
          radius={'little'}
          background={NamedColors.GOLD}
          marginBottom={littleGap}
        >
          <PFText testID="First-Advance-Label" variant={'label_sm'}>
            {t('YourFirstAdvance')}
          </PFText>
        </Box>
        <Box align="center">
          <PFText testID="First-Advance-Amount" variant={'d2'}>
            ${amount}.00
          </PFText>
          <PFText testID="Pay-In-Four-Installments" variant={'p'} color={NamedColors.SILVER}>
            {t('PayInFourInstallments')}
          </PFText>
        </Box>
        <Box width="auto" gap="medium">
          <Box
            testID="Advance-Steps-List"
            boxStyle={{borderBottomColor: NamedColors.ASH, borderBottomWidth: 1}}
            marginTop={'small'}
          >
            <InterstitialList items={stepsItems} illustrationSize={'small'} />
          </Box>
          <Box align={'center'} gap={'little'}>
            <PFText
              testID="Membership-Stress-Free"
              variant={'p_lg_semibold'}
              color={NamedColors.PRODUCT_BLUE}
            >
              {t('MembershipStressFree')}
            </PFText>
            <PFText testID="Pay-Fees-Without-Advance" variant={'p'} textAlign={'center'}>
              <Trans
                t={t}
                i18nKey="PayMultipleMembershipFeesWithoutAdvance"
                components={{bold: <PFText variant="p_semibold" />}}
              />
            </PFText>
          </Box>
          <Box align="center" gap={'small'}>
            <Button
              mode="primary"
              size="large"
              onPress={() => setShouldViewAgreementBottomSheet(true)}
              width="100%"
              testID="AcceptOfferBtn"
            >
              {t('BeginActivation')}
            </Button>
            <PFText variant={'p_sm'} color={NamedColors.SILVER}>
              {t('ExpiresIn')}
            </PFText>
          </Box>
        </Box>
      </BasicTile>
      <BottomSheet
        visible={shouldViewAgreementBottomSheet}
        onDismiss={async () => {
          await handleOnAcceptAutoPay()
          setShouldViewAgreementBottomSheet(false)
        }}
        dismissButton={{
          text: t('AcceptAndContinue'),
          testID: 'Membership-Approved-Tile-Accept-Agreement-Button',
          disabled: !isSelected,
        }}
        showCloseButton
        closeButtonAction={() => setShouldViewAgreementBottomSheet(false)}
        title={t('AcceptMembershipAgreement')}
        titleVariant={'h3'}
        titleAlign={'left'}
        showDots={false}
        testID="Accept-Membership-Agreement"
      >
        <SubscriptionLegalize
          onSetSelected={handleSetSelected}
          loanId={loanId}
          onCloseBottomSheet={() => setShouldViewAgreementBottomSheet(false)}
        />
      </BottomSheet>
    </Box>
  )
}
