import React, {FC, useCallback, useEffect, useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {useFocusEffect} from '@react-navigation/native'

import {
  PhoneNumberVerifiedDocument,
  ValidatePhoneNumberDocument,
} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerification.gqls'
import {CheckIsPlaidLayerAvailableDocument} from 'src/products/MCU/PhoneNumberInputVerification/queries/CheckIsPlaidLayerAvailable.gqls'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {Consumer} from '@possible/cassandra'
import {GetDisablePlaidLayer} from 'src/lib/LaunchArgs/DisablePlaidLayer'
import {isValidGraphQLError} from 'src/lib/errors/errors.types'
import {logPlaidLayerError} from 'src/products/MCU/PlaidLayers/PlaidLayers.utils'
import {PhoneNumberAlreadyVerifiedTemplate} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberVerifiedTemplate'
import {PhoneNumberInputVerificationTemplate} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerificationTemplate'
import {PhoneNumberValidationResponseCode} from '@possible/cassandra/src/types/types.mobile.generated'
import {AccountDeliveryMedium} from '@possible/cassandra/src/types/types.public.generated'
import {logErrorAndShowException} from 'src/lib/errors'
import {TrackAppEvent, VerifyPhoneEvent} from 'src/lib/Analytics/analytics_compat'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {useCassandraLazyQuery, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {usePhoneNumberInputVerificationNavigation} from 'src/products/MCU/PhoneNumberInputVerification/usePhoneNumberInputVerificationNavigation'
import {usePlaidLayersContext} from 'src/products/MCU/PlaidLayers/PlaidLayersContext'
import {WorkflowPreReqFulfillScreenProps, WorkflowsStackParams} from 'src/workflows/types'

export type PhoneNumberInputContainerProps = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<WorkflowsStackParams, 'PhoneConfirmation'>

const PhoneNumberInputVerificationContainer: FC<PhoneNumberInputContainerProps> = (props) => {
  const {navigation, route} = props
  const hasOptedOutOfPlaidLayer = route.params?.optedOutOfPlaidLayer ?? false
  const navigateAfterVerification = usePhoneNumberInputVerificationNavigation(navigation)
  const {t} = useTranslation(['PhoneConfirmation', 'PhoneNumberEntry', 'Common'])
  const [isSendingVerificationCode, setIsSendingVerificationCode] = useState(false)
  const [isDuplicatePhoneNumber, setIsDuplicatePhoneNumber] = useState(false)
  const [shouldShowVerificationCode, setShouldShowVerificationCode] = useState<boolean>(false)
  const [hasPhoneVerified, setHasPhoneVerified] = useState<boolean | undefined>(false)
  const {canAccessCards} = useCanAccessCards()
  /* 
  we use this state to check if the layer session has been created, 
  if the session is not created the user will keep moving with regular onboarding
  */
  const [hasLayerSessionCreated, setHasLayerSessionCreated] = useState(false)

  const [checkIsPhoneVerified] = useCassandraLazyQuery(PhoneNumberVerifiedDocument, {
    fetchPolicy: 'network-only',
  })

  const [validatePhoneNumber, {loading: isValidatingPhoneNumber}] = useCassandraLazyQuery(
    ValidatePhoneNumberDocument,
    {
      fetchPolicy: 'network-only',
    },
    (data) => data?.validatePhoneNumber?.phoneNumberValidationResponseCode,
  )

  const checkIfPhoneIsVerified = useCallback(async (): Promise<void> => {
    const response = await checkIsPhoneVerified()

    if (response) {
      setHasPhoneVerified(response?.data?.me?.profile?.phone?.verified)
    }
  }, [checkIsPhoneVerified])

  useFocusEffect(
    useCallback(() => {
      void checkIfPhoneIsVerified()
    }, [checkIfPhoneIsVerified]),
  )

  usePageViewedAnalytics({
    eventName: AppEvents.Name.enter_phone_number_screen_viewed,
    eventCategory: AppEvents.Category.Onboarding,
  })

  const [sendVerificationCode] = Consumer.hooks.usePhoneVerificationCodeSendMutation()
  const [validateCode] = Consumer.hooks.usePhoneVerificationCodeValidationMutation()

  const {isLoadingPlaidData, createLayerSession, checkIfEligibleForPlaidLayers} =
    usePlaidLayersContext()

  // Check layer is available based on coastal and non-costal states
  const {selectedData: isLayerAvailable, loading: isLoadingLayerAvailable} = useCassandraQuery(
    CheckIsPlaidLayerAvailableDocument,
    {
      fetchPolicy: 'network-only',
      onError: (error) => {
        logPlaidLayerError(error, 'Failed to check if Plaid Layer is available')
      },
    },
    (data) => data?.checkOneClickOnboardingAvailability?.isAvailable,
  )

  useEffect(() => {
    if (hasOptedOutOfPlaidLayer) {
      TrackAppEvent(AppEvents.Name.plaid_layer_opt_out, AppEvents.Category.PlaidSDK)
    }
  }, [hasOptedOutOfPlaidLayer])

  useEffect(() => {
    const shouldDisablePlaidLayer = GetDisablePlaidLayer()

    // Do not use Layer if it's disabled, already created, or unavailable
    if (
      shouldDisablePlaidLayer ||
      hasLayerSessionCreated ||
      isLoadingLayerAvailable ||
      !isLayerAvailable
    ) {
      return
    }

    createLayerSession()
      .then(() => setHasLayerSessionCreated(true))
      .catch((e) => {
        logPlaidLayerError(e, 'Failed to create Plaid Layer session')
      })
  }, [createLayerSession, hasLayerSessionCreated, isLayerAvailable, isLoadingLayerAvailable])

  const handleOptBackIntoPlaidLayers = (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.plaid_layer_opt_back_in, AppEvents.Category.PlaidSDK)

    return createLayerSession()
      .then(() => {
        setHasLayerSessionCreated(true)
        navigation.setParams({optedOutOfPlaidLayer: false})
      })
      .catch((e) => {
        logPlaidLayerError(e, 'Failed to create Plaid Layer session')
      })
  }

  const handleVerifiedSuccessfully = useCallback((): void => {
    navigateAfterVerification()
  }, [navigateAfterVerification])

  const handleOnCellPhoneNumberReady = async (phoneNumberFormatted: string): Promise<void> => {
    TrackAppEvent(AppEvents.Name.enter_phone_number_completed, AppEvents.Category.Onboarding)

    /* this is a server error used to check the call status, it's not displayed to the user
     *  most likely this will always be in English*/
    const duplicateNumberError = 'Phone number already exists'

    try {
      const phoneValidationStatus = (
        await validatePhoneNumber({variables: {phoneNumber: phoneNumberFormatted}})
      ).selectedData

      if (phoneValidationStatus === PhoneNumberValidationResponseCode.InUse) {
        setIsDuplicatePhoneNumber(true)
        return
      }
      /*
      we verify here if the layer session has been created first before we can check eligibility api,
      if there is any issue creating the layer session the user will move forward with regular onboarding process
      Cards does not use Plaid layers yet
      */
      if (hasLayerSessionCreated && !canAccessCards && !hasOptedOutOfPlaidLayer) {
        const isUserEligibleForOneClickOnboarding =
          await checkIfEligibleForPlaidLayers(phoneNumberFormatted)

        if (isUserEligibleForOneClickOnboarding) {
          navigation.navigate('ApplyInOneClick')
          return
        }
      }

      const response = await sendVerificationCode({
        variables: {authorizationCodeInput: {phoneNumber: phoneNumberFormatted, method: 'SMS'}},
      })
      if (response.errors) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw response.errors[0]
      }
      setShouldShowVerificationCode(true)
      TrackAppEvent(
        AppEvents.Name.enter_verification_code_screen_viewed,
        AppEvents.Category.Onboarding,
      )
    } catch (e) {
      if (isValidGraphQLError(e) && e.message === duplicateNumberError) {
        setIsDuplicatePhoneNumber(true)
      } else {
        void logErrorAndShowException(
          e,
          'PhoneNumberInputVerificationContainer, handleOnCellPhoneNumberReady:',
        )
      }
    } finally {
      setIsSendingVerificationCode(false)
    }
  }

  const handleOnSubmitVerificationCode = async (
    code: string,
    phoneNumber: string,
  ): Promise<void> => {
    try {
      TrackAppEvent(
        AppEvents.Name.enter_verification_code_submitted,
        AppEvents.Category.Onboarding,
        {
          screen: 'phone_number_verification',
        },
      )

      setIsSendingVerificationCode(true)

      const res = await validateCode({
        variables: {
          phoneNumberVerificationInput: {
            phoneNumber,
            code,
          },
        },
      })
      if (res.errors) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw res.errors[0]
      }

      VerifyPhoneEvent()

      handleVerifiedSuccessfully()
    } catch (e) {
      void logErrorAndShowException(
        e,
        'PhoneNumberInputVerificationContainer, handleOnSubmitVerificationCode:',
      )
    } finally {
      setIsSendingVerificationCode(false)
    }
  }

  const handleOnEditPhoneNumber = (): void => {
    navigation.setParams({optedOutOfPlaidLayer: false})
    setHasLayerSessionCreated(false)
    setShouldShowVerificationCode(false)
  }

  const handleMoveToNextStep = (): void => {
    setIsSendingVerificationCode(true)
    handleVerifiedSuccessfully()
    setIsSendingVerificationCode(false)
  }

  const handleClearDuplicatePhoneNumber = (): void => {
    setIsDuplicatePhoneNumber(false)
  }

  const handleResendCode = async (
    method: AccountDeliveryMedium,
    phoneNumber: string,
  ): Promise<void> => {
    TrackAppEvent(
      AppEvents.Name.enter_verification_code_resend_selected,
      AppEvents.Category.Activation,
      {method, screen: 'phone_number_verification'},
    )

    try {
      setIsSendingVerificationCode(true)
      const response = await sendVerificationCode({
        variables: {
          authorizationCodeInput: {
            phoneNumber,
            method,
          },
        },
      })

      if (response.errors) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw response.errors[0]
      }
    } catch (e) {
      void logErrorAndShowException(e, 'PhoneNumberInputVerificationContainer, handleResendCode:')
    } finally {
      setIsSendingVerificationCode(false)
    }
  }

  if (hasPhoneVerified) {
    return (
      <PhoneNumberAlreadyVerifiedTemplate
        onPhoneAlreadyVerified={handleMoveToNextStep}
        isActionDisabled={isSendingVerificationCode}
      />
    )
  }

  /*
    there are a couple of scenarios here that we want to keep the button disabled:
    isSendingVerificationCode = general busy for this screen
    isPlaidLayerActive = true if the layer session has been created and the user has not opted out
    isPlaidLayerLoadingOrIsMissingLinkSessionId = if isPlaidLayerActive is true we need to check for isLoadingPlaidData and need to wait for the linkSessionId
  */
  const isPlaidLayerActive = hasLayerSessionCreated && !hasOptedOutOfPlaidLayer

  const isActionDisabled =
    isSendingVerificationCode ||
    (isPlaidLayerActive ? isLoadingPlaidData : false) ||
    isValidatingPhoneNumber

  return (
    <PhoneNumberInputVerificationTemplate
      testID={'PhoneConfirmationPage'}
      onButtonPress={handleOnCellPhoneNumberReady}
      mainTitle={t('PhoneNumberEntry:EnterYourPhoneNumber')}
      mainBody={t('CellPhoneWillBeUsedForAccountVerification')}
      isDuplicateNumber={isDuplicatePhoneNumber}
      onClearDuplicatePhoneNumber={handleClearDuplicatePhoneNumber}
      isActionDisabled={isActionDisabled}
      shouldShowVerificationCode={shouldShowVerificationCode}
      onSubmitVerificationCode={handleOnSubmitVerificationCode}
      onResendMyCodeAction={handleResendCode}
      onEditPhoneNumber={handleOnEditPhoneNumber}
      navigation={navigation.getParent()}
      hasOptedOutOfPlaidLayer={hasOptedOutOfPlaidLayer}
      onOptBackIntoPlaidLayers={handleOptBackIntoPlaidLayers}
    />
  )
}

export {PhoneNumberInputVerificationContainer}
