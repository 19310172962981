/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {FC, useState} from 'react'
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {ContextualizedLogException, logErrorAndShowException} from 'src/lib/errors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {PFPhoneNumberInput} from 'src/designSystem/components/molecules/PFPhoneNumberInput/PFPhoneNumberInput'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {NamedColors} from 'src/designSystem/colors'
import {
  ButtonLockupPropsPrimary,
  ButtonLockupPropsSecondary,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {useCountdownTimer} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerification.utils'
import {PushPage} from 'src/navigation/NavHelper'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePfDispatch} from 'src/store/utils'
import {Logout} from 'src/api/MobileGatewayAPI/actions/logout'
import {openContactUsForm} from 'src/lib/contactUs'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {AccountDeliveryMedium} from '@possible/cassandra/src/types/types.public.generated'
import {InstantSignupAlert} from 'src/products/MCU/PhoneNumberInputVerification/InstantSignupAlert/InstantSignupAlert'

const CommonContinue = 'Common:Continue'

const phoneUtil = PhoneNumberUtil.getInstance()

export type PhoneNumberInputVerificationTemplateProps = {
  navigation: StackNavigationProp<
    MainStackParamList,
    | 'AccountRecovery'
    | 'AccountRecoveryAuthenticated'
    | 'PasswordRecoveryViaPhoneNumber'
    | 'SignupWorkflow'
  >
  onButtonPress: (phoneNumberFormatted: string, formattedNew: string) => Promise<void>
  isDuplicateNumber?: boolean
  isActionDisabled: boolean
  mainBody: string
  mainTitle: string
  testID?: string
  shouldShowVerificationCode?: boolean
  shouldShowEmailVerification?: boolean
  shouldShowNoPhoneAction?: boolean
  onSubmitVerificationCode?: (code: string, phoneNumber: string) => Promise<void>
  onResendMyCodeAction?: (method: AccountDeliveryMedium, phoneNumber: string) => Promise<void>
  onEditPhoneNumber?: () => void
  onClearDuplicatePhoneNumber?: () => void
  hasOptedOutOfPlaidLayer?: boolean
  onOptBackIntoPlaidLayers?: () => Promise<void>
}

const PhoneNumberInputVerificationTemplate: FC<PhoneNumberInputVerificationTemplateProps> = (
  props,
) => {
  const {
    navigation,
    testID,
    mainTitle,
    mainBody,
    onButtonPress,
    isDuplicateNumber,
    isActionDisabled,
    onEditPhoneNumber,
    shouldShowEmailVerification = false,
    shouldShowVerificationCode,
    onSubmitVerificationCode,
    shouldShowNoPhoneAction,
    onResendMyCodeAction,
    onClearDuplicatePhoneNumber,
    hasOptedOutOfPlaidLayer,
    onOptBackIntoPlaidLayers: handleOnOptBackIntoPlaidLayers,
  } = props
  const {t} = useTranslation('PhoneNumberEntry')
  const [phoneNumberText, setPhoneNumberText] = useState<string>('')
  const [smsCodeText, setSmsCodeText] = useState<string>('')
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false)
  const {isCounting, startTimer, handleResetCodeTimer, localizedText} = useCountdownTimer()
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const dispatch = usePfDispatch()

  let phoneNumberDisplayFormatted = ''

  const verifyPhoneNumberIsValid = (): boolean => {
    let isPhoneNumberValid = false

    if (phoneNumberText === '' || !isPhoneValid) {
      throw new Error(t('PhoneNumberFormatError'))
    }
    try {
      const phoneNumberParsed = phoneUtil.parse(phoneNumberText, 'US')
      if (phoneUtil.isValidNumber(phoneNumberParsed)) {
        /*keeping some consistency on passing the argument to the APIs with E164*/
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setPhoneNumber(phoneUtil.format(phoneNumberParsed, PhoneNumberFormat.E164))
        phoneNumberDisplayFormatted = phoneUtil.format(
          phoneNumberParsed,
          PhoneNumberFormat.INTERNATIONAL,
        )
        isPhoneNumberValid = true
      }
    } catch (e) {
      throw new Error(t('PhoneNumberNotValidError', {phoneNumberText}))
    }
    return isPhoneNumberValid
  }

  const onCellPhoneNumberReady = (): void => {
    try {
      if (verifyPhoneNumberIsValid()) {
        void onButtonPress(phoneNumberDisplayFormatted, phoneNumberText)
      }
    } catch (e) {
      void logErrorAndShowException(
        e,
        'PhoneNumberInputVerificationTemplate, onCellPhoneNumberReady:',
      )
    }
  }

  const submitVerificationCodeAction = (): void => {
    void onSubmitVerificationCode?.(smsCodeText, phoneNumber).then(() => setSmsCodeText(''))
  }
  const submitPhoneAction = (): void => {
    startTimer()
    onCellPhoneNumberReady()
  }

  const handleEditPhoneNumber = (): void => {
    setPhoneNumberText('')
    onEditPhoneNumber?.()
  }

  const handleResendCode = (method: AccountDeliveryMedium, phoneNumber: string): void => {
    handleResetCodeTimer()
    void onResendMyCodeAction?.(method, phoneNumber)
  }

  const handleFormatPhoneNumber = (formattedNumber: string): void => {
    if (isDuplicateNumber) {
      onClearDuplicatePhoneNumber?.()
      setPhoneNumberText(formattedNumber)
    }
    setPhoneNumberText(formattedNumber)
  }

  const handleOnNoPhone = (): void => {
    PushPage(navigation, 'TemporaryPassword')
  }

  const getButtonActions = (): {
    primaryAction: ButtonLockupPropsPrimary
    secondaryAction: ButtonLockupPropsSecondary | undefined
  } => {
    if (isDuplicateNumber) {
      // if the number is a duplicate they need to recover their login info
      return {
        primaryAction: {
          text: t('RecoverMyLoginInfo'),
          onPress: (): void => {
            PushPage(navigation, 'AccountRecoveryAuthenticated', {
              isFromDuplicate: true,
            })
          },
          disabled: isActionDisabled,
        },
        secondaryAction: {
          buttonText: t('UseDifferentLoginMethod'),
          hideIcon: true,
          onPress: (): void => {
            dispatch(Logout(false)).catch(
              ContextualizedLogException('Phone Number Entry screen: Failed to log out'),
            )
          },
          disabled: isActionDisabled,
        },
      }
    }
    if (shouldShowVerificationCode) {
      // if they entered their # and were sent a verification code we let them enter the code
      return {
        primaryAction: {
          onPress: submitVerificationCodeAction,
          text: t(CommonContinue),
          disabled: !smsCodeText || isActionDisabled,
          loading: isActionDisabled,
          testID: 'PhoneNumberEntrySubmitButton',
        },
        secondaryAction: {
          buttonText: localizedText,
          size: 'medium',
          disabled: isCounting,
          hideIcon: true,
          onPress: () => handleResendCode(AccountDeliveryMedium.Sms, phoneNumber),
        },
      }
    }
    // let the user enter their # and submit it
    return {
      primaryAction: {
        onPress: submitPhoneAction,
        text: t(CommonContinue),
        disabled: !isPhoneValid || isDuplicateNumber || isActionDisabled,
        loading: isActionDisabled,
        testID: 'PhoneNumberEntrySubmitButton',
      },
      secondaryAction: undefined,
    }
  }
  const {primaryAction, secondaryAction} = getButtonActions()

  const noPhoneCopy = (
    <Box direction="row" justify="center">
      <PFText variant={'p'} textAlign={'center'}>
        {t('NoPhone')}
      </PFText>
      <SvgLink
        onPress={handleOnNoPhone}
        linkType={'inline'}
        disabled={isActionDisabled}
        testID={'Enter_Temp_Password_Link'}
        linkText={t('EnterTempPassword')}
      />
    </Box>
  )

  const getVerificationCode = shouldShowVerificationCode ? (
    <Box direction="column" gap="small">
      <Box>
        <PFText variant="p_semibold">{t('EnterVerification')}</PFText>
      </Box>
      <Box>
        <PFText variant="p">{t('LookForATextMessage')}</PFText>
      </Box>
      <Box fill="horizontal">
        <PFTextInput
          label={'Verification code'}
          onChangeText={(code: string): void => setSmsCodeText(code)}
          maxLength={4}
          value={smsCodeText}
          testID="Phone-Verification-Code-Input"
        />
      </Box>
      <Box align="center" gap="tiny">
        <PFText variant="p" color={NamedColors.SILVER}>
          {t('TextNotComingThrough')}
        </PFText>
        {shouldShowEmailVerification ? (
          <SvgLink
            linkType="inline"
            textVariant="p_semibold"
            linkIcon="internal"
            onPress={(): void => handleResendCode(AccountDeliveryMedium.Email, phoneNumber)}
            testID={'Get-An-Email-Link'}
            linkText={t('Common:GetCodeViaEmail')}
          />
        ) : null}
        <SvgLink
          linkType="inline"
          textVariant="p_semibold"
          linkIcon="internal"
          onPress={(): void => handleResendCode(AccountDeliveryMedium.Voice, phoneNumber)}
          testID={'Get-A-Phone-Call-Link'}
          linkText={t('Common:GetAPhoneCall')}
        />
        <SvgLink
          linkType="inline"
          textVariant="p_semibold"
          linkIcon="internal"
          onPress={(): void => {
            TrackAppEvent(
              AppEvents.Name.enter_verification_code_contact_us_selected,
              AppEvents.Category.Activation,
            )
            openContactUsForm(navigation)
          }}
          testID={'Contact-Us-Link'}
          linkText={t('Common:ContactUs')}
        />
      </Box>
    </Box>
  ) : null

  return (
    <Page
      variant="generic"
      smallTopGap={true}
      testID={testID}
      buttonProps={buttonLockupProperties(primaryAction, secondaryAction)}
      title={mainTitle}
      description={mainBody}
      enabledKeyboardAvoidingView
    >
      <Box fill={'vertical'} gap="enormous" direction="column">
        <Box gap="small">
          <PFPhoneNumberInput
            testID={'PhoneNumberEntryInput'}
            value={phoneNumberText}
            onGetFormattedPhoneNumber={(formattedNumber): void =>
              handleFormatPhoneNumber(formattedNumber)
            }
            onPhoneNumberIsValid={(isValid): void => setIsPhoneValid(isValid)}
            hideCountryFlag
            placeholder="+1"
            icon={
              shouldShowVerificationCode
                ? {name: 'edit', colorVariant: 'default', size: 'small'}
                : undefined
            }
            onPressIcon={handleEditPhoneNumber}
            isEditable={!shouldShowVerificationCode}
            error={isDuplicateNumber ? t('DuplicatePhoneNumberErrorMessage') : undefined}
          />
          {shouldShowNoPhoneAction && !shouldShowVerificationCode ? noPhoneCopy : undefined}
          {hasOptedOutOfPlaidLayer && !shouldShowVerificationCode ? (
            <InstantSignupAlert onPress={handleOnOptBackIntoPlaidLayers} />
          ) : null}
        </Box>
        <Box>{getVerificationCode}</Box>
      </Box>
    </Page>
  )
}

export {PhoneNumberInputVerificationTemplate}
