/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react-prefer-function-component/react-prefer-function-component */

/* eslint-disable no-type-assertion/no-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'
import {Animated, Easing, ViewProps} from 'react-native'

import {isDeviceNotWeb} from 'src/lib/utils/platform'

interface IndicatorProps extends ViewProps {
  animationEasing?: typeof Easing.linear
  animationDuration?: number
  hideAnimationDuration?: number
  animating?: boolean
  interaction?: boolean
  hidesWhenStopped?: boolean
  renderComponent?: (props: {
    index: number
    count: number
    progress: Animated.Value
  }) => React.ReactNode
  count?: number
}

interface IndicatorState {
  progress: Animated.Value
  hideAnimation: Animated.Value
}

export class Indicator extends PureComponent<IndicatorProps, IndicatorState> {
  static defaultProps = {
    animationEasing: Easing.linear,
    animationDuration: 1200,
    hideAnimationDuration: 200,

    animating: true,
    interaction: true,
    hidesWhenStopped: true,

    count: 1,
  }

  static propTypes = {
    animationEasing: PropTypes.func,
    animationDuration: PropTypes.number,
    hideAnimationDuration: PropTypes.number,

    animating: PropTypes.bool,
    interaction: PropTypes.bool,
    hidesWhenStopped: PropTypes.bool,

    renderComponent: PropTypes.func,
    count: PropTypes.number,
  }

  private animationState: number = 0
  private savedValue: number = 0

  constructor(props: IndicatorProps) {
    super(props)

    /*
     *  0 -> 1
     *    | startAnimation
     *    | resumeAnimation
     *
     *  1 -> -1
     *    | stopAnimation
     *
     * -1 -> 0
     *    | saveAnimation
     */
    this.animationState = 0
    this.savedValue = 0

    const {animating = true} = this.props

    this.state = {
      progress: new Animated.Value(0),
      hideAnimation: new Animated.Value(animating ? 1 : 0),
    }
  }

  componentDidMount() {
    const {animating = true} = this.props

    if (animating) {
      this.startAnimation()
    }
  }

  componentDidUpdate(prevProps: IndicatorProps) {
    const {animating = true} = this.props
    const prevAnimating = prevProps.animating ?? true

    if (animating && !prevAnimating) {
      this.resumeAnimation()
    }

    if (!animating && prevAnimating) {
      this.stopAnimation()
    }

    if ((animating ? 1 : 0) !== (prevAnimating ? 1 : 0)) {
      const {hideAnimation} = this.state
      const {hideAnimationDuration: duration = 200} = this.props

      Animated.timing(hideAnimation, {
        toValue: animating ? 1 : 0,
        useNativeDriver: isDeviceNotWeb(),
      }).start()
    }
  }

  startAnimation() {
    const {progress} = this.state
    const {
      interaction = true,
      animationEasing = Easing.linear,
      animationDuration = 1200,
    } = this.props

    if (0 !== this.animationState) {
      return
    }

    const animation = Animated.timing(progress, {
      duration: animationDuration,
      easing: animationEasing,
      useNativeDriver: isDeviceNotWeb(),
      isInteraction: interaction,
      toValue: 1,
    })

    Animated.loop(animation).start()

    this.animationState = 1
  }

  stopAnimation() {
    const {progress} = this.state

    if (1 !== this.animationState) {
      return
    }

    const listener = progress.addListener(({value}) => {
      progress.removeListener(listener)
      progress.stopAnimation(() => this.saveAnimation(value))
    })

    this.animationState = -1
  }

  saveAnimation(value: number) {
    const {animating = true} = this.props

    this.savedValue = value
    this.animationState = 0

    if (animating) {
      this.resumeAnimation()
    }
  }

  resumeAnimation() {
    const {progress} = this.state
    const {interaction = true, animationDuration = 1200} = this.props

    if (0 !== this.animationState) {
      return
    }

    Animated.timing(progress, {
      useNativeDriver: isDeviceNotWeb(),
      isInteraction: interaction,
      duration: (1 - this.savedValue) * animationDuration,
      toValue: 1,
    }).start(({finished}) => {
      if (finished) {
        progress.setValue(0)

        this.animationState = 0
        this.startAnimation()
      }
    })

    this.savedValue = 0
    this.animationState = 1
  }

  renderComponent = (item: any, index: number) => {
    const {progress} = this.state
    const {renderComponent, count = 1} = this.props

    if ('function' === typeof renderComponent) {
      return renderComponent({index, count, progress})
    }

    return null
  }

  render() {
    const {hideAnimation} = this.state
    const {count = 1, hidesWhenStopped = true, ...props} = this.props

    const viewProps = {...props} as any

    if (hidesWhenStopped) {
      viewProps.style = [
        ...(Array.isArray(viewProps.style) ? viewProps.style : [viewProps.style]),
        {opacity: hideAnimation},
      ].filter(Boolean)
    }

    return (
      <Animated.View {...viewProps}>
        {Array.from(new Array(count), this.renderComponent)}
      </Animated.View>
    )
  }
}
