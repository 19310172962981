import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UseIsAdvanceQueryQueryVariables = Types.Exact<{[key: string]: never}>

export type UseIsAdvanceQueryQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    products: {
      __typename: 'ProductsCollection'
      eligible: {
        __typename: 'ProductsOffersCollection'
        all: Array<
          | {__typename: 'CardAccountOfferInfo'}
          | {
              __typename: 'LoanOfferInfo'
              offers: Array<{
                __typename: 'LoanOffer'
                loanTypeVariant?: Types.LoanTypeVariant | null
              }>
            }
        >
      }
    }
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename: 'Loan'
        id: string
        loanTypeInfo?: {__typename: 'LoanTypeInfo'; variant: Types.LoanTypeVariant} | null
      } | null
    }
  }
}

export const UseIsAdvanceQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'useIsAdvanceQuery'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'products'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'eligible'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'all'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'LoanOfferInfo'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'offers'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: '__typename'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'loanTypeVariant'},
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'loanTypeInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'variant'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseIsAdvanceQueryQuery, UseIsAdvanceQueryQueryVariables>
