/* eslint-disable testing-library/consistent-data-testid */
import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import HookForm, {FieldVariants} from 'src/designSystem/components/atoms/HookForm/HookForm'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {PFDescriptionLabel} from 'src/designSystem/components/molecules/PFDescriptionLabel/PFDescriptionLabel'
import {PFInfoCapsule} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {logErrorAndShowException} from 'src/lib/errors'
import {SessionReplay} from 'src/lib/sessionReplay/sessionReplay'
import {
  getHasErrorsOrMissingValues,
  ssnLength,
  validSsnField,
} from 'src/lib/utils/formValidationUtil'
import {maskPrefix} from 'src/lib/utils/ssnUtil'

import {
  SSNCollectionCompleteData,
  SSNCollectionTemplateInterface,
} from 'src/products/loans/PersonalInformation/SSNCollection/SSNCollection.types'

type SSNCollectionTemplateProps = SSNCollectionTemplateInterface

const SSNCollectionTemplate: React.FC<SSNCollectionTemplateProps> = (props) => {
  const {onComplete, onMoreInfoPressed, initialData, isEditable} = props

  const {t} = useTranslation(['PersonalInformation', 'BankPrivacy', 'Common'])

  const [ssn, setSSN] = useState<string>(
    initialData?.mask ? `${maskPrefix}${initialData.mask}` : '',
  )

  const [isCertifiedSsn, setIsCertifiedSsn] = useState<boolean>(false)

  const ssnString = t('Common:SocialSecurityNumber') ?? ''

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    reset,
  } = useForm<SSNCollectionCompleteData>({
    mode: 'all',
    defaultValues: {ssn},
  })
  const [hideInput, setHideInput] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [isChanged, setIsChanged] = useState(ssn.length === 0)

  const onSubmit = useCallback(
    async (inData: SSNCollectionCompleteData): Promise<void> => {
      try {
        setIsBusy(true)
        setHideInput(true)
        await onComplete(inData, isChanged)
      } catch (e) {
        void logErrorAndShowException(e, 'SSNCollectionTemplate, onSubmit:')
        setHideInput(false)
      } finally {
        setIsBusy(false)
      }
    },
    [isChanged, onComplete],
  )

  const handleFocus = (): void => {
    if (!isEditable) {
      return
    } else {
      setHideInput(false)
      reset({ssn: ''})
      setSSN('')
      setIsChanged(true)
    }
  }

  const formProps = {
    name: 'ssn',
    field: FieldVariants.TextField,
    rules: {
      required: t('Common:LabelIsRequired', {label: ssnString}) ?? undefined,
      validate: (value: string): string | undefined => {
        return isChanged && !validSsnField(value) ? t('SSNMustBe9Numbers') : undefined
      },
    },
  }

  const isDisabled: boolean = useMemo(() => {
    return getHasErrorsOrMissingValues(errors, watch) || !isCertifiedSsn
  }, [errors, watch, isCertifiedSsn])

  const buttonProps: ButtonLockupProps = {
    type: 'interstitial',
    primary: {
      text: t('Common:Next'),
      onPress: handleSubmit(onSubmit),
      disabled: isDisabled || isBusy,
      loading: isBusy,
      testID: 'ssn_next_button',
    },
    checkBox: {
      text: t('ICertifyThatMySSNIsCorrect'),
      onCheck: (checkValue: boolean) => setIsCertifiedSsn(checkValue),
      checked: isCertifiedSsn,
      testID: 'ssn_checkbox',
    },
  }

  const description = (
    <Box gap="medium" fill="horizontal">
      <PFDescriptionLabel
        descriptor="silhouette"
        label={t('SsnInfoWhy')}
        labelTestID={'ssn_info_why'}
        size="medium"
        linkText={t('LearnMoreAboutSSNRequirements')}
        linkOnPress={onMoreInfoPressed}
        linkTestID={'ssn_info_why_link'}
      />
    </Box>
  )

  const hookFormBoxProps: BoxProps = {
    width: '100%',
  }

  return (
    <Page
      variant={'generic'}
      smallTopGap={true}
      buttonProps={buttonProps}
      title={t('Ssn')}
      testID={'ssn_header'}
    >
      <Box width={'90%'} marginBottom={'large'}>
        {description}
      </Box>
      <HookForm control={control} errors={errors} box={hookFormBoxProps}>
        <PFTextInput
          editable={isEditable}
          selectTextOnFocus={isEditable}
          label={ssnString}
          testID={'ssn_input'}
          maxLength={isChanged ? ssnLength : undefined}
          autoCorrect={false}
          onFocus={handleFocus}
          formProps={formProps}
          errorOnEmpty={isChanged}
          keyboardType={'numeric'}
          returnKeyType={'done'}
          secureTextEntry={hideInput}
          {...SessionReplay.privacyProps()}
        />
      </HookForm>

      <Box marginHorizontal={'tiny'} marginTop={'small'} gap={'small'}>
        <PFInfoCapsule
          svgIcon={{name: 'lock', colorVariant: 'active', isFilled: true}}
          text={t('Security:BankPrivacy')}
          testID="ssn_bank_privacy"
        />
        <PFText variant="p_sm" disabled testID="ssn_disclosure">
          {t('SsnDisclosure')}
        </PFText>
      </Box>
    </Page>
  )
}

export {SSNCollectionTemplate}
