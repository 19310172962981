import {DecisioniongLoanDashboardAggregateStatusQueryHookResult} from 'src/products/loans/Dashboard/DashboardLoan/queries/useDashboardLoanAggregateStatusQuery'
import {DashboardLoanDecisioningProps} from 'src/products/loans/Dashboard/DashboardLoanDecisioning/DashboardLoanDecisioning.types'

/**
 * Constructs complete props for DashboardLoanDecisioning given GraphQL response data for DashboardLoanAggregateStatusQuery.
 */
export const getDashboardLoanDecisioningProps = (config: {
  decisioningLoanDashboardData: Pick<
    DecisioniongLoanDashboardAggregateStatusQueryHookResult,
    'decisioningLoanAggregateStatus' | 'userProfile'
  >
  isAdvance: boolean
  advanceLoanCount?: number
}): DashboardLoanDecisioningProps => {
  const {decisioningLoanDashboardData, isAdvance, advanceLoanCount} = config
  const {decisioningLoanAggregateStatus} = decisioningLoanDashboardData

  const props: DashboardLoanDecisioningProps = {
    delayed: decisioningLoanAggregateStatus.delayed,
    isAdvance,
    advanceLoanCount,
  }
  return props
}
