import React, {useEffect, useState, useRef} from 'react'
import {View, Text, StyleSheet} from 'react-native'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {StackNavigationProp} from '@react-navigation/stack'

import {ApplyMutation, ApplyQuery} from '@possible/cassandra/src/utils/operations'
import {PopPage} from 'src/navigation/NavHelper'
import {logErrorAndShowException} from 'src/lib/errors'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'
import {getAccountId} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import {
  BankYodleeFastLinkCompleteDocument,
  FastLinkInitInput,
  GetYodleeFastLinkInitArgsDocument,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePfDispatch} from 'src/store/utils'

type Props = {
  account?: string
  navigation: StackNavigationProp<MainStackParamList>
  onComplete: (complete: boolean) => void
}

const AggregatorYodlee = (props: Props) => {
  const {account, navigation, onComplete} = props
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const [fastLinkInfoJsonStrBase64, setFastLinkInforJsonStrBase64] = useState<string>('')
  const [busy, setBusy] = useState<boolean>(false)
  const [js, setJs] = useState<string>('')
  const dispatch = usePfDispatch()

  useEffect(() => {
    const load = async () => {
      try {
        const id: FastLinkInitInput = account ? {linkedAccountId: getAccountId(account)} : {}

        const fastLinkInfo = await ApplyQuery(GetYodleeFastLinkInitArgsDocument, {
          input: id,
        })

        const {fastLinkUrl, rsession, token, redirectReq, app, extraParams} =
          fastLinkInfo.data.getYodleeFastLinkInit
        const str = `postYodlee('${fastLinkUrl}', '${rsession}', '${token}', '${redirectReq.toString()}', '${app}', '${extraParams}')`
        const cbUrl = `${window.location.origin}/YodleeResponse.html`
        setFastLinkInforJsonStrBase64(
          btoa(
            JSON.stringify({
              ...fastLinkInfo.data.getYodleeFastLinkInit,
              extraParams: `callback=${encodeURI(cbUrl)}`,
            }),
          ),
        )
        setJs(str)
      } catch (e) {
        void logErrorAndShowException(e, 'AggregatorYodlee, load:')
      }
    }

    const onYodleeCallback = async (JSONcallBackStatus) => {
      if (busy) {
        return
      }

      setBusy(true)

      try {
        const res = await ApplyMutation(BankYodleeFastLinkCompleteDocument, {
          jsonCallbackStatus: JSONcallBackStatus,
        })

        PopPage(navigation)
        onComplete?.(!!res?.bankYodleeFastLinkComplete)
      } catch (e) {
        PopPage(navigation)

        void logErrorAndShowException(e, 'AggregatorYodlee, onYodleeCallback:')
      }
    }

    const onYodleeResult = (messageEvent) => {
      const yodleeResultStr = messageEvent?.data?.yodleeResultStr
      if (yodleeResultStr) {
        if (messageEvent.origin !== window.location.origin) {
          return
        }

        onYodleeCallback(yodleeResultStr)
      }
    }

    window.addEventListener('message', onYodleeResult, false)
    load()

    return () => {
      window.removeEventListener('message', onYodleeResult)
    }
  }, [account, busy, dispatch, navigation, onComplete])

  if (!js) {
    return null
  }

  if (busy) {
    return <Spinner />
  }

  if (fastLinkInfoJsonStrBase64) {
    return (
      <View style={styles.view}>
        <iframe
          ref={iFrameRef}
          id={'yodlee'}
          src={`${window.location.href}YodleeFixture2.html?param=${fastLinkInfoJsonStrBase64}`}
          height={'100%'}
          width={'100%'}
        />
      </View>
    )
  }

  return (
    <View style={styles.view}>
      <Text>Yodlee</Text>
    </View>
  )
}

export default withForwardedNavigationParams<Props>()(AggregatorYodlee)

const styles = StyleSheet.create({
  view: {
    backgroundColor: genericPageBackground,
    flex: 1,
    width: '100%',
  },
})
