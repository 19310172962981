import React, {FC, useCallback, useState} from 'react'
import {useFocusEffect} from '@react-navigation/native'

import {EmailVerificationTemplate} from 'src/products/loans/EmailVerification/EmailVerificationTemplate'
import {usePfSelector, usePfDispatch} from 'src/store/utils'
import {onboardingEmailCodeSentSelector} from 'src/lib/onboarding/selectors'
import {onboardingEmailCodeSent} from 'src/lib/onboarding/slice'
import {UserVerifyEmailSendCodeMutation} from '@possible/cassandra/src/user/authPrivateMethods'
import {ModifyEmailSendCodeMedium} from '@possible/cassandra/src/types/types.mobile.generated'
import {ShowException} from 'src/lib/errors'
import {userVerifyEmailConfirm} from '@possible/cassandra/src/user/authPublicMethods'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import Log from 'src/lib/loggingUtil'
import {EmailVerificationDataDocument} from 'src/products/loans/EmailVerification/EmailVerification.gqls'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'

export type EmailVerificationGQLContainerProps = {
  onEditEmail?: () => void
  onResendCodeTracking: () => void
  onCodeSubmitted: () => Promise<void> | void
  withoutSendCodeOverlay?: boolean
}

const EmailVerificationGQLContainer: FC<EmailVerificationGQLContainerProps> = ({
  onEditEmail,
  onResendCodeTracking,
  onCodeSubmitted,
  withoutSendCodeOverlay = false,
}) => {
  const {data: userEmailData, loading: isLoadingUserEmailData} = useCassandraQuery(
    EmailVerificationDataDocument,
    {
      fetchPolicy: 'network-only',
      onError: (error) => {
        Log.error(`Error fetching user email data: ${error.message}`)
      },
    },
  )
  const email = userEmailData?.me.profile?.email?.address ?? ''
  const isCodeSent = usePfSelector(onboardingEmailCodeSentSelector)
  const isEmailVerified = userEmailData?.me?.profile?.email?.verified ?? false
  const dispatch = usePfDispatch()

  /**
   * Send Code To Email overlay.
   */

  const [shouldShowSendCodeOverlay, setShouldShowSendCodeOverlay] = useState<boolean>(false)

  // Supposedly this will prevent the overlay showing up when React Navigation has
  // this component mounted due to back-button history but not displayed.
  // Probably, we shouldn't be using an overlay at all here.
  useFocusEffect(
    useCallback(() => {
      // Show overlay to send a code if code has not been sent and email not verified
      if (!isCodeSent && !isEmailVerified && !withoutSendCodeOverlay) {
        setShouldShowSendCodeOverlay(true)
      }
    }, [isCodeSent, isEmailVerified, withoutSendCodeOverlay]),
  )

  // Runs when user chooses to send a code to the email shown in the overlay
  const handleOnSendCode = async (): Promise<boolean> => {
    setShouldShowSendCodeOverlay(false)
    dispatch(onboardingEmailCodeSent())

    try {
      const isSuccess: boolean | undefined = await UserVerifyEmailSendCodeMutation({
        verificationCodeMedium: ModifyEmailSendCodeMedium.EmailCode,
      })

      if (isSuccess !== true) {
        throw Error('Could not send email code verification')
      }

      return true
    } catch (e) {
      logOfferApplicationError(e, 'EmailVerificationGQLContainer send email code error')
      ShowException(e)
      return false
    }
  }

  // Runs when user presses edit email button in overlay
  const handleOnCloseOverlayAndEditEmail = (): void => {
    setShouldShowSendCodeOverlay(false)
    onEditEmail?.()
  }

  /**
   * Submit code screen.
   */

  const handleOnResendCode = async (): Promise<boolean> => {
    onResendCodeTracking()
    return handleOnSendCode()
  }

  const handleOnSubmitCode = async (code: string): Promise<void> => {
    try {
      if (!email) {
        throw new Error('Email undefined for email verification')
      }

      const isSuccess: boolean | undefined = await userVerifyEmailConfirm({
        email,
        verificationCode: code,
      })

      if (isSuccess !== true) {
        throw Error('Failed to verifiy email')
      }

      await onCodeSubmitted()
    } catch (e) {
      logOfferApplicationError(e, 'EmailVerificationGQLContainer verifyEmailCode error')
      ShowException(e)
    }
  }

  if (isLoadingUserEmailData) {
    return <Spinner />
  }

  return (
    <EmailVerificationTemplate
      email={email}
      onSendCode={handleOnSendCode}
      onEditEmail={handleOnCloseOverlayAndEditEmail}
      onResendCode={handleOnResendCode}
      onSubmitCode={handleOnSubmitCode}
      showSendCodeOverlay={shouldShowSendCodeOverlay}
    />
  )
}

export {EmailVerificationGQLContainer}
