import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {Image, LayoutChangeEvent, LayoutRectangle, View, ViewStyle} from 'react-native'
import {SvgProps} from 'react-native-svg'

import Log from 'src/lib/loggingUtil'

type Props = {
  svg: React.FC<SvgProps>
} & Pick<ViewStyle, 'width' | 'height' | 'maxWidth'>

type SvgInfo = {height: number; width: number}

const getInnerStyleViewHeightAndWidth = (layout: LayoutRectangle, svgRatio: number) => {
  if (layout.height === 0) {
    return
  }

  const containerViewRatio = layout && layout.width / layout.height
  if (!containerViewRatio) return

  return containerViewRatio > svgRatio
    ? {height: layout.height, width: svgRatio * layout.height}
    : {width: layout.width, height: layout.width / svgRatio}
}

const getInnerStyleView = (
  svgInfo: SvgInfo | undefined,
  layout: LayoutRectangle | undefined,
  width?: Props['width'],
  height?: Props['height'],
) => {
  if (svgInfo?.height === undefined || svgInfo.width === undefined) return
  if (layout?.height === undefined || layout.width === undefined) return

  if (svgInfo.height === 0) {
    return
  }

  const svgRatio = svgInfo.width / svgInfo.height
  if (svgRatio == 0) return
  if (height && width) {
    return getInnerStyleViewHeightAndWidth(layout, svgRatio)
  } else if (width) {
    return {width: layout.width, height: layout.width / svgRatio}
  } else if (height) {
    return {height: layout.height, width: layout.height * svgRatio}
  }
}

const PFSvgContain: FC<Props> = (props) => {
  const [layout, setLayout] = useState<LayoutRectangle>()
  const [svgInfo, setSvgInfo] = useState<SvgInfo>()

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setLayout(event.nativeEvent.layout)
  }, [])

  useEffect(() => {
    let isMounted = true

    Image.getSize(
      `${props.svg}`,
      (width, height) => {
        if (isMounted) {
          setSvgInfo({height, width})
        }
      },
      () => {
        Log.debug('PFSvgContain, Image.getSize: failed to get SVG size')
      },
    )

    return () => {
      isMounted = false
    }
  }, [props.svg])

  const innerViewStyle = useMemo(
    () => getInnerStyleView(svgInfo, layout, props.width, props.height),
    [svgInfo, layout, props.width, props.height],
  )

  return (
    <View
      onLayout={onLayout}
      style={{width: props.width, height: props.height, maxWidth: props.maxWidth}}
    >
      {innerViewStyle ? (
        <View style={{...innerViewStyle}}>
          <Image
            style={{width: '100%', height: '100%'}}
            source={{uri: `${props.svg}`}}
            resizeMode={'contain'}
          />
        </View>
      ) : undefined}
    </View>
  )
}

export default PFSvgContain
