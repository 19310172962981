import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UserProfileInfoQueryVariables = Types.Exact<{[key: string]: never}>

export type UserProfileInfoQuery = {
  __typename?: 'Query'
  me: {
    __typename: 'User'
    id: string
    profile?: {
      __typename: 'UserProfile'
      name?: {
        __typename: 'Name'
        firstName: string
        lastName?: string | null
        middleName?: string | null
      } | null
      phone?: {__typename: 'Phone'; verified: boolean; number?: string | null} | null
      email?: {__typename: 'Email'; address?: string | null; verified: boolean} | null
      home?: {
        __typename: 'Location'
        address?: {
          __typename: 'Address'
          street1?: string | null
          street2?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
        } | null
      } | null
    } | null
  }
}

export type MembershipSubscriptionQueryVariables = Types.Exact<{[key: string]: never}>

export type MembershipSubscriptionQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    subscriptions: {
      __typename?: 'SubscriptionCollection'
      current?: {
        __typename?: 'Subscription'
        id: string
        firstPaymentAt: string
        statusData: {
          __typename?: 'SubscriptionStatusData'
          status: Types.SubscriptionStatus
          substatus: Types.SubscriptionSubstatus
          statusReason: string
        }
        payments: {
          __typename: 'SubscriptionPaymentCollection'
          all: Array<{
            __typename: 'SubscriptionPayment'
            id: string
            status: Types.SubscriptionPaymentStatus
            amount: string
            scheduledAt: string
          }>
          next?: {
            __typename: 'SubscriptionPayment'
            id: string
            amount: string
            scheduledAt: string
          } | null
        }
      } | null
    }
  }
}

export type CancelMembershipMutationVariables = Types.Exact<{[key: string]: never}>

export type CancelMembershipMutation = {
  __typename?: 'Mutation'
  subscriptionCancel: {__typename?: 'Subscription'; id: string}
}

export const UserProfileInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UserProfileInfo'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'profile'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'name'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'middleName'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'phone'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'email'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'address'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'home'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'address'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'street1'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'street2'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'postalCode'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserProfileInfoQuery, UserProfileInfoQueryVariables>
export const MembershipSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'MembershipSubscription'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'subscriptions'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'current'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'statusData'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'substatus'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'statusReason'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'firstPaymentAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'payments'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'all'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'scheduledAt'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'next'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'scheduledAt'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MembershipSubscriptionQuery, MembershipSubscriptionQueryVariables>
export const CancelMembershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CancelMembership'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'subscriptionCancel'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelMembershipMutation, CancelMembershipMutationVariables>
