import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'

import {AddressCompletionMethod} from 'src/products/MCU/Address/AddressCollectionTemplate'
import {WorkflowPreReqFulfillScreenProps, WorkflowsStackParams} from 'src/workflows/types'
import {AddressUpdateGQLContainer} from 'src/products/MCU/Address/AddressUpdateGQLContainer'
import {getIsAppRevampFunctionalUpdatesEnabled} from 'src/lib/experimentation/appRevampFeatureFlag'

type Props = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<WorkflowsStackParams, 'AddressHome'>

const AddressWorkflowContainer: React.FC<Props> = (props) => {
  const {onPreReqFulfilled} = props
  const isAppRevampFunctionalUpdatesEnabled = getIsAppRevampFunctionalUpdatesEnabled()

  const handleComplete: AddressCompletionMethod = useCallback(async () => {
    await onPreReqFulfilled()
  }, [onPreReqFulfilled])

  return (
    <AddressUpdateGQLContainer
      onComplete={handleComplete}
      isAppRevampFunctionalUpdatesEnabled={isAppRevampFunctionalUpdatesEnabled}
    />
  )
}

export {AddressWorkflowContainer}
