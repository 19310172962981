import React, {Component} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {logErrorAndShowException} from 'src/lib/errors'
import {PushPage} from 'src/navigation/NavHelper'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import {completeUra} from 'src/cassandra'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'

type State = {
  busy: boolean
}

type Props = PropsFromRedux &
  URAProps &
  WithTranslation & {
    navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
  }

class URAUpdateSSN extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      busy: false,
    }
  }

  onContinue() {
    PushPage(this.props.navigation, 'SsnConfirm', {
      onComplete: () => this.onComplete(),
      ura_id: this.props.ura_id,
      clearDefaultSsnOnInit: true,
      isFromUra: true,
    })
  }

  async onComplete() {
    try {
      this.props.navigation.pop() //pop the SSN capture screen first
      this.setState({busy: true})

      await completeUra(this.props.ura_id)

      this.props.close() //pop the entire stack
    } catch (e) {
      void logErrorAndShowException(e, 'URAUpdateSSN, onComplete:')
    } finally {
      this.setState({busy: false})
    }
  }

  render() {
    const primary = {
      text: this.props.t('Continue'),
      disabled: this.state.busy,
      onPress: () => {
        this.onContinue()
      },
    }
    return (
      <URAModalContent
        uraId={this.props.ura_id}
        ura={this.props.ura}
        primary={primary}
        close={this.props.close}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ura: get_ura_by_id_from_redux(ownProps.ura_id, state),
  }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withTranslation('Common')(URAUpdateSSN))
