import React from 'react'
import {useTranslation} from 'react-i18next'

import {DebitCardPaymentMethodLink} from 'src/products/general/GeneralPaymentMethods/PaymentMethodLink/DebitCardPaymentMethodLink'
import {ActionListItemProps} from 'src/products/general/components/molecules/ActionListItem/ActionListItem'
import {AchPaymentMethodLink} from 'src/products/general/GeneralPaymentMethods/PaymentMethodLink/AchPaymentMethodLink'
import {PFStatusPillProps} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {NamedColors} from 'src/designSystem/colors'
import {PaymentMethodLinkSubsets} from 'src/products/general/GeneralPaymentMethods/PaymentMethodLink/PaymentMethodLink.types'

type PaymentMethodLinkProps = Partial<ActionListItemProps> & {
  paymentMethod?: PaymentMethodLinkSubsets
  onPressViewPaymentMethods?: () => void
  rightStatusPill?: PFStatusPillProps
  analyticEvent: () => void
}

/**
 * Displays a payment method with a button to edit / change it.
 */
export const PaymentMethodLink = ({
  paymentMethod,
  onPressViewPaymentMethods,
  rightStatusPill,
  analyticEvent,
  ...props
}: PaymentMethodLinkProps): JSX.Element | null => {
  const {t} = useTranslation('MakePayment')
  const handleOnPress = (): void => {
    analyticEvent()
    onPressViewPaymentMethods?.()
  }

  let titleStatusPill: PFStatusPillProps | undefined
  if (paymentMethod?.isPaymentMethodNew) {
    titleStatusPill = {
      text: t('NewAccount'),
      color: NamedColors.PRODUCT_BLUE,
      variant: 'outline',
      shape: 'rectangle',
      testID: 'StatusPill-New-Account',
    }
  }

  switch (paymentMethod?.__typename) {
    case 'AchPaymentMethod':
      return (
        <AchPaymentMethodLink
          paymentMethod={paymentMethod}
          onPress={handleOnPress}
          titleStatusPill={titleStatusPill}
          rightStatusPill={rightStatusPill}
          {...props}
        />
      )
    case 'DebitCardPaymentMethod':
      return (
        <DebitCardPaymentMethodLink
          paymentMethod={paymentMethod}
          onPress={handleOnPress}
          titleStatusPill={titleStatusPill}
          rightStatusPill={rightStatusPill}
          {...props}
        />
      )
    default:
      return null
  }
}
