import React from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {PFStatusPillProps} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import CreditCard from 'src/assets/illustrations/Credit-Card.png'
import {ApplicationTileProps} from 'src/products/loans/ProductChoice/ApplicationTile'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {Image} from 'react-native'

type CardApplicationTileProps = Pick<
  ApplicationTileProps,
  'creditAmount' | 'monthlyFee' | 'onApplyClick' | 'buttonDisabled'
> & {
  isInCardBureauFlow: boolean
}

export const CardApplicationTile: React.FC<CardApplicationTileProps> = (props) => {
  const {
    creditAmount,
    monthlyFee,
    onApplyClick: handleOnApplyClick,
    buttonDisabled: isButtonDisabled,
  } = props
  const {t} = useTranslation(['ProductSelection', 'PossibleCard', 'Common'])

  const pill: PFStatusPillProps = {
    text: t('Common:Beta'),
    color: NamedColors.SAND,
    fontColor: 'textPrimary',
  }

  return (
    <BasicTile padding="small" gap="tiny" align="center" pill={pill} pillAlignment="left">
      <Box align="center" gap="tiny">
        <Image source={CreditCard} style={styles.image} />
        <PFText variant="h3">
          {t('PossibleCard:CreditLimitWithAmount', {
            creditLimitAmount: convertToDollarAmt(creditAmount, false),
          })}
        </PFText>
        <PFText variant="p_semibold">
          {t('PossibleCard:MonthlyMembership', {
            monthlyMembershipAmount: convertToDollarAmt(monthlyFee, false),
          })}
        </PFText>
        <PFText variant="p">{t('PossibleCard:BuildCreditHistory')}</PFText>
        <PFText variant="p">{t('PossibleCard:FixedMonthlyFee')}</PFText>
        <PFText variant="p">{t('PossibleCard:NoInterestOrLateFees')}</PFText>
      </Box>
      <Box marginVertical="small">
        <Button
          mode="primary"
          size="large"
          onPress={handleOnApplyClick}
          disabled={isButtonDisabled}
        >
          {t('PossibleCard:Apply')}
        </Button>
      </Box>
      <PFText variant="p_sm" color={NamedColors.SILVER} textAlign="center">
        {t('PossibleCard:LoanCardDisclaimer')}
      </PFText>
    </BasicTile>
  )
}

const styles = {
  image: {
    width: 130,
    height: 122,
  },
}
