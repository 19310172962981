import React from 'react'
import {useTranslation} from 'react-i18next'

import {DebitCardSubmitValues} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/DebitCard.utils'
import {DebitCardTemplate} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/DebitCardTemplate'
import {PaymentMethodsAlertModals} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals'
import {PaymentMethodsAlertModalErrorReason} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

export type CollectDebitCardNumbersForLoanTemplateProps = {
  errorReason?: PaymentMethodsAlertModalErrorReason
  isSubmitting: boolean
  showFailedToLoadError?: boolean
  onDismissErrorModal: () => void
  onSubmit: (data: DebitCardSubmitValues) => Promise<void>
  isAdvance: boolean
}

/**
 * Template for the screen to collect debit card information from a user for use with a loan.
 */
const CollectDebitCardNumbersForLoanTemplate: React.FC<
  CollectDebitCardNumbersForLoanTemplateProps
> = (props) => {
  const {
    isSubmitting,
    showFailedToLoadError,
    errorReason,
    isAdvance,
    onDismissErrorModal: handleOnDismissErrorModal,
    onSubmit: handleOnSubmit,
  } = props
  const {t} = useTranslation('AccountManagement')

  return (
    <BaseTemplate isError={showFailedToLoadError} testID="CollectDebitCardNumbersForLoan">
      <PaymentMethodsAlertModals
        paymentType={'CARD'}
        isInLoanApprovalFlow={true}
        reason={errorReason}
        onOkay={handleOnDismissErrorModal}
      />
      <DebitCardTemplate
        isAdvance={isAdvance}
        onSubmit={handleOnSubmit}
        loading={isSubmitting}
        title={t('AddDebitCard')}
        hiddenFields={['name']}
      />
    </BaseTemplate>
  )
}

export {CollectDebitCardNumbersForLoanTemplate}
