import React, {FC, useEffect, useState} from 'react'

import {readDevMode} from 'src/lib/devMode'
import Log from 'src/lib/loggingUtil'
import {VersionFooterDevTools} from 'src/products/general/VersionFooter/VersionFooterDevTools'

const VersionFooter: FC = () => {
  const [isDevModeEnabled, setIsDevModeEnabled] = useState(false)

  useEffect(() => {
    readDevMode()
      .then((devMode) => {
        setIsDevModeEnabled(devMode)
      })
      .catch((e) => {
        Log.warn('Error reading dev mode', e)
      })
  }, [])

  return <VersionFooterDevTools isDevMode={isDevModeEnabled} />
}

export default VersionFooter
