import React, {useCallback, useState} from 'react'
import {View} from 'react-native'
import {useTranslation} from 'react-i18next'

import {BankSupportDisclaimerInline} from 'src/products/loans/components/molecules/BankSupportDisclaimerInline/BankSupportDisclaimerInline'
import {completeUra} from '@possible/cassandra'
import {logErrorAndShowException} from 'src/lib/errors'
import {PendingURAType} from 'src/lib/ura/types'
import {PfReduxState} from 'src/reducers/types'
import {uraIds} from 'src/lib/ura/selectors'
import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import {useBankAggregator} from 'src/products/general/GeneralPaymentMethods/useBankAggregator'
import {useMainNavigator} from 'src/nav/AppNavHelper'
import {usePfSelector} from 'src/store/utils'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'

export type LinkAnotherAccountURAProps = URAProps & {
  hideSupportedBanks?: boolean
}

const LinkAnotherAccount: React.FC<LinkAnotherAccountURAProps> = (props) => {
  const {ura_id: uraId, close, hideSupportedBanks} = props
  const {t} = useTranslation('UserRequestedAction')

  const [isBusy, setIsBusy] = useState(false)
  const mainStackNav = useMainNavigator()
  const {openBankAggregator} = useBankAggregator(mainStackNav)
  const ura = usePfSelector((state: PfReduxState): PendingURAType | undefined =>
    uraIds(state)?.get(uraId),
  )

  const onBankComplete = useCallback(
    async (success: boolean): Promise<void> => {
      try {
        if (success) {
          await completeUra(uraId)
          close?.()
        }
      } catch (e) {
        void logErrorAndShowException(e, 'LinkAnotherAccount, onBankComplete:')
      }
    },
    [close, uraId],
  )

  const onLinkAccount = useCallback(async (): Promise<void> => {
    setIsBusy(true)
    await openBankAggregator({
      onBankLinkComplete: async () => {
        await onBankComplete(true)
      },
    })
    setIsBusy(false)
  }, [onBankComplete, openBankAggregator])

  const primary = {
    text: t('LinkAnotherAccount'),
    disabled: isBusy,
    onPress: (): void => {
      void onLinkAccount()
    },
  }

  const bodyExtra = (
    <View>
      {hideSupportedBanks === false ? (
        <BankSupportDisclaimerInline navigation={mainStackNav} />
      ) : null}
    </View>
  )

  return (
    <URAModalContent
      uraId={uraId}
      ura={ura}
      extra={bodyExtra}
      primary={primary}
      // this URA should not be closable unless the user has successfully completed the action
      close={(): void => {}}
    />
  )
}

export {LinkAnotherAccount}
