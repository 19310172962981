import React, {useId} from 'react'
import {TouchableOpacity, Clipboard, StyleSheet, View} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import Snackbar from 'src/lib/Snackbar'
import {type PrivacyProps} from 'src/lib/sessionReplay/sessionReplay'
import {littleGap, mediumGap} from 'src/designSystem/layout'

type CardValue = {
  actionText: string | undefined
  ariaLabel: string | undefined
  label: string
  privacyProps: PrivacyProps | undefined
  value: string | undefined
}

type CardDetailsContentProps = {
  cardValues: CardValue[]
}

export const CardDetailsContent: React.FC<CardDetailsContentProps> = (props) => {
  const {cardValues} = props
  const key = useId()

  const content = cardValues.map(({actionText, ariaLabel, label, privacyProps = {}, value}) =>
    value && actionText ? (
      <TouchableOpacity
        aria-label={ariaLabel}
        key={`${key}-${label}`}
        onPress={(): void => {
          Clipboard.setString(value)
          Snackbar.show({
            title: actionText,
            duration: Snackbar.LENGTH_LONG,
          })
        }}
        style={styles.row}
      >
        <PFText variant="p_semibold">{label}</PFText>

        <View style={styles.flex} testID={`CardDetailsContent-${label}`} {...privacyProps}>
          <PFText variant="p" textAlign="right">
            {value}
          </PFText>
        </View>

        <SvgIcon name={'copy'} size={'small'} colorVariant={'default'} />
      </TouchableOpacity>
    ) : (
      <View key={`${key}-${label}`} style={styles.row}>
        <PFText variant="p_semibold">{label}</PFText>
        <View style={styles.flex}>
          <PFText variant="p" textAlign="right">
            {value}
          </PFText>
        </View>
      </View>
    ),
  )

  return <>{content}</>
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: littleGap,
    paddingVertical: mediumGap / 2,
  },
})
