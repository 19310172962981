import React from 'react'
import {useTranslation} from 'react-i18next'

import {Alert} from 'src/designSystem/components/molecules/Alert/Alert'
import Box from 'src/designSystem/components/atoms/Box/Box'

const OverpayAlert = (): JSX.Element => {
  const {t} = useTranslation('MakePaymentAmountEntry')

  return (
    <Box paddingHorizontal={'medium'} paddingTop={'small'}>
      <Alert
        title={t('OverpayAlertTitle')}
        description={t('OverpayAlertDescription')}
        level={'warning'}
      />
    </Box>
  )
}

export {OverpayAlert}
