import React, {FC} from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {CardReapply} from 'src/products/card/CardReapply'
import {useIsCardEligible} from 'src/products/card/Application/UseIsCardEligible/useIsCardEligible'
import {isUserRejectedDueToQuota} from 'src/products/card/LoanDash/utils'
import {useCards} from 'src/products/card/hooks/useCards'
import {CardApplicationRejected} from 'src/products/card/Application/CardApplicationRejected/CardApplicationRejected'
import {GetUserEmailDocument} from 'src/products/general/EmailConfirmation/queries/getUserEmail.gqls'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

export const CardApplicationRejectedContainer: FC = () => {
  const {activeCardStatus, loading: isLoadingCards} = useCards()
  const {isLoading: isUserEligibleToApplyLoading, isEligibleToReapply} = useIsCardEligible()
  const {selectedData: emailAddress, loading: isLoadingUserEmail} = useCassandraQuery(
    GetUserEmailDocument,
    {},
    (data) => data.me.profile?.email?.address,
  )
  const isLoading = isLoadingCards || isUserEligibleToApplyLoading || isLoadingUserEmail

  usePageViewedAnalytics({
    eventName: CardEvents.card_rejected_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  if (isLoading) {
    return null
  } else if (isEligibleToReapply) {
    return <CardReapply tileMarginHorizontal={0} />
  } else if (isUserRejectedDueToQuota(activeCardStatus)) {
    return (
      <CardApplicationRejected
        variant="quota"
        loading={isLoading}
        emailAddress={emailAddress ?? ''}
      />
    )
  } else {
    return (
      <CardApplicationRejected
        variant="default"
        loading={isLoading}
        emailAddress={emailAddress ?? ''}
      />
    )
  }
}
