import React, {useState} from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import {ModifyEmailSendCodeMedium} from '@possible/cassandra/src/types/types.mobile.generated'
import {UserVerifyEmailSendCodeMutation} from '@possible/cassandra/src/user/authPrivateMethods'
import {
  trackEditEmail,
  trackResendCode,
} from 'src/products/loans/EmailVerification/EmailVerification.utils'
import {
  GetUserEmailDocument,
  GetUserEmailQuery,
} from 'src/products/general/EmailConfirmation/queries/getUserEmail.gqls'
import {ActivationEvents, AppEvents} from 'src/lib/Analytics/app_events'
import {EmailConfirmationTemplate} from 'src/products/general/EmailConfirmation/EmailConfirmationTemplate'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {openContactUsForm} from 'src/lib/contactUs'
import {logErrorAndShowException} from 'src/lib/errors'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {userVerifyEmailConfirm} from '@possible/cassandra/src/user/authPublicMethods'
import {validEmail} from 'src/lib/utils/formValidationUtil'
import {EmailVerifiedTemplate} from 'src/products/loans/EmailVerification/EmailVerifiedTemplate'

export type EmailConfirmationProps = {
  onCodeSubmitted: () => Promise<void> | void
}

const EmailConfirmationGQLContainer: React.FC<EmailConfirmationProps> = (props): JSX.Element => {
  const {onCodeSubmitted} = props
  const navigation = useNavigation<StackNavigationProp<MainStackParamList, 'EmailConfirmation'>>()

  const [email, setEmail] = useState<string>('')

  const {loading: isLoadingUserEmail, selectedData: userEmailData} = useCassandraQuery(
    GetUserEmailDocument,
    {
      fetchPolicy: 'cache-first',
      onCompleted: (data: GetUserEmailQuery) => {
        const prefilledEmail = data?.me?.profile?.email?.address
        if (prefilledEmail) {
          setEmail(prefilledEmail)
        }
      },
    },
    (data) => data?.me?.profile?.email,
  )
  const [verificationCode, setVerificationCode] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [shouldShowVerificationCode, setShouldShowVerificationCode] = useState<boolean>(false)

  const isEmailValid = validEmail(email)

  const handleOnChangeEmail = (value: string): void => {
    setEmail(value)
  }

  const handleOnChangeCode = (value: string): void => {
    setVerificationCode(value)
  }

  const handleOnPressEditEmail = (): void => {
    trackEditEmail()
    setShouldShowVerificationCode(false)
    setVerificationCode('')
  }

  const handleOnPressResend = (): void => {
    trackResendCode()
    void handleSendEmailWithCode()
  }

  const handleOnPressSubmit = (): void => {
    if (!shouldShowVerificationCode && isEmailValid) {
      setShouldShowVerificationCode(true)
      void handleSendEmailWithCode()
      return
    }

    if (isEmailValid) {
      void handleVerifyEmailConfirm()
    }
  }

  const handleVerifyEmailConfirm = async (): Promise<void> => {
    try {
      if (!email) {
        throw new Error('Email undefined for email verification')
      }

      const isSuccess: boolean | undefined = await userVerifyEmailConfirm({
        email,
        verificationCode,
      })

      if (isSuccess !== true) {
        throw Error('Failed to verify email')
      }

      void onCodeSubmitted()
    } catch (e) {
      TrackAppEvent(ActivationEvents.email_verification_failed, AppEvents.Category.Activation)
      void logErrorAndShowException(e, 'EmailConfirmationV2 verifyEmailCode error')
    }
  }

  const handleSendEmailWithCode = async (): Promise<void> => {
    setIsSubmitting(true)
    try {
      const wasCodeSent: boolean | undefined = await UserVerifyEmailSendCodeMutation({
        verificationCodeMedium: ModifyEmailSendCodeMedium.EmailCode,
      })

      if (wasCodeSent !== true) {
        throw Error('Failed to send email code verification')
      }
    } catch (e) {
      void logErrorAndShowException(e, 'Error on handleSendVerificationCode')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleOnNavigateToContactUs = (): void => {
    openContactUsForm(navigation)
  }

  if (userEmailData?.verified === true) {
    const handleOnContinuePressed = async (): Promise<void> => {
      setIsSubmitting(true)
      try {
        await onCodeSubmitted()
      } catch (error) {
        void logErrorAndShowException(error, 'Error on handleOnContinuePressed')
      } finally {
        setIsSubmitting(false)
      }
    }

    return (
      <EmailVerifiedTemplate
        onContinuePressed={handleOnContinuePressed}
        isContinueBusy={isSubmitting}
      />
    )
  }

  return (
    <EmailConfirmationTemplate
      shouldShowVerificationCode={shouldShowVerificationCode}
      isLoadingUserEmail={isLoadingUserEmail}
      email={email}
      onChangeCode={handleOnChangeCode}
      onChangeEmail={handleOnChangeEmail}
      onNavigateToContactUs={handleOnNavigateToContactUs}
      onPressSubmit={handleOnPressSubmit}
      onPressResend={handleOnPressResend}
      onPressEditEmail={handleOnPressEditEmail}
      isSubmittingCode={isSubmitting}
      verificationCodeText={verificationCode}
    />
  )
}

export {EmailConfirmationGQLContainer}
