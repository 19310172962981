import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {Consumer} from '@possible/cassandra'

import {logErrorAndShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PopPage} from 'src/navigation/NavHelper'
import IDCameraPage from 'src/products/general/components/organisms/IDCameraPage/IDCameraPage'
import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

type Props = URAProps & StackScreenProps<MainStackParamList, 'RetakeFrontDL'>

const RetakeFrontDL: React.FC<Props> = (props) => {
  const {navigation, route, complete_ura, onComplete} = props

  const onFrontConfirm = async (): Promise<void> => {
    try {
      const {ura_id} = route.params

      if (ura_id && (complete_ura === undefined || complete_ura)) {
        await Consumer.methods.completeUra(ura_id)
      }
      onComplete?.()
      PopPage(navigation)
    } catch (e) {
      void logErrorAndShowException(e, 'RetakeFrontDL, onFrontConfirm:')
    }
  }

  return <IDCameraPage onComplete={onFrontConfirm} />
}

export default withForwardedNavigationParams<Props>()(RetakeFrontDL)
