import {AccountAndRoutingProvidedValue} from 'src/lib/loans/reducers/types'

export const DISBURSEMENT_METHOD_SELECTED = 'DISBURSEMENT_METHOD_SELECTED'
export const PAYMENT_METHOD_SELECTED = 'PAYMENT_METHOD_SELECTED'
export const ACCOUNT_AND_ROUTING_PROVIDED = 'ACCOUNT_AND_ROUTING_PROVIDED'

export function AccountAndRoutingProvided(value: AccountAndRoutingProvidedValue): {
  type: typeof ACCOUNT_AND_ROUTING_PROVIDED
  value: AccountAndRoutingProvidedValue
} {
  return {
    type: ACCOUNT_AND_ROUTING_PROVIDED,
    value,
  }
}
