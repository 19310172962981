import i18next from 'i18next'

import Log from 'src/lib/loggingUtil'
import Snackbar from 'src/lib/Snackbar'
import {isDev} from 'src/lib/utils/environmentUtil'
import {ErrorMessageLookup, isValidGraphQLError} from 'src/lib/errors/errors.types'

const UnknownErrorString = 'Unknown Error'
const genericErrorLocalizationKey = 'Common:GenericError'

const loadErrorsNamespace = async (): Promise<void> => {
  await i18next.loadNamespaces('Errors')
}
loadErrorsNamespace().catch(LogException)

// Due to javascript catch limitations all errors are caught as `any`
/** Shows Snackbar with generic error message.
 * Only shows custom message for `MGE1001` error Code (See Errors.json localization file)
 * @deprecated use logErrorAndShowException instead
 **/
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function ShowException(e: any): void {
  let err: string
  if (!e) {
    err = UnknownErrorString
  } else if (e instanceof Error) {
    if (e.message) {
      err = e.message
    } else if (e.name) {
      err = e.name
    } else {
      err = 'Exception'
    }
  } else if (typeof e === 'object') {
    const eObj = e as {message?: string}
    if (eObj.message) {
      err = eObj.message
    } else {
      err = UnknownErrorString
    }
  } else if (typeof e === 'string') {
    err = e
  } else {
    err = UnknownErrorString
  }

  if (err) {
    const i18nstr = `Errors:${err}`
    const title = i18next.exists(i18nstr) ? i18next.t(i18nstr) : err

    Snackbar.error({
      title,
      duration: Snackbar.LENGTH_LONG,
    })
  }
}

// Due to javascript catch limitations all errors are caught as `any`
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function LogException(e: any, message?: string): void {
  if (!e) {
    Log.error(UnknownErrorString, message)
  } else if (e instanceof Error) {
    Log.error(e, message)
  } else if (typeof e === 'string') {
    Log.error(e, message)
  } else {
    Log.error(UnknownErrorString, message)
  }
}

export function ContextualizedLogException(context: string): (e: unknown) => void {
  return (e: unknown): void => {
    LogException(e, context)
  }
}

// Due to javascript catch limitations all errors are caught as `any`
/** Logs and shows generic error message in Snackbar.
 * It prevents error message to show in production, but shows in dev environment. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const logErrorAndShowException = async (err: any, context?: string): Promise<void> => {
  Log.error(err, context ?? '')

  const defaultErrorMessage = i18next.t(genericErrorLocalizationKey)

  const isDevEnv = await isDev()

  let errorMessage = defaultErrorMessage
  if (isDevEnv && err instanceof Error) {
    errorMessage = err?.message ?? defaultErrorMessage
  }

  Snackbar.error({
    title: errorMessage,
    duration: Snackbar.LENGTH_LONG,
  })
}

type ShowErrorMessageArguments = {
  error: unknown
  /** localize error message based on the `errorCode`.
   * When this function is not defined or returns `null`, we show the default error message. */
  errorMessageMapper?: ErrorMessageLookup
  context?: string
}

/** Shows up the snackbar with a localized error message, unless `errorMessageMapper`
 * is not defined, or `error` doesn't have `extensions.errorCode`; fallbacks to generic error message. \
 * And logs the `error` with `context`. */
export const logAndShowErrorMessage = ({
  error,
  errorMessageMapper,
  context,
}: ShowErrorMessageArguments): void => {
  const defaultErrorMessage = i18next.t(genericErrorLocalizationKey)

  let errorMessage = defaultErrorMessage

  if (errorMessageMapper && isValidGraphQLError(error)) {
    const errorCode: string =
      typeof error.extensions.errorCode === 'string' ? error.extensions.errorCode : ''

    const mappedMessage = errorMessageMapper(errorCode)

    // Ensure the mapper does not return the error code itself as the message
    if (mappedMessage === errorCode) {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.warn(
          'errorMessageMapper returned with the backend error code. It should not be exposed to the user.\nLocalize the message instead.',
        )
      }
    } else {
      errorMessage = mappedMessage ?? defaultErrorMessage
    }
  }

  Log.error(error, context ?? '')

  Snackbar.error({
    title: errorMessage,
    duration: Snackbar.LENGTH_LONG,
  })
}
