import {LinkedAccount} from '@possible/cassandra/src/types/types.mobile.generated'
import {banking} from '@possible/generated/proto'

import {BankInfoAdded, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import AppNav from 'src/nav/AppNavActions'
import {aggregatorsId} from 'src/lib/user/userEnvConsts'
import {hasValidFundableAccountGql} from 'src/lib/user/utils'
import {MainStackNavigationProp, MainStackPages} from 'src/nav/MainStackParamsList'
import {showUnsupportedAccountsPopup} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/UnsupportedAccount'
import {
  logAddPaymentMethodErrorAndShowException,
  convertAggregatorStringToType,
} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {callbackStatus} from 'src/products/general/GeneralPaymentMethods/types'
import {ApplyQuery} from '@possible/cassandra/src/utils/operations'
import {UserEnvDocument} from 'src/lib/user/queries/UserEnv.gqls'
const yodleeComponent: MainStackPages = 'AggregatorYodlee'
const plaidComponent: MainStackPages = 'AggregatorPlaid'

/**
 * Handle after bank linking is complete. Tracks events and shows unsupported accounts popup if necessary.
 *
 * @deprecated use the GeneralPaymentMethods/useBankAggregator() hook instead of calling this directly
 */
export function onBankLinkingCompleteGql<
  LinkedAccountSubset extends Pick<LinkedAccount, 'status' | 'isLoginRequired'>,
>(status: string, accounts: LinkedAccountSubset[] | undefined | null, bank: string): boolean {
  BankInfoAdded()
  switch (status) {
    case callbackStatus.EXITED_SUCCESS:
      return true
    case callbackStatus.SUCCESS:
      TrackAppEvent(AppEvents.Name.link_bank_account_successful, AppEvents.Category.Application, {
        bank: bank,
      })
      if (hasValidFundableAccountGql<LinkedAccountSubset>(accounts)) {
        return true
      } else {
        showUnsupportedAccountsPopup()
        return false
      }
    case callbackStatus.ERROR:
      TrackAppEvent(AppEvents.Name.link_bank_account_failed, AppEvents.Category.Application, {
        bank: bank,
      })
      return false
    default:
      return false
  }
}

export type LaunchAggregatorOnCompleteCallback = (
  status: string,
  bank: string,
  linkedAccounts?: LinkedAccount[],
) => void | Promise<void>
export type LaunchAggregatorYodleeOnCompleteCallback = (success: boolean) => void
export type SwitchBankAggregatorCallback = (alternateId: string, accountId?: string) => void

/**
 * Launches the bank aggregator for the given account.
 *
 * @deprecated use the GeneralPaymentMethods/useBankAggregator() hook instead of calling this directly
 */
export const launchAggregator = async (
  navigation: MainStackNavigationProp,
  account: banking.ILinkedAccount | undefined | null,
  onComplete: LaunchAggregatorOnCompleteCallback | undefined,
  switchCallback: SwitchBankAggregatorCallback,
  // eslint-disable-next-line max-params
): Promise<void> => {
  try {
    const {data: envData, error: envError} = await ApplyQuery(UserEnvDocument)
    if (envError) {
      throw envError
    }

    const alternateAggregator: aggregatorsId =
      convertAggregatorStringToType(envData.getUserEnv.bankingAggregatorId) ?? aggregatorsId.plaid
    const aggregatorId: aggregatorsId =
      convertAggregatorStringToType(account?.aggregatorId) ?? alternateAggregator

    /* For Yodlee we just get a blob of data that we send to the server to decode so we don't have the name of the bank...*/

    const handleOnComplete =
      aggregatorId === aggregatorsId.yodlee
        ? (success: boolean): void =>
            void onComplete?.(success ? callbackStatus.SUCCESS : callbackStatus.ERROR, aggregatorId)
        : onComplete

    launchBankAggregator(aggregatorId, account, navigation, handleOnComplete, switchCallback)
  } catch (error) {
    logAddPaymentMethodErrorAndShowException(error)
  }
}

const launchBankAggregator = (
  aggregatorId: aggregatorsId,
  account: LinkedAccount | banking.ILinkedAccount | undefined | null,
  navigation: MainStackNavigationProp,
  onComplete:
    | LaunchAggregatorOnCompleteCallback
    | LaunchAggregatorYodleeOnCompleteCallback
    | undefined,
  onSwitch: SwitchBankAggregatorCallback | undefined,
  // eslint-disable-next-line max-params
): void => {
  const props = {
    account,
    onComplete,
    onSwitch,
    windowObjectReference: undefined,
  }

  switch (aggregatorId) {
    case aggregatorsId.plaid:
    case aggregatorsId.mocked:
      AppNav.push(navigation, plaidComponent, {...props, accountId: account?.id})
      break
    case aggregatorsId.yodlee:
      AppNav.push(navigation, yodleeComponent, props)
      break
    default:
      logAddPaymentMethodErrorAndShowException(`Unsupported aggregator`)
  }
}

/**
 * Launches the alternate bank aggregator (i.e. Yodlee if previously tried Plaid) for the given account.
 *
 * @deprecated use the GeneralPaymentMethods/useBankAggregator() hook instead of calling this directly
 */
export const launchAlternateAggregator = (
  alternateId: string,
  accountId: string | undefined,
  navigation: MainStackNavigationProp,
  onComplete: LaunchAggregatorOnCompleteCallback | undefined,
  // eslint-disable-next-line max-params
): void => {
  const alternateAggregatorId: aggregatorsId | undefined =
    convertAggregatorStringToType(alternateId)
  if (alternateAggregatorId === aggregatorsId.yodlee) {
    AppNav.push(navigation, yodleeComponent, {
      // account id will probably be undefined for yodlee
      accountId,
      onComplete,
    })
  } else {
    logAddPaymentMethodErrorAndShowException(
      new Error(
        `BankAggregatorHelper launchAlternateAggregator() invalid alternateAggregatorId provided, alternateAggregatorId=${alternateAggregatorId}`,
      ),
    )
  }
}
