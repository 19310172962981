import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import MoneyRoundAbout from 'src/assets/illustrations/MoneyRoundAbout.svg'

export type LoanDecisioningTileProps = {
  isAdvance: boolean
  isForRepeatAdvance?: boolean
}

/**
 * A tile to inform the user that their loan has been submitted.
 * @example <LoanDecisioningTile />
 */

export const LoanDecisioningTile: React.FC<LoanDecisioningTileProps> = (props) => {
  const {isAdvance, isForRepeatAdvance} = props
  const {t} = useTranslation('DashboardLoanDecisioningTile')

  const title = useMemo(() => {
    if (isAdvance === true) {
      if (isForRepeatAdvance === true) {
        return t('ProcessingYourAdvance')
      }
      return t('CheckingEligibility')
    }

    return t('ApplicationSubmitted')
  }, [isAdvance, isForRepeatAdvance, t])

  return (
    <BasicTile padding="medium">
      <Box>
        <Box fill="horizontal" align="start" marginBottom="tiny">
          <PFSvgContain svg={MoneyRoundAbout} width={92} />
        </Box>

        <Box gap="small" marginBottom="medium">
          <PFText testID="Loan-Decisioning-Tile-Application-Submitted" variant="h3">
            {title}
          </PFText>
          {isForRepeatAdvance === true && isAdvance === true ? null : (
            <PFText testID="Loan-Decisioning-Tile-Take-Up-Hours" variant="p">
              {t('ThisMayTakeUpToACoupleHours')}
            </PFText>
          )}
        </Box>

        {isForRepeatAdvance === true && isAdvance === true ? (
          <Box gap="tiny">
            <PFText testID="Loan-Decisioning-Tile-Checking-Banking-Details" variant="p">
              {t('CheckingBankingDetails')}
            </PFText>
          </Box>
        ) : (
          <Box gap="tiny">
            <PFText testID="Loan-Decisioning-Tile-If-Approved-Text" variant="p_semibold">
              {isAdvance ? t('IfEligibleWeWillAskYouTo') : t('IfApprovedBoldText')}
            </PFText>
            <PFText testID="Loan-Decisioning-Tile-If-Approved1" variant="p">
              • {isAdvance ? t('AgreeToFirstAdvanceAmount') : t('ConfirmTheLoanAmount')}
            </PFText>
            <PFText testID="Loan-Decisioning-Tile-If-Approved2" variant="p">
              •{' '}
              {isAdvance
                ? t('TransferYourFirstAdvanceAndActivateMembership')
                : t('TellUsWhereToSendFunds')}
            </PFText>
            <PFText testID="Loan-Decisioning-Tile-If-Approved3" variant="p">
              • {isAdvance ? t('TellUsWhereToSendFunds') : t('AgreeToDisclosures')}
            </PFText>
            {isAdvance ? (
              <PFText testID="Loan-Decisioning-Tile-If-Approved3" variant="p">
                • {t('AgreeToDisclosures')}
              </PFText>
            ) : null}
          </Box>
        )}
        {!isAdvance ? (
          <Box marginTop="medium">
            <PFText testID="Loan-Decisioning-Tile-Offer-Highest-Amount" variant="p">
              {t('OfferHighestAmount')}
            </PFText>
          </Box>
        ) : null}
      </Box>
    </BasicTile>
  )
}
