import React, {useState} from 'react'
import {StyleSheet, View} from 'react-native'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {useTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PopPage, PushPage} from 'src/navigation/NavHelper'
import {logErrorAndShowException} from 'src/lib/errors'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import Image from 'src/products/general/Image/Image'
import {GetFullStateFromAbv} from 'src/lib/us_states'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {ImageInfo} from 'src/lib/utils/formData'
import {userIdSelector} from 'src/api/selectors/selectors'
import {usePfSelector} from 'src/store/utils'
import {uploadAndCreateDocument} from 'src/lib/utils/files'
import {
  UserDocumentSubType,
  UserDocumentType,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {Consumer} from '@possible/cassandra'
import {SessionReplay} from 'src/lib/sessionReplay/sessionReplay'

type ProofOfResidenceConfirmProps = StackScreenProps<
  MainStackParamList,
  'ProofOfResidenceConfirm'
> & {
  onRetake: () => void
  imageInfo: ImageInfo
  selectedState: string
  isAddressFilled?: boolean
  onComplete: (address: object | undefined) => void
}

const ProofOfResidenceConfirm = withForwardedNavigationParams<ProofOfResidenceConfirmProps>()((
  props,
): JSX.Element => {
  const {
    onRetake,
    navigation,
    imageInfo,
    isAddressFilled = false,
    onComplete,
    selectedState,
  } = props

  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [isUploaded, setIsUploaded] = useState<boolean>(false)
  const userId = usePfSelector(userIdSelector)
  const {t} = useTranslation(['PersonalInformation', 'Common'])
  const retakeAction = (): void => {
    onRetake?.()
    PopPage(navigation)
  }
  const onAddressComplete = (address?: object): void => {
    navigation.goBack()
    onComplete?.(address)
  }

  const onConfirm = async (): Promise<void> => {
    try {
      setIsBusy(true)

      if (!isUploaded) {
        const didUploadSucceed = await uploadAndCreateDocument({
          imageInfo,
          type: UserDocumentType.Legal,
          subType: UserDocumentSubType.ProofOfResidency,
          userId,
        })
        if (didUploadSucceed) {
          setIsUploaded(true)
        } else {
          setIsUploaded(false)
        }
      }

      if (isAddressFilled) {
        onAddressComplete()
      } else {
        const fullSelectedState = GetFullStateFromAbv(selectedState) || selectedState
        PushPage(navigation, 'AddressUpdate', {
          title: t('ConfirmInStateAddress', {selectedState: fullSelectedState}),
          onComplete: (address: Consumer.types.Address) => onAddressComplete(address),
          address: {
            state: selectedState,
          },
        })
      }
    } catch (e) {
      void logErrorAndShowException(e, 'ProofOfResidenceConfirm, onConfirm:')
    } finally {
      setIsBusy(false)
    }
  }

  const action = {
    onPress: onConfirm,
    text: t('SubmitResidencyPhoto'),
    disabled: isBusy,
    loading: isBusy,
  }

  const secondaryAction = {
    onPress: retakeAction,
    buttonText: t('Common:UpdateImage'),
    disabled: isBusy,
  }

  return (
    <GenericNonModalTemplate
      title={t('MakeSureItIsClear')}
      description={t('PictureDescription')}
      actionBlock={buttonLockupProperties(action, secondaryAction)}
    >
      <View
        style={styles.confirm_content_view}
        testID={'ProofOfResidenceConfirm-Image-Preview'}
        {...SessionReplay.privacyProps()}
      >
        <Image style={styles.image} source={{uri: imageInfo?.uri}} />
      </View>
    </GenericNonModalTemplate>
  )
})

export {ProofOfResidenceConfirm, type ProofOfResidenceConfirmProps}

const styles = StyleSheet.create({
  confirm_content_view: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-start',
  },
  image: {
    aspectRatio: 1,
    resizeMode: 'cover',
    width: '100%',
  },
})
