import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {PushPage} from 'src/navigation/NavHelper'
import {DocumentRow} from 'src/products/card/CardsHistory/DocumentRow'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardAccountIdQueryDocument} from 'src/products/card/Common/CardAccountIdQuery.gqls'
import {useIsAdvance} from 'src/lib/advance/useIsAdvance/useIsAdvance'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'

type Props = StackScreenProps<MainStackParamList, 'DocumentsHistory'>
export const DocumentsHistory: React.FC<Props> = (props) => {
  const {navigation} = props
  const {t} = useTranslation(['DocumentsHistory', 'Common'])
  const {selectedData: hasCardAccount} = useCassandraQuery(
    CardAccountIdQueryDocument,
    {},
    (data) => !!data?.me?.cardAccounts?.active?.id,
  )
  const {loading: isLoadingAdvance, isAdvance} = useIsAdvance()

  const cardItem = {
    id: 'possibleCard',
    title: t('PossibleCard'),
    handleOnPress: (): void => {
      TrackAppEvent(
        AppEvents.Name.lefthand_drawer_card_statements_and_documents_selected,
        AppEvents.Category.Admin,
      )
      PushPage(navigation, 'CardsHistory')
    },
  }

  const loanItem = {
    id: 'loans',
    title: isAdvance ? t('CashAdvance') : t('Loans'),
    handleOnPress: (): void => {
      PushPage(navigation, 'LoanHistory', {
        isAdvance,
      })
    },
  }

  const items = hasCardAccount ? [cardItem, loanItem] : [loanItem]

  const rows = items.map((item) => {
    return (
      <DocumentRow name={item.title} key={item.id} onPress={item.handleOnPress} testID={item.id} />
    )
  })

  if (isLoadingAdvance) {
    return <Spinner />
  }

  return (
    <Page variant={'generic'} title={t('HistoryStatementsAndDocuments')} smallTopGap={true}>
      <Box gap={'large'} marginTop={'medium'}>
        {rows}
      </Box>
    </Page>
  )
}
