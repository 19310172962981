import React, {FC, ReactElement} from 'react'
import {View, StyleSheet} from 'react-native'

import {Loading} from 'src/designSystem/components/atoms/Loading/Loading'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {DefaultVariantsColor, NamedColors, ColorVariants} from 'src/designSystem/colors'
import {littleGap, smallGap} from 'src/designSystem/layout'
import {
  PFStatusPillProps,
  PFStatusPill,
} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import StackedButtons, {
  StackedButtonsAction,
} from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import {Color} from 'src/designSystem/types'

const tileHeight: number = 90 as const

export type PromoTileProps = {
  loading?: boolean
  titleText: string | ReactElement
  contentText: string | ReactElement
  footerText?: string | ReactElement
  pillText?: string
  pillTextColor?: ColorVariants
  pillColor?: Color
  image?: ReactElement
  imageWidth?: number
  tileRadius?: number
  primaryAction?: StackedButtonsAction
  secondaryAction?: StackedButtonsAction[]
  backgroundColor?: BoxProps['background']
  titleColor?: Color
  bodyColor?: Color
  testID?: string
}

const PromoTileLoader = (): ReactElement => (
  <View style={styles.loader}>
    <Loading type={'loader0'} size={'large'} />
  </View>
)

/**
 * Generic tile to display information alongside an image or icon.
 * Supports a title, a pill with text above the title, content text, optional action buttons, and optional footer text.
 * @example <PromoTile titleText="Title" contentText="Content" />
 */
export const PromoTile: FC<PromoTileProps> = (props) => {
  const {
    titleText,
    contentText,
    footerText,
    pillText,
    pillTextColor = 'textPrimary',
    pillColor = NamedColors.SAND,
    image,
    imageWidth = 80,
    loading = false,
    tileRadius,
    primaryAction,
    secondaryAction,
    backgroundColor,
    titleColor = DefaultVariantsColor.primary,
    bodyColor = DefaultVariantsColor.black,
    testID,
  } = props
  let pillContent: ReactElement | undefined
  let buttonContent: ReactElement | undefined
  let footerContent: ReactElement | undefined

  const tileContent = (
    <View style={styles.contentContainer} testID={testID}>
      <View style={styles.text}>
        <View style={{marginBottom: littleGap}}>
          {typeof titleText === 'string' ? (
            <PFText textAlign={'left'} variant={'h3'} color={titleColor}>
              {titleText}
            </PFText>
          ) : (
            titleText
          )}
        </View>
        {typeof contentText === 'string' ? (
          <PFText variant={'p'} textAlign={'left'} color={bodyColor}>
            {contentText}
          </PFText>
        ) : (
          contentText
        )}
      </View>
      {image ? <View style={[styles.imageContainer, {width: imageWidth}]}>{image}</View> : null}
    </View>
  )

  if (pillText) {
    const statusPill: PFStatusPillProps = {
      text: pillText,
      color: pillColor,
      fontColor: pillTextColor,
    }
    pillContent = (
      <View style={styles.pill}>
        <PFStatusPill {...statusPill} />
      </View>
    )
  }

  if (primaryAction || secondaryAction) {
    buttonContent = (
      <View style={{marginTop: smallGap}}>
        <StackedButtons primary={primaryAction} secondary={secondaryAction} />
      </View>
    )
  }

  if (footerText) {
    footerContent = (
      <View style={styles.footerContent}>
        {typeof footerText === 'string' ? (
          <PFText variant={'p_sm'} textAlign={'center'} color={bodyColor}>
            {footerText}
          </PFText>
        ) : (
          footerText
        )}
      </View>
    )
  }

  return (
    <Box>
      <BasicTile
        padding={smallGap}
        radius={tileRadius}
        styles={backgroundColor ? {background: backgroundColor} : undefined}
      >
        {loading ? (
          <PromoTileLoader />
        ) : (
          <>
            {pillContent}
            {tileContent}
            {buttonContent}
            {footerContent}
          </>
        )}
      </BasicTile>
    </Box>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerContent: {
    marginTop: smallGap,
  },
  imageContainer: {
    alignItems: 'flex-end',
    flexGrow: 1,
    flexShrink: 0,
    width: 100,
  },
  loader: {
    height: tileHeight,
  },
  pill: {
    borderRadius: 4,
    marginBottom: smallGap,
    marginTop: littleGap,
  },
  text: {
    flexGrow: 0,
    flexShrink: 1,
    marginRight: 5,
  },
})
