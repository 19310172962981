import {StackScreenProps} from '@react-navigation/stack'

import {Address} from '@possible/cassandra/src/types/types.mobile.generated'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, AppEvent} from 'src/lib/Analytics/app_events'
import {EditPassword, EditEmail} from 'src/navigation/const'
import {
  onSendEmailVerificationCode,
  onEditEmailVerificationSuccess,
  BusyHook,
} from 'src/products/general/EmailConfirmation/EmailConfirmation.utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type Props = StackScreenProps<MainStackParamList, 'Profile'>
type Navigation = Props['navigation']

export const formatAddressMultiline = (address?: Partial<Address> | null): string => {
  let userAddress = ''
  let empty = true
  if (address?.street1) {
    empty = false
    userAddress = userAddress.concat(address?.street1)
  }
  if (address?.street2) {
    empty = false
    userAddress = userAddress.concat(`${empty ? '' : '\n'}${address?.street2}`)
  }
  if (address?.city || address?.state || address?.postalCode) {
    userAddress = userAddress.concat(
      `${empty ? '' : '\n'}${address?.city}${address?.city ? ', ' : ''}${address?.state} ${
        address?.postalCode
      }`,
    )
  }
  return userAddress
}

export const openEditPassword = (navigation: Navigation): void => {
  TrackAppEvent(AppEvents.Name.edit_password_selected, AppEvents.Category.Admin)
  navigation?.navigate(EditPassword)
}

export const openEditEmail = (navigation: Navigation): void => {
  TrackAppEvent(AppEvents.Name.edit_email_address_selected, AppEvents.Category.Admin, {
    from: 'Profile',
  })
  navigation?.navigate(EditEmail, {fromProfile: true})
}

export const onEditAddress = (navigation: Navigation, title: string): void => {
  navigation?.navigate('AddressUpdate', {
    title: title,
  })
}
export const verifyEmail = async (navigation: Navigation, setBusy: BusyHook): Promise<void> => {
  const event: AppEvent = {
    name: AppEvents.Name.profile_send_email_verification_code,
    category: AppEvents.Category.Admin,
  }
  await onSendEmailVerificationCode(
    event,
    onEditEmailVerificationSuccess,
    navigation,
    setBusy,
    true,
  )
}
