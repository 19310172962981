import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {
  LinkedAccountOwnerType,
  LinkedAccountOwnershipValidationType,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {
  accountsNeedsReview,
  typeOfValidationError,
} from 'src/products/MCU/AccountManagementV2/AccountManagement.utils'
import {LinkedAccountOwnershipSummarySubset} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'

const getOwnershipAmplitudeEvent = ({
  doesAccountNeedReview,
  hasNameValidationError,
  hasStateValidationError,
  isBusinessAccount,
  isJointAccount,
}) => {
  if (isJointAccount) {
    return (): void =>
      TrackAppEvent(
        BankAccountManagementEvents.bank_ownership_joint_account_viewed,
        AppEvents.Category.BankAccountManagement,
      )
  } else if (isBusinessAccount) {
    return (): void =>
      TrackAppEvent(
        BankAccountManagementEvents.bank_ownership_non_personal_account_viewed,
        AppEvents.Category.BankAccountManagement,
      )
  } else if (hasNameValidationError && doesAccountNeedReview) {
    return (): void =>
      TrackAppEvent(
        BankAccountManagementEvents.bank_ownership_name_challenge_viewed,
        AppEvents.Category.BankAccountManagement,
      )
  } else if (hasNameValidationError && !hasStateValidationError) {
    return (): void =>
      TrackAppEvent(
        BankAccountManagementEvents.bank_ownership_name_fail_viewed,
        AppEvents.Category.BankAccountManagement,
      )
  } else if (hasStateValidationError && !hasNameValidationError) {
    return (): void =>
      TrackAppEvent(
        BankAccountManagementEvents.bank_ownership_address_fail_viewed,
        AppEvents.Category.BankAccountManagement,
      )
  } else if (hasNameValidationError || hasStateValidationError) {
    return (): void =>
      TrackAppEvent(
        BankAccountManagementEvents.bank_ownership_name_and_address_fail_viewed,
        AppEvents.Category.BankAccountManagement,
      )
  } else {
    return (): void => {}
  }
}

type UseOwnershipStatusProps = {
  ownershipSummary?: LinkedAccountOwnershipSummarySubset
}

type UseOwnershipStatusReturn = {
  doesAccountNeedReview: boolean
  hasNameValidationError: boolean
  hasStateValidationError: boolean
  isBusinessAccount: boolean
  isJointAccount: boolean
  ownershipAmplitudeEvent: () => void
}

export const useOwnershipStatus = (props: UseOwnershipStatusProps): UseOwnershipStatusReturn => {
  const {ownershipSummary} = props

  const hasNameValidationError = typeOfValidationError(
    ownershipSummary?.validationErrors,
    LinkedAccountOwnershipValidationType.Name,
  )

  const hasStateValidationError = typeOfValidationError(
    ownershipSummary?.validationErrors,
    LinkedAccountOwnershipValidationType.State,
  )

  const doesAccountNeedReview = accountsNeedsReview(ownershipSummary?.validationErrors)

  const isBusinessAccount =
    ownershipSummary?.ownerType === LinkedAccountOwnerType.Association ||
    ownershipSummary?.ownerType === LinkedAccountOwnerType.Trust

  const isJointAccount = ownershipSummary?.ownerType === LinkedAccountOwnerType.Joint

  const ownershipAmplitudeEvent = getOwnershipAmplitudeEvent({
    doesAccountNeedReview,
    hasNameValidationError,
    hasStateValidationError,
    isBusinessAccount,
    isJointAccount,
  })

  return {
    doesAccountNeedReview,
    hasNameValidationError,
    hasStateValidationError,
    isBusinessAccount,
    isJointAccount,
    ownershipAmplitudeEvent,
  }
}
