/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react-prefer-function-component/react-prefer-function-component */

/* eslint-disable @typescript-eslint/no-unused-vars */

import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'
import {View, Animated, Easing, ViewStyle, StyleProp, StyleSheet} from 'react-native'

import {Indicator} from 'src/designSystem/components/atoms/Loading/indicators/indicator'

interface MaterialIndicatorProps {
  animationDuration?: number
  trackWidth?: number
  color?: string
  size?: number
  style?: StyleProp<ViewStyle>
}

interface RenderComponentProps {
  index: number
  count: number
  progress: Animated.Value
}

export class MaterialIndicator extends PureComponent<MaterialIndicatorProps> {
  static defaultProps = {
    animationDuration: 4000,

    color: 'rgb(0, 0, 0)',
    size: 40,
  }

  static propTypes = {
    ...Indicator.propTypes,

    trackWidth: PropTypes.number,
    color: PropTypes.string,
    size: PropTypes.number,
  }

  _renderComponent = ({index, count, progress}: RenderComponentProps) => {
    const {
      size = 40,
      color = 'rgb(0, 0, 0)',
      trackWidth: borderWidth = size / 10,
      animationDuration = 4000,
    } = this.props

    const frames = (60 * animationDuration) / 1000
    const easing = Easing.bezier(0.4, 0.0, 0.7, 1.0)

    const sa = 7.5
    const ea = 30

    const sequences = 3
    const rotations = 5

    const inputRange = Array.from(
      new Array(frames),
      (item, frameIndex) => frameIndex / (frames - 1),
    )

    const outputRange = Array.from(new Array(frames), (item, frameIndex) => {
      let progress = (2 * sequences * frameIndex) / (frames - 1)
      const rotation = index ? +(360 - sa) : -(180 - sa)

      const sequence = Math.ceil(progress)

      if (sequence % 2) {
        progress = progress - sequence + 1
      } else {
        progress = sequence - progress
      }

      const direction = index ? -1 : +1

      return direction * (180 - (sa + ea)) * easing(progress) + rotation + 'deg'
    })

    const layerStyle = {
      width: size,
      height: size,
      transform: [
        {
          rotate: 90 - sa + 'deg',
        },
        {
          rotate: progress.interpolate({
            inputRange: [0, 1],
            outputRange: ['0deg', 360 * rotations + 'deg'],
          }),
        },
      ],
    }

    const viewportStyle = {
      width: size,
      height: size,
      transform: [
        {
          translateY: index ? -size / 2 : 0,
        },
        {
          rotate: progress.interpolate({inputRange, outputRange}),
        },
      ],
    }

    const containerStyle = {
      width: size,
      height: size / 2,
      overflow: 'hidden' as const,
    }

    const offsetStyle = index ? {top: size / 2} : null

    const lineStyle = {
      width: size,
      height: size,
      borderColor: color,
      borderRadius: size / 2,
      borderWidth,
    }

    return (
      <Animated.View style={styles.layer} key={index}>
        <Animated.View style={layerStyle}>
          <Animated.View style={[containerStyle, offsetStyle]} collapsable={false}>
            <Animated.View style={viewportStyle}>
              <Animated.View style={containerStyle} collapsable={false}>
                <Animated.View style={lineStyle} />
              </Animated.View>
            </Animated.View>
          </Animated.View>
        </Animated.View>
      </Animated.View>
    )
  }

  render() {
    const {style, size: width = 40, size: height = 40, ...props} = this.props

    return (
      <View style={[styles.container, style]}>
        <Indicator
          style={{width, height}}
          renderComponent={this._renderComponent}
          {...props}
          count={2}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  layer: {
    ...StyleSheet.absoluteFillObject,

    justifyContent: 'center',
    alignItems: 'center',
  },
})
