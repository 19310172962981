import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PopPage, PushPage} from 'src/navigation/NavHelper'
import {usePfSelector} from 'src/store/utils'
import {uraIds} from 'src/lib/ura/selectors'
import {PfReduxState} from 'src/reducers/types'
import {completeUra} from 'src/cassandra'
import {logErrorAndShowException} from 'src/lib/errors'
import {LightboxNoticeAction} from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'

export enum LinkUsableAccountUraType {
  LinkAccountWithNewName = 'link_account_with_new_name',
  LinkPersonalAccount = 'link_personal_account',
  RelinkAccountSetPrimary = 'relink_account_set_primary',
}

type Props = {
  uraId: string
  type: LinkUsableAccountUraType
  navigation?: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}

const eventsMap = {
  viewed: {
    link_account_with_new_name: BankAccountManagementEvents.link_account_with_new_name_URA_viewed,
    link_personal_account: BankAccountManagementEvents.select_personal_account_URA_viewed,
    relink_account_set_primary: BankAccountManagementEvents.relink_account_set_primary_URA_viewed,
  },
  completed: {
    link_account_with_new_name:
      BankAccountManagementEvents.link_account_with_new_name_URA_completed,
    link_personal_account: BankAccountManagementEvents.select_personal_account_URA_completed,
    relink_account_set_primary:
      BankAccountManagementEvents.relink_account_set_primary_URA_completed,
  },
  error: {
    link_account_with_new_name: BankAccountManagementEvents.link_account_with_new_name_URA_error,
    link_personal_account: BankAccountManagementEvents.select_personal_account_URA_error,
    relink_account_set_primary: BankAccountManagementEvents.relink_account_set_primary_URA_error,
  },
  cta: {
    link_account_with_new_name: BankAccountManagementEvents.link_account_with_new_name_URA_CTA,
    link_personal_account: BankAccountManagementEvents.select_personal_account_URA_CTA,
    relink_account_set_primary: BankAccountManagementEvents.relink_account_set_primary_URA_CTA,
  },
}

const LinkUsableAccount: FC<Props> = (props) => {
  const {uraId, navigation, type} = props
  const {t} = useTranslation('UserRequestedAction')
  // URA redux state needs proper typing before this can work
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  const ura: any = usePfSelector((state: PfReduxState) => uraIds(state)?.get(uraId))

  TrackAppEvent(eventsMap.viewed[type], AppEvents.Category.BankAccountManagement)

  const close = (): void => {
    navigation?.goBack()
  }

  const onChooseNewAccountComplete = async (): Promise<void> => {
    try {
      TrackAppEvent(eventsMap.completed[type], AppEvents.Category.BankAccountManagement)
      await completeUra(uraId)
      PopPage(navigation)
      close()
    } catch (e) {
      void logErrorAndShowException(e, 'LinkUsableAccount, onChooseNewAccountComplete:')
      TrackAppEvent(eventsMap.error[type], AppEvents.Category.BankAccountManagement)
    }
  }

  const onChooseAccount = (): void => {
    TrackAppEvent(eventsMap.cta[type], AppEvents.Category.BankAccountManagement)
    PushPage(navigation, 'AccountManagementV2', {
      onChooseNewAccountComplete,
      isFromURA: true,
    })
  }

  const primaryCta: LightboxNoticeAction = {
    text:
      type === LinkUsableAccountUraType.RelinkAccountSetPrimary
        ? t('VisitAccountManagement')
        : t('LinkAnotherAccount'),
    size: 'large',
    onPress: () => onChooseAccount(),
  }

  return (
    <URAModalContent
      uraId={uraId}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      ura={ura}
      primary={primaryCta}
      close={close}
    />
  )
}

export {LinkUsableAccount}
