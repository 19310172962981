import React, {useState} from 'react'

import {Consumer} from '@possible/cassandra'
import {useCassandraLazyQuery, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {StateSelectedDocument} from 'src/products/MCU/Address/queries/AddressCollection.gqls'
import {MainStackScreenProps} from 'src/nav/MainStackParamsList'
import {allowedStates} from 'src/lib/us_states'
import {ClearSelectedOfferAction, SetWorkflowStateAction} from 'src/workflows/slice'
import {usePfDispatch} from 'src/store/utils'
import {
  UpdateAddressGetOffersDocument,
  UpdateAddressGetOffersQuery,
} from 'src/products/MCU/Address/queries/UpdateAddressGetOffers.gqls'
import {SelectLoanOffer} from 'src/products/loans/Dashboard/DashboardLoanUtils/DashboardLoan.utils'
import Log from 'src/lib/loggingUtil'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {AddressUpdateGQLContainer} from 'src/products/MCU/Address/AddressUpdateGQLContainer'
import {getIsAppRevampFunctionalUpdatesEnabled} from 'src/lib/experimentation/appRevampFeatureFlag'

type Props = MainStackScreenProps<'UpdateAddress'>

const UpdateAddressContainer: React.FC<Props> = ({navigation}) => {
  const dispatch = usePfDispatch()
  const isAppRevampFunctionalUpdatesEnabled = getIsAppRevampFunctionalUpdatesEnabled()

  const [newState, setNewState] = useState<string | null>(null)

  const {selectedData: selectedState, loading: isLoadingSelectedState} = useCassandraQuery(
    StateSelectedDocument,
    {},
    (data) => data?.me?.onboarding?.loan?.stateSelected,
  )

  const handleOnChangeToSupportedState = async (
    data: UpdateAddressGetOffersQuery,
  ): Promise<void> => {
    const userOffers = data?.me?.products?.eligible?.all?.filter(
      (offer) => offer?.__typename === 'LoanOfferInfo',
    )
    if (!userOffers) {
      throw new Error('No offers found')
    }

    const newStateLoanOffer = SelectLoanOffer(userOffers)

    await dispatch(ClearSelectedOfferAction())

    if (newStateLoanOffer) {
      await dispatch(
        SetWorkflowStateAction({
          selectedOffer: {offerId: newStateLoanOffer, metFrontEndPreReqs: []},
        }),
      )

      TrackAppEvent(AppEvents.Name.review_change_state_supported, AppEvents.Category.Onboarding, {
        oldState: selectedState,
        newState,
      })

      navigation.reset({
        index: 0,
        routes: [
          {
            name: 'OfferApplicationWorkflow',
            params: {
              offerId: newStateLoanOffer,
            },
          },
        ],
      })
    }
  }

  const [fetchOffers, {loading: isLoadingOffers}] = useCassandraLazyQuery(
    UpdateAddressGetOffersDocument,
    {
      onCompleted: (data) => void handleOnChangeToSupportedState(data),
      onError: (error) => {
        Log.error(`${error.message} - UpdateAddress Screen - UpdateAddressGetOffersDocument query`)
      },
    },
  )

  const handleOnComplete = async (newAddress: Consumer.types.Address): Promise<void> => {
    if (!newAddress.state || selectedState === newAddress.state) {
      navigation.pop()
      return
    }

    const isAllowedState = allowedStates().includes(newAddress.state)

    setNewState(newAddress.state)
    // On fetchOffers completion, handles change to supported state
    await fetchOffers()

    if (!isAllowedState) {
      // Handles change to unsupported state
      TrackAppEvent(AppEvents.Name.review_change_state_unsupported, AppEvents.Category.Onboarding, {
        oldState: selectedState,
        newState: newAddress.state,
      })

      navigation.reset({
        index: 0,
        routes: [{name: 'UnsupportedStateWaitList'}],
      })
    }
  }

  return (
    <AddressUpdateGQLContainer
      onComplete={handleOnComplete}
      loading={isLoadingSelectedState || isLoadingOffers}
      isForReviewAndEdit={true}
      isAppRevampFunctionalUpdatesEnabled={isAppRevampFunctionalUpdatesEnabled}
    />
  )
}

export {UpdateAddressContainer}
