import {StackNavigationProp} from '@react-navigation/stack'
import {useCallback} from 'react'

import {getIsAppRevampFunctionalUpdatesEnabled} from 'src/lib/experimentation/appRevampFeatureFlag'
import {usePushPermissions} from 'src/lib/usePushPermissions'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {WorkflowsStackParams} from 'src/workflows/types'

type UsePhoneNumberInputVerificationNavigationType = (
  navigation: StackNavigationProp<WorkflowsStackParams, 'PhoneConfirmation'>,
) => () => void

export const usePhoneNumberInputVerificationNavigation: UsePhoneNumberInputVerificationNavigationType =
  (navigation: StackNavigationProp<WorkflowsStackParams, 'PhoneConfirmation'>) => {
    const {canAccessCards: isCardUser} = useCanAccessCards()
    const [hasPushPermissions] = usePushPermissions()
    const isAppRevampFunctionalUpdatesFFEnabled = getIsAppRevampFunctionalUpdatesEnabled()

    return useCallback((): void => {
      if (!isCardUser && !isAppRevampFunctionalUpdatesFFEnabled) {
        navigation.navigate('ApplicationSteps')
      } else if (isDeviceWeb() || hasPushPermissions || isAppRevampFunctionalUpdatesFFEnabled) {
        navigation.navigate('PersonalInformation')
      } else {
        navigation.navigate('NotificationsPermissions')
      }
    }, [hasPushPermissions, isAppRevampFunctionalUpdatesFFEnabled, isCardUser, navigation])
  }
