import PropTypes from 'prop-types'
import {Image, Text, TextInput, View} from 'react-native'

import {DevModeFilePath} from 'src/lib/devMode/devMode.consts'
import Log from 'src/lib/loggingUtil'
import RNFS from 'src/lib/RNFS/RNFS'

Log.log('Monkey Patching WebApp React Native Prop Types')

// This was copied over from the original `devMode.ts` file
// but I have no idea why it was in there (and not some place
// named more appropriately):
// @ts-expect-error Gotta do what we gotta do
Text.propTypes = {style: PropTypes.any}
// @ts-expect-error Gotta do what we gotta do
View.propTypes = {style: PropTypes.any}
// @ts-expect-error Gotta do what we gotta do
Image.propTypes = {style: PropTypes.any}
// // @ts-ignore
// ReactNative.ViewPropTypes = {style: PropTypes.any}
// @ts-expect-error Gotta do what we gotta do
TextInput.propTypes = {style: PropTypes.any}

if (__DEV__) {
  void RNFS.writeFile(DevModeFilePath, 'dev_mode', 'utf8')
}
