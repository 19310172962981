import {useCallback} from 'react'

import {useCassandraLazyQuery} from '@possible/cassandra/src/utils/hooks'
import {updateAnalyticsWithCohortData} from 'src/api/actions/card/utils'
import {Consumer, CohortPhase} from 'src/cassandra'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'
import {useGetCohortUserType} from 'src/products/card/Application/useGetCohortUserType/useGetCohortUserType'
import {useIsCardEligible} from 'src/products/card/Application/UseIsCardEligible/useIsCardEligible'
import {GetCardCohortPhaseDocument} from 'src/products/loans/Landing/GetCardCohortPhase.gqls'
import {useGetCardsChannel} from 'src/products/loans/Landing/useGetCardsChannel'

/**
 * This hook sets the required user misc props for cards. These include:
 * `possibleCard` - this always set to true
 * `cohortPhase` and `cohortUserType` - these are set on the card landing page
 * If they have already been set, they should not be overwritten
 * This hook also sets the user's `cohortPhase` and `cohortUserType` in Amplitude
 */
type UseInitializeCardsType = () => {
  initializeCards: () => Promise<void>
  isLoading: boolean
}

export const useInitializeCards: UseInitializeCardsType = () => {
  const {cohortUserType, isLoading: isLoadingUserType} = useGetCohortUserType()
  const [cardToggleApplicationProperties] =
    Consumer.hooks.useCardAccountToggleApplicationPropertiesMutation()
  const getCardsChannel = useGetCardsChannel()
  const [queryCardCohortPhase] = useCassandraLazyQuery(GetCardCohortPhaseDocument, {
    fetchPolicy: 'network-only',
  })
  const {isLoading: isEligibleToApplyLoading, isEligibleForCards} = useIsCardEligible()

  // Calls UserMiscProps to set the user cohort data if this data has not already been set
  const initializeCards = useCallback(async () => {
    try {
      // If the user is rejected + not eligible to reapply, don't enable cards for them
      // This happens for rejected users who click on the deep link again
      if (!isEligibleForCards) {
        return
      }

      // Get cohort from BE
      const {data} = await queryCardCohortPhase({variables: {channel: getCardsChannel()}})
      const cohortPhase = data?.me.products.properties?.cardCohortPhase ?? undefined

      // Add user to cards if a cohort is provided
      const isEnabled = !!cohortPhase && cohortPhase !== CohortPhase.None
      Log.debug('Adding user to cards')
      TrackAppEvent(CardEvents.user_added_to_cards, AppEvents.Category.Card)
      await cardToggleApplicationProperties({
        variables: {
          applicationDataInput: {
            enabled: isEnabled,
            miscData: {
              cohortPhase,
              cohortUserType,
            },
            extraFlags: [],
          },
        },
      })

      Log.debug('Updating user analytics')
      updateAnalyticsWithCohortData({
        possibleCard: isEnabled,
        cohortPhase,
        cohortUserType,
      })
    } catch (e) {
      Log.error(e, 'Error setting user cohort properties')
    }
  }, [
    cohortUserType,
    cardToggleApplicationProperties,
    getCardsChannel,
    queryCardCohortPhase,
    isEligibleForCards,
  ])

  return {initializeCards, isLoading: isEligibleToApplyLoading || isLoadingUserType}
}
