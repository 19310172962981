import {
  LoanCustomPaymentIntention,
  LoanCustomPaymentOption,
  LoanPaymentStatusCode,
} from '@possible/cassandra/src/types/types.mobile.generated'
import i18n from 'src/lib/localization/i18n'

import {PaymentAction} from 'src/products/general/MakePayment/PaymentInput/PaymentInput.types'
import {
  convertPaymentIntentionStringToEnum,
  getLoanPaymentOptionLabel,
  getLoanPaymentOptionTrackingId,
} from 'src/products/loans/MakePayment/LoanPay.utils'
import {
  LoanPayAmountEntryPayment,
  LoanPayAmountEntryPaymentOption,
} from 'src/products/loans/MakePayment/LoanPayAmountEntry/LoanPayAmountEntry.types'

/**
 * Get the button actions for the list of loan make payment options (Full Balance, Missed Installment, etc)
 * to be used with <PaymentInput /> component.
 */
export const getLoanPaymentOptionButtons = (args: {
  customPaymentOptions: LoanCustomPaymentOption[]
  selectedLoanCustomPaymentOption?: LoanCustomPaymentOption
}): {
  buttonActions: PaymentAction[] | undefined
  selectedButton: PaymentAction | undefined
} => {
  const {customPaymentOptions, selectedLoanCustomPaymentOption} = args

  const buttonActions: PaymentAction[] = customPaymentOptions.map((option) => ({
    amount: option.amount,
    label: getLoanPaymentOptionLabel(option),
    trackingId: getLoanPaymentOptionTrackingId(option),
  }))

  const selectedButton = buttonActions.find((action) => {
    const buttonPaymentIntention = convertPaymentIntentionStringToEnum(action.trackingId)
    return buttonPaymentIntention === selectedLoanCustomPaymentOption?.intention
  })

  return {
    buttonActions,
    selectedButton,
  }
}

/**
 * Get optional notice text to display to users, such as if they already have scheduled payments in progress.
 */
export const getInfoNoticeText = (args: {
  selectedButton?: PaymentAction
  payments: LoanPayAmountEntryPayment[]
}): string | undefined => {
  const {selectedButton, payments} = args
  const isOverdueBalanceSelected =
    selectedButton?.trackingId === LoanCustomPaymentIntention.OverdueBalance.valueOf()

  if (isOverdueBalanceSelected) {
    return i18n.t('MakePaymentAmountEntry:OverdueBalancePaymentInfo')
  }
  const paymentInProgress = payments.find(
    (payment) => payment.statusCode === LoanPaymentStatusCode.InProgress,
  )
  return paymentInProgress
    ? i18n.t('MakePaymentAmountEntry:YourScheduledPayment', {
        paymentAmount: paymentInProgress.amount,
        accountMask: paymentInProgress.lastTransaction?.accountMask,
      })
    : undefined
}

/**
 * Get the default payment option to select.
 */
export const getDefaultLoanPaymentOption = (args: {
  customPaymentOptions: LoanPayAmountEntryPaymentOption[]
}): LoanPayAmountEntryPaymentOption | undefined => {
  const {customPaymentOptions} = args
  // sort payment options by lowest first
  const customPaymentOptionsSortedLowestFirst = customPaymentOptions
    ?.map((thisPaymentOption) => ({...thisPaymentOption}))
    .sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount))

  // select a default payment option/intention as the lowest amount
  return customPaymentOptionsSortedLowestFirst?.[0]
}
