import React, {FC, useEffect, useState} from 'react'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {logErrorAndShowException, logAndShowErrorMessage} from 'src/lib/errors'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {GenericVerification} from 'src/products/loans/PhoneConfirmation/GenericVerification'
import {verificationMethod} from 'src/products/MCU/RegistrationOrLogin/OTPVerification'
import AppNav from 'src/nav/AppNavActions'
import {Consumer, UserVerificationMessageType, Maybe} from 'src/cassandra'
import {getUserProfile} from 'src/lib/card/selectors'
import {snackbarErrorMessage} from 'src/lib/Snackbar/util'
import {HeaderBackButton} from 'src/nav/Header/HeaderBackButton/HeaderBackButton'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {UserMfaDeliveryMedium} from '@possible/cassandra/src/types/types.public.generated'

type Props = StackScreenProps<MainStackParamList, 'OTPIdentityVerification'> & {
  resolve: () => boolean
  verificationType: Consumer.types.UserVerificationType
  onCancel?: () => void
}

export const phoneNumberVerificationMethodToUserVerificationMessageType = (
  methodType: UserMfaDeliveryMedium,
): UserVerificationMessageType => {
  const conversion = {
    [UserMfaDeliveryMedium.Voice]: UserVerificationMessageType.PhoneVoice,
    [UserMfaDeliveryMedium.Email]: UserVerificationMessageType.EmailCode,
    [UserMfaDeliveryMedium.Sms]: UserVerificationMessageType.PhoneSms,
  }
  return methodType in conversion ? conversion[methodType] : UserVerificationMessageType.PhoneSms
}

const userVerificationMessageToAccountMfaDeliveryMedium = (
  userVerificationMessage: UserVerificationMessageType,
): UserMfaDeliveryMedium => {
  if (
    userVerificationMessage === UserVerificationMessageType.EmailLink ||
    userVerificationMessage === UserVerificationMessageType.EmailCode
  ) {
    return UserMfaDeliveryMedium.Email
  } else if (userVerificationMessage === UserVerificationMessageType.PhoneVoice) {
    return UserMfaDeliveryMedium.Voice
  } else {
    return UserMfaDeliveryMedium.Sms
  }
}

const getSource = (
  deliveryMethod: UserVerificationMessageType,
  userProfile: Maybe<Consumer.types.UserProfile>,
): string | undefined => {
  const sources = {
    [UserVerificationMessageType.EmailCode]: userProfile?.email?.address,
    [UserVerificationMessageType.EmailLink]: userProfile?.email?.address,
    [UserVerificationMessageType.PhoneSms]: userProfile?.phone?.number,
    [UserVerificationMessageType.PhoneVoice]: userProfile?.phone?.number,
  }
  return sources[deliveryMethod] ?? undefined
}

type HeaderProps = Pick<Props, 'navigation' | 'onCancel'>
const OTPIdentityVerificationHeader: FC<HeaderProps> = ({navigation, onCancel: handleOnCancel}) => (
  <Box paddingHorizontal="medium" paddingVertical="small">
    <HeaderBackButton navigation={navigation} onPress={handleOnCancel} />
  </Box>
)

export const OTPIdentityVerification: FC<Props> = (props) => {
  const {navigation, resolve, verificationType, onCancel: handleOnCancel} = props
  const [requestMfa] = Consumer.hooks.useRequestMfaMutation()
  const [validateMfa] = Consumer.hooks.useValidateMfaMutation()
  // Default delivery method is sms
  const [deliveryMethod, setDeliveryMethod] = useState<UserVerificationMessageType>(
    UserVerificationMessageType.PhoneSms,
  )
  const {t} = useTranslation(['OTPVerification', 'Common'])
  const userProfile = useSelector(getUserProfile)

  const callRequestMfa = async (mfaDeliveryMethod: UserVerificationMessageType): Promise<void> => {
    await requestMfa({
      variables: {
        messageType: mfaDeliveryMethod,
        verificationType,
      },
    })
  }

  React.useLayoutEffect(() => {
    if (handleOnCancel) {
      const header = (
        <OTPIdentityVerificationHeader navigation={navigation} onCancel={handleOnCancel} />
      )
      navigation.setOptions({
        header: () => header,
      })
    }
  }, [navigation, handleOnCancel])

  useEffect(() => {
    TrackAppEvent(AppEvents.Name.otp_code_screen_viewed, AppEvents.Category.Admin)
    void callRequestMfa(deliveryMethod)
    // Call requestMfa on load. Don't call it again here if the deps change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onResend = async (resendMethod: UserMfaDeliveryMedium): Promise<boolean> => {
    let isSuccessful = true

    try {
      TrackAppEvent(AppEvents.Name.otp_resend_verification_selected, AppEvents.Category.Activation)
      const newDeliveryMethod =
        phoneNumberVerificationMethodToUserVerificationMessageType(resendMethod)
      setDeliveryMethod(newDeliveryMethod)
      await callRequestMfa(newDeliveryMethod)
    } catch (e) {
      void logErrorAndShowException(e, 'OTPIdentityVerification, onResend:')
      isSuccessful = false
    }
    return isSuccessful
  }

  const onPress = async (code: string): Promise<void> => {
    try {
      TrackAppEvent(AppEvents.Name.otp_verification_code_submitted, AppEvents.Category.Admin, {
        method: deliveryMethod,
      })

      const response = await validateMfa({
        variables: {
          source: getSource(deliveryMethod, userProfile) ?? '',
          verificationType,
          verificationCode: code,
        },
      })

      // Rate limited
      if (response?.errors?.[0]?.extensions?.errorCode === 'MGE0007') {
        snackbarErrorMessage(t('RateLimited'))
        return
      }

      // Other error
      if (response?.errors?.[0]) {
        logAndShowErrorMessage({error: response?.errors?.[0]})
        return
      }

      // Failed validation
      if (!response?.data?.validateMfa) {
        snackbarErrorMessage(t('UnabledToVerify'))
        return
      }

      AppNav.pop(navigation)
      resolve()
    } catch (e) {
      void logErrorAndShowException(e, 'OTPIdentityVerification, onPress:')
    }
  }

  return (
    <GenericVerification
      resendAction={(resendMethod: UserMfaDeliveryMedium): Promise<boolean> =>
        onResend(resendMethod)
      }
      submitAction={(code: string): Promise<void> => onPress(code)}
      verificationMethod={verificationMethod(
        userVerificationMessageToAccountMfaDeliveryMedium(deliveryMethod),
      )}
    />
  )
}

export default withForwardedNavigationParams<Props>()(OTPIdentityVerification)
