import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigation} from '@react-navigation/native'
import {useSelector} from 'react-redux'
import {StackNavigationProp} from '@react-navigation/stack'

import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'
import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PfReduxState} from 'src/reducers/types'
import {LightboxNoticeAction} from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'

type DocumentIdExpiredURAProps = Pick<URAProps, 'ura_id'> & Partial<Pick<URAProps, 'close'>>

export const DocumentIdExpiredURA: React.FC<DocumentIdExpiredURAProps> = ({
  ura_id: uraId,
  /**
   * the actual `close` function is injected by Lightbox.
   * Although it's not required we need to call it to close the lightbox.
   */
  close: onClose = (): void => {},
}) => {
  const {t} = useTranslation('UserRequestedAction')
  const navigation = useNavigation<StackNavigationProp<MainStackParamList, 'DocumentIdExpired'>>()

  const ura = useSelector((state: PfReduxState) => get_ura_by_id_from_redux(uraId, state))

  const handleRetake = (): void => {
    navigation.navigate('RetakeFrontDL', {
      ura_id: uraId,
      onComplete: onClose,
    })
  }

  const primary: LightboxNoticeAction = {
    text: t('DocumentIdExpiredCTA'),
    testID: 'Document-Id-Expired-CTA',
    onPress: handleRetake,
  }

  return <URAModalContent uraId={uraId} ura={ura} primary={primary} close={onClose} />
}
