import React from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'

import {StateSelectTemplate} from 'src/products/general/StateSelect/StateSelectTemplate'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {UserSetResidenceStateDocument} from 'src/products/general/StateSelect/queries/UserSetResidenceState.gqls'
import {StateSelectFormType} from 'src/products/general/StateSelect/StateSelect.types'
import Log from 'src/lib/loggingUtil'
import {isAdvanceState} from 'src/lib/advance/CashAdvanceUtils'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type Props = WorkflowPreReqFulfillScreenProps

const StateSelectWorkflowContainer: React.FC<Props> = (props): JSX.Element => {
  const {onPreReqFulfilled} = props
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const {canAccessCards} = useCanAccessCards()

  const [selectState, {loading: isLoadingStateSelect}] = useCassandraMutation(
    UserSetResidenceStateDocument,
    {
      onError: (error) => {
        Log.error(`${error.message} - StateSelect Screen - UserSetResidenceStateDocument mutation`)
      },
    },
  )

  usePageViewedAnalytics({
    eventCategory: AppEvents.Category.Application,
    eventName: AppEvents.Name.state_selector_viewed,
  })

  const handleOnSubmit = async ({state}: StateSelectFormType): Promise<void> => {
    if (!state) {
      return
    }

    await selectState({variables: {state}})
    TrackAppEvent(AppEvents.Name.state_selector_submitted, AppEvents.Category.Application, {
      state,
    })
    if (isAdvanceState(state, canAccessCards)) {
      navigation.navigate('AdvanceSplashScreen')
    } else {
      void onPreReqFulfilled()
    }
  }
  return <StateSelectTemplate onSubmit={handleOnSubmit} isLoading={isLoadingStateSelect} />
}

export {StateSelectWorkflowContainer}
