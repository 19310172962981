import React, {useEffect} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {CompositeNavigationProp, useNavigation} from '@react-navigation/native'

import {Loading} from 'src/designSystem/components/atoms/Loading/Loading'
import {WorkflowsStackParams} from 'src/workflows/types'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type WorkflowLoadingProps = {
  onNavigationLoaded?: (navigation: StackNavigationProp<WorkflowsStackParams>) => void
}

type WorkflowLoadingNavigationType = CompositeNavigationProp<
  StackNavigationProp<WorkflowsStackParams>,
  StackNavigationProp<MainStackParamList>
>

export const WorkflowLoading: React.FC<WorkflowLoadingProps> = (props) => {
  const {onNavigationLoaded} = props
  const navigation = useNavigation<WorkflowLoadingNavigationType>()

  useEffect(() => {
    if (navigation && onNavigationLoaded) {
      const workflowNav = navigation
      onNavigationLoaded(workflowNav)
    }
  }, [navigation, onNavigationLoaded])

  return (
    <Box fill justify="center" align="center" testID="WorkflowLoading">
      <Loading size="large" type="loader0" />
    </Box>
  )
}
