import {ApolloQueryResult} from '@apollo/client'

import {Consumer} from '@possible/cassandra'
import {ProductsQuery} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCallback, useRef, useState} from 'react'

import {MoveToNextPreReqRoute} from 'src/workflows/navigation'
import {MoveToNextPreReqRouteArgsType, PreReqType} from 'src/workflows/types'

/**
 * The type of the function returned by useMoveToNextPreReqRoute().
 */
type UseMoveToNextPreReqRouteResult = <
  T extends ApolloQueryResult<unknown> = ApolloQueryResult<Consumer.types.ProductsQuery>,
>(
  // this is the same as MoveToNextPreReqRoute but it excludes workflowName since that
  // will be provided to useMoveToNextPreReqRoute() and passed to MoveToNextPreReqRoute
  args: Omit<MoveToNextPreReqRouteArgsType<T>, 'workflowName'>,
) => Promise<void>

const useMoveToNextPreReqRoute = ({
  unmet: initialUnmet,
  met: initialMet,
  workflowName,
}: {
  met: PreReqType[]
  unmet: PreReqType[]
  workflowName: string
}): UseMoveToNextPreReqRouteResult => {
  const isNavigatingRef = useRef(false)
  const [previousPreReqs, setPreviousPreReqs] = useState<
    MoveToNextPreReqRouteArgsType['previousPreReqs']
  >({met: initialMet, unmet: initialUnmet})

  return useCallback(
    async <T extends ApolloQueryResult<unknown> = ApolloQueryResult<ProductsQuery>>(
      args: Omit<MoveToNextPreReqRouteArgsType<T>, 'workflowName'>,
    ): Promise<void> => {
      if (isNavigatingRef.current) {
        // avoid spamming navigation multiple times if nav has already started
        return
      }
      isNavigatingRef.current = true
      try {
        const newPreReqs = await MoveToNextPreReqRoute<T>({
          ...args,
          previousPreReqs,
          workflowName,
        })
        setPreviousPreReqs(newPreReqs)
      } finally {
        // wait for navigation animation to complete before allowing another navigation
        await new Promise((resolve) => setTimeout(resolve, 500))

        isNavigatingRef.current = false
      }
    },
    [previousPreReqs, workflowName],
  )
}

export {useMoveToNextPreReqRoute}
