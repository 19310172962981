import React, {useEffect} from 'react'

import {Loading} from 'src/designSystem/components/atoms/Loading/Loading'

export type AggregatorPlaidTemplateProps = {
  onOpenLink: () => void
}

/**
 * This component just calls the onOpenLink prop on mount.
 * It might seem superfluous but it maintains consistency with
 * the Template/Container pattern.
 */
const AggregatorPlaidTemplate: React.FC<AggregatorPlaidTemplateProps> = (props) => {
  const {onOpenLink} = props

  useEffect(() => {
    onOpenLink()
  }, [onOpenLink])

  return <Loading type="loader0" size="large" />
}

export default AggregatorPlaidTemplate
