import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import DefaultTertiaryButton from 'src/products/loans/components/atoms/TertiaryButton/DefaultTertiaryButton'
import {smallGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {LinkedAccount} from '@possible/cassandra/src/types/types.mobile.generated'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {isAccountLinkedGql} from 'src/lib/user/utils'
import {NamedColors} from 'src/designSystem/colors'
import {
  ButtonLockupPropsPrimary,
  ButtonLockupPropsSecondary,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'

type ReapplicationBankLinkedAccountSubset = Pick<
  LinkedAccount,
  'status' | 'isLoginRequired' | 'institution' | 'mask'
>

type ReapplicationBankTemplateProps = {
  isLatestAccount?: ReapplicationBankLinkedAccountSubset
  isLoading: boolean
  isPreferredAccount?: ReapplicationBankLinkedAccountSubset
  onContinue: () => Promise<void>
  onLinkNewAccount: () => void
  onRelinkAccount: () => void
}
/** 
    This page is displayed during reapplication. If the user is rejected,
    the preferred account might not be set. In that case we use the latest
    linked institution 
**/

const ReapplicationBankTemplate: React.FC<ReapplicationBankTemplateProps> = (props) => {
  const {
    isLatestAccount,
    isLoading,
    isPreferredAccount,
    onContinue,
    onLinkNewAccount,
    onRelinkAccount,
  } = props
  const {t} = useTranslation(['Bank', 'Common'])

  const preferredInstitutionName = isPreferredAccount?.institution?.name
  const latestInstitutionName =
    isLatestAccount && isAccountLinkedGql(isLatestAccount)
      ? isLatestAccount.institution?.name
      : undefined
  const selectedInstitutionName = preferredInstitutionName ?? latestInstitutionName
  const preferredAccountMask = isPreferredAccount?.mask ?? ''
  const accountDescStr = preferredAccountMask
    ? t('AccountEndingIn', {mask: preferredAccountMask})
    : undefined

  const isPreferredAccountLinked = isPreferredAccount
    ? isAccountLinkedGql(isPreferredAccount)
    : false
  const isLatestLinkedAccountLinked = isLatestAccount ? isAccountLinkedGql(isLatestAccount) : false

  const isPreferredAccountUnlinked = !!isPreferredAccount && !isPreferredAccountLinked
  const isUsableLinkedAccount =
    (isPreferredAccount && isPreferredAccountLinked) || isLatestLinkedAccountLinked

  const continueButtonTestId = 'Continue-Button'

  let primaryAction: ButtonLockupPropsPrimary
  let secondaryAction: ButtonLockupPropsSecondary | undefined
  let title: string
  let description: string

  if (isUsableLinkedAccount) {
    // if their account is linked and usable they're all set and can continue
    // or they can link a different bank if they want
    title = t('AccountAlreadyLinked')
    description = t('SuccessfullyConnectedBank')
    primaryAction = {
      disabled: isLoading,
      loading: isLoading,
      onPress: onContinue,
      text: t('Common:Continue'),
      testID: continueButtonTestId,
    }
    secondaryAction = {
      onPress: onLinkNewAccount,
      buttonText: t('LinkDifferentBank'),
      testID: 'Link-Different-Bank-Button',
    }
  } else if (isPreferredAccountUnlinked) {
    // if primary account is unlinked they need to re-link it
    title = t('RelinkBankAccount')
    description = t('BankConnectionLost')
    primaryAction = {
      onPress: onRelinkAccount,
      text: t('RelinkMyBankAccount'),
      testID: continueButtonTestId,
    }
    secondaryAction = undefined
  } else {
    // if they have no usable linked account and no primary they need to link a new account
    title = t('LinkBankAccount')
    description = t('PleaseLinkAnAccount')
    primaryAction = {
      onPress: onLinkNewAccount,
      text: t('LinkMyBankAccount'),
      testID: continueButtonTestId,
    }
    secondaryAction = undefined
  }

  return (
    <Page
      title={
        <PFText textAlign="center" variant="h3">
          {title}
        </PFText>
      }
      description={
        <PFText textAlign="center" variant="p_sm" textProps={{style: styles.description}}>
          {description}
        </PFText>
      }
      buttonProps={buttonLockupProperties(primaryAction, secondaryAction)}
      variant={'generic'}
      smallTopGap
    >
      {selectedInstitutionName ? (
        <Box alignSelf="start" flex={1} fill={'horizontal'}>
          <DefaultTertiaryButton subText={accountDescStr} disabled={true}>
            {selectedInstitutionName}
          </DefaultTertiaryButton>
          <Box marginTop={smallGap * 2}>
            <PFText variant={'p_sm'} color={NamedColors.SILVER} testID="Bank-Verification-Info">
              <Trans
                i18nKey={'Bank:VerificationProcessInfo'}
                components={{
                  bold: <PFText variant="p_sm_semibold" color={NamedColors.SILVER} />,
                }}
              />
            </PFText>
          </Box>
        </Box>
      ) : null}
    </Page>
  )
}

export {ReapplicationBankTemplate}

const styles = StyleSheet.create({
  description: {
    paddingHorizontal: 20,
  },
})
