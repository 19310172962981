/* eslint-disable import/no-default-export */
/* eslint-disable no-console */

import {triggerUnloadEvent} from 'src/native/webToNativeCompatibility'
import {localLogLevel, remoteLogLevel, LogLevels} from 'src/config'
import EventStreamSingleton from 'src/lib/EventStream/EventStream'
import {setupConsoleFilter} from 'src/lib/filterConsoleLogs'

setupConsoleFilter()

const getLocalLogFunction = (
  logLevel: LogLevels,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((message?: any, ...optionalParams: any[]) => void) => {
  switch (logLevel) {
    case LogLevels.Info:
      return console.info
    case LogLevels.Debug:
      return console.debug
    case LogLevels.Warn:
      return console.warn
    case LogLevels.Error:
      return console.error
    default:
      return console.log
  }
}

const shouldLogLocally = (logLevel: LogLevels): boolean => {
  return logLevel >= localLogLevel()
}

//This is currently a very simple implementation. We could tie this into a FeatureFlag
//to increase logging levels for certain customers who continue to have issues.
const shouldLogRemotely = (logLevel: LogLevels): boolean => {
  return logLevel >= remoteLogLevel()
}

export const argsToMessage = (args: unknown[]): string => {
  let message = ''

  for (let i = 0; i < args.length; i++) {
    const arg = args[i]
    if (arg instanceof Error) {
      message += `${arg.name}: ${arg.message}`
    } else {
      switch (typeof arg) {
        case 'undefined':
          message += 'undefined'
          break
        case 'object':
          message += JSON.stringify(arg)
          break
        case 'number':
        case 'string':
        case 'boolean':
        case 'bigint':
        case 'symbol':
          message += arg.toString()
          break
        case 'function':
          message += '[Function]'
          break
      }
    }

    if (i < args.length - 1) {
      message += ', '
    }
  }

  return message
}

/*
 Unified Log Entry point. Logs are forwarded to the console when in dev mode.
 Logs are also sent to Datadog.
 For Datadog the filtered log level is set in Analytics/index.ts. See DatadogLogs.createLogger.
 */
export default class Log {
  static warnOnceMap = new Set()

  static handleRemoteLog = (
    logLevel: LogLevels,
    args: unknown[],
    context: Record<string, unknown> | undefined,
  ): void => {
    const message = argsToMessage(args)
    EventStreamSingleton.emit({
      type: 'log',
      severity: 'info', //LogLevels.Log is not a convetional log level, so we use info. This should be debug if we had one.
      data: message,
      context: {
        args,
        ...(context ? {context} : undefined),
      },
    })
  }

  static handleLocalLog = (
    logLevel: LogLevels,
    args: unknown[],
    context?: Record<string, unknown>,
  ): void => {
    if (shouldLogLocally(logLevel)) {
      //Dir is an odd duck. Handle it separately.
      if (logLevel === LogLevels.Dir) {
        console.dir(args)
        return
      }

      const logger = getLocalLogFunction(logLevel)
      if (context) {
        logger(...args, context)
      } else {
        logger(...args)
      }
    }
  }

  static dir = (args: unknown[]): void => {
    Log.handleLocalLog(LogLevels.Dir, args)

    if (shouldLogRemotely(LogLevels.Dir)) {
      EventStreamSingleton.emit({
        type: 'log',
        severity: 'info', //LogLevels.Dir is not a convetional log level, so we use info. This should be debug if we had one.
        data: argsToMessage(args),
      })
    }
  }

  static debugWithContext = (
    context: Record<string, unknown> | undefined,
    ...args: unknown[]
  ): void => {
    Log.handleLocalLog(LogLevels.Debug, args, context)

    if (shouldLogRemotely(LogLevels.Log)) {
      const message = argsToMessage(args)
      EventStreamSingleton.emit({
        type: 'log',
        severity: 'debug', //LogLevels.Log is not a convetional log level, so we use info. This should be debug if we had one.
        data: message,
        context: {
          args,
          ...(context ? {context} : undefined),
        },
      })
    }
  }

  static debug = (...args: unknown[]): void => {
    Log.debugWithContext(undefined, args)
  }

  static logWithContext = (
    context: Record<string, unknown> | undefined,
    ...args: unknown[]
  ): void => {
    Log.handleLocalLog(LogLevels.Log, args, context)

    if (shouldLogRemotely(LogLevels.Log)) {
      const message = argsToMessage(args)
      EventStreamSingleton.emit({
        type: 'log',
        severity: 'info', //LogLevels.Log is not a convetional log level, so we use info. This should be debug if we had one.
        data: message,
        context: {
          args,
          ...(context ? {context} : undefined),
        },
      })
    }
  }

  static log = (...args: unknown[]): void => {
    return Log.logWithContext(undefined, ...args)
  }

  /** Similar to log(), except logs to Datadog */
  static infoWithContext = (
    context: Record<string, unknown> | undefined,
    ...args: unknown[]
  ): void => {
    Log.handleLocalLog(LogLevels.Info, args, context)

    if (shouldLogRemotely(LogLevels.Log)) {
      const message = argsToMessage(args)

      EventStreamSingleton.emit({
        type: 'log',
        severity: 'info',
        data: message,
        ...(context ? {context} : undefined),
      })
    }
  }

  static info = (...args: unknown[]): void => {
    Log.infoWithContext(undefined, ...args)
  }

  static warnWithContext = (
    context: Record<string, unknown> | undefined,
    ...args: unknown[]
  ): void => {
    Log.handleLocalLog(LogLevels.Warn, args, context)
    if (shouldLogLocally(LogLevels.Warn)) {
      if (context) {
        console.warn(...args, context)
      } else {
        console.warn(...args)
      }
    }

    if (shouldLogRemotely(LogLevels.Warn)) {
      const message = argsToMessage(args)
      EventStreamSingleton.emit({
        type: 'log',
        severity: 'warn',
        data: message,
        context: {
          args,
          ...(context ? {context} : undefined),
        },
      })
    }
  }

  static warn = (...args: unknown[]): void => {
    return Log.warnWithContext(undefined, ...args)
  }

  static warnOnceWithContext = (
    context: Record<string, unknown> | undefined,
    ...args: unknown[]
  ): void => {
    const message = argsToMessage(args)
    if (Log.warnOnceMap.has(message)) {
      return
    }

    Log.warnWithContext(context, message)
  }

  static warnOnce = (...args: unknown[]): void => {
    return Log.warnOnceWithContext(undefined, ...args)
  }

  static getErrorDataAndContext = (
    extraContext: Record<string, unknown> | undefined,
    error: unknown,
    msg: string,
  ): {data: string; context: Record<string, unknown> | undefined} => {
    let data: string = `${msg} `
    let context: Record<string, unknown> | undefined = undefined

    if (error instanceof Error) {
      data += `${error.message}`
      context = {
        error,
        stack: error.stack,
        ...(extraContext ? {extraContext} : undefined),
      }
    } else {
      if (typeof error === 'string') {
        data += `${error}`
      }

      context = {
        error,
        stack: new Error().stack,
        ...(extraContext ? {extraContext} : undefined),
      }
    }

    return {data, context}
  }

  static errorWithContext = (
    extraContext: Record<string, unknown> | undefined,
    error: unknown,
    msg = '',
  ): void => {
    Log.handleLocalLog(LogLevels.Error, [msg, error], extraContext)

    if (shouldLogRemotely(LogLevels.Error)) {
      const {data, context} = Log.getErrorDataAndContext(extraContext, error, msg)

      EventStreamSingleton.emit({
        type: 'log',
        severity: 'error',
        data,
        context,
      })
      triggerUnloadEvent() //causes Datadog to flush logs.
    }
  }

  static error = (error: unknown, msg = ''): void => {
    return Log.errorWithContext(undefined, error, msg)
  }
}
