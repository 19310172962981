import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LightboxNoticeAction} from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {completeUra} from '@possible/cassandra'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import Snackbar from 'src/lib/Snackbar'

type Props = {
  uraId: string
  close: () => void
  componentId?: string | null
  isAutopay: boolean
}

const eventMap = {
  viewEvent: {
    default: AppEvents.Name.change_default_payment_method_URA_viewed,
    autopay: AppEvents.Name.autopay_reauth_viewed,
  },
  acceptEvent: {
    default: AppEvents.Name.change_default_payment_method_URA_completed,
    autopay: AppEvents.Name.autopay_reauth_accepted,
  },
  declinedEvent: {
    default: AppEvents.Name.change_default_payment_method_URA_completed,
    autopay: AppEvents.Name.autopay_reauth_declined,
  },
}

export const ChangeDefaultPaymentMethod: React.FunctionComponent<
  Props & {
    navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
  }
> = (props) => {
  const {componentId, uraId, close, navigation, isAutopay = false} = props

  const {t} = useTranslation(['UserRequestedAction', 'Common'])
  const dispatch = usePfDispatch()

  const ura = usePfSelector((state) => get_ura_by_id_from_redux(uraId, state))
  const modifiedUra = ura
    ? {
        ...ura,
        actionBody: '', // Removes body text, which is not needed for this URA
      }
    : undefined
  const eventCategory = isAutopay
    ? AppEvents.Category.Functional
    : AppEvents.Category.BankAccountManagement

  useEffect(() => {
    TrackAppEvent(
      isAutopay ? eventMap.viewEvent.autopay : eventMap.viewEvent.default,
      eventCategory,
    )
  }, [dispatch, uraId, componentId, close])

  const handleNavigateToAcceptAgreements = (): void => {
    if (
      !ura?.actionData?.paymentInstrumentId ||
      ura?.requestContext?.type !== 'loan' ||
      !ura?.requestContext?.id
    ) {
      logAddPaymentMethodError(new Error('No paymentInstrumentId or loanId in URA actionData'))
      Snackbar.error({
        title: t('Common:GenericError'),
        duration: Snackbar.LENGTH_LONG,
      })
      return
    }

    navigation.push('AcceptLoanAutoPayAgreement', {
      newDefaultPaymentMethodId: ura.actionData.paymentInstrumentId,
      loanId: ura.requestContext.id,
      uraId,
      eventDetails: {
        eventCategory,
        eventName: isAutopay ? eventMap.acceptEvent.autopay : eventMap.acceptEvent.default,
      },
    })
  }

  const primary: LightboxNoticeAction = {
    text: isAutopay ? t('Common:Continue') : t('YesUpdateMyAutoPay'),
    onPress: handleNavigateToAcceptAgreements,
    size: 'large',
  }

  const secondary: LightboxNoticeAction = {
    text: isAutopay ? t('Common:NoThankYou') : t('DoNotUpdate'),
    onPress: async () => {
      await completeUra(uraId)
      TrackAppEvent(
        isAutopay ? eventMap.declinedEvent.autopay : eventMap.declinedEvent.default,
        eventCategory,
        {
          did_update_payment_method: false,
        },
      )
      close()
    },
    size: 'large',
  }

  return (
    <URAModalContent
      uraId={uraId}
      ura={modifiedUra}
      primary={primary}
      secondary={secondary}
      close={close}
    />
  )
}
