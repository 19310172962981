import React from 'react'

import {
  UsePageViewedAnalyticsProps,
  usePageViewedAnalytics,
} from 'src/lib/Analytics/usePageViewedAnalytics'
import {MakePaymentSuccessTemplate} from 'src/products/general/MakePayment/MakePaymentSuccess/MakePaymentSuccessTemplate'
import {
  BaseTemplate,
  BaseTemplateProps,
} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

type MakePaymentSuccessContainerProps = {
  testID: string
  pageViewedAnalytics: UsePageViewedAnalyticsProps
  onOkay: () => void
  paymentAmount: string
  executeDate: string
  executeDateFormat?: string
  overrideTitle?: string
} & Pick<BaseTemplateProps, 'onErrorBoundary'>

const MakePaymentSuccessContainer: React.FC<MakePaymentSuccessContainerProps> = (
  props,
): JSX.Element => {
  const {
    pageViewedAnalytics,
    onOkay: handleOnOkay,
    paymentAmount,
    executeDate,
    executeDateFormat,
    overrideTitle,
    onErrorBoundary: handleOnErrorBoundary,
    testID,
  } = props

  usePageViewedAnalytics(pageViewedAnalytics)

  return (
    <BaseTemplate onErrorBoundary={handleOnErrorBoundary}>
      <MakePaymentSuccessTemplate
        onOkay={handleOnOkay}
        paymentAmount={paymentAmount}
        executeDate={executeDate}
        executeDateFormat={executeDateFormat}
        overrideTitle={overrideTitle}
        testID={testID}
      />
    </BaseTemplate>
  )
}

export {MakePaymentSuccessContainer}
