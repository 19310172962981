/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react-prefer-function-component/react-prefer-function-component */
/* eslint-disable @typescript-eslint/unbound-method */

import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'
import {Animated, Easing, ViewStyle, StyleProp, StyleSheet} from 'react-native'

import {Indicator} from 'src/designSystem/components/atoms/Loading/indicators/indicator'

interface DotIndicatorProps {
  animationEasing?: typeof Easing.ease
  color?: string
  count?: number
  size?: number
  style?: StyleProp<ViewStyle>
}

interface RenderComponentProps {
  index: number
  count: number
  progress: Animated.Value
}

export class DotIndicator extends PureComponent<DotIndicatorProps> {
  static defaultProps = {
    animationEasing: Easing.inOut(Easing.ease),

    color: 'rgb(0, 0, 0)',
    count: 4,
    size: 16,
  }

  static propTypes = {
    ...Indicator.propTypes,

    color: PropTypes.string,
    size: PropTypes.number,
  }

  constructor(props: DotIndicatorProps) {
    super(props)

    this.renderComponent = this.renderComponent.bind(this)
  }

  renderComponent({index, count, progress}: RenderComponentProps) {
    const {size = 16, color: backgroundColor = 'rgb(0, 0, 0)'} = this.props

    const style = {
      width: size,
      height: size,
      margin: size / 2,
      borderRadius: size / 2,
      backgroundColor,
      transform: [
        {
          scale: progress.interpolate({
            inputRange: [
              0.0,
              (index + 0.5) / (count + 1),
              (index + 1.0) / (count + 1),
              (index + 1.5) / (count + 1),
              1.0,
            ],
            outputRange: [1.0, 1.36, 1.56, 1.06, 1.0],
          }),
        },
      ],
    }

    return <Animated.View style={style} key={index} />
  }

  render() {
    const {style, ...props} = this.props

    return (
      <Indicator
        style={[styles.container, style]}
        renderComponent={this.renderComponent}
        {...props}
      />
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
})
