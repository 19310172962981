import React, {useState} from 'react'
import {FetchResult} from '@apollo/client'

import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {VerifyYourIdentityTemplate} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentityTemplate'
import {
  UserModifyProfileDocument,
  UserModifyProfileMutation,
  UserModifyProfileMutationVariables,
} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity.gqls'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import Log from 'src/lib/loggingUtil'
import {PersonalInformationDocument} from 'src/products/MCU/VerifyYourIdentity/operations/PersonalInformation.gqls'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {
  VerifyYourIdentityContainerProps,
  VerifyYourIdentityFormData,
} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity.types'
import {logErrorAndShowException} from 'src/lib/errors'
import {formatDate, graphqlDateFormat} from 'src/lib/utils/date'
import {cleanUpSSN} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity.utils'

const VerifyYourIdentityGQLContainer: React.FC<VerifyYourIdentityContainerProps> = (props) => {
  const {onComplete: handleOnComplete, isForReviewAndEdit = false, refetchQueriesOnSubmit} = props
  const [isSubmittingPersonalInfo, setIsSubmittingPersonalInfo] = useState<boolean>(false)
  const {selectedData: personalInformationData, loading: isLoadingPersonalInfo} = useCassandraQuery(
    PersonalInformationDocument,
    {
      fetchPolicy: 'network-only',
    },
    (data) => ({
      firstName: data.me.profile?.name?.firstName ?? '',
      lastName: data.me.profile?.name?.lastName ?? '',
      birthday: data.me.profile?.birthDate ?? '',
      ssn: '',
    }),
  )

  const [submitUserPersonalInfo] = useCassandraMutation<
    UserModifyProfileMutation,
    UserModifyProfileMutationVariables
  >(UserModifyProfileDocument, {
    onError: (error) => {
      Log.error(`${error.message} - VerifyYourIdentity Screen - userModifyProfile mutation`)
      throw new Error('Unable to submit personal info.')
    },
    onCompleted: () => {
      TrackAppEvent(AppEvents.Name.personal_details_completed, AppEvents.Category.Application)
      TrackAppEvent(AppEvents.Name.personal_details_ssn_completed, AppEvents.Category.Application)
    },
  })

  const submitUserPersonalInfoHandler = async (options: {
    variables: {input: UserModifyProfileMutationVariables}
  }): Promise<FetchResult<UserModifyProfileMutation>> => {
    return submitUserPersonalInfo({
      variables: options.variables.input,
      ...(refetchQueriesOnSubmit !== undefined ? {refetchQueries: refetchQueriesOnSubmit} : {}),
    })
  }

  const handleSubmitUserPersonalInfo = async (data: VerifyYourIdentityFormData): Promise<void> => {
    const input: UserModifyProfileMutationVariables = {}
    setIsSubmittingPersonalInfo(true)

    try {
      if (data.firstName && data.lastName) {
        input.name = {
          first: data.firstName,
          last: data.lastName,
        }
      }

      if (data.birthday) {
        input.dob = {dob: formatDate(data.birthday, graphqlDateFormat)}
      }

      if (data.ssn) {
        input.ssn = {ssn: cleanUpSSN(data.ssn)}
      }

      const resp = await submitUserPersonalInfoHandler({variables: {input}})

      if (resp.errors) {
        throw new Error(resp.errors[0].message)
      }

      await handleOnComplete()
    } catch (e) {
      void logErrorAndShowException(e, 'VerifyYourIdentityTemplate, handleOnSubmit:')
    } finally {
      setIsSubmittingPersonalInfo(false)
    }
  }

  return (
    <BaseTemplate isLoading={isLoadingPersonalInfo}>
      <VerifyYourIdentityTemplate
        {...props}
        isForReviewAndEdit={isForReviewAndEdit}
        onComplete={handleOnComplete}
        defaultValues={personalInformationData}
        onSubmitUserPersonalInfo={handleSubmitUserPersonalInfo}
        isSubmittingPersonalInfo={isSubmittingPersonalInfo}
      />
    </BaseTemplate>
  )
}

export {VerifyYourIdentityGQLContainer}
