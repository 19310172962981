import React, {FC, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'

import {
  ButtonLockupPropsPrimary,
  ButtonLockupPropsSecondary,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import PFEmailInput from 'src/designSystem/components/molecules/PFEmailInput/PFEmailInput'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {validEmail} from 'src/lib/utils/formValidationUtil'
import {useCountdownTimer} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerification.utils'

export type EmailConfirmationTemplateProps = {
  onChangeEmail: (value: string) => void
  onChangeCode: (value: string) => void
  onPressSubmit: () => void
  onPressResend: () => void
  onNavigateToContactUs: () => void
  onPressEditEmail: () => void
  isSubmittingCode: boolean
  isLoadingUserEmail: boolean
  email: string
  shouldShowVerificationCode: boolean
  verificationCodeText: string
}

const EmailConfirmationTemplate: FC<EmailConfirmationTemplateProps> = (props): JSX.Element => {
  const {
    email,
    onChangeEmail: handleOnChangeEmail,
    onChangeCode: handleOnChangeCode,
    onNavigateToContactUs: handleOnNavigateToContactUs,
    onPressSubmit,
    onPressResend,
    onPressEditEmail,
    isSubmittingCode,
    isLoadingUserEmail,
    shouldShowVerificationCode,
    verificationCodeText,
  } = props

  const {t} = useTranslation(['EmailConfirmation', 'Common'])
  const emailInputIcon: SvgIconProps | undefined = shouldShowVerificationCode
    ? {name: 'edit', colorVariant: 'default', size: 'small'}
    : undefined

  const {isCounting, handleResetCodeTimer, localizedText} = useCountdownTimer()

  const isEmailValid = validEmail(email)

  const isPrimaryActionDisabled =
    !isEmailValid ||
    isSubmittingCode ||
    (shouldShowVerificationCode
      ? !verificationCodeText || verificationCodeText.length < 4 || !isCounting
      : false)

  const onHandlePrimaryAccountEvent = useCallback((): void => {
    handleResetCodeTimer()
    onPressSubmit()
  }, [onPressSubmit, handleResetCodeTimer])

  const handleResendCode = useCallback((): void => {
    handleResetCodeTimer()
    onPressResend()
  }, [handleResetCodeTimer, onPressResend])

  const handleOnPressEditEmail = useCallback((): void => {
    handleResetCodeTimer()
    onPressEditEmail()
  }, [handleResetCodeTimer, onPressEditEmail])

  const primaryAction: ButtonLockupPropsPrimary = useMemo(
    () => ({
      testID: 'Submit-Email-Or-Send-Code',
      onPress: onHandlePrimaryAccountEvent,
      text: !shouldShowVerificationCode ? t('SubmitMyEmail') : t('VerifyMyEmail'),
      disabled: isPrimaryActionDisabled,
      loading: isSubmittingCode,
    }),
    [
      t,
      isPrimaryActionDisabled,
      isSubmittingCode,
      onHandlePrimaryAccountEvent,
      shouldShowVerificationCode,
    ],
  )

  const secondaryAction: ButtonLockupPropsSecondary = useMemo(
    () => ({
      onPress: handleResendCode,
      buttonText: localizedText,
      disabled: isSubmittingCode || isCounting,
      size: 'medium',
      hideIcon: true,
    }),
    [handleResendCode, isCounting, isSubmittingCode, localizedText],
  )

  return (
    <Page
      variant={'generic'}
      smallTopGap={true}
      buttonProps={
        shouldShowVerificationCode
          ? buttonLockupProperties(primaryAction, secondaryAction)
          : buttonLockupProperties(primaryAction)
      }
      title={t('VerifyEmail')}
      description={t('CheckYourEmail')}
      enabledKeyboardAvoidingView
      testID={'Email-Confirmation-Page'}
    >
      <PFEmailInput
        label={t('Common:Email')}
        value={email}
        onChangeText={handleOnChangeEmail}
        icon={emailInputIcon}
        onPressIcon={handleOnPressEditEmail}
        isEditable={!shouldShowVerificationCode || !isLoadingUserEmail}
      />
      {shouldShowVerificationCode ? (
        <Box marginTop={'medium'} gap={'tiny'}>
          <Box marginBottom={'small'}>
            <PFText variant={'label_md'}>{t('EnterVerificationCode')}</PFText>
          </Box>
          <PFTextInput
            label={t('VerificationCode')}
            onChangeText={handleOnChangeCode}
            maxLength={4}
            value={verificationCodeText}
            keyboardType={'number-pad'}
            testID={'Email-Verification-Code-Input'}
          />
          <Box align={'center'} marginTop={'small'} gap={'tiny'}>
            <PFText variant={'p'} color={NamedColors.SILVER}>
              {t('DidntReceiveYourCode')}
            </PFText>
            <SvgLink
              linkType={'inline'}
              textVariant={'p_semibold'}
              linkIcon={'internal'}
              onPress={handleOnNavigateToContactUs}
              testID={'Contact-Us-Link'}
              linkText={t('Common:ContactUs')}
            />
          </Box>
        </Box>
      ) : null}
    </Page>
  )
}

export {EmailConfirmationTemplate as EmailConfirmationTemplate}
