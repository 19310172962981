import React from 'react'

import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  ConfirmDebitCardDocument,
  ConfirmDebitCardQuery,
  ConfirmDebitCardSaveDocument,
  LoanSetDisbursementMethodConfirmDebitCardDocument,
} from 'src/products/loans/LoanApprovedActivation/ConfirmDebitCard/ConfirmDebitCard.gqls'
import {ConfirmDebitCardTemplate} from 'src/products/loans/LoanApprovedActivation/ConfirmDebitCard/ConfirmDebitCardTemplate'
import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {usePromise} from 'src/lib/usePromise/usePromise'
import {useAcceptLoan} from 'src/products/loans/LoanApprovedActivation/useAcceptLoan/useAcceptLoan'
import {useIsAdvance} from 'src/lib/advance/useIsAdvance/useIsAdvance'

export type ConfirmDebitCardGQLContainerProps = {
  onConfirm: () => void | Promise<void>
  onAddNewDebitCard: () => void | Promise<void>
}

/**
 * GQL container for the ConfirmDebitCard screen that queries for necessary data from GQL.
 */
export const ConfirmDebitCardGQLContainer: React.FC<ConfirmDebitCardGQLContainerProps> = (
  props,
) => {
  const {onConfirm, onAddNewDebitCard: handleOnAddNewCard} = props
  const {
    loading: isLoading,
    selectedData,
    error: queryError,
  } = useCassandraQuery(
    ConfirmDebitCardDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (from) => {
      const primaryBankAccount = from.me.bankAccounts.all?.find((account) => {
        return account.preferredFundingSource === true
      })
      let primaryAccountDebitCard:
        | (NonNullable<ConfirmDebitCardQuery['me']['paymentMethods']['loanEligible']>[0] & {
            __typename: 'DebitCardPaymentMethod'
          })
        | null = null
      if (from.me.paymentMethods.loanEligible) {
        for (let i = 0; i < from.me.paymentMethods.loanEligible?.length; i++) {
          const paymentMethod = from.me.paymentMethods.loanEligible[i]
          if (
            paymentMethod.__typename === 'DebitCardPaymentMethod' &&
            paymentMethod.card.account?.id === primaryBankAccount?.id
          ) {
            primaryAccountDebitCard = paymentMethod
          }
        }
      }
      return {
        primaryBankAccount,
        primaryAccountDebitCard,
        loanId: from.me.loans.latestActionableLoan?.id,
      }
    },
  )

  const [submitConfirmDebitCard] = useCassandraMutation(ConfirmDebitCardSaveDocument)

  const [setLoanDisbursementMethod] = useCassandraMutation(
    LoanSetDisbursementMethodConfirmDebitCardDocument,
  )

  const [acceptLoan] = useAcceptLoan()
  const {isAdvance} = useIsAdvance()

  const [handleOnConfirm, {isLoading: isHandleOnConfirmExecuting}] = usePromise(
    async (): Promise<void> => {
      try {
        if (!selectedData?.loanId) {
          throw new Error('No loanId found')
        }

        if (!selectedData?.primaryAccountDebitCard) {
          throw new Error('No debit card found')
        }

        const disbursementResponse = await setLoanDisbursementMethod({
          variables: {
            loanId: selectedData.loanId,
            paymentInstrumentId: selectedData.primaryAccountDebitCard.id,
          },
        })

        if (disbursementResponse?.errors) {
          throw disbursementResponse.errors[0]
        }

        const submitConfirmDebitResponse = await submitConfirmDebitCard({
          variables: {
            loanId: selectedData.loanId,
          },
        })

        if (submitConfirmDebitResponse?.errors) {
          throw submitConfirmDebitResponse.errors[0]
        }

        if (isAdvance === true && selectedData?.loanId != null) {
          await acceptLoan({
            loanId: selectedData.loanId,
          }).then(async () => await onConfirm())
        } else {
          await onConfirm()
        }
      } catch (e) {
        logOfferActivationErrorAndShowException(
          e,
          'ConfirmDebitCardGQLContainer handleOnConfirm() failed',
        )
      }
    },
  )

  return (
    <ConfirmDebitCardTemplate
      isAdvance={isAdvance}
      isError={!!queryError}
      isLoading={!selectedData && isLoading}
      debitCard={{
        mask: selectedData?.primaryAccountDebitCard?.card.mask ?? undefined,
        expirationDate: selectedData?.primaryAccountDebitCard?.expDate ?? undefined,
        institutionName: selectedData?.primaryAccountDebitCard?.card.institution?.name ?? undefined,
      }}
      confirmBtn={{
        onPress: async (): Promise<void> => {
          await handleOnConfirm()
        },
        isDisabled: isLoading || !!queryError || isHandleOnConfirmExecuting,
        isLoading: isHandleOnConfirmExecuting,
      }}
      addNewDebitCardBtn={{
        onPress: handleOnAddNewCard,
        isDisabled: isLoading || !!queryError || isHandleOnConfirmExecuting,
      }}
    />
  )
}
