import React, {FC, useCallback, useEffect, useState} from 'react'
import {useFocusEffect} from '@react-navigation/native'

import {UpdateDisbursementMethod} from 'src/api/actions/loans/loanActions'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'

import {
  useCassandraLazyQuery,
  useCassandraMutation,
  useCassandraQuery,
} from '@possible/cassandra/src/utils/hooks'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {useRemoteValueJson} from 'src/lib/RemoteConfig'
import {INSTANT_DEBIT_ENABLED} from 'src/lib/RemoteConfig/parameterkeys'
import {transferMethods, transferMethodsType} from 'src/lib/loans/consts'
import {
  accountAndRoutingProvidedSelector,
  disbursementMethodSelectedSelector,
} from 'src/lib/loans/selector'
import {
  logOfferActivationError,
  logOfferActivationErrorAndShowException,
} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {
  LoanDisbursementMethodSelectionDocument,
  LoanDisbursementMethodSelectionPaymentMethodsForRtpDocument,
  LoanDisbursementMethodSelectionSaveDocument,
} from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodSelection.gqls'
import {LoanDisbursementMethodSelectionTemplate} from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodSelectionTemplate'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {
  LoanPaymentMethod,
  ProcessorNetwork,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {usePromise} from 'src/lib/usePromise/usePromise'
import {useAcceptLoan} from 'src/products/loans/LoanApprovedActivation/useAcceptLoan/useAcceptLoan'
import {useIsAdvance} from 'src/lib/advance/useIsAdvance/useIsAdvance'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'

type Props = {
  onContinue: () => Promise<void>
  onHasActiveLoan?: () => void | Promise<void>
}

const LoanDisbursementMethodSelectionGQLContainer: FC<Props> = (props) => {
  const {onContinue, onHasActiveLoan} = props
  const [showIsEligibleForRTP, setShowIsEligibleForRTP] = useState<boolean>(false)
  const dispatch = usePfDispatch()
  const accountAndRouting = usePfSelector(accountAndRoutingProvidedSelector)
  const [acceptLoan] = useAcceptLoan()
  const {loading: isLoadingAdvance, isAdvance} = useIsAdvance()
  const [isSubmittingAdvance, setIsSubmittingAdvance] = useState<boolean>(false)

  const {selectedData: isRTPEligible} = useCassandraQuery(
    LoanDisbursementMethodSelectionPaymentMethodsForRtpDocument,
    {
      fetchPolicy: 'network-only',
      onError: (e) => {
        logOfferActivationError(
          e,
          'LoanDisbursementMethodSelection failed to check payment methods for RTP eligible',
        )
      },
    },
    (data) => {
      return data?.me?.paymentMethods?.all?.some(
        (method) =>
          method?.__typename === 'AchPaymentMethod' &&
          method.account?.preferredFundingSource === true &&
          method.availableNetworks.includes(ProcessorNetwork.Rtp),
      )
    },
  )

  const [fetchQuery, {selectedData}] = useCassandraLazyQuery(
    LoanDisbursementMethodSelectionDocument,
    {
      onError: (e) => {
        logOfferActivationError(
          e,
          'LoanDisbursementMethodSelection failed to get latest loan status',
        )
      },
    },
    (from) => {
      return {
        loanId: from.me.loans.latestActionableLoan?.id,
        hasActiveLoan:
          from.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
          'ActiveLoanAggregateStatus',
        loanTypeVariant: from?.me?.loans?.latestActionableLoan?.loanTypeInfo?.variant,
      }
    },
  )
  const loanId = selectedData?.loanId

  const [submitSaveSelection] = useCassandraMutation(LoanDisbursementMethodSelectionSaveDocument)

  // the selected disbursement method is stored in redux until the loan is accepted on the back end
  const selectedDisbursementMethod:
    | Extract<transferMethodsType, 'ach' | 'interchange'>
    | undefined = usePfSelector(disbursementMethodSelectedSelector)

  const instantDebitRemoteValue = useRemoteValueJson(INSTANT_DEBIT_ENABLED)
  const isInstantDebitDisabled = instantDebitRemoteValue === false

  const fetchQueryToCheckForActiveLoan = useCallback(() => {
    void fetchQuery().then((res) => {
      // if we somehow end up here with an active loan the parent may want to be notified
      if (res.selectedData?.hasActiveLoan === true) {
        logOfferActivationError(
          'LoanDisbursementMethodSelection encountered when user has an active loan',
        )
        void onHasActiveLoan?.()
      }
    })
  }, [fetchQuery, onHasActiveLoan])

  useFocusEffect(fetchQueryToCheckForActiveLoan)

  const handleOnSelectDebitCardOption = (): void => {
    const paymentMethod = transferMethods.interchange
    TrackAppEvent(AppEvents.Name.disbursement_method_selected, AppEvents.Category.Activation, {
      paymentMethod,
    })
    void dispatch(UpdateDisbursementMethod(paymentMethod))
  }

  const handleOnSelectAchOption = useCallback((): void => {
    const paymentMethod = transferMethods.ach
    TrackAppEvent(AppEvents.Name.disbursement_method_selected, AppEvents.Category.Activation, {
      paymentMethod,
    })
    void dispatch(UpdateDisbursementMethod(paymentMethod))
  }, [dispatch])

  const continueWithAdvance = useCallback(async (): Promise<void> => {
    if (!loanId) {
      throw new Error('no loanId found')
    }

    setIsSubmittingAdvance(true)
    await acceptLoan({
      loanId,
      accountNumber: accountAndRouting?.account_number,
      routingNumber: accountAndRouting?.routing_number,
    }).then(async () => await onContinue())
    setIsSubmittingAdvance(false)
  }, [acceptLoan, onContinue, loanId, accountAndRouting])

  const [handleOnContinue, {isLoading: isHandleOnContinueExecuting}] = usePromise(
    async (): Promise<void> => {
      TrackAppEvent(AppEvents.Name.disbursement_method_confirmed, AppEvents.Category.Activation, {
        paymentMethod: selectedDisbursementMethod,
      })

      try {
        if (!loanId) {
          throw new Error('no loanId found')
        }
        let paymentMethod: LoanPaymentMethod | undefined
        switch (selectedDisbursementMethod) {
          case 'ach':
            paymentMethod = LoanPaymentMethod.Ach
            break
          case 'interchange':
            paymentMethod = LoanPaymentMethod.DebitCard
            break
          default:
            paymentMethod = undefined
            break
        }
        if (!paymentMethod) {
          throw new Error(
            `no valid payment method found, selectedDisbursementMethod="${selectedDisbursementMethod}"`,
          )
        }
        await submitSaveSelection({
          variables: {
            input: {
              loanId,
              disbursementMethod: paymentMethod,
            },
          },
        }).then(async () => {
          if (isRTPEligible === true && paymentMethod === LoanPaymentMethod.Ach) {
            setShowIsEligibleForRTP(true)
          } else {
            if (isAdvance === true && paymentMethod === LoanPaymentMethod.Ach) {
              await continueWithAdvance()
            } else {
              await onContinue()
            }
          }
        })
      } catch (e) {
        logOfferActivationErrorAndShowException(
          e,
          'LoanDisbursementMethodSelection failed to save loan disbursement method selection',
        )
      }
    },
  )

  // if debit card is disabled we only allow ach
  useEffect(() => {
    if (isInstantDebitDisabled && selectedDisbursementMethod !== 'ach') {
      handleOnSelectAchOption()
    }
  }, [selectedDisbursementMethod, handleOnSelectAchOption, isInstantDebitDisabled])

  if (isLoadingAdvance) {
    return <Spinner />
  }

  return (
    <LoanDisbursementMethodSelectionTemplate
      isContinueBtnDisabled={!selectedDisbursementMethod}
      isInstantDebitDisabled={isInstantDebitDisabled}
      isSubmitting={isHandleOnContinueExecuting}
      onSelectDebitCard={handleOnSelectDebitCardOption}
      onSelectAch={handleOnSelectAchOption}
      onContinue={async (): Promise<void> => {
        await handleOnContinue()
      }}
      selectedMethod={selectedDisbursementMethod}
      showIsEligibleForRTP={showIsEligibleForRTP}
      closeIsEligibleForRTP={() => setShowIsEligibleForRTP(false)}
      continueWithRTP={isAdvance ? continueWithAdvance : onContinue}
      isSubmittingAdvance={isSubmittingAdvance}
      isAdvance={isAdvance}
    />
  )
}

export {LoanDisbursementMethodSelectionGQLContainer}
