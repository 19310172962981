import {gql} from '@apollo/client'

import {LoanFragment} from '../fragments'

export const LoanApply = gql`
  ${LoanFragment}
  mutation LoanApply($applyInput: LoanApplyInput!) {
    loanApply(applyInput: $applyInput) {
      ...LoanFragment
    }
  }
`

export const LoanCancel = gql`
  ${LoanFragment}
  mutation LoanCancel($loanId: ID!, $reason: String, $reasonCodes: [String]!) {
    loanCancel(loanId: $loanId, reason: $reason, reasonCodes: $reasonCodes) {
      ...LoanFragment
    }
  }
`

export const LoanAccept = gql`
  ${LoanFragment}
  mutation LoanAccept($acceptInput: LoanAcceptInput!) {
    loanAccept(acceptInput: $acceptInput) {
      ...LoanFragment
      borrower {
        user {
          id
        }
      }
    }
  }
`

export const LoanSetPaymentDates = gql`
  ${LoanFragment}
  mutation LoanSetPaymentDates($paymentDatesInput: LoanSetPaymentDatesInput!) {
    loanSetPaymentDates(paymentDatesInput: $paymentDatesInput)
  }
`

export const GetLoanDocument = gql`
  mutation LoanGetDocument($loanId: ID!, $type: LoanDocumentType) {
    loanGetDocument(loanId: $loanId, type: $type) {
      url
      expiresAt
    }
  }
`

export const LoanDocumentsDownload = gql`
  mutation LoanDocumentsDownload($loanId: ID!) {
    loanDocumentsDownload(loanId: $loanId) {
      documents {
        type
        download {
          url
          expiresAt
        }
      }
    }
  }
`

export const PrequalificationStatus = gql`
  query PrequalificationStatus($assessmentDate: DateTime!) {
    loanGetPrequalification(assessmentDate: $assessmentDate) {
      status
      amount
    }
  }
`

export const GetLoanFundingStatus = gql`
  query GetLoanFundingStatus($loanId: ID!) {
    getLoanFundingStatus(loanId: $loanId) {
      status
      processorStatus
      displayStatus
      displayProcessorStatus
    }
  }
`

export const NextAvailablePaymentDate = gql`
  query NextAvailablePaymentDate(
    $loanId: ID!
    $timeNow: DateTime!
    $forDisbursement: Boolean!
    $userId: ID!
  ) {
    getNextAvailablePaymentDate(
      loanId: $loanId
      timeNow: $timeNow
      forDisbursement: $forDisbursement
      userId: $userId
    ) {
      settlementDelayDays
      settlesOnHolidaysAndWeekends
      desiredSettlementDatetime
      adjustedSettlementDatetime
      submitForProcessingBy
      timeNow
    }
  }
`
