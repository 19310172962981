import {useEffect} from 'react'
import {Linking} from 'react-native'

import {
  LoanActiveStatusAlertCollection,
  LoanPayment,
  LoanPaymentStatusCode,
} from '@possible/cassandra/src/types/consumer'
import {ActiveLoanDashboardAggregateStatusQueryHookResult} from 'src/products/loans/Dashboard/DashboardLoan/queries/useDashboardLoanAggregateStatusQuery'
import {DashboardNavigation} from 'src/products/loans/Dashboard/DashboardLoanUtils/DashboardLoan.utils'
import {usePfDispatch} from 'src/store/utils'
import * as DashboardLoanUtils from 'src/products/loans/Dashboard/DashboardLoanUtils/DashboardLoan.utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {usePrevious} from 'src/lib/utils/hooks'
import {isDeviceAndroidWeb} from 'src/lib/utils/platform'
import Log from 'src/lib/loggingUtil'
import {
  DashboardLoanActiveLoanDisbursementFields,
  DashboardLoanActiveLoanPaymentFields,
  DashboardLoanActiveProps,
} from 'src/products/loans/Dashboard/DashboardLoanActive/DashboardLoanActive.types'

/**
 * Constructs complete props for DashboardLoanActive given GraphQL response data for DashboardLoanAggregateStatusQuery.
 */
export const getDashboardLoanActiveProps = (config: {
  navigation: DashboardNavigation
  activeLoanDashboardData: Pick<
    ActiveLoanDashboardAggregateStatusQueryHookResult,
    'activeLoanAggregateStatus' | 'loanTypeVariant' | 'subscriptions'
  >
  dispatch: ReturnType<typeof usePfDispatch>
  loanId?: string
  shouldShowMoneyIsOnTheWayMessage?: boolean
  isAdvance: boolean
}): DashboardLoanActiveProps => {
  const {activeLoanDashboardData, navigation, loanId, shouldShowMoneyIsOnTheWayMessage, isAdvance} =
    config
  const {activeLoanAggregateStatus} = activeLoanDashboardData

  const payments: DashboardLoanActiveLoanPaymentFields[] = []
  if (activeLoanAggregateStatus?.activePayments?.payments?.length) {
    activeLoanAggregateStatus.activePayments.payments.forEach(
      (thisPayment: DashboardLoanActiveLoanPaymentFields) => {
        payments.push({
          id: thisPayment.id,
          ordinal: thisPayment.ordinal,
          amount: thisPayment.amount,
          originalDate: thisPayment.originalDate,
          rescheduledDate: thisPayment.rescheduledDate,
          statusCode: thisPayment.statusCode,
          methodCode: thisPayment.methodCode,
          lastTransaction: thisPayment.lastTransaction,
          lastFailedTransaction: thisPayment.lastFailedTransaction,
          payingOffNow: thisPayment.payingOffNow,
        })
      },
    )
  }

  const loanCount = activeLoanAggregateStatus.activeLoanCount
  const hasPreviousLoan = loanCount > 1

  const disbursement: DashboardLoanActiveLoanDisbursementFields =
    activeLoanAggregateStatus.disbursement

  const props: DashboardLoanActiveProps = {
    hasPreviousLoan,
    payments,
    amountProcessing: activeLoanAggregateStatus.amountProcessing,
    actions: activeLoanAggregateStatus.actions,
    customPaymentOptions: activeLoanAggregateStatus.customPaymentOptions,
    disbursement,
    alerts: activeLoanAggregateStatus.alerts,
    loanTypeVariant: activeLoanDashboardData.loanTypeVariant,
    subscriptions: activeLoanDashboardData.subscriptions,
    amountOutstanding: activeLoanAggregateStatus.amountOutstanding,
    onMakeAPayment: () => {
      void onMakeAPayment({
        navigation,
      })
    },
    onUpdatePaymentDates: () => {
      DashboardLoanUtils.onUpdateLoanPaymentDates({
        navigation,
        multiPaymentUpgradeAvailable: activeLoanAggregateStatus.multiPaymentUpgradeAvailable,
        loanId: loanId,
      })
    },
    onShowDefaultExplanation: (): void => {
      DashboardLoanUtils.onShowDefaultExplanation({
        onMakeAPayment: (): void => {
          void onMakeAPayment({
            navigation,
          })
        },
      })
    },
    onShowChargedOffExplanation: (): void => {
      DashboardLoanUtils.onShowChargedOffExplanation({
        onMakeAPayment: (): void => {
          void onMakeAPayment({
            navigation,
          })
        },
      })
    },
    onContactUs: () => DashboardLoanUtils.onContactUs({navigation}),
    onRelinkBankAccount: (): void => {
      onRelinkBankAccount({
        navigation,
      })
    },
    onViewLoanHistory: (): void => {
      DashboardLoanUtils.onViewLoanHistory({navigation})
    },
    onUpdatePaymentMethod: (): void => {
      onUpdatePaymentMethod({
        navigation,
      })
    },
    loanId: loanId,
    shouldShowMoneyIsOnTheWayMessage: shouldShowMoneyIsOnTheWayMessage,
    navigation,
    isAdvance,
  }
  return props
}

/**
 * Given active loan alerts, track the corresponding analytics events.
 * @param params
 */
export const useDashboardLoanActiveAlertsAnalytics = (params: {
  alerts: LoanActiveStatusAlertCollection
}): void => {
  const {alerts} = params
  const {
    isChargedOff,
    isInDefault,
    isSuspended,
    hasOneMissedPayment,
    hasPaymentMethodIssue,
    hasPaymentLateNotFailed,
  } = determineLoanAlertStatuses({alerts})
  const isInDefaultPrevious = usePrevious(isInDefault)
  const isChargedOffPrevious = usePrevious(isChargedOff)
  const hasOneMissedPaymentPrevious = usePrevious(hasOneMissedPayment)
  const hasPaymentLateNotFailedPrevious = usePrevious(hasPaymentLateNotFailed)
  const isSuspendedPrevious = usePrevious(isSuspended)
  const hasPaymentMethodIssuePrevious = usePrevious(hasPaymentMethodIssue)
  // the first time this user views an alert we will track that
  useEffect(() => {
    if (isInDefault && !isInDefaultPrevious) {
      TrackAppEvent(
        ManageActiveLoanEvents.alert_viewed_in_default,
        AppEvents.Category.ManageActiveLoan,
      )
    }
  }, [isInDefault, isInDefaultPrevious])
  useEffect(() => {
    if (isChargedOff && !isChargedOffPrevious) {
      TrackAppEvent(
        ManageActiveLoanEvents.alert_viewed_charged_off,
        AppEvents.Category.ManageActiveLoan,
      )
    }
  }, [isChargedOff, isChargedOffPrevious])
  useEffect(() => {
    if (hasOneMissedPayment && !hasOneMissedPaymentPrevious) {
      TrackAppEvent(
        ManageActiveLoanEvents.alert_viewed_one_missed_payment,
        AppEvents.Category.ManageActiveLoan,
      )
    }
  }, [hasOneMissedPayment, hasOneMissedPaymentPrevious])
  useEffect(() => {
    if (hasPaymentLateNotFailed && !hasPaymentLateNotFailedPrevious) {
      TrackAppEvent(
        ManageActiveLoanEvents.alert_viewed_payment_late_not_failed,
        AppEvents.Category.ManageActiveLoan,
      )
    }
  }, [hasPaymentLateNotFailed, hasPaymentLateNotFailedPrevious])
  useEffect(() => {
    if (isSuspended && !isSuspendedPrevious) {
      TrackAppEvent(
        ManageActiveLoanEvents.alert_viewed_suspended,
        AppEvents.Category.ManageActiveLoan,
      )
    }
  }, [isSuspended, isSuspendedPrevious])
  useEffect(() => {
    if (hasPaymentMethodIssue && !hasPaymentMethodIssuePrevious) {
      TrackAppEvent(
        ManageActiveLoanEvents.alert_viewed_payment_method_issue,
        AppEvents.Category.ManageActiveLoan,
      )
    }
  }, [hasPaymentMethodIssue, hasPaymentMethodIssuePrevious])
}

/**
 * Analyze the list of alerts for this loan to get booleans of various loan states.
 */
export const determineLoanAlertStatuses = (params: {
  alerts: LoanActiveStatusAlertCollection
}): {
  isChargedOff: boolean
  isInDefault: boolean
  isSuspended: boolean
  hasOneMissedPayment: boolean
  hasPaymentMethodIssue: boolean
  hasPaymentLateNotFailed: boolean
} => {
  const {alerts} = params
  let isChargedOff = false
  let isInDefault = false
  let isSuspended = false
  let hasOneMissedPayment = false
  let hasPaymentLateNotFailed = false
  let hasPaymentMethodIssue = false
  alerts?.items.forEach((thisAlertItem) => {
    if (thisAlertItem.__typename === 'LoanActiveAlertChargedOff') {
      isChargedOff = true
    } else if (thisAlertItem.__typename === 'LoanActiveAlertInDefault') {
      isInDefault = true
    } else if (
      // "FailedPayment" replaced the deprecated "LatePayment"
      thisAlertItem.__typename === 'LoanActiveAlertFailedPayment' ||
      thisAlertItem.__typename === 'LoanActiveAlertLatePayment'
    ) {
      hasOneMissedPayment = true
    } else if (thisAlertItem.__typename === 'LoanActiveAlertPaymentMethodIssue') {
      hasPaymentMethodIssue = true
    } else if (thisAlertItem.__typename === 'LoanActiveAlertLoanSuspended') {
      isSuspended = true
    } else if (thisAlertItem.__typename === 'LoanActiveAlertLatePaymentNotFailed') {
      hasPaymentLateNotFailed = true
    }
  })
  return {
    isChargedOff,
    isInDefault,
    isSuspended,
    hasOneMissedPayment,
    hasPaymentMethodIssue,
    hasPaymentLateNotFailed,
  }
}

/**
 * Allow user to relink their bank account.
 */
export const onRelinkBankAccount = (params: {navigation: DashboardNavigation}): void => {
  TrackAppEvent(
    ManageActiveLoanEvents.relink_bank_account_selected,
    AppEvents.Category.ManageActiveLoan,
  )
  const {navigation} = params
  DashboardLoanUtils.onManagePaymentAccounts({
    navigation,
  })
}

/**
 * Allow user to update their payment method.
 */
export const onUpdatePaymentMethod = (params: {navigation: DashboardNavigation}): void => {
  TrackAppEvent(
    ManageActiveLoanEvents.update_payment_method_selected,
    AppEvents.Category.ManageActiveLoan,
  )
  const {navigation} = params
  DashboardLoanUtils.onManagePaymentAccounts({
    navigation,
  })
}

/**
 * Allow a user to pay off their remaining balance now.
 */
export const onMakeAPayment = async (params: {
  navigation: DashboardNavigation
  // eslint-disable-next-line @typescript-eslint/require-await
}): Promise<void> => {
  const {navigation} = params
  TrackAppEvent(
    ManageActiveLoanEvents.pay_remaining_now_selected,
    AppEvents.Category.ManageActiveLoan,
  )
  navigation.push('LoanPay')
}

/**
 * Determine if the user currently has any payments being processed.
 * @param args
 * @returns
 */
export const isProcessingPayments = (args: {
  payments: Pick<LoanPayment, 'statusCode' | 'payingOffNow'>[]
}): boolean => {
  const {payments} = args
  for (const thisPayment of payments) {
    if (
      thisPayment.statusCode === LoanPaymentStatusCode.InProgress ||
      (thisPayment.statusCode === LoanPaymentStatusCode.Pending && thisPayment.payingOffNow)
    ) {
      return true
    }
  }

  return false
}

/**
 * Sends users on web to the mobile app if appropriate.
 */
export const useRedirectActiveLoanWebUserToAppIfAppropriate = (): void => {
  const mobileAppHomeUrl = 'possiblefinance://home'
  useEffect(() => {
    if (isDeviceAndroidWeb()) {
      Linking.canOpenURL(mobileAppHomeUrl)
        .then((canOpen) => {
          if (canOpen) {
            Linking.openURL(mobileAppHomeUrl).catch((e) => {
              Log.error(
                e,
                'redirectWebUserToAppIfAppropriate Failed to redirect web user to mobile app',
              )
            })
          }
        })
        .catch((e) => {
          Log.error(
            e,
            'redirectWebUserToAppIfAppropriate Failed to determine if app link can be opened while redirecting web user to mobile app',
          )
        })
    }
  }, [])
}
