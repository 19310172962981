import * as Types from '../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type DashboardLoanAggregateStatusQueryVariables = Types.Exact<{[key: string]: never}>

export type DashboardLoanAggregateStatusQuery = {
  __typename?: 'Query'
  me: {
    __typename: 'User'
    id: string
    profile?: {
      __typename?: 'UserProfile'
      email?: {__typename?: 'Email'; address?: string | null; verified: boolean} | null
      name?: {__typename?: 'Name'; firstName: string} | null
    } | null
    products: {
      __typename?: 'ProductsCollection'
      eligible: {
        __typename: 'ProductsOffersCollection'
        all: Array<
          | {__typename: 'CardAccountOfferInfo'}
          | {
              __typename: 'LoanOfferInfo'
              category: Types.ProductCategory
              loanOfferAvailabilityStatus: Types.LoanOfferAvailabilityStatus
              offers: Array<{
                __typename?: 'LoanOffer'
                id: string
                loanTypeVariant?: Types.LoanTypeVariant | null
              }>
            }
        >
      }
    }
    loans: {
      __typename: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        loanTypeInfo?: {__typename?: 'LoanTypeInfo'; variant: Types.LoanTypeVariant} | null
        aggregateStatus:
          | {
              __typename: 'ActiveLoanAggregateStatus'
              amountBorrowed: string
              amountOutstanding: string
              amountPaidOff: string
              amountProcessing: string
              code: Types.LoanAggregateStatusCode
              multiPaymentUpgradeAvailable: boolean
              activeLoanCount: number
              actions: {
                __typename?: 'LoanActiveStatusActionCollection'
                actions: Array<Types.LoanActiveStatusActions>
              }
              alerts: {
                __typename?: 'LoanActiveStatusAlertCollection'
                items: Array<
                  | {
                      __typename: 'LoanActiveAlertChargedOff'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                    }
                  | {
                      __typename: 'LoanActiveAlertDisbursementCompleted'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                    }
                  | {
                      __typename: 'LoanActiveAlertDisbursementDelayed'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                    }
                  | {
                      __typename: 'LoanActiveAlertFailedPayment'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                    }
                  | {
                      __typename: 'LoanActiveAlertFinalPaymentProcessing'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                    }
                  | {
                      __typename: 'LoanActiveAlertInDefault'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                      daysUntilChargeOff: number
                    }
                  | {
                      __typename: 'LoanActiveAlertLatePayment'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                    }
                  | {
                      __typename: 'LoanActiveAlertLatePaymentNotFailed'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                      paymentDate: string
                      rescheduleOnOrBeforeDate: string
                    }
                  | {
                      __typename: 'LoanActiveAlertLoanSuspended'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                    }
                  | {
                      __typename: 'LoanActiveAlertPaymentCompleted'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                    }
                  | {
                      __typename: 'LoanActiveAlertPaymentMethodIssue'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                    }
                  | {
                      __typename: 'LoanActiveAlertUpcomingPayment'
                      type: Types.LoanActiveStatusPaymentAlerts
                      triggeredAt: string
                      priority: number
                      category: Types.LoanActiveStatusPaymentAlertCategory
                      daysUntilDue: number
                    }
                >
              }
              customPaymentOptions: {
                __typename?: 'LoanCustomPaymentOptionCollection'
                options: Array<{
                  __typename?: 'LoanCustomPaymentOption'
                  intention: Types.LoanCustomPaymentIntention
                  amount: string
                  installmentCount: number
                }>
              }
              disbursement: {
                __typename?: 'LoanDisbursement'
                amount: string
                accountMask: string
                accountFriendlyName: string
                completedOn?: string | null
                method: Types.LoanPaymentMethod
                status: Types.FundingAuthorizationProcessorStatus
                updatedOn: string
              }
              activePayments: {
                __typename?: 'LoanPaymentCollection'
                payments: Array<{
                  __typename?: 'LoanPayment'
                  amount: string
                  currency?: string | null
                  executeEffectiveDateTime?: string | null
                  failureCount: number
                  fees: string
                  fundingId?: string | null
                  id: string
                  interest: string
                  loanId: string
                  methodCode: Types.LoanPaymentMethod
                  ordinal: number
                  originalDate: string
                  paidOffDateTime?: string | null
                  payingOffNow: boolean
                  principal: string
                  rescheduledDate?: string | null
                  statusCode: Types.LoanPaymentStatusCode
                  userId: string
                  lastTransaction?: {
                    __typename?: 'LoanPaymentTransaction'
                    accountMask?: string | null
                    accountFriendlyName?: string | null
                  } | null
                  lastFailedTransaction?: {
                    __typename?: 'LoanPaymentTransaction'
                    accountMask?: string | null
                    accountFriendlyName?: string | null
                  } | null
                }>
              }
              unavailablePaymentDates?: {
                __typename?: 'LoanPaymentDateCollection'
                dates?: Array<string> | null
              } | null
            }
          | {
              __typename: 'ApprovedLoanAggregateStatus'
              amountApproved: string
              amountRequested: string
              code: Types.LoanAggregateStatusCode
              expirationDateTime: string
              multiPaymentUpgradeAvailable: boolean
              counterOfferCodes: {
                __typename?: 'AdverseReasonCodeCollection'
                codes: Array<Types.AdverseReasonCode | null>
              }
            }
          | {
              __typename: 'ClosedLoanAggregateStatus'
              code: Types.LoanAggregateStatusCode
              prequalAmount?: string | null
              reapplyOn: string
              reason: Types.LoanClosedReasonCode
              closedLoanCount: number
              closedPayments: {
                __typename?: 'LoanPaymentCollection'
                payments: Array<{
                  __typename?: 'LoanPayment'
                  amount: string
                  failureCount: number
                  fees: string
                  id: string
                  loanId: string
                  methodCode: Types.LoanPaymentMethod
                  ordinal: number
                  originalDate: string
                  paidOffDateTime?: string | null
                  payingOffNow: boolean
                  rescheduledDate?: string | null
                  statusCode: Types.LoanPaymentStatusCode
                  userId: string
                  lastTransaction?: {
                    __typename?: 'LoanPaymentTransaction'
                    accountMask?: string | null
                    accountFriendlyName?: string | null
                  } | null
                }>
              }
              closedDisbursement?: {
                __typename?: 'LoanDisbursement'
                amount: string
                accountMask: string
                accountFriendlyName: string
                completedOn?: string | null
                method: Types.LoanPaymentMethod
                status: Types.FundingAuthorizationProcessorStatus
                updatedOn: string
              } | null
            }
          | {
              __typename: 'DecisioningLoanAggregateStatus'
              code: Types.LoanAggregateStatusCode
              delayed: boolean
            }
          | {__typename: 'UndefinedLoanAggregateStatus'; code: Types.LoanAggregateStatusCode}
      } | null
    }
    subscriptions: {
      __typename?: 'SubscriptionCollection'
      current?: {
        __typename?: 'Subscription'
        id: string
        firstPaymentAt: string
        statusData: {
          __typename?: 'SubscriptionStatusData'
          status: Types.SubscriptionStatus
          substatus: Types.SubscriptionSubstatus
          statusReason: string
        }
        payments: {
          __typename?: 'SubscriptionPaymentCollection'
          all: Array<{
            __typename?: 'SubscriptionPayment'
            id: string
            subscriptionId: string
            status: Types.SubscriptionPaymentStatus
            statusReason: string
            amount: string
            scheduledAt: string
            processorNetwork: Types.ProcessorNetwork
            paymentInstrumentId?: string | null
            fundingAuthorizationId?: string | null
          }>
        }
      } | null
    }
  }
}

export const DashboardLoanAggregateStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'DashboardLoanAggregateStatus'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'profile'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'email'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'address'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'name'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'firstName'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'products'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'eligible'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'all'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'LoanOfferInfo'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'category'}},
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'loanOfferAvailabilityStatus',
                                          },
                                          name: {kind: 'Name', value: 'offerAvailabilityStatus'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'offers'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'loanTypeVariant'},
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'loanTypeInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'variant'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aggregateStatus'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'UndefinedLoanAggregateStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'DecisioningLoanAggregateStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'delayed'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ApprovedLoanAggregateStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'amountApproved'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'amountRequested'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'counterOfferCodes'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'codes'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'expirationDateTime'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'multiPaymentUpgradeAvailable',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ActiveLoanAggregateStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'actions'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'actions'},
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'alerts'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'items'},
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {kind: 'Name', value: 'aggregations'},
                                                    value: {
                                                      kind: 'ListValue',
                                                      values: [
                                                        {
                                                          kind: 'ObjectValue',
                                                          fields: [
                                                            {
                                                              kind: 'ObjectField',
                                                              name: {
                                                                kind: 'Name',
                                                                value: 'categories',
                                                              },
                                                              value: {
                                                                kind: 'ListValue',
                                                                values: [
                                                                  {
                                                                    kind: 'EnumValue',
                                                                    value: 'CRITICAL',
                                                                  },
                                                                  {
                                                                    kind: 'EnumValue',
                                                                    value: 'WARNING',
                                                                  },
                                                                ],
                                                              },
                                                            },
                                                            {
                                                              kind: 'ObjectField',
                                                              name: {kind: 'Name', value: 'limit'},
                                                              value: {kind: 'IntValue', value: '1'},
                                                            },
                                                          ],
                                                        },
                                                        {
                                                          kind: 'ObjectValue',
                                                          fields: [
                                                            {
                                                              kind: 'ObjectField',
                                                              name: {
                                                                kind: 'Name',
                                                                value: 'categories',
                                                              },
                                                              value: {
                                                                kind: 'ListValue',
                                                                values: [
                                                                  {
                                                                    kind: 'EnumValue',
                                                                    value: 'INFO',
                                                                  },
                                                                  {
                                                                    kind: 'EnumValue',
                                                                    value: 'INFO_LIGHT',
                                                                  },
                                                                ],
                                                              },
                                                            },
                                                            {
                                                              kind: 'ObjectField',
                                                              name: {kind: 'Name', value: 'limit'},
                                                              value: {kind: 'IntValue', value: '1'},
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'LoanActiveStatusPaymentAlertType',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: '__typename',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'type'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'triggeredAt',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'priority'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'category'},
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'LoanActiveAlertInDefault',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: '__typename',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'type'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'triggeredAt',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'daysUntilChargeOff',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'LoanActiveAlertUpcomingPayment',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: '__typename',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'type'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'triggeredAt',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'daysUntilDue',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'LoanActiveAlertLatePaymentNotFailed',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: '__typename',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {kind: 'Name', value: 'type'},
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'triggeredAt',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'paymentDate',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'rescheduleOnOrBeforeDate',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'customPaymentOptions'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'options'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'intention'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'amount'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'installmentCount',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          alias: {kind: 'Name', value: 'activeLoanCount'},
                                          name: {kind: 'Name', value: 'loanCount'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'amountBorrowed'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'amountOutstanding'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'amountPaidOff'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'amountProcessing'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'disbursement'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'amount'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'accountMask'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'accountFriendlyName'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'completedOn'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'method'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'status'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'updatedOn'},
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          alias: {kind: 'Name', value: 'activePayments'},
                                          name: {kind: 'Name', value: 'payments'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'payments'},
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {kind: 'Name', value: 'orderBy'},
                                                    value: {
                                                      kind: 'EnumValue',
                                                      value: 'RESCHEDULED_DATE',
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'amount'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'currency'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'executeEffectiveDateTime',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'failureCount'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'fees'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'fundingId'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'id'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'interest'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'loanId'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'methodCode'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'ordinal'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'originalDate'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'paidOffDateTime',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'payingOffNow'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'principal'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'rescheduledDate',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'statusCode'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'userId'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastTransaction',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'accountMask',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'accountFriendlyName',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastFailedTransaction',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'accountMask',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'accountFriendlyName',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'unavailablePaymentDates'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'dates'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'multiPaymentUpgradeAvailable',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ClosedLoanAggregateStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'prequalAmount'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'reapplyOn'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
                                        {
                                          kind: 'Field',
                                          alias: {kind: 'Name', value: 'closedLoanCount'},
                                          name: {kind: 'Name', value: 'loanCount'},
                                        },
                                        {
                                          kind: 'Field',
                                          alias: {kind: 'Name', value: 'closedPayments'},
                                          name: {kind: 'Name', value: 'payments'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'payments'},
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {kind: 'Name', value: 'orderBy'},
                                                    value: {
                                                      kind: 'EnumValue',
                                                      value: 'RESCHEDULED_DATE',
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'amount'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'failureCount'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'fees'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'id'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'loanId'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'methodCode'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'ordinal'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'originalDate'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'paidOffDateTime',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'payingOffNow'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'rescheduledDate',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'statusCode'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'userId'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastTransaction',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'accountMask',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'accountFriendlyName',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          alias: {kind: 'Name', value: 'closedDisbursement'},
                                          name: {kind: 'Name', value: 'disbursement'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'amount'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'accountMask'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'accountFriendlyName'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'completedOn'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'method'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'status'},
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'updatedOn'},
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'subscriptions'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'current'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'statusData'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'substatus'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'statusReason'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'firstPaymentAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'payments'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'all'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'subscriptionId'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'statusReason'},
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'scheduledAt'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'processorNetwork'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'paymentInstrumentId'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'fundingAuthorizationId'},
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardLoanAggregateStatusQuery,
  DashboardLoanAggregateStatusQueryVariables
>
