import {
  NextAvailablePaymentDateDocument,
  NextAvailablePaymentDateQuery,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {ApplyQuery} from '@possible/cassandra/src/utils/operations'

import {userIdSelector} from 'src/api/selectors/selectors'
import {DISBURSEMENT_METHOD_SELECTED} from 'src/lib/loans/actions'
import {transferMethodsType} from 'src/lib/loans/consts'
import Log from 'src/lib/loggingUtil'
import {getPfStore} from 'src/store'
import {PfDispatch} from 'src/store/types'

/**
 * Retrieves a loan's transfers and dispatches an action to update redux
 * @throws error if graphql call isn't successful
 * @returns the loan transfers
 */

export async function getNextAvailableSettlementDate(
  loanId: string,
  timeNow: string,
  forDisbursement: boolean,
): Promise<NextAvailablePaymentDateQuery['getNextAvailablePaymentDate'] | undefined> {
  const state = getPfStore().getState()
  const userId = userIdSelector(state)

  try {
    const responseData: NextAvailablePaymentDateQuery = (
      await ApplyQuery(NextAvailablePaymentDateDocument, {
        loanId,
        timeNow,
        forDisbursement,
        userId,
      })
    ).data

    if (!responseData) {
      throw new Error('Failed to retrieve next available settlement date')
    }

    return responseData['getNextAvailablePaymentDate']
  } catch (e) {
    Log.error(e, 'getNextAvailableSettlementDate error:')
  }
}

export async function getNextAvailableDisbursementDate(
  loanId: string,
  givenTimeNow: string,
): Promise<NextAvailablePaymentDateQuery['getNextAvailablePaymentDate'] | undefined> {
  return getNextAvailableSettlementDate(loanId, givenTimeNow, true)
}

export function UpdateDisbursementMethod(method: transferMethodsType) {
  // eslint-disable-next-line @typescript-eslint/require-await
  return async (dispatch: PfDispatch): Promise<void> => {
    dispatch({type: DISBURSEMENT_METHOD_SELECTED, value: method})
  }
}
