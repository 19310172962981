import React from 'react'

import {
  BottomSheet,
  BottomSheetProps,
} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'
import Button from 'src/designSystem/components/atoms/Button/Button'
import OverlaySimple, {
  OverlaySimpleProps,
} from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import {isDeviceWeb} from 'src/lib/utils/platform'

/**
 * BottomSheetOrOverlay is a simple wrapper around BottomSheet and OverlaySimple.
 * BottomSheet's are great on mobile, but they can look awful on web.
 * This component takes a BottomSheet's props and displays a BottomSheet in mobile, and an Overlay in web
 */

export type BottomSheetOrOverlayProps = BottomSheetProps &
  Pick<
    OverlaySimpleProps,
    'dismissButtonSize' | 'dismissButtonText' | 'okayButtonSize' | 'okayButtonText' | 'onOkay'
  > & {
    _storybookOnly_isWeb?: boolean // Only used by storybook
  }

export const BottomSheetOrOverlay = (props: BottomSheetOrOverlayProps): JSX.Element => {
  const {
    _storybookOnly_isWeb: isWebProp,
    titleVariant,
    graphic,
    testID,
    children,
    dismissButtonSize = 'medium',
    okayButtonSize = 'medium',
    dismissButtonText,
    okayButtonText,
    onOkay: handleOnOkay,
  } = props

  const isWeb = isWebProp === undefined ? isDeviceWeb() : isWebProp

  if (isWeb) {
    return (
      <OverlaySimple
        titleSize={titleVariant}
        image={graphic}
        testID={testID ?? ''}
        dismissButtonText={dismissButtonText}
        dismissButtonSize={dismissButtonSize}
        okayButtonSize={okayButtonSize}
        hideOnOkay={false}
        onOkay={handleOnOkay}
        {...props}
      >
        {children}
      </OverlaySimple>
    )
  } else {
    return (
      <BottomSheet
        {...props}
        dismissButton={
          dismissButtonText
            ? {
                text: dismissButtonText,
                size: dismissButtonSize,
              }
            : undefined
        }
      >
        {children}
        {okayButtonText ? (
          <Button onPress={handleOnOkay} size={okayButtonSize} width="100%" mode="primary">
            {okayButtonText}
          </Button>
        ) : null}
      </BottomSheet>
    )
  }
}
