import {
  LinkedAccount,
  AchPaymentMethod,
  DebitCardPaymentMethod,
  CheckPaymentMethod,
} from '@possible/cassandra/src/types/consumer'

export type PaymentAccount =
  | LinkedAccount
  | AchPaymentMethod
  | DebitCardPaymentMethod
  | CheckPaymentMethod
  | null
  | undefined

export enum PaymentFlow {
  Onboarding,
  PayNow,
  LoanPay,
  PayOverTime,
  SwitchToAutopay,
}

export enum PaymentMethodTypesEnum {
  Plaid = 'Plaid',
  Yodlee = 'Yodlee',
  AccountRouting = 'AccountRouting',
  DebitCard = 'DebitCard',
}

export type PaymentMethodTypeForAnalytics =
  | 'ach_routing_account'
  | 'ach_linked_with_aggregator'
  | 'debit_card'

export const callbackStatus = Object.freeze({
  SUCCESS: 'success',
  UNSUPPORTED_BANK: 'unsupported_bank',
  BANK_REQUIRES_SWITCH: 'bank_requires_switch',
  ERROR: 'error',
  EXITED_SUCCESS: 'exited_success',
})

type BankAggregatorCompletedStatusKeys = keyof typeof callbackStatus
export type BankAggregatorCompletedStatus =
  (typeof callbackStatus)[BankAggregatorCompletedStatusKeys]
