import React, {FC, ReactElement, useEffect, useState} from 'react'
import {useNavigation} from '@react-navigation/native'

import URAVerifyNameMatchesID from 'src/products/general/UserRequestedActions/URAVerifyNameMatchesID'
import URAUpdateSSN from 'src/products/general/UserRequestedActions/URAUpdateSSN'
import URAUpdateAddress from 'src/products/general/UserRequestedActions/URAUpdateAddress'
import {RelinkAccount} from 'src/products/general/UserRequestedActions/RelinkAccount'
import RetakeLightBox from 'src/products/general/UserRequestedActions/RetakeLightBox'
import {AchAccountNumber} from 'src/products/general/UserRequestedActions/AchAccountNumber/AchAccountNumber'
import {InterchangeAccountNumberLightBox} from 'src/products/general/UserRequestedActions/InterchangeAccountNumber/InterchangeAccountNumberLightBox'
import URAOther from 'src/products/general/UserRequestedActions/URAOther'
import {LinkAnotherAccount} from 'src/products/general/UserRequestedActions/LinkAnotherAccount/LinkAnotherAccount'
import ProofOfResidenceLightBox from 'src/products/general/UserRequestedActions/ProofOfResidenceLightBox'
import OverduePayment from 'src/products/general/UserRequestedActions/OverduePayment'
import Log from 'src/lib/loggingUtil'
import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import {URAUpdateAction} from 'src/lib/ura/actions/uraActions'
import {
  LinkUsableAccount,
  LinkUsableAccountUraType,
} from 'src/products/general/UserRequestedActions/LinkUsableAccount/LinkUsableAccount'
import {BankAddressMismatch} from 'src/products/general/UserRequestedActions/BankAddressMismatch/BankAddressMismatch'
import {UnfreezeBureauFrozenFile} from 'src/products/general/UserRequestedActions/UnfreezeBureauFrozenFile/UnfreezeBureauFrozenFile'
import {usePfDispatch} from 'src/store/utils'
import {UploadDocumentsModal} from 'src/products/general/UserRequestedActions/UploadDocumentsModal'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {ChangeDefaultPaymentMethod} from 'src/products/general/UserRequestedActions/ChangeDefaultPaymentMethod'
import {useMaintenanceMode} from 'src/lib/RealtimeDatabase'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {UseUraPendingUserActionsDocument} from 'src/nav/UseUra/UseUra.gqls'
import AppNavActions from 'src/nav/AppNavActions'
import {DocumentIdExpiredURA} from 'src/products/general/UserRequestedActions/DocumentIDExpiration/DocumentIDExpiration'

const genericURAAction = (ura_id: string): React.ReactElement => <URAOther ura_id={ura_id} />

const linkAnotherAccountAction = (ura_id: string): React.ReactElement => (
  // @ts-expect-error requires props added by caller
  <LinkAnotherAccount ura_id={ura_id} />
)
const retakeDLPictureAction = (ura_id: string): React.ReactElement => (
  <RetakeLightBox ura_id={ura_id} routeName={'RetakeFrontDL'} />
)
const proofOfResidenceAction = (ura_id: string): React.ReactElement => (
  <ProofOfResidenceLightBox ura_id={ura_id} />
)
const ssnCardAction = (ura_id: string): React.ReactElement => (
  <RetakeLightBox ura_id={ura_id} routeName={'SSNCard'} />
)
const paymentsOverdue = (ura_id: string): React.ReactElement => <OverduePayment uraId={ura_id} />

const URALookUp = new Map([
  ['other', genericURAAction],
  ['unrecognized', genericURAAction],
  [
    'verify_name_matches_id',
    (ura_id: string): React.ReactElement => {
      return <URAVerifyNameMatchesID ura_id={ura_id} />
    },
  ],
  ['payment_reschedule_request', genericURAAction],
  [
    'update_pii_ssn',
    (ura_id: string): React.ReactElement => {
      return <URAUpdateSSN ura_id={ura_id} />
    },
  ],
  ['ssn_card', ssnCardAction],
  [
    'update_pii_address',
    (ura_id: string): React.ReactElement => {
      return <URAUpdateAddress ura_id={ura_id} />
    },
  ],
  ['link_account_with_more_data', linkAnotherAccountAction],
  ['link_supported_institution', linkAnotherAccountAction],
  ['link_supported_accounts', linkAnotherAccountAction],
  [
    'payment_instrument_update_required',
    (ura_id: string): React.ReactElement => {
      // @ts-expect-error requires props added by caller
      return <LinkAnotherAccount hideSupportedBanks ura_id={ura_id} />
    },
  ],
  [
    'payments_reauth_required',
    (ura_id: string): React.ReactElement => {
      return <RelinkAccount uraId={ura_id} />
    },
  ],
  [
    'relink_account',
    (ura_id: string): React.ReactElement => {
      return <RelinkAccount uraId={ura_id} />
    },
  ],
  ['retake_id_corners', retakeDLPictureAction],
  ['retake_id_expired', retakeDLPictureAction],
  ['retake_id_fingers', retakeDLPictureAction],
  ['retake_id_form', retakeDLPictureAction],
  ['retake_id_front', retakeDLPictureAction],
  ['retake_id_lighting', retakeDLPictureAction],
  ['identity_docs_required', retakeDLPictureAction],
  [
    'retake_liveness',
    (ura_id: string): React.ReactElement => {
      return <RetakeLightBox ura_id={ura_id} routeName={'RetakeLiveness'} />
    },
  ],
  ['out_of_state', proofOfResidenceAction],
  [
    'por_bank_address_mismatch',
    (ura_id: string): React.ReactElement => {
      // @ts-expect-error requires props added by caller
      return <BankAddressMismatch ura_id={ura_id} />
    },
  ],
  ['por_date', proofOfResidenceAction],
  ['por_ln_mismatch', proofOfResidenceAction],

  [
    'account_number_required_ach',
    (ura_id: string): React.ReactElement => {
      // @ts-expect-error requires props added by caller
      return <AchAccountNumber ura_id={ura_id} />
    },
  ],
  [
    'account_number_required_interchange',
    (ura_id: string): React.ReactElement => {
      // @ts-expect-error requires props added by caller
      return <InterchangeAccountNumberLightBox ura_id={ura_id} />
    },
  ],
  ['contact_us', genericURAAction],
  ['payments_overdue', paymentsOverdue],
  [
    'link_personal_account',
    (ura_id: string): React.ReactElement => {
      return (
        <LinkUsableAccount uraId={ura_id} type={LinkUsableAccountUraType.LinkPersonalAccount} />
      )
    },
  ],
  [
    'link_account_with_new_name',
    (ura_id: string): React.ReactElement => {
      return (
        <LinkUsableAccount uraId={ura_id} type={LinkUsableAccountUraType.LinkAccountWithNewName} />
      )
    },
  ],
  [
    'relink_account_set_primary',
    (ura_id: string): React.ReactElement => {
      return (
        <LinkUsableAccount uraId={ura_id} type={LinkUsableAccountUraType.RelinkAccountSetPrimary} />
      )
    },
  ],
  [
    'unfreeze_bureau_frozen_file',
    (ura_id: string): React.ReactElement => {
      // @ts-expect-error requires props added by caller
      return <UnfreezeBureauFrozenFile ura_id={ura_id} />
    },
  ],
  [
    'joint_account_verification',
    (ura_id: string): React.ReactElement => {
      return (
        // @ts-expect-error requires props added by caller
        <UploadDocumentsModal
          ura_id={ura_id}
          uraViewedEvent={{
            eventName: AppEvents.Name.joint_account_verifcation_URA_viewed,
            eventCategory: AppEvents.Category.Application,
          }}
          uploadDocumentsRoute={'JointAccountVerification'}
        />
      )
    },
  ],
  [
    'docs_proof_of_income',
    (ura_id: string): React.ReactElement => {
      return (
        // @ts-expect-error requires props added by caller
        <UploadDocumentsModal
          ura_id={ura_id}
          uraViewedEvent={{
            eventName: AppEvents.Name.proof_of_income_URA_viewed,
            eventCategory: AppEvents.Category.Application,
          }}
          uploadDocumentsRoute={'ProofOfIncome'}
        />
      )
    },
  ],
  [
    'change_default_payment_method_request',
    (uraId: string): React.ReactElement => {
      return (
        // @ts-expect-error requires props added by caller
        <ChangeDefaultPaymentMethod uraId={uraId} />
      )
    },
  ],
  [
    'id_document_expired',
    (ura_id: string): React.ReactElement => {
      return <DocumentIdExpiredURA ura_id={ura_id} />
    },
  ],
  [
    'autopay_reauth_required',
    (uraId: string): ReactElement => {
      // @ts-expect-error requires props added by caller
      return <ChangeDefaultPaymentMethod uraId={uraId} isAutopay={true} />
    },
  ],
])

const StandardPollInterval = Math.round(5000 + Math.random() * 2500)
const MaintenancePollInterval = Math.round(5000 * 60 + Math.random() * 2500)

/**
 * Wrapper component that displays URA modals on top of any children
 * when the user has a pending URA.
 */
export const UseUra: FC<React.PropsWithChildren> = (props) => {
  const {children} = props

  const navigation = useNavigation()
  const isMaintenanceModeEnabled = useMaintenanceMode()
  const dispatch = usePfDispatch()

  const {selectedData: pendingUrasQueryData} = useCassandraQuery(
    UseUraPendingUserActionsDocument,
    {
      fetchPolicy: 'network-only',
      pollInterval: isMaintenanceModeEnabled ? MaintenancePollInterval : StandardPollInterval,
    },
    (data) => {
      // the GQL schema says these can be null so filter those out
      return data.me.userRequestedActions.pending?.filter((ura) => !!ura)
    },
  )

  // the ID of the URA that is currently visible
  const [liveUraId, setLiveUraId] = useState<string | null>(null)

  useEffect(() => {
    if (pendingUrasQueryData) {
      // store the pending URAs in the redux store. this really shouldn't be necessary since
      // it's in apollo client cache and it's only used by children of this component but
      // it stays for now since all URA components pull data from the store
      dispatch(URAUpdateAction(pendingUrasQueryData))
    }
  }, [dispatch, pendingUrasQueryData])

  useEffect(() => {
    const firstPendingUra = pendingUrasQueryData?.[0]
    if (!firstPendingUra && liveUraId) {
      // pop off the remaining lightbox modal if it's remaining (probably because the URA was closed in IAM)
      AppNavActions.pop(navigation)
      setLiveUraId(null)
    }
    const isAPendingUraVisible = !!pendingUrasQueryData?.find((ura) => ura.id === liveUraId)
    // if there is a pending URA and no URA is currently visible we will show one
    if (firstPendingUra && !isAPendingUraVisible) {
      const thisUraComponentHandler = URALookUp.get(
        String(firstPendingUra.actionType).toLowerCase(),
      )
      const isDismissible = firstPendingUra.optional
      const modalKey = `URAModal${firstPendingUra.id}`
      if (thisUraComponentHandler) {
        setLiveUraId(firstPendingUra.id)
        ShowLightbox(thisUraComponentHandler(firstPendingUra.id), !isDismissible, false, modalKey)
      } else {
        Log.error('No URA component found for action type', firstPendingUra.actionType)
      }
    }
  }, [setLiveUraId, liveUraId, pendingUrasQueryData, navigation])

  return <>{children}</>
}
