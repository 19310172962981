import React from 'react'
import {createStackNavigator} from '@react-navigation/stack'

import {subStackScreenOptions} from 'src/nav/Stacks/subStackScreenOptions'
import {LoanPayMethods} from 'src/products/loans/MakePayment/LoanPayMethods/LoanPayMethods'
import {LoanPayConfirm} from 'src/products/loans/MakePayment/LoanPayConfirm/LoanPayConfirm'
import {LoanPaySuccess} from 'src/products/loans/MakePayment/LoanPaySuccess/LoanPaySuccess'
import {LoanPayFailure} from 'src/products/loans/MakePayment/LoanPayFailure/LoanPayFailure'
import type {LoanPayNavigatorStack} from 'src/products/loans/MakePayment/LoanPayNavigatorStack'
import {LoanPayProvider} from 'src/products/loans/MakePayment/LoanPayProvider'
import {LoanPayAmountEntry} from 'src/products/loans/MakePayment/LoanPayAmountEntry/LoanPayAmountEntry'

const {Navigator, Screen} = createStackNavigator<LoanPayNavigatorStack>()

/* Consider using `Group` after future navigation refactor. Using a nested `Navigator` is the
 * safest way to reset our current `screenOptions` without affecting the rest of the app nav. */
const LoanPayNavigator = (): JSX.Element => {
  return (
    /* wrap the entire navigator in the LoansMakePaymentContext provider so that all screens have access to shared query data and local UI state */
    <LoanPayProvider>
      <Navigator initialRouteName={'LoanPayAmountEntry'} screenOptions={subStackScreenOptions}>
        <Screen component={LoanPayConfirm} name={'LoanPayConfirm'} />
        <Screen component={LoanPayAmountEntry} name={'LoanPayAmountEntry'} />
        <Screen component={LoanPayMethods} name={'LoanPayMethods'} />
        <Screen
          component={LoanPayFailure}
          name={'LoanPayFailure'}
          options={{
            cardStyle: {backgroundColor: 'transparent'},
            presentation: 'transparentModal',
          }}
        />
        <Screen component={LoanPaySuccess} name={'LoanPaySuccess'} options={{headerShown: false}} />
      </Navigator>
    </LoanPayProvider>
  )
}

export {LoanPayNavigator}
