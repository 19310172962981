import React, {FC, ReactNode} from 'react'
import {SafeAreaView} from 'react-native-safe-area-context'
import {ScrollView, StyleSheet, View, Image, ImageSourcePropType} from 'react-native'
import {connect, ConnectedProps} from 'react-redux'

import {primaryBackgroundColor} from 'src/designSystem/semanticColors'
import {smallGap, largeGap, gapSizeMap} from 'src/designSystem/layout'
import TileListView from 'src/products/loans/components/molecules/TileListView/TileListView'
import FullPageLoading from 'src/designSystem/components/templates/FullPageLoading/FullPageLoading'
import {pfHeaderHeight} from 'src/nav/Header/HeaderConstants'
import {NetworkFailureError} from 'src/products/loans/NetworkFailureError/NetworkFailureError'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {SizeVariants} from 'src/designSystem/types'
import {PfReduxState} from 'src/reducers/types'

export type TileListTemplateProps = {
  header?: ReactNode
  list: ReactNode[]
  footer?: ReactNode
  loading?: boolean
  backgroundImageSrc?: ImageSourcePropType
  paddingSide?: SizeVariants | number
  paddingBottom?: SizeVariants | number
  listViewMarginTop?: number
  headerSpacerWebMultiplierOverride?: number
}
const TileListTemplate: FC<TileListTemplateProps & PropsFromRedux> = ({
  header,
  list,
  footer,
  loading,
  backgroundImageSrc,
  badConnection,
  paddingSide = 0,
  paddingBottom = 0,
  listViewMarginTop = largeGap,
  headerSpacerWebMultiplierOverride,
}) => {
  if (loading) {
    return <FullPageLoading animating={loading} backgroundColor={primaryBackgroundColor} />
  }
  if (badConnection) {
    return (
      <NetworkFailureError
        logo={header !== null && header !== undefined}
        backgroundColor={primaryBackgroundColor}
      />
    )
  }

  let headerSpaceWebMultiple: number = header ? 2 : 3
  if (headerSpacerWebMultiplierOverride) {
    headerSpaceWebMultiple = headerSpacerWebMultiplierOverride
  }
  const headerSpacerNativeMultipler = 1

  let sidePaddingSize: SizeVariants | number
  if (typeof paddingSide === 'number') {
    sidePaddingSize = paddingSide
  } else if (gapSizeMap[paddingSide]) {
    sidePaddingSize = gapSizeMap[paddingSide]
  } else {
    sidePaddingSize = 0
  }

  let bottomPaddingSize: SizeVariants | number

  if (typeof paddingBottom === 'number') {
    bottomPaddingSize = paddingBottom
  } else if (gapSizeMap[paddingBottom]) {
    bottomPaddingSize = gapSizeMap[paddingBottom]
  } else {
    bottomPaddingSize = 0
  }

  return (
    <>
      {backgroundImageSrc ? (
        <Image
          source={backgroundImageSrc}
          style={[StyleSheet.absoluteFill, styles.backgroundImage]}
        />
      ) : null}
      <View
        key="spacer"
        style={{
          height:
            (isDeviceWeb() ? headerSpaceWebMultiple : headerSpacerNativeMultipler) * pfHeaderHeight,
        }}
      />
      <SafeAreaView
        style={[
          styles.container,
          {
            paddingLeft: sidePaddingSize,
            paddingRight: sidePaddingSize,
            paddingBottom: bottomPaddingSize,
          },
        ]}
        edges={['top']}
      >
        {header}
        <ScrollView
          contentContainerStyle={styles.scrollViewContainer}
          showsVerticalScrollIndicator={false}
        >
          <View
            style={[
              styles.listView,
              {
                marginTop: listViewMarginTop,
              },
            ]}
          >
            <TileListView list={list} />
          </View>
          {footer ? <View style={styles.footerView}>{footer}</View> : null}
        </ScrollView>
      </SafeAreaView>
    </>
  )
}

const mapStateToProps = (
  state: PfReduxState,
): {
  badConnection: boolean
} => {
  return {
    badConnection: state.api.badConnection,
  }
}
const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(TileListTemplate)

const contentMaxWidth: number = 450 as const

const styles = StyleSheet.create({
  backgroundImage: {
    resizeMode: 'stretch',
  },
  container: {
    alignSelf: 'center',
    flex: 1,
    maxWidth: contentMaxWidth,
    width: '100%',
  },
  footerView: {
    marginBottom: 2 * smallGap,
    marginTop: 2 * smallGap,
  },
  listView: {
    flex: 1,
  },
  scrollViewContainer: {
    flexGrow: 1,
    paddingTop: 4, //this is to prevent the cutting of the shadow
  },
})
