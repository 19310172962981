import React, {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'

import {SurveyTemplate} from 'src/products/general/components/templates/SurveyTemplate/SurveyTemplate'
import {OnContinueAfterMarketingSurveyOptionSelected} from 'src/products/general/MarketingSurvey/MarketingSurvey.types'

export type MarketingSurveyTemplateProps = {
  onContinue: OnContinueAfterMarketingSurveyOptionSelected
  marketingSourceOptions?: string[]
}

const defaultMarketingSourceOptions: string[] = [
  i18next.t('LoansMarketingSurvey:FacebookInstagram'),
  i18next.t('LoansMarketingSurvey:TikTok'),
  i18next.t('LoansMarketingSurvey:CreditKarma'),
  i18next.t('LoansMarketingSurvey:MoneyLion'),
  i18next.t('LoansMarketingSurvey:ProvidersApp'),
  i18next.t('LoansMarketingSurvey:BrowsingApple'),
  i18next.t('LoansMarketingSurvey:BrowsingGoogle'),
  i18next.t('LoansMarketingSurvey:SomeoneIKnow'),
  i18next.t('LoansMarketingSurvey:LetterInTheMail'),
]

/**
 * Template for the marketing survey that asks users "How did you hear about us?".
 */
const MarketingSurveyTemplate: FC<MarketingSurveyTemplateProps> = (props) => {
  const {onContinue, marketingSourceOptions} = props
  const {t} = useTranslation('LoansMarketingSurvey')
  const [isBusy, setIsBusy] = useState(false)

  // use options from props if provided, otherwise fallback to defaults
  const marketingSourceOptionsToUse = React.useMemo(
    () => marketingSourceOptions ?? defaultMarketingSourceOptions,
    [marketingSourceOptions],
  )

  const handleOnPressContinue = async (
    selectedSourceOption: string,
    otherValue?: string,
  ): Promise<void> => {
    setIsBusy(true)
    await onContinue({
      selectedSourceOption,
      otherValue,
    })
    setIsBusy(false)
  }

  return (
    <SurveyTemplate
      onContinue={(value: string, otherValue?: string): Promise<void> =>
        handleOnPressContinue(value, otherValue)
      }
      title={t('HowDidYouHear')}
      radioButtonExtraInputLabel={t('WhereDidYouHear')}
      showDontRecall
      options={marketingSourceOptionsToUse}
      isContinueLoading={isBusy}
    />
  )
}

export {MarketingSurveyTemplate}
