import React, {RefObject, forwardRef, useMemo} from 'react'

import {TextInput, TextInputProps, ViewStyle} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {InputFieldStyle, selectionColor} from 'src/designSystem/components/atoms/PFTextInput/styles'
import {isDeviceWeb} from 'src/lib/utils/platform'

export type InputProps = TextInputProps & {
  value?: string
  hasIcon: boolean
  editable?: boolean
  numberOfLines?: number
  onChangeText: (value: string) => void
  onFocus: () => void
  onBlur: () => void
  ref?: React.Ref<any>
  testID?: string
}

const Input = forwardRef((props: InputProps, ref: React.Ref<any>) => {
  const {
    value,
    multiline,
    hasIcon,
    editable,
    numberOfLines,
    onChangeText,
    onFocus,
    onBlur,
    testID,
    ...otherProps
  } = props

  const inputFieldStyle: ViewStyle = useMemo(
    () => InputFieldStyle(hasIcon, !!multiline),
    [hasIcon, multiline],
  )

  const adjustMultilineTextInputHeightWeb = (e) => {
    if (isDeviceWeb() && multiline) {
      // https://github.com/necolas/react-native-web/issues/795
      const target = e.target ?? e.nativeEvent.target
      target.style.height = 0
      target.style.height = `${target.scrollHeight}px`
    }
  }

  return (
    <TextInput
      ref={ref}
      onLayout={adjustMultilineTextInputHeightWeb}
      onChange={adjustMultilineTextInputHeightWeb}
      testID={testID}
      {...otherProps}
      value={value}
      underlineColorAndroid={NamedColors.TRANSPARENT}
      selectionColor={selectionColor}
      style={inputFieldStyle}
      editable={editable}
      multiline={multiline}
      numberOfLines={
        isDeviceWeb() ? 1 : numberOfLines // https://github.com/necolas/react-native-web/issues/795
      }
      onChangeText={onChangeText}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
})

Input.displayName = 'Input'

export default Input

export type InputRef = RefObject<TextInput>
