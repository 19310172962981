import {CLEAR_USER_ACTION} from 'src/api/actions/actionsNames'
import * as loanActions from 'src/lib/loans/actions'
import {LoanState} from 'src/lib/loans/reducers/types'

export const LoansStateDefault: LoanState = {
  accountAndRoutingProvided: undefined,
  paymentMethodSelected: undefined,
  disbursementMethodSelected: undefined,
}

export default function reducer(state: LoanState = LoansStateDefault, action) {
  switch (action.type) {
    case loanActions.DISBURSEMENT_METHOD_SELECTED:
      return {...state, disbursementMethodSelected: action.value}
    case loanActions.PAYMENT_METHOD_SELECTED:
      return {...state, paymentMethodSelected: action.value}
    case loanActions.ACCOUNT_AND_ROUTING_PROVIDED:
      return {...state, accountAndRoutingProvided: action.value}
    case CLEAR_USER_ACTION:
      return LoansStateDefault
    default:
      return state
  }
}
