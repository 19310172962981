import React, {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import {Animated, View} from 'react-native'

import {animationDuration, animationEasing} from 'src/designSystem/animations'
import {black, textDisabled} from 'src/designSystem/colors'
import {FormFieldProps} from 'src/designSystem/components/atoms/HookForm/HookForm'
import Dropdown from 'src/designSystem/components/atoms/PFDropdown/Dropdown/Dropdown'
import styles from 'src/designSystem/components/atoms/PFDropdown/styles'
import PFFieldBase from 'src/designSystem/components/atoms/PFFieldBase/PFFieldBase'
import {
  PFFieldBaseStates,
  PFFieldBaseStateTypes,
} from 'src/designSystem/components/atoms/PFFieldBase/types'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgIconColorVariantNames} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'
import {isStringValueEmpty} from 'src/designSystem/lib/dataUtil'
import {getBaseFieldState, getErrorState} from 'src/designSystem/lib/fieldUtil'
import {textInputEmptyColor} from 'src/designSystem/semanticColors'
import {Color} from 'src/designSystem/types'
import {isDeviceIOS, isDeviceWeb} from 'src/lib/utils/platform'

export type PFDropdownOptionProps = {
  label: string
  value: any
  color?: any
}

export type PFDropdownProps = {
  label: string
  options: PFDropdownOptionProps[]
  shouldUseValueForLabel?: boolean
  value?: string
  onSelection?: (value: any) => void
  placeholder: string
  error?: string
  infoMessage?: string
  shouldErrorOnEmpty?: boolean
  onFocus?: () => void
  onBlur?: () => void
  isEditable?: boolean
  formProps?: FormFieldProps
  testID?: string
  ref?: React.Ref<any>
}

const getTextColor = (isValueEmpty: boolean): Color => {
  return isValueEmpty ? textInputEmptyColor : black
}

const getIconColorVariant = (dropdownState: PFFieldBaseStateTypes): SvgIconColorVariantNames => {
  return dropdownState === PFFieldBaseStates.ACTIVE ? 'info' : 'default'
}

const getFieldActive = (isValueEmpty: boolean, hasFocus: boolean): boolean => {
  return !isValueEmpty || hasFocus
}

type LabelValueProps = {
  options: PFDropdownOptionProps[]
  value: string | undefined
  isValueEmpty: boolean
  shouldUseValueForLabel: PFDropdownProps['shouldUseValueForLabel']
  hasFocus: boolean
  placeholder: string
}

//useValueForLabel will display the value instead of the label when the dropdown is closed
const getLabelValue = (props: LabelValueProps): string => {
  const {options, value, isValueEmpty, shouldUseValueForLabel = true, hasFocus, placeholder} = props
  const selectedOption = options.find((item) => item.value === value)
  if (selectedOption) {
    return shouldUseValueForLabel ? String(selectedOption.value) : selectedOption.label
  } else if (isValueEmpty && hasFocus) {
    return placeholder
  } else {
    return ' ' //using spacing here to force text render content, otherwise it will cause a flick when set value
  }
}

const PFDropdown = forwardRef((props: PFDropdownProps, ref: React.Ref<any>) => {
  const {
    value,
    label,
    isEditable = true,
    options,
    shouldUseValueForLabel,
    onFocus,
    onBlur,
    onSelection,
    placeholder,
    testID,
    infoMessage,
    error,
    shouldErrorOnEmpty,
  } = props

  const dropdownRef: RefObject<any> = useRef(null)
  useImperativeHandle(ref, () => ({
    toggle: (focus): void => {
      if (isDeviceWeb()) {
        setHasFocus(!!focus)
      } else if (isDeviceIOS()) {
        dropdownRef?.current?.togglePicker(true)
      } else {
        /* android not supported */
      }
    },
  }))

  const placeholderObj: PFDropdownOptionProps = {
    label: placeholder,
    value: undefined,
    color: textDisabled,
  }
  const [dropdownState, setDropdownState] = useState<PFFieldBaseStateTypes>(
    PFFieldBaseStates.DEFAULT,
  )
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const [hasBlur, setHasBlur] = useState<boolean>(false)
  const [isFirstAttempt, setIsFirstAttempt] = useState<boolean>(true)
  const isValueEmpty: boolean = isStringValueEmpty(value)
  const animationControl: Animated.Value = useMemo(
    () => new Animated.Value(isValueEmpty ? 0 : 1),
    [isValueEmpty],
  )

  useEffect(() => {
    const hasErrorState = getErrorState(shouldErrorOnEmpty, isValueEmpty, isFirstAttempt, error)
    setDropdownState(getBaseFieldState(hasErrorState, isValueEmpty, hasFocus))
  }, [value, hasFocus, isValueEmpty, isFirstAttempt, shouldErrorOnEmpty, error])

  const runAnimations: (activeState: boolean) => void = useCallback(
    (activeState: boolean) => {
      Animated.timing(animationControl, {
        toValue: activeState ? 1 : 0,
        duration: animationDuration,
        useNativeDriver: false,
        easing: animationEasing,
      }).start()
    },
    [animationControl],
  )

  useEffect(() => {
    runAnimations(getFieldActive(isValueEmpty, hasFocus))
  }, [isValueEmpty, hasFocus, runAnimations])

  useEffect(() => {
    if (hasFocus) {
      onFocus?.()
    }
    // onFocus dep causes this to hook to execute far too often
    // disabling until we agree on a better solution for parent function deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFocus])

  useEffect(() => {
    if (hasBlur) {
      setHasBlur(false)
      onBlur?.()
      if (isFirstAttempt) {
        setIsFirstAttempt(false)
      }
    }
  }, [hasBlur, onBlur, isFirstAttempt])

  const iconColorVariant = useMemo(() => getIconColorVariant(dropdownState), [dropdownState])
  const textColor = useMemo(() => getTextColor(isValueEmpty), [isValueEmpty])

  const handleOnValueChange = (newValue): void => {
    onSelection?.(newValue)
    setIsFirstAttempt(false) // android support (this should be removed when picker supports onClose event)
  }

  const dropdownIcon: () => SvgIconProps = () => ({
    name: 'chevronDown',
    colorVariant: iconColorVariant,
  })

  return (
    <Dropdown
      testID={testID}
      ref={dropdownRef}
      editable={isEditable}
      animationControl={animationControl}
      hasFocus={hasFocus}
      value={value}
      placeholderObj={placeholderObj}
      options={options}
      setHasFocus={setHasFocus}
      setHasBlur={setHasBlur}
      onValueChange={handleOnValueChange}
    >
      <PFFieldBase
        label={label}
        animationControl={animationControl}
        infoMessage={infoMessage}
        error={error}
        editable={isEditable}
        icon={dropdownIcon()}
        onPressIcon={() => setHasFocus(!hasFocus)}
        fieldState={dropdownState}
      >
        <View style={styles.textBox}>
          <PFText variant={'p'} color={textColor}>
            {getLabelValue({
              options,
              value,
              isValueEmpty,
              shouldUseValueForLabel,
              hasFocus,
              placeholder,
            })}
          </PFText>
        </View>
      </PFFieldBase>
    </Dropdown>
  )
})

PFDropdown.displayName = 'PFDropdown'

export {PFDropdown}
