import React, {FC} from 'react'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {paymentScheduleBackground} from 'src/designSystem/semanticColors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {Loading} from 'src/designSystem/components/atoms/Loading/Loading'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {getFormattedAccountType} from 'src/lib/user/utils'

export type PaymentInfoBoxPayment = {
  id: string
  date: string
  amount: string
}

type PaymentRowProps = {
  payment: PaymentInfoBoxPayment
}

type PaymentRowsProps = {
  payments: PaymentInfoBoxPayment[]
}

export type PaymentInfoBoxProps = {
  payments: PaymentInfoBoxPayment[]
  preferredAccountInstitutionName: string
  preferredAccountMask: string
  preferredAccountType: string
  isAccountDataLoading?: boolean
}

const PaymentRow: FC<PaymentRowProps> = (props) => {
  const {payment} = props
  return (
    <Box justify="between" marginVertical={'little'} direction="row" width={140}>
      <Box>
        <PFText variant={'p_semibold'}>{moment(payment.date).format('MMM DD')}</PFText>
      </Box>
      <Box align={'end'}>
        <PFText variant={'p_semibold'}>${payment.amount}</PFText>
      </Box>
    </Box>
  )
}

const PaymentRows: FC<PaymentRowsProps> = (props) => {
  const {payments} = props
  return (
    <Box>
      {payments?.map((payment) => <PaymentRow key={`payment-${payment.id}`} payment={payment} />)}
    </Box>
  )
}

const PaymentInfoBox: FC<PaymentInfoBoxProps> = (props) => {
  const {
    payments,
    preferredAccountInstitutionName,
    preferredAccountMask,
    preferredAccountType,
    isAccountDataLoading,
  } = props
  const {t} = useTranslation('LoanApproved')

  return (
    <Box
      align={'center'}
      background={paymentScheduleBackground}
      paddingTop={'medium'}
      paddingBottom={'small'}
      paddingHorizontal={'small'}
    >
      {isAccountDataLoading ? null : (
        <PFText textAlign={'center'} variant={'p_sm'}>
          {t('FromAccount')}
        </PFText>
      )}
      <Box
        flex
        marginHorizontal={'small'}
        marginTop={'little'}
        marginBottom={isAccountDataLoading ? 'little' : undefined}
      >
        {isAccountDataLoading ? (
          <Loading type={'loader0'} size={'large'} />
        ) : (
          <PFText textAlign={'center'} variant={'p_semibold'}>
            {t('PreferredAccountDetails', {
              name: preferredAccountInstitutionName,
              type: getFormattedAccountType(preferredAccountType),
              mask: preferredAccountMask,
            })}
          </PFText>
        )}
      </Box>
      {isAccountDataLoading ? null : (
        <Box marginTop={'small'}>
          <PFText textAlign={'center'} variant={'p_sm'}>
            {t('OnDate')}
          </PFText>
        </Box>
      )}
      <PaymentRows payments={payments ?? []} />
    </Box>
  )
}

export {PaymentInfoBox}
