import React, {FC, useCallback} from 'react'

import {LoanAmountSelectionGQLContainer} from 'src/products/loans/LoanAmountSelection/LoanAmountSelectionGQLContainer'
import {MainStackScreenProps} from 'src/nav/MainStackParamsList'

type Props = MainStackScreenProps<'LoanAmount'>

const LoanAmountSelectionRouteContainer: FC<Props> = ({navigation, route}) => {
  const {
    params: {previousAmountSelected},
  } = route

  const handleOnComplete = useCallback((): Promise<void> => {
    return new Promise(() => navigation.pop())
  }, [navigation])

  return (
    <LoanAmountSelectionGQLContainer
      onPreReqFulfilled={handleOnComplete}
      previousAmountSelected={previousAmountSelected}
    />
  )
}

export {LoanAmountSelectionRouteContainer}
