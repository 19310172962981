import React, {useState} from 'react'
import {View, StyleSheet, LayoutChangeEvent} from 'react-native'
import {Document, Page, pdfjs} from 'react-pdf'
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from 'react-navigation-props-mapper'
import {useTranslation} from 'react-i18next'

import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import {ScrollViewBase} from 'src/designSystem/components/molecules/ScrollViewBase/ScrollViewBase'
import {pfHeaderHeight} from 'src/nav/Header/HeaderConstants'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {SessionReplay} from 'src/lib/sessionReplay/sessionReplay'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

//see https://github.com/wojtekmaj/react-pdf/wiki/Known-issues
//It's preferred we CopyWebpackPlugin to copy pdf.worker.js during build. But we're doing this for simplicity
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

type PDFViewerProps = ForwardedStackScreenProps<MainStackParamList, 'PDFViewer'>

const PDFViewer = withForwardedNavigationParams<PDFViewerProps>()((props) => {
  const {uri} = props

  const [numPages, setNumPages] = useState(0)
  const [width, setWidth] = useState(0)
  const {t} = useTranslation('Documents')

  const handleOnLoadSuccess: Parameters<typeof Document>[0]['onLoadSuccess'] = (event) => {
    setNumPages(event.numPages)
  }

  function renderPages(): JSX.Element[] {
    const pages: JSX.Element[] = []
    for (let i = 0; i < numPages; i++) {
      pages.push(<Page key={`PDFPage${i}`} pageNumber={i + 1} width={width} />)
    }
    return pages
  }

  const handleOnLayout = (event: LayoutChangeEvent): void =>
    setWidth(event.nativeEvent.layout.width)

  const handleOnPress = async (): Promise<void> => {
    await fetch(uri)
      .then((res) => res.blob())
      .then((blob) => {
        const file = window.URL.createObjectURL(blob)
        window.open(file)
      })
  }

  return (
    <View onLayout={handleOnLayout} style={styles.container}>
      <ScrollViewBase
        style={styles.scrollView}
        testID={'PDFViewer—ScrollViewBase'}
        {...SessionReplay.privacyProps()}
      >
        <Document file={uri} onLoadSuccess={handleOnLoadSuccess}>
          {renderPages()}
        </Document>
      </ScrollViewBase>
      <View style={styles.floatingButton}>
        <Button
          mode={'secondary'}
          size={'large'}
          icon={{name: 'download', colorVariant: 'default'}}
          onPress={handleOnPress}
        >
          {t('Download')}
        </Button>
      </View>
    </View>
  )
})

export {PDFViewer}

const styles = StyleSheet.create({
  container: {
    backgroundColor: genericPageBackground,
    flex: 1,
    width: '100%',
  },
  floatingButton: {
    alignItems: 'center',
    bottom: 24,
    position: 'absolute',
    width: '100%',
  },
  scrollView: {
    marginTop: pfHeaderHeight,
  },
})
