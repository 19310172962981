import React, {useState} from 'react'
import {StyleSheet, TouchableOpacity, View} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {pageMarginHorizontal} from 'src/designSystem/layout'
import {Color} from 'src/designSystem/types'
import {DefaultVariantsColor, NamedColors, textDisabled} from 'src/designSystem/colors'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {
  PFStatusPill,
  PFStatusPillProps,
} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'

// This is only used within Loan Adhoc Payment. Card uses CardActionListItem
export type ActionListItemProps = {
  isDisabled?: boolean
  isFirstChild?: boolean
  isPadded?: boolean
  onPress?: () => void
  titleStatusPill?: PFStatusPillProps
  rightStatusPill?: PFStatusPillProps
  moreInfoText?: string
  subTitle?: string | JSX.Element
  title: string
  titleColor?: Color
  testID?: string
}

type ActionListItemContentProps = Omit<
  ActionListItemProps,
  'isFirstChild' | 'isPadded' | 'onPress' | 'rightStatusPill'
> & {
  showMoreInfo?: boolean
  rightContent?: JSX.Element | null
}

const ActionListItemContent = ({
  titleStatusPill,
  rightContent,
  moreInfoText,
  showMoreInfo,
  title,
  titleColor = DefaultVariantsColor.black,
  subTitle,
  isDisabled,
}: ActionListItemContentProps): JSX.Element => {
  return (
    <Box align={'center'} direction={'row'} paddingVertical={'small'}>
      <Box gap={'tiny'} grow shrink={1}>
        {titleStatusPill ? <PFStatusPill {...titleStatusPill} /> : null}
        <Box direction={'row'} align={'center'} gap={'tiny'}>
          <PFText variant={'p_semibold'} color={isDisabled ? textDisabled : titleColor}>
            {title}
          </PFText>
          {moreInfoText ? <SvgIcon name={'info'} colorVariant={'active'} isFilled /> : null}
        </Box>
        {showMoreInfo ? (
          <PFText variant={'p_sm'} color={NamedColors.SILVER}>
            {moreInfoText}
          </PFText>
        ) : null}
        {subTitle ? (
          <PFText variant={'p'} color={isDisabled ? textDisabled : 'textPrimary'}>
            {subTitle}
          </PFText>
        ) : null}
      </Box>
      {rightContent}
    </Box>
  )
}

const ActionListItem = ({
  isDisabled,
  moreInfoText,
  rightStatusPill,
  isFirstChild,
  isPadded,
  onPress,
  testID,
  ...props
}: ActionListItemProps): JSX.Element => {
  const wrapperStyle = [
    styles.listSeparator,
    isFirstChild && styles.firstChild,
    isPadded && styles.padded,
  ]

  const [showMoreInfo, setShowMoreInfo] = useState(false)

  const handleMoreInfoOnPress = (): void => {
    setShowMoreInfo(!showMoreInfo)
  }

  const handleOnPress = moreInfoText ? handleMoreInfoOnPress : onPress

  const getRightComponent = (): JSX.Element | null => {
    if (!onPress || moreInfoText) return null
    if (rightStatusPill) {
      return <PFStatusPill {...rightStatusPill} />
    } else {
      return <SvgIcon name={'chevronRight'} colorVariant={isDisabled ? 'inactive' : 'active'} />
    }
  }

  return (
    //  Borders render outside of `TouchableOpacity` so they don't change opacity unevenly `onPress`.
    <View style={wrapperStyle} testID={testID}>
      {handleOnPress ? (
        <TouchableOpacity
          disabled={isDisabled && !moreInfoText}
          onPress={handleOnPress}
          testID={`${testID}-OnPress`}
        >
          <ActionListItemContent
            {...props}
            isDisabled={isDisabled}
            moreInfoText={moreInfoText}
            showMoreInfo={showMoreInfo}
            rightContent={getRightComponent()}
          />
        </TouchableOpacity>
      ) : (
        <ActionListItemContent {...props} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  firstChild: {
    borderTopWidth: 1,
  },
  listSeparator: {
    borderBottomWidth: 1,
    borderColor: NamedColors.ASH,
  },
  padded: {
    paddingHorizontal: pageMarginHorizontal,
  },
})

export {ActionListItem}
