import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {AppEvents} from 'src/lib/Analytics/app_events'
import {completeUra} from '@possible/cassandra'
import {logErrorAndShowException} from 'src/lib/errors'
import {PendingURAType} from 'src/lib/ura/types'
import {PfReduxState} from 'src/reducers/types'
import {PushPage} from 'src/navigation/NavHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {uraIds} from 'src/lib/ura/selectors'
import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import {useBankAggregator} from 'src/products/general/GeneralPaymentMethods/useBankAggregator'
import {useMainNavigator} from 'src/nav/AppNavHelper'
import {usePfSelector} from 'src/store/utils'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'

type BankAddressMismatchURAProps = URAProps

const BankAddressMismatch: React.FC<BankAddressMismatchURAProps> = (props) => {
  const {ura_id: uraId, close} = props
  const {t} = useTranslation('UserRequestedAction')

  const [isBusy, setIsBusy] = useState(false)
  const mainStackNav = useMainNavigator()
  const {openBankAggregator} = useBankAggregator(mainStackNav)

  const ura = usePfSelector((state: PfReduxState): PendingURAType | undefined =>
    uraIds(state)?.get(uraId),
  )

  const onBankComplete = useCallback(
    async (success: boolean): Promise<void> => {
      try {
        if (success) {
          await completeUra(uraId)
          close?.()
        }
      } catch (e) {
        void logErrorAndShowException(e, 'BankAddressMismatch, onBankComplete:')
      }
    },
    [close, uraId],
  )

  const onLinkAccount = useCallback(async (): Promise<void> => {
    setIsBusy(true)
    await openBankAggregator({
      onBankLinkComplete: async () => {
        await onBankComplete(true)
      },
    })
    setIsBusy(false)
  }, [onBankComplete, openBankAggregator])

  const onComplete = useCallback(async (): Promise<void> => {
    try {
      TrackAppEvent(
        AppEvents.Name.provide_proof_of_residency_completed,
        AppEvents.Category.Application,
      )
      setIsBusy(true)
      await completeUra(uraId)
      close?.()
    } catch (e) {
      void logErrorAndShowException(e, 'BankAddressMismatch, onComplete:')
    } finally {
      setIsBusy(false)
    }
  }, [close, uraId])

  const onRetake = useCallback((): void => {
    PushPage(mainStackNav, 'ProofOfResidence', {
      noAddressFill: true,
      uraActionType: ura?.actionType,
      onProofComplete: () => onComplete(),
    })
  }, [mainStackNav, onComplete, ura?.actionType])

  const primary = {
    text: t('VerifyTheAddress'),
    disabled: isBusy,
    onPress: onRetake,
  }

  const secondary = {
    text: t('LinkANewAccount'),
    disabled: isBusy,
    onPress: onLinkAccount,
  }

  return (
    <URAModalContent
      uraId={uraId}
      ura={ura}
      primary={primary}
      secondary={secondary}
      // this URA should not be closable unless the user has successfully completed the action
      close={(): void => {}}
    />
  )
}

export {BankAddressMismatch}
