import React, {ReactNode, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {
  LoanActiveStatusPaymentAlertItem,
  LoanActiveStatusPaymentAlerts,
  SubscriptionStatus,
} from '@possible/cassandra/src/types/types.mobile.generated'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import TileListView, {
  type ReactNodeWithStyle,
} from 'src/products/loans/components/molecules/TileListView/TileListView'
import {AdvanceAlertTile} from 'src/products/loans/Dashboard/AdvanceAlertTile/AdvanceAlertTile'
import {DashboardLoanActiveProps} from 'src/products/loans/Dashboard/DashboardLoanActive/DashboardLoanActive.types'
import {
  determineLoanAlertStatuses,
  useDashboardLoanActiveAlertsAnalytics,
  useRedirectActiveLoanWebUserToAppIfAppropriate,
} from 'src/products/loans/Dashboard/DashboardLoanActive/DashboardLoanActive.utils'
import {MoneyOnTheWayBottomSheetContainer} from 'src/products/loans/Dashboard/DashboardLoanActive/MoneyOnTheWay/MoneyOnTheWayBottomSheetContainer'
import {
  LoanAlertTile,
  LoanAlertTileProps,
} from 'src/products/loans/Dashboard/LoanAlertTile/LoanAlertTile'
import {
  LoanCalendarAndPaymentTile,
  LoanCalendarAndPaymentTileProps,
} from 'src/products/loans/Dashboard/LoanCalendarAndPaymentTile/LoanCalendarAndPaymentTile'
import {LoanHistoryTile} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile'
import {LoanHistoryTileProps} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile.types'
import {
  LoanProgressTile,
  LoanProgressTileProps,
} from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressTile'
import {Alert} from 'src/designSystem/components/molecules/Alert/Alert'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {LoanBrazeTiles} from 'src/products/loans/Dashboard/LoanBrazeTiles/LoanBrazeTiles'

const DashboardLoanActive: React.FC<DashboardLoanActiveProps> = (
  props: DashboardLoanActiveProps,
) => {
  const {t} = useTranslation('DashboardLoanActive')
  const {
    loanId,
    shouldShowMoneyIsOnTheWayMessage,
    navigation,
    payments,
    amountProcessing,
    alerts,
    actions,
    disbursement,
    hasPreviousLoan,
    onUpdatePaymentDates,
    onMakeAPayment,
    onShowChargedOffExplanation,
    onShowDefaultExplanation,
    onContactUs,
    onRelinkBankAccount,
    onViewLoanHistory,
    onUpdatePaymentMethod,
    loanTypeVariant,
    subscriptions,
    amountOutstanding,
    isAdvance,
  } = props

  const isContentCardsEnabled = useIsFeatureFlagEnabled('braze-content-cards')

  const tiles: (ReactNode | ReactNodeWithStyle)[] = []
  // track when this dashboard is viewed
  useEffect(() => {
    TrackAppEvent(
      ManageActiveLoanEvents.active_dashboard_viewed,
      AppEvents.Category.ManageActiveLoan,
    )
  }, [])

  useDashboardLoanActiveAlertsAnalytics({alerts})

  // some users on web should be sent back to the mobile app when their loan is active.
  // this is typically for Android users who were sent to web during loan application
  useRedirectActiveLoanWebUserToAppIfAppropriate()

  const {isChargedOff, isInDefault, isSuspended, hasPaymentMethodIssue} =
    determineLoanAlertStatuses({alerts})

  // note that alerts are ordered by the BE so we can just display as received
  alerts?.items?.forEach((thisAlert: LoanActiveStatusPaymentAlertItem) => {
    if (thisAlert.type !== LoanActiveStatusPaymentAlerts.None) {
      const thisAlertTileProps: LoanAlertTileProps = {
        alert: thisAlert,
        actions,
        loanPayments: payments,
        onRelinkBankAccount,
        onContactUs,
        onUpdateLoanPaymentDates: onUpdatePaymentDates,
        onMakeAPayment,
        onUpdatePaymentMethod,
        testID: `DashboardLoanActive-LoanAlertTile-${thisAlert.type}`,
      }
      const alertTile =
        isAdvance === true ? (
          <AdvanceAlertTile {...thisAlertTileProps} />
        ) : (
          <LoanAlertTile {...thisAlertTileProps} />
        )
      tiles.push(alertTile)
    }
  })
  // If user cancels membership with an active advance - this screen displays until advance paid
  if (
    isAdvance === true &&
    subscriptions?.current?.statusData.status === SubscriptionStatus.Cancelled &&
    amountOutstanding !== '0.00'
  ) {
    // Display alert for canceled membership with active advance
    tiles.push(
      <Alert
        level="info"
        title={t('YourPossibleMembershipIsCancelled')}
        description={t('MembershipFeeWillDiscontinueEndOfMonth')}
      />,
    )
  }

  const loanCalendarTileProps: LoanCalendarAndPaymentTileProps = {
    isChargedOff,
    isInDefault,
    hasPaymentMethodIssue,
    loanPaymentDates: payments,
    actions,
    amountProcessing,
    onMakeAPayment,
    onUpdatePaymentDates,
    testID: 'DashboardLoanActive-LoanCalendarAndPaymentTile',
  }
  tiles.push(<LoanCalendarAndPaymentTile {...loanCalendarTileProps} />)

  const loanProgressTileProps: LoanProgressTileProps = {
    onShowDefaultExplanation,
    onShowChargedOffExplanation,
    onReschedule: onUpdatePaymentDates,
    onContactUs,
    onRelinkBankAccount,
    payments,
    amountProcessing,
    isInDefault,
    isChargedOff,
    isSuspended,
    fundingFailed: hasPaymentMethodIssue,
    testID: 'DashboardLoanActive-LoanProgressTile',
  }
  tiles.push(<LoanProgressTile {...loanProgressTileProps} />)

  if (isContentCardsEnabled)
    tiles.push({
      element: <LoanBrazeTiles location={2} includeMarginTop />,
      style: {
        marginTop: 0,
      },
    })

  const loanHistoryTileProps: LoanHistoryTileProps = {
    loanPayments: payments,
    hasPreviousLoan,
    onViewLoanHistory,
    loanDisbursement: disbursement,
    loanTypeVariant,
    subscriptions,
    testID: 'DashboardLoanActive-LoanHistoryTile',
    isAdvance,
  }
  tiles.push(<LoanHistoryTile {...loanHistoryTileProps} />)

  return (
    <>
      <TileListView testID="DashboardLoanActive" list={tiles} />
      {loanId && shouldShowMoneyIsOnTheWayMessage ? (
        <MoneyOnTheWayBottomSheetContainer
          onDismiss={() => {
            navigation?.setParams({showMoneyIsOnTheWayMessage: false})
          }}
          disbursement={disbursement}
          loanId={loanId}
          shouldShowMoneyIsOnTheWayMessage={shouldShowMoneyIsOnTheWayMessage}
        />
      ) : null}
    </>
  )
}

export {DashboardLoanActive}
