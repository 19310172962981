import {identify} from 'logrocket'

import {EnvironmentType} from '@possible/cassandra'
import Log from 'src/lib/loggingUtil'
import {getEnvironment} from 'src/lib/utils/environmentUtil'

type IdentifyUser = (userId: string, params?: Parameters<typeof identify>[0]) => void

type LRRequest = {
  body?: string
  reqId: string
  headers: {[key: string]: string | undefined}
  url: string
  method: string
  referrer?: string
  mode?: string
  credentials?: string
}
type RequestSanitizer = (request: LRRequest) => LRRequest | null
type ResponseSanitizer = (request: LRRequest) => LRRequest | null

const getLogRocketAppId = async (): Promise<string> =>
  (await getEnvironment()) === EnvironmentType.Prod
    ? 'dokrpq/possible-production'
    : 'dokrpq/possible-dev'

const sessionReplayLogger = (error: unknown, message: string): void => {
  Log.error(error, `[SessionReplay]: ${message}`)
}

const requestSanitizer: RequestSanitizer = (request) => {
  request.body = undefined

  return request
}

const responseSanitizer: ResponseSanitizer = (response) => {
  response.body = undefined

  return response
}

const redactionTag = 'private'

export {getLogRocketAppId, redactionTag, requestSanitizer, responseSanitizer, sessionReplayLogger}
export type {IdentifyUser}
