import React, {useState} from 'react'

import {StateSelectedDocument} from 'src/products/MCU/Address/queries/AddressCollection.gqls'
import {
  AddressCollectionTemplate,
  AddressCompletionMethod,
} from 'src/products/MCU/Address/AddressCollectionTemplate'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {AddressUpdateErrorModal} from 'src/products/general/AddressUpdates/AddressUpdateErrorModal'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {Consumer} from '@possible/cassandra'
import {useUpdateUserAddress} from 'src/products/MCU/Address/address.utils'
import {LoanSubmissionDocument} from 'src/products/loans/LoanApplicationSubmission/operations/LoanSubmission.gqls'
import {ProductsDocument} from 'src/cassandra'
import Log from 'src/lib/loggingUtil'

type Props = {
  onComplete: AddressCompletionMethod
  loading?: boolean
  isForReviewAndEdit?: boolean
  isAppRevampFunctionalUpdatesEnabled?: boolean
}

const AddressUpdateGQLContainer: React.FC<Props> = ({
  onComplete: handleOnComplete,
  loading: isLoading,
  isForReviewAndEdit = false,
  isAppRevampFunctionalUpdatesEnabled = false,
}) => {
  const [showAddressErrorModal, setShowAddressErrorModal] = useState(false)

  const hideAddressErrorModal = (): void => setShowAddressErrorModal(false)
  const handleOnError = (): void => {
    return setShowAddressErrorModal(true)
  }

  const {selectedData: selectedState, loading: isLoadingSelectedState} = useCassandraQuery(
    StateSelectedDocument,
    {},
    (data) => data?.me?.onboarding?.loan?.stateSelected,
  )

  const [updateUserAddress] = useUpdateUserAddress(Consumer.types.AddressType.Home, {
    refetchQueries: [ProductsDocument, LoanSubmissionDocument],
    onError: (error) => {
      Log.error(
        `${error.message} - UpdateAddress Screen - UserModifyAddressComponentsDocument mutation`,
      )
    },
  })

  return (
    <BaseTemplate isLoading={isLoadingSelectedState || isLoading}>
      <AddressCollectionTemplate
        updateAddress={updateUserAddress}
        onComplete={handleOnComplete}
        selectedState={selectedState}
        onError={handleOnError}
        isForReviewAndEdit={isForReviewAndEdit}
        isAppRevampFunctionalUpdatesEnabled={isAppRevampFunctionalUpdatesEnabled}
      />
      {isAppRevampFunctionalUpdatesEnabled ? (
        <AddressUpdateErrorModal
          hideModal={hideAddressErrorModal}
          showModal={showAddressErrorModal}
          canUpdateAddress={true}
        />
      ) : null}
    </BaseTemplate>
  )
}

export {AddressUpdateGQLContainer}
