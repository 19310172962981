// We have to use `console` in this file because this file
// represents the service that logs to Datadog. We can't use
// `Log` from `loggingUtil` because that would create a circular
// dependency.
/* eslint-disable no-console */

import {EVENT_SERVICE_LOGGING} from 'src/config'
import Datadog from 'src/lib/Analytics/Datadog'
import EventStreamSingleton, {EventStream} from 'src/lib/EventStream/EventStream'
import {
  EventStreamAnalyticEvent,
  EventStreamLogEvent,
  EventStreamMetadata,
} from 'src/lib/EventStream/EventStream.types'
import {EventStreamServiceBase} from 'src/lib/Services/EventStreamServiceBase'
import {getEnvironment} from 'src/lib/utils/environmentUtil'

const log = (message: string, ...args: unknown[]): void => {
  if (EVENT_SERVICE_LOGGING) {
    console.log(`[DatadogService] ${message}`, ...args)
  }
}

/**
 * DatadogService is a wrapper around the Datadog SDK.
 * It receives `log` events from the EventStream and
 * forwards them to Datadog.
 */
class DatadogService extends EventStreamServiceBase {
  _currentUserId: string | undefined

  init = async (): Promise<void> => {
    log('init...')
    const environment = await getEnvironment()
    log(`init with environment: ${environment}`)
    Datadog.init(environment)
    log('init complete')
  }

  metadataUpdated = (metadata: EventStreamMetadata): void => {
    log(`metadata updated: ${JSON.stringify(metadata)}`)
    if (metadata.userId !== this._currentUserId) {
      this._currentUserId = metadata.userId
      Datadog.setUserId(metadata.userId)
    }
  }

  logListener = (event: EventStreamLogEvent): void => {
    log(event.data)
    const object = {
      ...event.context,
      user_id: this._currentUserId,
    }
    switch (event.severity) {
      case 'info':
        Datadog.info(event.data, object)
        break
      case 'warn':
        Datadog.warn(event.data, object)
        break
      case 'error':
        Datadog.error(event.data, object)
        break
      default:
      // do nothing
    }
  }

  analyticListener = (event: EventStreamAnalyticEvent): void => {
    const logString = `[Analytic Event] ${event.name}`
    log(logString, event.data, event.category)

    Datadog.info(logString, {
      data: event.data,
      category: event.category,
      user_id: this._currentUserId,
    })
  }

  startListening(eventStream: EventStream = EventStreamSingleton): void {
    super.startListening(eventStream)
    eventStream.addListener('log', this.logListener)
    eventStream.addListener('analytic', this.analyticListener)
  }

  stopListening(eventStream: EventStream = EventStreamSingleton): void {
    super.stopListening(eventStream)
    eventStream.removeListener('log', this.logListener)
    eventStream.removeListener('analytic', this.analyticListener)
  }
}

export {DatadogService}
