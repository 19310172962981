import React from 'react'

import {ColorVariants, DefaultVariantsColor, NamedColors} from 'src/designSystem/colors'
import {MaterialIndicator} from 'src/designSystem/components/atoms/Loading/indicators/material-indicator'
import {UIActivityIndicator} from 'src/designSystem/components/atoms/Loading/indicators/ui-activity-indicator'
import {BallIndicator} from 'src/designSystem/components/atoms/Loading/indicators/ball-indicator'
import {DotIndicator} from 'src/designSystem/components/atoms/Loading/indicators/dot-indicator'

export type LoadingType = 'loader0' | 'loader1' | 'loader2' | 'loader3'
export type LoaderSize = 'small' | 'medium' | 'large'
export type LoadingProps = {
  type: LoadingType
  color?: ColorVariants | NamedColors
  size: LoaderSize
}

const sizeToPoints = {
  small: 10,
  medium: 18,
  large: 24,
}

export const Loading: React.FC<LoadingProps> = (props) => {
  const {color, size, type} = props
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  const modColor = DefaultVariantsColor[(color as ColorVariants) ?? 'primary'] ?? color
  switch (type) {
    case 'loader0':
      return <MaterialIndicator size={sizeToPoints[size]} color={modColor} />
    case 'loader1':
      return <UIActivityIndicator size={sizeToPoints[size]} color={modColor} />
    case 'loader2':
      return <BallIndicator size={sizeToPoints[size]} color={modColor} />
    case 'loader3':
      return <DotIndicator size={sizeToPoints[size] / 2} count={3} color={modColor} />
  }
}
