import {StackScreenProps} from '@react-navigation/stack'
import React, {FC} from 'react'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

import {completeUra} from 'src/cassandra'
import {logErrorAndShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import SelfieCameraPage from 'src/products/general/components/organisms/SelfieCameraPage/SelfieCameraPage'

type Props = {
  ura_id: string
  onComplete: () => void
} & StackScreenProps<MainStackParamList, 'RetakeLiveness'>

const RetakeLiveness: FC<Props> = (props) => {
  const {ura_id, onComplete, navigation} = props

  const onLivenessConfirm = async (): Promise<void> => {
    try {
      await completeUra(ura_id)

      onComplete?.()
      navigation.pop()
    } catch (e) {
      void logErrorAndShowException(e, 'RetakeLiveness, onLivenessConfirm:')
    }
  }

  return <SelfieCameraPage onComplete={onLivenessConfirm} />
}

export default withForwardedNavigationParams<Props>()(RetakeLiveness)
