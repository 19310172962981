import 'setimmediate'
import 'react-native-gesture-handler'
import React from 'react'
import ReactDOM from 'react-dom/client'

import 'src/WebAppConfig'
import {rootTagNameWeb} from 'src/lib/utils/platform'
import {AppWrapper} from 'src/AppWrapper/AppWrapper.web'

const htmlRoot = document.getElementById(rootTagNameWeb)
if (!htmlRoot) {
  throw new Error('No root element found')
}
const reactRoot = ReactDOM.createRoot(htmlRoot)

reactRoot.render(<AppWrapper />)
