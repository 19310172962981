import React from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {openContactUsForm} from 'src/lib/contactUs'
import {useNavigation} from '@react-navigation/native'

type Fn = () => void

type Props = {
  canUpdateAddress?: boolean
  showModal: boolean
  hideModal: Fn
}

const AddressUpdateErrorModal = (props: Props): JSX.Element => {
  const {canUpdateAddress = false, hideModal, showModal} = props
  const {t} = useTranslation(['Common', 'Profile'])

  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  const contactUsAction: Fn = () => {
    openContactUsForm(navigation)
    hideModal()
  }
  const hideModalAction: Fn = () => hideModal()
  const handlePrimaryAction = canUpdateAddress ? hideModalAction : contactUsAction
  const handleSecondaryAction = canUpdateAddress ? contactUsAction : hideModalAction
  const okayButtonText = canUpdateAddress ? t('TryAgain') : t('ContactUs')
  const dismissButtonText = canUpdateAddress ? t('ContactUs') : t('NeverMind')

  return (
    <OverlaySimple
      title={t('Profile:AddressErrorModalTitle')}
      testID="Address-Update-Alert-Modal"
      visible={showModal}
      onOkay={handlePrimaryAction}
      onDismiss={handleSecondaryAction}
      okayButtonText={okayButtonText}
      dismissButtonText={dismissButtonText}
    />
  )
}

export {AddressUpdateErrorModal}
