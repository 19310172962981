import {initializeChaos} from '@possible/chaos'
import {InitClient} from 'src/api/actions/client'
import {EnvironmentType} from 'src/cassandra'
import * as Notifications from 'src/initNotifications'
import Analytics from 'src/lib/Analytics'
import {initializeAmplitudeExperimentation} from 'src/lib/experimentation/amplitude/amplitude'
import {getIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {getIsSessionReplayEnabled} from 'src/lib/LaunchArgs/enableSessionReplay'
import Log from 'src/lib/loggingUtil'
import {init as RealtimeDatabaseInit} from 'src/lib/RealtimeDatabase'
import {init as RemoteConfigInit} from 'src/lib/RemoteConfig'
import {SessionReplayService} from 'src/lib/Services/SessionReplay/SessionReplayService'
import {getEnvironment} from 'src/lib/utils/environmentUtil'
import {getPfStore} from 'src/store'

const NewSessionReplayService: SessionReplayService = new SessionReplayService()

async function appInit(): Promise<void> {
  const pfStore = getPfStore()

  if (pfStore) {
    /* Used to grab the Redux store state
    if (__DEV__) {

      // @ts-ignore
      window.pfStore = pfStore
      // @ts-ignore
      window.dumpState = () => {
        const state = util.inspect(pfStore!.getState(), false, null, true)//last param enable colors
        console.log(state)
      }
    }
    */

    Notifications.init()
    await Analytics.init(pfStore)
    RemoteConfigInit() //this calls a method from Analytics so the order is important
    RealtimeDatabaseInit()

    await pfStore.dispatch(InitClient())
    await initializeAmplitudeExperimentation(undefined)
    const isTestingSessionReplay = getIsFeatureFlagEnabled('session-replay')

    try {
      const environment: EnvironmentType = await getEnvironment()

      if (
        // Only enable in prod if the launch argument is `true` (default behavior)
        (environment === EnvironmentType.Prod && getIsSessionReplayEnabled()) ||
        // Or if the environment is not prod and the feature flag is enabled
        isTestingSessionReplay
      ) {
        await NewSessionReplayService.init()
        NewSessionReplayService.startListening()
      }

      if (environment !== EnvironmentType.Prod) {
        Log.debug('Initializing @possible/chaos')
        // initialize @possible/chaos package for simulating failures in preprod environments
        initializeChaos({
          store: pfStore,
          chaosSliceKey: 'chaos',
        })
      }
    } catch (e) {
      Log.error(e, 'Failed to get environment in app init.')
    }
  } else {
    Log.error('Somehow the store is not available in appInit. This should not happen.')
  }
}

export {
  appInit,
  NewSessionReplayService, // this is exported for testing only.
}
