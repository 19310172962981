import React from 'react'
import {useTranslation} from 'react-i18next'

import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'

type MakePaymentFailureTemplateProps = {
  onTryAgain: () => void
  onCancel: () => void
  overrideTitle?: string
  overrideText?: string
}

export const MakePaymentFailureTemplate: React.FC<MakePaymentFailureTemplateProps> = (
  props,
): JSX.Element => {
  const {onTryAgain, onCancel, overrideTitle, overrideText} = props
  const {t} = useTranslation(['MakePayment', 'Common'])

  return (
    <Overlay
      visible
      title={overrideTitle ?? t('SomethingWentWrong')}
      text={overrideText ?? t('UnableToProcessPayment')}
      okayButtonText={t('Common:TryAgain')}
      onOkay={(): void => onTryAgain()}
      dismissButtonText={t('Common:Cancel')}
      onDismiss={(): void => {
        onCancel()
      }}
      testID="MakePaymentFailureOverlayModal"
    />
  )
}
