import React from 'react'

import {PFInfoCapsule} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'

type MakeAmountEntryInfoCapsuleProps = {
  text: string
}

const MakeAmountEntryInfoCapsule: React.FC<MakeAmountEntryInfoCapsuleProps> = (
  props,
): JSX.Element => {
  const {text} = props

  return (
    <PFInfoCapsule svgIcon={{name: 'largeArrowUpRight', colorVariant: 'success'}} text={text} />
  )
}

export {MakeAmountEntryInfoCapsule}
