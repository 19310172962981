import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  ActionItemProps,
  ActionList,
} from 'src/products/loans/LoanApplicationSubmission/ActionList/ActionList'
import {prefixSsn} from 'src/lib/utils/ssnUtil'
import {NamedColors} from 'src/designSystem/colors'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {formatDate} from 'src/lib/utils/date'
import {APIDateFormat, apiParseDateFormat} from 'src/lib/time_util'
import {AdvanceMonthlyFee} from 'src/lib/advance/CashAdvanceUtils'

export type LoanSubmissionTemplateProps = {
  isSubmitBtnLoading: boolean
  isSubmitBtnDisabled: boolean
  loanAmount: string
  fullName: string
  email: string
  bankAccount: string
  birthDate: string
  address: string
  ssnLastFour: string
  canUpdateAddress: boolean
  onSubmitLoanApplication: () => void | Promise<void>
  onPressContactUs: () => void
  onPressEdit?: (fieldName: string) => void
  isAdvance?: boolean
  contUwAdvanceAmount?: string
  advanceCount?: number
}

/**
 * Template for the LoanSubmission screen to apply and reapply for a loan. Displays a summary of their personal information with a button to confirm
 * and submit their loan application.
 */
const LoanSubmissionTemplate: React.FC<LoanSubmissionTemplateProps> = (
  props: LoanSubmissionTemplateProps,
) => {
  const {
    isSubmitBtnDisabled,
    isSubmitBtnLoading,
    loanAmount,
    fullName,
    email,
    bankAccount,
    birthDate,
    address,
    ssnLastFour,
    canUpdateAddress,
    onSubmitLoanApplication,
    onPressContactUs: handleOnPressContactUs,
    onPressEdit,
    isAdvance,
    contUwAdvanceAmount,
    advanceCount,
  } = props

  const {t} = useTranslation(['LoanSubmission', 'Common', 'PersonalInformation'])

  // If onPressEdit is not provided, undefined means the action button will not be rendered
  const handleOnPressEdit = useCallback(
    (fieldName: string): (() => void) | undefined =>
      onPressEdit ? (): void => onPressEdit(fieldName) : undefined,
    [onPressEdit],
  )

  const costItem: ActionItemProps = useMemo(() => {
    const amountRequestedTitle = t('Common:AmountRequested')

    if (isAdvance !== true) {
      return {
        title: amountRequestedTitle,
        text: `$${loanAmount}`,
        handleOnPress: handleOnPressEdit('loanAmount'),
      }
    }

    const hasValidAdvanceAmount = contUwAdvanceAmount && parseFloat(contUwAdvanceAmount) !== 0

    return hasValidAdvanceAmount === true
      ? {
          title: t('LoanSubmission:AdvanceAmount'),
          text: `$${contUwAdvanceAmount}`,
        }
      : {
          title: t('Common:FlatMonthlyMembership'),
          text: `$${AdvanceMonthlyFee}`,
        }
  }, [contUwAdvanceAmount, handleOnPressEdit, isAdvance, loanAmount, t])

  const actionItems: ActionItemProps[] = [
    {
      title: t('Common:Name'),
      text: fullName,
      handleOnPress: handleOnPressEdit('name'),
      testID: 'Name-Id',
    },
    costItem,
    {
      title: t('Common:Email'),
      text: email,
      testID: 'Email-Id',
    },
    {
      title: t('LoanSubmission:BankAccount'),
      text: bankAccount,
      handleOnPress: handleOnPressEdit('bankLinking'),
      testID: 'Bank-Account-Id',
    },
    {
      title: t('Common:BirthDate'),
      text: formatDate(birthDate, APIDateFormat, apiParseDateFormat),
      handleOnPress: handleOnPressEdit('birthDate'),
      isSessionReplayPrivacyEnabled: true,
      testID: 'Birth-Date-Id',
    },
    {
      title: t('SSN'),
      text: prefixSsn(ssnLastFour),
      handleOnPress: handleOnPressEdit('ssn'),
      isSessionReplayPrivacyEnabled: true,
      testID: 'SSN-Id',
    },
    {
      title: t('Common:Address'),
      text: address,
      handleOnPress: handleOnPressEdit('address'),
      actionIcon: canUpdateAddress ? 'edit' : 'info',
      testID: 'Address-Id',
    },
  ]

  const buttonText = useMemo(() => {
    if (isAdvance === true && advanceCount !== undefined && advanceCount >= 1) {
      return t('LoanSubmission:RequestAdvance')
    }

    if (isAdvance === true) {
      return t('LoanSubmission:ConfirmAndSubmit')
    }

    return t('LoanSubmission:SubmitApplication')
  }, [advanceCount, isAdvance, t])

  return (
    <Page
      variant="generic"
      smallTopGap={true}
      noHorizontalPadding
      title={isAdvance === true ? t('ReviewToContinue') : t('ReviewAndSubmit')}
      buttonProps={{
        type: 'singleButton',
        primary: {
          testID: 'LoanSubmissionTemplate-submit-btn',
          text: buttonText,
          onPress: () => onSubmitLoanApplication(),
          loading: isSubmitBtnLoading,
          disabled: isSubmitBtnDisabled,
        },
      }}
    >
      <ActionList items={actionItems} separatorType={'between'} />
      <Box gap="small" margin={'medium'}>
        <Box
          border={{width: 1, color: NamedColors.ASH}}
          radius={4}
          padding={'small'}
          direction={'row'}
          gap="tiny"
        >
          <Box marginRight={'tiny'}>
            <SvgIcon size={'medium'} colorVariant={'info'} isFilled name={'profile'} />
          </Box>
          <PFText variant="p" color={'info'} onPress={handleOnPressContactUs}>
            {t('Common:ContactUs')}
            <PFText variant="p"> {t('ToUpdateYourInfo')}</PFText>
          </PFText>
        </Box>

        {isAdvance === false ? (
          <PFText variant="p_sm" color={NamedColors.SILVER}>
            {t('SsnDisclosure')}
          </PFText>
        ) : null}

        <PFText variant="p_sm" color={NamedColors.SILVER}>
          {t('PersonalInformation:YourLocationDisclaimer')}
        </PFText>
      </Box>
    </Page>
  )
}

export {LoanSubmissionTemplate}
