import React, {ReactNode, useEffect, useMemo, useState, useCallback} from 'react'
import {
  DimensionValue,
  Dimensions,
  Image,
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native'
import {SafeAreaView} from 'react-native-safe-area-context'
import {SvgProps} from 'react-native-svg'

import {
  genericPageBackground,
  modernDashboardHeaderBackground,
} from 'src/designSystem/semanticColors'
import {white} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SizeVariants, Color} from 'src/designSystem/types'
import Log from 'src/lib/loggingUtil'
import {isDeviceAndroid, isDeviceIOS, isDeviceWeb} from 'src/lib/utils/platform'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import HeaderSpacer from 'src/designSystem/components/atoms/HeaderSpacer/HeaderSpacer'
import ButtonLockup, {
  ButtonLockupProps,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {ShadowStyle} from 'src/designSystem/shadows'

const increasePaddingWidthThreshold: number = 320 as const
const defaultOffsetTop: number = 32 as const
const smallFormatiPhoneHeightThreshold: number = 800 as const
const smallFormatiPhoneOffsetTop: number = 28 as const
const androidOffsetTop: number = 38 as const
const webOffsetTop: number = 56 as const
const titleMinHeight: number = 56 as const

type BannerDimensions = {
  width: number
  height: number
}

type DashboardHeaderProps = {
  banner: React.FC<SvgProps>
  backgroundColor?: Color
  hasTitle?: boolean
}
const DashboardHeader: React.FC<DashboardHeaderProps> = (props) => {
  const {banner, backgroundColor = modernDashboardHeaderBackground, hasTitle} = props
  const [bannerDimensions, setBannerDimensions] = useState<BannerDimensions>({width: 0, height: 0})

  const {width: screenWidth} = Dimensions.get('screen')

  // I need PFSvgContain to act more like a PFSvgCover
  // So if the screen is wider than banner width
  // scale width to 100%
  // Otherwise, use the banner height
  useEffect(() => {
    if (isDeviceWeb()) {
      Image.getSize(
        `${banner}`,
        (width, height) => {
          setBannerDimensions({
            width,
            height,
          })
        },
        (error) => {
          Log.debug('Dashboard, Image.getSize: failed to get SVG size', error)
        },
      )
    } else {
      const svg = banner({})
      if (!svg || !React.isValidElement(svg)) return
      const {width, height} = svg.props
      if (!width || !height) return
      setBannerDimensions({width, height})
    }
  }, [banner])

  const scaleWidth: boolean = screenWidth > bannerDimensions.width

  const boxWidth: DimensionValue = Platform.select<DimensionValue>({
    ios: screenWidth,
    android: screenWidth,
    default: '100%',
  })

  const normalizedHeight: number = hasTitle
    ? bannerDimensions.height
    : bannerDimensions.height - titleMinHeight

  // SVG is wrapped in an additional box (w/ height & overflow hidden)
  // to get it to look consistent
  return (
    <Box position="absolute" background={backgroundColor} fill="horizontal" width={boxWidth}>
      <Box height={normalizedHeight} overflow="hidden">
        <PFSvgContain
          width={scaleWidth ? '100%' : undefined}
          height={scaleWidth ? undefined : normalizedHeight}
          svg={banner}
        />
      </Box>
    </Box>
  )
}

type DashboardProps = {
  title?: string
  banner?: React.FC<SvgProps>
  backgroundColor?: Color
  headerItem?: ReactNode
  items?: ReactNode[]
  footerItem?: ReactNode
  noHorizontalPadding?: boolean
  theme?: 'light' | 'dark'
  buttonProps?: ButtonLockupProps
}

const Dashboard: React.FC<DashboardProps> = (props) => {
  const {
    title,
    banner,
    backgroundColor = modernDashboardHeaderBackground,
    headerItem,
    items,
    footerItem,
    noHorizontalPadding,
    buttonProps,
    theme,
  } = props

  const {width, height} = Dimensions.get('window')
  let offsetTop = defaultOffsetTop
  if (isDeviceIOS()) {
    if (height < smallFormatiPhoneHeightThreshold) {
      offsetTop = smallFormatiPhoneOffsetTop
    }
  } else if (isDeviceAndroid()) {
    offsetTop = androidOffsetTop
  } else {
    offsetTop = webOffsetTop
  }

  const paddingHorizontal: SizeVariants = useMemo(
    () => (width > increasePaddingWidthThreshold ? 'medium' : 'little'),
    [width],
  )

  const isLightTheme = theme === 'light'

  const getBottomView = useCallback(() => {
    if (!buttonProps) {
      return
    }

    const buttonStackViewStyles = [styles.buttonStackView, ShadowStyle(true)]

    return (
      <View style={buttonStackViewStyles} key={'dashboard-bottom-view'}>
        <ButtonLockup {...buttonProps} />
      </View>
    )
  }, [buttonProps])

  return (
    <>
      <StatusBar
        key={'dashboard-statusbar'}
        barStyle={isLightTheme ? 'dark-content' : 'light-content'}
        backgroundColor={backgroundColor}
      />
      <View style={styles.headerSpaceWrapper} key={'dashboard-header-space'}>
        <HeaderSpacer backgroundColor={backgroundColor} />
      </View>
      <SafeAreaView
        key={'dashboard-safearea'}
        style={[styles.safeAreaStyle, {backgroundColor}]}
        edges={['top']}
      >
        <ScrollView
          style={{backgroundColor}}
          contentContainerStyle={{
            ...styles.scrollViewContainerStyle,
            backgroundColor: backgroundColor ?? white,
          }}
        >
          <Box gap="small">
            {banner ? (
              <DashboardHeader
                banner={banner}
                backgroundColor={backgroundColor}
                hasTitle={!!title}
              />
            ) : null}

            {title ? (
              <Box paddingHorizontal="medium" marginTop={offsetTop}>
                <PFText variant="h3" color={isLightTheme ? 'textPrimary' : 'inverse'}>
                  {title}
                </PFText>
              </Box>
            ) : undefined}

            <Box paddingHorizontal={noHorizontalPadding ? 0 : paddingHorizontal} gap="medium">
              {headerItem}
              {items}
            </Box>
          </Box>

          <Box
            marginHorizontal="enormous"
            align={'center'}
            marginBottom="enormous"
            marginTop="medium"
          >
            {footerItem}
          </Box>
        </ScrollView>
      </SafeAreaView>
      {getBottomView()}
    </>
  )
}

export default Dashboard

const contentMaxWidth: number = 450 as const
const styles = StyleSheet.create({
  buttonStackView: {
    backgroundColor: genericPageBackground,
    width: '100%',
    ...Platform.select({
      web: {
        maxWidth: contentMaxWidth,
        alignSelf: 'center',
      },
    }),
  },
  headerSpaceWrapper: {
    ...Platform.select({
      web: {
        maxWidth: contentMaxWidth,
        alignSelf: 'center',
        width: '100%',
      },
    }),
  },
  safeAreaStyle: {
    flex: 1,
    width: '100%',
    ...Platform.select({
      web: {
        maxWidth: contentMaxWidth,
        alignSelf: 'center',
      },
    }),
  },
  scrollViewContainerStyle: {
    backgroundColor: white,
    flexGrow: 1,
    justifyContent: 'space-between',
  },
})
