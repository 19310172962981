import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from 'react-navigation-props-mapper'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Snackbar from 'src/lib/Snackbar'
import {useSetPrimaryAccount} from 'src/products/MCU/AccountManagementV2/PrimaryAccount/useSetPrimaryAccount/useSetPrimaryAccount'
import {AcceptAgreementsV2Template} from 'src/products/MCU/AccountManagementV2/AcceptAgreementsV2Template'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'
import {
  AcceptAgreementsV2Document,
  GenerateAchAgreementMutationDocument,
} from 'src/products/MCU/AccountManagementV2/AcceptPrimaryAccountAgreement/AcceptPrimaryAccountAgreement.gqls'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {usePromise} from 'src/lib/usePromise/usePromise'

type Props = ForwardedStackScreenProps<MainStackParamList, 'AcceptPrimaryAccountAgreement'>

const AcceptPrimaryAccountAgreement: FC<Props> = (props) => {
  const {navigation, accountMask, linkedAccountIdForPrimary, onAccept} = props

  const {t} = useTranslation(['LoanApproved', 'AccountManagement', 'Common'])
  const tryAgain = t('Common:TryAgain')

  const {
    selectedData: latestLoanId,
    loading: isLoadingLatestLoanId,
    error: errorLatestLoanId,
    refetch: handleRefetchLatestLoanId,
  } = useCassandraQuery(
    AcceptAgreementsV2Document,
    {
      fetchPolicy: 'cache-first',
      onError: (error) => {
        logAddPaymentMethodError(error, 'AcceptPrimaryAccountAgreement: Error loading loan ID.')
      },
      onCompleted: (data) => {
        if (!data?.me?.loans?.latestActionableLoan?.id) {
          logAddPaymentMethodError(
            new Error(
              'AcceptPrimaryAccountAgreement: Empty latestActionableLoan.id received from server.',
            ),
          )
        }
      },
    },
    (from) => from.me.loans.latestActionableLoan?.id,
  )

  const [setPrimaryAccount] = useSetPrimaryAccount(linkedAccountIdForPrimary)

  const [generateAndShowAchAgreement, {loading: isLoadingAchAgreement}] = useCassandraMutation(
    GenerateAchAgreementMutationDocument,
    {
      onError: (error) => {
        Snackbar.error({
          title: tryAgain,
          duration: Snackbar.LENGTH_LONG,
        })
        logAddPaymentMethodError(
          error,
          'AcceptPrimaryAccountAgreement: Unable to load url for ACH documents',
        )
      },
      onCompleted: (data) => {
        const url = data?.loanGenerateAchAgreement.download?.url
        if (!url) {
          Snackbar.error({
            title: tryAgain,
            duration: Snackbar.LENGTH_LONG,
          })
          logAddPaymentMethodError(
            new Error(
              'AcceptPrimaryAccountAgreement: Empty ACH agreement URL returned from server.',
            ),
          )
          return
        }

        displayPdf(url, navigation)
      },
    },
  )

  const [executeOnAccept, {isLoading: isOnAcceptLoading}] = usePromise(async () => {
    const setPrimaryResponse = await setPrimaryAccount()
    if (setPrimaryResponse.errors) {
      Snackbar.error({
        title: t('AccountManagement:failedToSetPrimaryAccount'),
        duration: Snackbar.LENGTH_LONG,
      })
      return
    }

    await onAccept()
  })

  const handleACHAgreementPressed = async (): Promise<void> => {
    if (!latestLoanId || isLoadingAchAgreement) {
      return
    }
    await generateAndShowAchAgreement({
      variables: {
        loanId: latestLoanId,
        linkedAccountId: linkedAccountIdForPrimary,
      },
    })
  }

  const handleOnAccept = async (): Promise<void> => {
    await executeOnAccept()
  }

  return (
    <BaseTemplate
      isLoading={isLoadingLatestLoanId}
      isError={!!errorLatestLoanId}
      onRetry={handleRefetchLatestLoanId}
    >
      <AcceptAgreementsV2Template
        isLoadingOnContinue={isLoadingAchAgreement || isOnAcceptLoading}
        accountMask={accountMask}
        onACHAgreementPressed={handleACHAgreementPressed}
        onAccept={handleOnAccept}
        onRefuse={(): void => navigation.pop()}
      />
    </BaseTemplate>
  )
}

const AcceptAgreementsV2WithNavProps = withForwardedNavigationParams<Props>()(
  AcceptPrimaryAccountAgreement,
)

export {AcceptAgreementsV2WithNavProps as AcceptPrimaryAccountAgreement}
