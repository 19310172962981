import React, {FC, useState} from 'react'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {StackScreenProps} from '@react-navigation/stack'

import {logErrorAndShowException} from 'src/lib/errors'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {GenericVerification} from 'src/products/loans/PhoneConfirmation/GenericVerification'
import {MfaLogin} from 'src/api/MobileGatewayAPI/actions'
import {usePfDispatch} from 'src/store/utils'
import {LoginResponseHandler} from 'src/products/MCU/RegistrationOrLogin/LoginResponseHandler'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {userResendLoginWithMfa} from '@possible/cassandra/src/user/authPublicMethods'
import {UserMfaDeliveryMedium} from '@possible/cassandra/src/types/types.public.generated'
import {MobileGatewayApiResponse} from 'src/api/MobileGatewayAPI/MobileGatewayApi.types'

type Props = StackScreenProps<MainStackParamList, 'OTPVerification'> & {
  email: string
  password: string
  mfaDeliveryMedium: UserMfaDeliveryMedium
}

export const isDeliveryMediumPhone = (medium: UserMfaDeliveryMedium): boolean =>
  medium === UserMfaDeliveryMedium.Sms || medium === UserMfaDeliveryMedium.Voice
export const verificationMethod = (mfaDeliveryMedium: UserMfaDeliveryMedium): 'PHONE' | 'EMAIL' =>
  isDeliveryMediumPhone(mfaDeliveryMedium) ? 'PHONE' : 'EMAIL'

const OTPVerification: FC<Props> = (props) => {
  const {email, password, mfaDeliveryMedium} = props
  const dispatch = usePfDispatch()
  const [response, setResponse] = useState<MobileGatewayApiResponse | undefined>(undefined)
  const [method, setMethod] = useState<UserMfaDeliveryMedium>(mfaDeliveryMedium)

  usePageViewedAnalytics({
    eventName: AppEvents.Name.otp_code_screen_viewed,
    eventCategory: AppEvents.Category.Admin,
    eventArgs: {
      method: mfaDeliveryMedium,
    },
  })

  const onResend = async (resendMethod: UserMfaDeliveryMedium): Promise<boolean> => {
    let wasSuccessful = true

    try {
      TrackAppEvent(
        AppEvents.Name.otp_resend_verification_selected,
        AppEvents.Category.Activation,
        {
          resendMethod: resendMethod,
        },
      )
      setMethod(resendMethod)
      const hasResponse = await userResendLoginWithMfa({
        email,
        password,
        mfaDeliveryMedium: resendMethod,
      })
      if (!hasResponse) {
        throw Error('Failed to resend login with mfa')
      }
    } catch (e) {
      void logErrorAndShowException(e, 'OTPVerification, onResend:')
      wasSuccessful = false
    }
    return wasSuccessful
  }

  const onPress = async (code: string): Promise<void> => {
    setResponse(undefined)
    try {
      TrackAppEvent(AppEvents.Name.otp_verification_code_submitted, AppEvents.Category.Admin, {
        mfaDeliveryMedium,
      })

      const loginResponse = await dispatch(MfaLogin(email, password, code, method))
      setResponse(loginResponse)
    } catch (e) {
      void logErrorAndShowException(e, 'OTPVerification, onPress:')
    }
  }

  return (
    <>
      {LoginResponseHandler({
        capsuleErrorCodes: [],
        response,
      })}
      <GenericVerification
        resendAction={(resendMethod: UserMfaDeliveryMedium): Promise<boolean> =>
          onResend(resendMethod)
        }
        submitAction={(code: string): Promise<void> => onPress(code)}
        verificationMethod={verificationMethod(mfaDeliveryMedium)}
        testID="OTP-Verification"
      />
    </>
  )
}

export default withForwardedNavigationParams<Props>()(OTPVerification)
