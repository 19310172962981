import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {BankAggregatorAccountSelectionGQLContainer} from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/BankAggregatorAccountSelectionGQLContainer'
import {BankAggregatorAccountSelectionGQLContainerProps} from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/BankAggregatorAccountSelection.types'

type PropsFromRouter = StackScreenProps<
  MainStackParamList,
  'BankAggregatorAccountSelectionForMakePayment'
>

type BankAggregatorAccountSelectionForMakePaymentParams = Pick<
  BankAggregatorAccountSelectionGQLContainerProps,
  | 'accountsFromBankAggregator'
  | 'onVerifyRoutingAndAccountFailed'
  | 'onVerifyRoutingAndAccountSucceeded'
>

function isValidParams(
  params: unknown,
): params is BankAggregatorAccountSelectionForMakePaymentParams {
  return (
    typeof params === 'object' &&
    params !== null &&
    'accountsFromBankAggregator' in params &&
    'onVerifyRoutingAndAccountFailed' in params &&
    'onVerifyRoutingAndAccountSucceeded' in params
  )
}

/**
 * Screen that is used as part of make payment flow to select which bank account to use
 * after linking with Plaid.
 */
const BankAggregatorAccountSelectionForMakePayment: React.FC<PropsFromRouter> = ({route}) => {
  if (!isValidParams(route.params)) {
    throw new Error('Invalid route params')
  }

  const {
    accountsFromBankAggregator,
    onVerifyRoutingAndAccountFailed: handleOnVerifyRoutingAndAccountFailed,
    onVerifyRoutingAndAccountSucceeded: handleOnVerifyRoutingAndAccountSucceeded,
  } = route.params
  const {t} = useTranslation(['BankAggregatorAccountSelection'])
  usePageViewedAnalytics({
    eventName: ManageActiveLoanEvents.loans_adhoc_aggregator_account_selection_screen_viewed,
    eventCategory: AppEvents.Category.ManageActiveLoan,
  })
  return (
    <BankAggregatorAccountSelectionGQLContainer
      analytics={{
        selectAccount: {
          name: ManageActiveLoanEvents.loans_adhoc_aggregator_account_selection_cta_selected,
          category: AppEvents.Category.ManageActiveLoan,
        },
        verifyRoutingAccountSucceeded: {
          name: ManageActiveLoanEvents.loans_adhoc_aggregator_account_selection_verify_succeeded,
          category: AppEvents.Category.ManageActiveLoan,
        },
        verifyRoutingAccountFailed: {
          name: ManageActiveLoanEvents.loans_adhoc_aggregator_account_selection_verify_failed,
          category: AppEvents.Category.ManageActiveLoan,
        },
      }}
      accountsFromBankAggregator={accountsFromBankAggregator}
      showNewAccountIndicator={false}
      title={t('ChooseAccount')}
      description={t('MakingOneTimePayment')}
      continueBtn={{
        testID: 'BankAggregatorAccountSelectionForMakePayment-ContinueBtn',
      }}
      failedVerifyBtn={{
        testID: 'BankAggregatorAccountSelectionForMakePayment-FailedVerifyBtn',
      }}
      syncingOverlay={{
        title: t('SyncingInfo'),
        description: t('SyncingDontClose'),
      }}
      onVerifyRoutingAndAccountFailed={handleOnVerifyRoutingAndAccountFailed}
      onVerifyRoutingAndAccountSucceeded={handleOnVerifyRoutingAndAccountSucceeded}
    />
  )
}

export {BankAggregatorAccountSelectionForMakePayment}
