import React from 'react'
import {CompositeScreenProps} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {UsePageViewedAnalyticsProps} from 'src/lib/Analytics/usePageViewedAnalytics'
import {LoanPayNavigatorStack} from 'src/products/loans/MakePayment/LoanPayNavigatorStack'
import {MakePaymentFailureContainer} from 'src/products/general/MakePayment/MakePaymentFailure/MakePaymentFailureContainer'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {trackLoansMakePaymentScreenRenderFailure} from 'src/products/loans/MakePayment/LoanPay.utils'

export type LoanPayFailureProps = CompositeScreenProps<
  StackScreenProps<LoanPayNavigatorStack, 'LoanPayFailure'>,
  StackScreenProps<MainStackParamList>
>

const LoanPayFailure = ({navigation, route}: LoanPayFailureProps): JSX.Element => {
  const pageViewedAnayltics: UsePageViewedAnalyticsProps = {
    eventName: ManageActiveLoanEvents.loans_adhoc_failure_modal_viewed,
    eventCategory: AppEvents.Category.ManageActiveLoan,
    eventArgs: {
      payment_method_type: route.params.paymentMethodTypeForAnalytics,
    },
  }

  const handleOnTryAgain = (): void => {
    TrackAppEvent(
      ManageActiveLoanEvents.loans_adhoc_failure_modal_cta_try_again,
      AppEvents.Category.ManageActiveLoan,
    )
    navigation.goBack()
  }
  const handleOnCancel = (): void => {
    TrackAppEvent(
      ManageActiveLoanEvents.loans_adhoc_failure_modal_cta_cancel,
      AppEvents.Category.ManageActiveLoan,
    )
    navigation.navigate('Dashboard')
  }
  const handleOnErrorBoundary = (e: Error): void => {
    trackLoansMakePaymentScreenRenderFailure(e, 'LoanPayFailure')
  }
  return (
    <MakePaymentFailureContainer
      pageViewedAnalytics={pageViewedAnayltics}
      onTryAgain={handleOnTryAgain}
      onCancel={handleOnCancel}
      onErrorBoundary={handleOnErrorBoundary}
    />
  )
}

export {LoanPayFailure}
