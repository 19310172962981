import React, {FC, useMemo, useCallback, useEffect} from 'react'
import {StyleSheet, View} from 'react-native'
import {Trans, useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {omit as omitFields} from 'lodash'

import Page from 'src/designSystem/components/organisms/Page/Page'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import HookForm, {
  DefaultFormGapSizeVariant,
  FieldVariants,
  FormFieldProps,
} from 'src/designSystem/components/atoms/HookForm/HookForm'
import {getHasErrorsOrMissingValues} from 'src/lib/utils/formValidationUtil'
import {gapSizeMap} from 'src/designSystem/layout'
import {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {numberFilter, cvvFilter} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {
  DebitCardSubmitValues,
  expirationFilter,
  isValidCVV,
  isValidCardExpiration,
  isValidName,
  isValidNumber,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/DebitCard.utils'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {ButtonLockupPropsSecondary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {NamedColors} from 'src/designSystem/colors'
import {SessionReplay} from 'src/lib/sessionReplay/sessionReplay'

type DebitCardTemplateFields = 'name' | 'number' | 'expiration' | 'cvv'

export type DebitCardTemplateProps = {
  onSubmit: (data: DebitCardSubmitValues) => Promise<void>
  debitInputSecondaryAction?: ButtonLockupPropsSecondary
  loading: boolean
  title?: string
  hiddenFields?: DebitCardTemplateFields[]
  isAdvance: boolean
}

const DebitCardTemplate: FC<DebitCardTemplateProps> = ({
  onSubmit,
  loading,
  title,
  debitInputSecondaryAction,
  hiddenFields = [],
  isAdvance,
}: DebitCardTemplateProps) => {
  const {t} = useTranslation(['AccountManagement', 'Common', 'Bank'])
  const required = 'Common:Required'
  const invalidError = 'Common:InvalidError'
  const commonName = 'Common:Name'
  const commonExpiration = 'Common:Expiration'
  const commonCvv = 'Common:CVV'
  const cardNumber = 'CardNumber'

  const getCtaText = (isAdvance: boolean, isSubmitting: boolean): string => {
    if (!isAdvance) {
      return t('Common:Continue')
    }

    return isSubmitting ? t('Advance:Transferring') : t('Advance:TransferFunds')
  }

  const {
    control,
    handleSubmit,

    watch,
    formState: {errors},
    reset,
    formState: {isSubmitted},
  } = useForm<DebitCardSubmitValues>({
    mode: 'onChange',
  })

  const formProps = useMemo(
    (): Partial<Record<DebitCardTemplateFields, FormFieldProps>> =>
      omitFields(
        {
          name: {
            name: 'name',
            field: FieldVariants.TextField,
            rules: {
              required: t(required),
              validate: (value: string) =>
                !isValidName(value)
                  ? t(invalidError, {replace: {label: t(commonName)}})
                  : undefined,
            },
            viewStyle: styles.fullRow,
          },
          number: {
            name: 'number',
            field: FieldVariants.TextField,
            rules: {
              required: t(required),
              validate: (value: string) =>
                !isValidNumber(value)
                  ? t(invalidError, {replace: {label: t(cardNumber)}})
                  : undefined,
            },
            viewStyle: styles.fullRow,
          },
          expiration: {
            name: 'expiration',
            field: FieldVariants.TextField,
            rules: {
              required: t(required),
              validate: (value: string) =>
                !isValidCardExpiration(value)
                  ? t(invalidError, {replace: {label: t(commonExpiration)}})
                  : undefined,
            },
            viewStyle: styles.splitRowItemLeft,
          },
          cvv: {
            name: 'cvv',
            field: FieldVariants.TextField,
            rules: {
              required: t(required),
              validate: (value: string) =>
                !isValidCVV(value) ? t(invalidError, {replace: {label: t(commonCvv)}}) : undefined,
            },
            viewStyle: styles.splitRowItemRight,
          },
        },
        hiddenFields,
      ),
    [hiddenFields, t],
  )

  useEffect(() => {
    if (isSubmitted) {
      reset({
        name: '',
        number: '',
        expiration: '',
        cvv: '',
      })
    }
  }, [isSubmitted, reset])

  const formRequiredFields = useMemo(() => Object.keys(formProps), [formProps])

  const checkIsValid = useCallback((): boolean => {
    return !getHasErrorsOrMissingValues(errors, watch, formRequiredFields)
  }, [errors, watch, formRequiredFields])

  const boxProps: BoxProps = {
    gap: 'none',
    direction: 'row',
    wrap: 'wrap',
  }

  const action = {
    text: getCtaText(isAdvance, loading),
    disabled: !checkIsValid() || loading,
    onPress: handleSubmit(onSubmit),
    loading: loading,
    testID: 'DebitCard_Continue_Button',
  }

  let secondaryAction: ButtonLockupPropsSecondary | undefined
  if (debitInputSecondaryAction) {
    secondaryAction = {
      ...debitInputSecondaryAction,
      disabled: loading,
    }
  }

  const renderFields = useMemo(
    () =>
      Object.keys(formProps).map((key) => {
        if (key === 'name') {
          return (
            <PFTextInput
              key={key}
              label={t(commonName)}
              returnKeyType={'done'}
              errorOnEmpty={false}
              formProps={formProps.name}
              testID={'DebitCard_Name_Input'}
            />
          )
        }
        if (key === 'number') {
          return (
            <PFTextInput
              key={key}
              label={t(cardNumber)}
              returnKeyType={'done'}
              keyboardType={'numeric'}
              errorOnEmpty={false}
              formProps={formProps.number}
              changeFilter={numberFilter}
              testID={'DebitCard_Number_Input'}
              {...SessionReplay.privacyProps()}
            />
          )
        }
        if (key === 'expiration') {
          return (
            <PFTextInput
              key={key}
              label={t(commonExpiration)}
              returnKeyType={'done'}
              keyboardType={'numeric'}
              errorOnEmpty={false}
              formProps={formProps.expiration}
              changeFilter={expirationFilter}
              testID={'DebitCard_Expiration_Input'}
              {...SessionReplay.privacyProps()}
            />
          )
        }
        if (key === 'cvv') {
          return (
            <PFTextInput
              key={key}
              label={t(commonCvv)}
              returnKeyType={'done'}
              keyboardType={'numeric'}
              errorOnEmpty={false}
              formProps={formProps.cvv}
              changeFilter={cvvFilter}
              testID={'DebitCard_CVV_Input'}
              maxLength={4}
              {...SessionReplay.privacyProps()}
            />
          )
        }
      }),
    [formProps, t],
  )

  return (
    <BaseTemplate
      onErrorBoundary={(e): void =>
        logAddPaymentMethodError(e, 'Error Boundary on DebitCardTemplate')
      }
    >
      <Page
        smallTopGap={true}
        buttonProps={buttonLockupProperties(action, secondaryAction)}
        variant={'generic'}
        title={title ?? t('AddDebitCard')}
        testID="Debit-Card-Page-Id"
      >
        <View style={styles.cardStyle}>
          <View style={styles.cardType}>
            <PFText variant={'p_semibold'} color={'white'}>
              Debit Card
            </PFText>
          </View>
          <View style={styles.cardName}>
            <PFText variant={'p_semibold'} color={'success'}>
              {watch('name')}
            </PFText>
          </View>
          <View
            style={styles.cardNumber}
            testID={'DebitCardTemplate-MockCard-Number'}
            {...SessionReplay.privacyProps()}
          >
            <PFText variant={'p_semibold'} color={'white'}>
              {watch('number')}
            </PFText>
          </View>
          <View
            style={styles.cardExpiration}
            testID={'DebitCardTemplate-MockCard-Expiration'}
            {...SessionReplay.privacyProps()}
          >
            <PFText variant={'p'} color={'success'}>
              {watch('expiration')}
            </PFText>
          </View>
          <View
            style={styles.cardCVV}
            testID={'DebitCardTemplate-MockCard-CVV'}
            {...SessionReplay.privacyProps()}
          >
            <PFText variant={'p'} color={'success'}>
              {watch('cvv')}
            </PFText>
          </View>
        </View>
        <HookForm control={control} errors={errors} box={boxProps}>
          {renderFields}
        </HookForm>
        <PFText variant={'p_sm'} color={NamedColors.SILVER}>
          <Trans
            i18nKey={'Bank:VerificationProcessInfoShort'}
            components={{
              bold: <PFText variant="p_sm_semibold" color={NamedColors.SILVER} />,
            }}
          />
        </PFText>
      </Page>
    </BaseTemplate>
  )
}

export {DebitCardTemplate}

const styles = StyleSheet.create({
  cardCVV: {
    position: 'absolute',
    right: gapSizeMap['medium'],
    top: '75%',
  },
  cardExpiration: {
    left: gapSizeMap['medium'],
    position: 'absolute',
    top: '75%',
  },
  cardName: {
    left: gapSizeMap['medium'],
    position: 'absolute',
    top: '50%',
  },
  cardNumber: {
    left: gapSizeMap['medium'],
    position: 'absolute',
    top: '62.5%',
  },
  cardStyle: {
    backgroundColor: 'black',
    borderRadius: 10,
    height: 200,
    width: '100%',
    position: 'relative',
    marginBottom: gapSizeMap['medium'],
  },
  cardType: {
    left: gapSizeMap['medium'],
    position: 'absolute',
    top: '10%',
  },
  fullRow: {
    paddingBottom: gapSizeMap[DefaultFormGapSizeVariant],
    width: '100%',
  },
  splitRowItemLeft: {
    paddingBottom: gapSizeMap[DefaultFormGapSizeVariant],
    paddingRight: 2,
    width: '50%',
  },
  splitRowItemRight: {
    paddingBottom: gapSizeMap[DefaultFormGapSizeVariant],
    width: '50%',
  },
})
