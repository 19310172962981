import React from 'react'
import {StyleSheet, View, ViewStyle} from 'react-native'

import {dividerColor} from 'src/designSystem/semanticColors'
import {smallGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type DividerWithTextOrIconProps = {
  text?: string
  icon?: SvgIconProps
  color?: string
  hasNoTextNoIcon?: boolean
}

const DividerWithTextOrIcon = (props: DividerWithTextOrIconProps): JSX.Element => {
  const {text, icon, color, hasNoTextNoIcon} = props
  const lineColorStyle: ViewStyle = color
    ? {backgroundColor: color}
    : {backgroundColor: dividerColor}

  if (hasNoTextNoIcon === true) {
    return (
      <View style={styles.containerView}>
        <View style={[styles.lineView, lineColorStyle]} />
      </View>
    )
  }

  return (
    <View style={styles.containerView}>
      <View style={[styles.lineView, styles.leftLine, lineColorStyle]} />
      {icon ? (
        <SvgIcon name={icon.name} colorVariant={icon.colorVariant} size={'large'} />
      ) : (
        <PFText variant="p" textAlign="center">
          {text}
        </PFText>
      )}
      <View style={[styles.lineView, styles.rightLine, lineColorStyle]} />
    </View>
  )
}
export {DividerWithTextOrIcon}

const styles = StyleSheet.create({
  containerView: {flexDirection: 'row'},
  leftLine: {
    marginRight: smallGap,
  },
  lineView: {
    alignSelf: 'center',
    flex: 1,
    height: 1,
    width: '100%',
  },
  rightLine: {
    marginLeft: smallGap,
  },
})
