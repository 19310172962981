import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack/lib/typescript/src/types'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {littleGap, mediumGap, smallGap, tinyGap} from 'src/designSystem/layout'
import {AppEvents, CashAdvanceEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  InfoIconGrid,
  InfoIconGridItem,
} from 'src/designSystem/components/molecules/InfoIconGrid/InfoIconGrid'
import {boxRadiusMap} from 'src/designSystem/components/atoms/Box/styles'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useWorkflows} from 'src/workflows/hooks/useWorkflows'

const AdvanceSplashScreen: React.FC = () => {
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const {t} = useTranslation(['Advance', 'Common'])
  const workflows = useWorkflows()

  usePageViewedAnalytics({
    eventName: CashAdvanceEvents.advance_intro_viewed,
    eventCategory: AppEvents.Category.CashAdvance,
  })

  const handleOnApply = (): void => {
    if (workflows?.status === 'READY' && workflows?.unmetMinPreReqs?.length === 0) {
      navigation.reset({
        index: 0,
        routes: [{name: 'OfferApplicationWorkflow'}],
      })
    } else {
      navigation.reset({
        index: 0,
        routes: [{name: 'SignupWorkflow'}],
      })
    }
  }

  const screenTitle = (
    <PFText variant={'h1'} textAlign={'center'} testID={'Advance-Splash-Screen-Title'}>
      <Trans t={t} i18nKey={'GetUpTo300InMinutes'}>
        Get up to
        <PFText
          variant={'h1'}
          color={NamedColors.BLACK}
          textProps={{
            style: {
              backgroundColor: NamedColors.GOLD,
            },
          }}
        >
          {` $300`}
        </PFText>
        in minutes*
      </Trans>
    </PFText>
  )

  const iconGridItems: InfoIconGridItem[] = [
    {
      icon: 'money',
      title: '$50-300',
      subtitle: t('PayCheckAdvances'),
    },
    {
      icon: 'chart',
      title: t('In4Installments'),
      subtitle: t('NoAddedCost'),
    },
    {
      icon: 'bank',
      title: t('InstantTransfer'),
      subtitle: t('NoAddedCost'),
    },
    {
      icon: 'calendar',
      title: t('FreeRescheduling'),
      subtitle: t('NoAddedCost'),
    },
  ]

  const bottomInfo = (
    <Box
      flex
      direction={'column'}
      background={NamedColors.WHITESMOKE}
      align={'center'}
      padding={smallGap}
      justify={'center'}
      radius={boxRadiusMap.small}
      gap={'small'}
      marginTop={mediumGap}
      fill={'horizontal'}
    >
      <Box
        background={NamedColors.PRODUCT_BLUE}
        paddingVertical={tinyGap}
        paddingHorizontal={tinyGap}
        radius={boxRadiusMap.little}
      >
        <PFText variant={'label_md'} color={NamedColors.WHITE} testID={'Membership-Guarantee'}>
          {t('MembershipGuarantee')}
        </PFText>
      </Box>
      <PFText variant={'p_semibold'} testID={'Never-Pay-Fees'} textAlign={'center'}>
        {t('NeverPayFess')}
      </PFText>
      <PFText variant={'p_semibold'} testID={'Cancel-Anytime'}>
        {t('CancelAnytime')}
      </PFText>
    </Box>
  )

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:ApplyNow'),
          onPress: handleOnApply,
          testID: 'Advance-Splash-Screen-Apply-Now',
        },
      }}
      variant={'generic'}
      testID={'Advance-Splash-Screen'}
      smallTopGap
    >
      <Box flex align={'center'} marginTop={'little'}>
        {screenTitle}
        <Box marginTop={littleGap}>
          <PFText variant="p" testID={'No-Credit-Check'}>
            {t('NoCreditCheckNoCostToCheck')}
          </PFText>
        </Box>
        <Box marginTop={mediumGap}>
          <PFText variant={'p_lg_semibold'} testID={'For-Membership-You-Get'}>
            {t('ForMembershipYouGet')}
          </PFText>
        </Box>
        <Box marginTop={smallGap}>
          <InfoIconGrid items={iconGridItems} />
        </Box>
        {bottomInfo}
        <Box paddingHorizontal={tinyGap} marginTop={smallGap}>
          <PFText
            variant={'p_sm'}
            color={NamedColors.SILVER}
            testID={'Advance-Splash-Screen-Disclosure'}
          >
            {t('SplashScreenDisclosure')}
          </PFText>
        </Box>
      </Box>
    </Page>
  )
}

export {AdvanceSplashScreen}
