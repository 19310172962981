import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {banking} from '@possible/generated/proto'

import {AlertProps} from 'src/designSystem/components/molecules/Alert/Alert'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

import {
  LinkedAccountSubsetAfterPlaid,
  useBankAggregator,
} from 'src/products/general/GeneralPaymentMethods/useBankAggregator'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {aggregatorsId} from 'src/lib/user/userEnvConsts'

type AddOrRelinkAccountForPrimary = (args: {
  onChoosePrimaryAccountComplete: (args: {
    linkedAccounts?: LinkedAccountSubsetAfterPlaid[]
  }) => Promise<void>
  accountToRelink?: banking.ILinkedAccount
}) => void

export type UseAccountManagementBankLinkingReturn = {
  addOrRelinkAccountForPrimary: AddOrRelinkAccountForPrimary
  alerts: AlertProps[] | undefined
}

/**
 * Allow a component to add a new primary bank account or to relink
 * an existing account.
 */
export const useAccountManagementBankLinking = (): UseAccountManagementBankLinkingReturn => {
  const navigation = useNavigation<StackNavigationProp<MainStackParamList, 'AccountManagementV2'>>()
  const {t} = useTranslation('AccountManagement')

  const [alerts, setAlerts] = useState<AlertProps[]>()

  const {openBankAggregator} = useBankAggregator(navigation)

  /**
   * Allows components to add a new account with the aggregator and set it as primary.
   */
  const addOrRelinkAccountForPrimary: AddOrRelinkAccountForPrimary = (args) => {
    const {onChoosePrimaryAccountComplete, accountToRelink} = args

    openBankAggregator({
      // user will be sent to primary account selection
      selectAndVerifyAccountMode: 'ForPrimaryAccount',

      account: accountToRelink,

      /**
       * Callback when the user has completed linking a bank account AND after selecting a primary account.
       */
      onBankLinkComplete: ({linkedAccounts, newAchPaymentMethod, aggregatorId}): void => {
        TrackAppEvent(
          BankAccountManagementEvents.account_management_plaid_complete,
          AppEvents.Category.BankAccountManagement,
        )

        const accountId = newAchPaymentMethod?.account?.id
        const accountMask = newAchPaymentMethod?.bankAccount.mask ?? ''

        // Do not track if the same account was relinked
        if (!accountToRelink || accountToRelink?.id !== accountId) {
          TrackAppEvent(
            BankAccountManagementEvents.add_new_payment_account_completed,
            AppEvents.Category.BankAccountManagement,
            {
              type: aggregatorId === aggregatorsId.yodlee ? 'link_with_yodlee' : 'link_with_plaid',
            },
          )
        }

        if (!accountId) {
          logAddPaymentMethodError(
            new Error(
              'useAccountManagementBankLinking() accountId is not defined in onBankLinkComplete',
            ),
          )
          TrackAppEvent(
            BankAccountManagementEvents.account_management_primary_account_set_error,
            AppEvents.Category.BankAccountManagement,
          )
          return
        }

        // After selecting a primary account, users must accept the ACH agreement
        navigation.replace('AcceptPrimaryAccountAgreement', {
          accountMask,
          linkedAccountIdForPrimary: accountId,
          onAccept: async (): Promise<void> => {
            // Call onChoosePrimaryAccountComplete callback from caller once the user has accepted the ACH agreement
            await onChoosePrimaryAccountComplete({linkedAccounts})
          },
        })

        setAlerts?.([{level: 'success', title: t('AccountSuccessfullyAdded')}])
      },
    })
  }

  return {
    addOrRelinkAccountForPrimary,
    alerts,
  }
}
