import {StackNavigationProp} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {callbackStatus} from 'src/products/general/GeneralPaymentMethods/types'
import {getFilteredLinkedBankAccountsGql} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'
import {
  launchAggregator,
  launchAlternateAggregator,
  onBankLinkingCompleteGql,
} from 'src/products/card/PaymentMethods/BankAggregatorHelperDeprecated/BankAggregatorHelper'
import {showUnsupportedInstitutionPopup} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/UnsupportedInstitutions/UnsupportedInstitutions'

type OnCompleteType = (bankName: string) => void

export const useBankAggregator = (
  navigation: StackNavigationProp<MainStackParamList>,
): ((onComplete: OnCompleteType) => Promise<void> | void) => {
  return async (onComplete: OnCompleteType): Promise<void> => {
    const bankAggregatorCallback = async (status: string, bank: string): Promise<void> => {
      if (status === callbackStatus.UNSUPPORTED_BANK) {
        showUnsupportedInstitutionPopup(bank)
      } else {
        const linkedAccounts = await getFilteredLinkedBankAccountsGql()
        if (onBankLinkingCompleteGql(status, linkedAccounts, bank)) {
          onComplete(bank)
        }
      }
    }

    const bankAggregatorSwitchCallback = (
      alternateId: string,
      accountId: string | undefined,
    ): void => {
      launchAlternateAggregator(alternateId, accountId, navigation, (success, bank) =>
        bankAggregatorCallback(success, bank),
      )
    }

    await launchAggregator(
      navigation,
      null,
      (success, bank) => bankAggregatorCallback(success, bank),
      (accountId, onCompleteBankAggregator) =>
        bankAggregatorSwitchCallback(accountId, onCompleteBankAggregator),
    )
  }
}
