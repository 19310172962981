import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {littleGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Button from 'src/designSystem/components/atoms/Button/Button'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import CashAdvanceReadyTransfer from 'src/assets/illustrations/Cash-Advance-Ready-Transfer.svg'
import {ButtonAction} from 'src/designSystem/types'
import {NamedColors} from 'src/designSystem/colors'

export type AdvanceReadyToTransferTileProps = {
  amount: number
  onAcceptOffer: ButtonAction
  testID?: string
}

/**
 * A tile to inform the user that their advance is ready to transfer.
 * @example <AdvanceReadyToTransferTile testID="AdvanceReadyToTransferTile" amount={100} onAcceptNextAdvance={() => Promise.resolve()} />
 */
const AdvanceReadyToTransferTile: React.FC<AdvanceReadyToTransferTileProps> = ({
  testID,
  amount,
  onAcceptOffer,
}: AdvanceReadyToTransferTileProps) => {
  const {t} = useTranslation(['DashboardAdvanceReadyToTransferTile', 'Common'])

  return (
    <BasicTile padding="medium" testID={testID}>
      <Box gap={'small'}>
        <Box padding="tiny" align="start" marginBottom={littleGap}>
          <PFSvgContain svg={CashAdvanceReadyTransfer} width={80} />
        </Box>
        <Box align="start" gap={'small'}>
          <PFText variant={'h3'}>
            <Trans
              t={t}
              i18nKey={'YourAdvanceIsReadyToTransfer'}
              values={{amount}}
              components={{
                amountText: <PFText variant={'h3'} color={NamedColors.PRODUCT_BLUE} />,
              }}
            />
            {/* {t('YourAdvanceIsReadyToTransfer', {amount})
          } */}
          </PFText>
          <PFText variant={'p'}>{t('SeeYourAdvanceSchedule')}</PFText>
        </Box>
        <Box align="center">
          <Button
            mode="primary"
            size="large"
            onPress={async () => {
              await onAcceptOffer?.()
            }}
            width="100%"
            testID="AcceptOfferBtn"
          >
            {t('Common:Continue')}
          </Button>
        </Box>
      </Box>
    </BasicTile>
  )
}

export {AdvanceReadyToTransferTile}
