/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react-prefer-function-component/react-prefer-function-component */
/* eslint-disable @typescript-eslint/unbound-method */

/* eslint-disable no-type-assertion/no-type-assertion */

import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'
import {View, Animated, ViewStyle, StyleProp, StyleSheet} from 'react-native'

import {Indicator} from 'src/designSystem/components/atoms/Loading/indicators/indicator'

interface BallIndicatorProps {
  color?: string
  count?: number
  size?: number
  style?: StyleProp<ViewStyle>
}

interface RenderComponentProps {
  index: number
  count: number
  progress: Animated.Value
}

export class BallIndicator extends PureComponent<BallIndicatorProps> {
  static defaultProps = {
    color: 'rgb(0, 0, 0)',
    count: 8,
    size: 40,
  }

  static propTypes = {
    ...Indicator.propTypes,

    color: PropTypes.string,
    size: PropTypes.number,
  }

  constructor(props: BallIndicatorProps) {
    super(props)

    this.renderComponent = this.renderComponent.bind(this)
  }

  renderComponent({index, count, progress}: RenderComponentProps) {
    const {size, color: backgroundColor} = this.props
    const angle = (index * 360) / count

    const layerStyle = {
      transform: [
        {
          rotate: angle + 'deg',
        },
      ],
    }

    const inputRange = Array.from(new Array(count + 1), (item, index) => index / count)

    const outputRange = Array.from(
      new Array(count),
      (item, index) => 1.2 - (0.5 * index) / (count - 1),
    )

    for (let j = 0; j < index; j++) {
      outputRange.unshift(outputRange.pop()!)
    }

    outputRange.unshift(...outputRange.slice(-1))

    const ballStyle = {
      margin: (size ?? 40) / 20,
      backgroundColor,
      width: (size ?? 40) / 5,
      height: (size ?? 40) / 5,
      borderRadius: (size ?? 40) / 10,
      transform: [
        {
          scale: progress.interpolate({inputRange, outputRange}),
        },
      ],
    }

    return (
      <Animated.View style={[styles.layer, layerStyle]} key={index}>
        <Animated.View style={ballStyle} />
      </Animated.View>
    )
  }

  render() {
    const {style, size, ...props} = this.props
    const width = size
    const height = size

    return (
      <View style={[styles.container, style]}>
        <Indicator style={{width, height}} renderComponent={this.renderComponent} {...props} />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },

  layer: {
    ...StyleSheet.absoluteFillObject,

    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
})
