import React from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'

export type MembershipTileProps = {
  amountApproved: string | undefined
}

export const MembershipTile: React.FC<MembershipTileProps> = (props) => {
  const {amountApproved} = props
  const {t} = useTranslation(['Advance', 'Common'])

  return (
    <BasicTile styles={{background: NamedColors.WHITESMOKE}}>
      <Box gap={'small'} direction={'column'} justify={'between'} padding={'small'}>
        <PFText testID="Deposit-Transfer-Text" variant={'p_semibold'}>
          {t('DepositWithFreeInstantTransfer')}
        </PFText>
        <Box testID="Amount-Box" direction={'row'} justify={'between'} align={'center'}>
          <PFText variant={'p'}>{t('Common:Today')}</PFText>
          <Box padding={'little'} radius={'small'} background={NamedColors.WHITE}>
            <PFText variant={'p_semibold'} color={NamedColors.PRODUCT_BLUE}>
              + ${amountApproved}
            </PFText>
          </Box>
        </Box>
      </Box>
    </BasicTile>
  )
}
