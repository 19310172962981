import React, {ReactNode, useEffect} from 'react'

import TileListView from 'src/products/loans/components/molecules/TileListView/TileListView'
import {
  LoanApprovedTile,
  LoanApprovedTileProps,
} from 'src/products/loans/Dashboard/LoanApprovedTile/LoanApprovedTile'
import {
  LoanCounterOfferTile,
  LoanCounterOfferTileProps,
} from 'src/products/loans/Dashboard/LoanCounterOfferTile/LoanCounterOfferTile'
import {
  AdverseReasonCode,
  ApprovedLoanAggregateStatus,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CashAdvanceEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {MembershipApprovedTileContainer} from 'src/products/advance/Dashboard/MembershipApprovedTile/MembershipApprovedTileContainer'
import {AdvanceReadyToTransferTile} from 'src/products/advance/Dashboard/AdvanceReadyToTransferTile/AdvanceReadyToTransferTile'

export type DashboardLoanApprovedProps = Pick<
  ApprovedLoanAggregateStatus,
  'amountApproved' | 'amountRequested' | 'expirationDateTime'
> &
  Pick<
    LoanCounterOfferTileProps,
    'onViewCounterOffer' | 'onDeclineCounterOffer' | 'onContinueWithCounterOffer'
  > &
  Pick<LoanApprovedTileProps, 'onAcceptOffer'> & {
    counterOfferCodes: (AdverseReasonCode | null)[]
    firstName: string
    isAdvance: boolean
    advanceLoanCount?: number
  }

/**
 * Dashboard displayed when a loan is approved but not yet accepted.
 * @example <DashboardLoanApproved amountApproved="100.00" amountRequested="100.00" expirationDateTime="2023-05-09T12:00Z" .../>
 */
const DashboardLoanApproved: React.FC<DashboardLoanApprovedProps> = (props) => {
  const {
    amountApproved,
    amountRequested,
    expirationDateTime,
    onViewCounterOffer: handleOnViewCounterOffer,
    onDeclineCounterOffer: handleOnDeclineCounterOffer,
    onContinueWithCounterOffer: handleOnContinueWithCounterOffer,
    onAcceptOffer: handleOnAcceptOffer,
    firstName,
    counterOfferCodes,
    isAdvance,
    advanceLoanCount,
  } = props
  const amountApprovedNumber = parseFloat(amountApproved)
  const amountRequestedNumber = parseFloat(amountRequested)
  const hasCounterOffer = amountApprovedNumber < amountRequestedNumber

  useEffect(() => {
    TrackAppEvent(
      ManageActiveLoanEvents.approved_dashboard_viewed,
      AppEvents.Category.ManageActiveLoan,
      {
        isCounterOffer: hasCounterOffer,
      },
    )
  }, [hasCounterOffer])

  const tiles: ReactNode[] = []

  const handleOnAdvanceAcceptOffer = async (): Promise<void> => {
    TrackAppEvent(
      CashAdvanceEvents.advance_transfer_continue_clicked,
      AppEvents.Category.ManageActiveLoan,
      {
        source: 'Dashboard-Repeat-Advance-Approved-AdvanceReadyToTransferTile',
      },
    )
    await handleOnAcceptOffer?.()
  }

  if (isAdvance === false && hasCounterOffer) {
    tiles.push(
      <LoanCounterOfferTile
        testID="DashboardLoanApproved-LoanCounterOfferTile"
        onViewCounterOffer={handleOnViewCounterOffer}
        onDeclineCounterOffer={handleOnDeclineCounterOffer}
        onContinueWithCounterOffer={handleOnContinueWithCounterOffer}
        amountApproved={amountApprovedNumber}
        amountRequested={amountRequestedNumber}
        firstName={firstName}
        counterOfferCodes={counterOfferCodes}
      />,
    )
  } else {
    if (isAdvance === true) {
      if (advanceLoanCount !== undefined && advanceLoanCount > 1) {
        tiles.push(
          <AdvanceReadyToTransferTile
            testID="Dashboard-Repeat-Advance-Approved-AdvanceReadyToTransferTile"
            amount={parseFloat(amountApproved)}
            onAcceptOffer={handleOnAdvanceAcceptOffer}
          />,
        )
      } else {
        tiles.push(
          <MembershipApprovedTileContainer
            testID="DashboardLoanApproved-MembershipApprovedTile"
            amount={parseFloat(amountApproved)}
            onAcceptOffer={handleOnAcceptOffer}
          />,
        )
      }
    } else {
      tiles.push(
        <LoanApprovedTile
          testID="DashboardLoanApproved-LoanApprovedTile"
          amount={parseFloat(amountApproved)}
          expirationDate={new Date(expirationDateTime)}
          onAcceptOffer={handleOnAcceptOffer}
        />,
      )
    }
  }

  return <TileListView list={tiles} testID="DashboardLoanApproved" />
}

export {DashboardLoanApproved}
