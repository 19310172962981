import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {Consumer} from '@possible/cassandra'

import {logErrorAndShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PopPage} from 'src/navigation/NavHelper'
import SSNCameraPage from 'src/products/general/components/organisms/CameraPage/SSNCameraPage/SSNCameraPage'
import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

type SSNCardProps = StackScreenProps<MainStackParamList, 'SSNCard'> & URAProps

const SSNCard: React.FC<SSNCardProps> = (props) => {
  const {navigation, route, complete_ura, onComplete} = props

  const {ura_id} = route.params

  const onSSNCardConfirm = async (): Promise<void> => {
    try {
      if (complete_ura === undefined || complete_ura) {
        await Consumer.methods.completeUra(ura_id)
      }
      onComplete?.()
      PopPage(navigation)
    } catch (e) {
      void logErrorAndShowException(e, 'SSNCard, onSSNCardConfirm:')
    }
  }

  return <SSNCameraPage onComplete={onSSNCardConfirm} />
}

export default withForwardedNavigationParams<SSNCardProps>()(SSNCard)
