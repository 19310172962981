import {
  CreateUserDocumentInput,
  UserDocumentSubType,
  UserDocumentType,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {
  getUserDocumentGenerateUploadUrl,
  getUserDocumentWithPresignedUrl,
} from '@possible/cassandra/src/user/methods'
import Log from 'src/lib/loggingUtil'
import {getImageFileName, ImageCapture} from 'src/lib/utils/formData'

async function uploadDocumentToS3(blob: Blob, presignedUrl: string): Promise<Response | undefined> {
  try {
    const results = await fetch(presignedUrl, {
      method: 'PUT',
      body: blob,
      headers: {
        'x-amz-server-side-encryption': 'AES256',
        'content-type': blob.type,
      },
    })
    Log.debug('[Status]', results.status, '[Content-Type]:', blob.type, '[blobUri]:', results.url)
    return results
  } catch (e) {
    Log.error(e, 'Failed to upload user document')
    return undefined
  }
}

async function fetchImage(imageInfo: ImageCapture): Promise<Response> {
  const imageUri = imageInfo.dataUri ?? imageInfo.uri
  const errorMessage = 'Cound not upload file. Failed to fetch image'

  if (imageUri === undefined) throw Error(errorMessage)

  try {
    const response = await fetch(imageUri)

    return response
  } catch (error) {
    throw Error(`${errorMessage}: ${String(error)}`)
  }
}

export const uploadAndCreateDocument = async ({
  imageInfo,
  type,
  subType,
  userId,
  loanId,
}: {
  imageInfo: ImageCapture | undefined
  type: UserDocumentType
  subType: UserDocumentSubType
  userId?: string
  loanId?: string
}): Promise<true | undefined> => {
  if (imageInfo && userId !== undefined) {
    const fileName = imageInfo?.fileName ?? imageInfo?.file?.name ?? getImageFileName(imageInfo)

    if (!fileName) {
      throw Error('File name not detected')
    }

    const uploadInfo = await getUserDocumentGenerateUploadUrl(fileName)
    if (!uploadInfo) {
      throw Error('Could not upload file. Failed to generate presigned url')
    }
    const presignedUrl = uploadInfo?.presignedUrl
    if (!presignedUrl) {
      throw Error('Could not upload file. Failed to generate presigned url')
    }
    const imageResponse = await fetchImage(imageInfo)
    let blobFile = await imageResponse.blob()
    // Fetch is setting blob type to image/jpeg even if it's a png
    if (fileName?.includes('.png')) {
      blobFile = new Blob([blobFile], {type: 'image/png'})
    }
    const uploadResponse = await uploadDocumentToS3(blobFile, presignedUrl)
    if (!uploadResponse || uploadResponse.status < 200 || uploadResponse.status > 299) {
      throw Error(uploadResponse?.status + ' ' + uploadResponse?.statusText)
    }
    const createUserDocumentInput: CreateUserDocumentInput = {
      documentId: uploadInfo.documentId,
      fileName: fileName,
      userId: userId,
      type: type,
      subType: subType,
    }

    const confirmDocumentCreated = await getUserDocumentWithPresignedUrl({
      loanId: loanId ?? null,
      ...createUserDocumentInput,
    })

    if (confirmDocumentCreated?.document.id !== undefined) {
      return true
    } else {
      throw Error('Could not create document with presigned url')
    }
  }
}
