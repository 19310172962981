import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import Log from 'src/lib/loggingUtil'
import {UseGetAdvanceCountDocument} from 'src/lib/advance/useGetAdvanceCount/useGetAdvanceCountQuery.gqls'

/**
 * custom hook that queries the BE and gets how many advances the user has applied for
 * @returns number
 */

type UseGetAdvanceCountReturnType = {
  loading: boolean
  count: number | undefined
}

const useGetAdvanceCount = (): UseGetAdvanceCountReturnType => {
  const {data: advanceCountData, loading: isLoadingAdvanceCount} = useCassandraQuery(
    UseGetAdvanceCountDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (error) => {
        Log.error('Unable to determine advance counts: ', error.message)
      },
    },
  )

  const countApplied = advanceCountData?.me?.loans?.countApplied

  return {loading: isLoadingAdvanceCount, count: countApplied}
}

export {useGetAdvanceCount}
