import {StackScreenProps} from '@react-navigation/stack'
import React, {useState} from 'react'
import {Trans, withTranslation, WithTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {connect, ConnectedProps} from 'react-redux'

import {Logout} from 'src/api/MobileGatewayAPI/actions/logout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {PageViewedAnalyticsProvider} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {openContactUsForm} from 'src/lib/contactUs'
import {logErrorAndShowException} from 'src/lib/errors'
import Snackbar from 'src/lib/Snackbar'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import ActivityIndicator from 'src/products/general/components/atoms/ActivityIndicator/ActivityIndicator'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {userAccountRecovery} from '@possible/cassandra/src/user/authPublicMethods'

// Follow up ticket ENG-17638 has been created to address the typescript errors that were disabled when the InlineLink was updated to SvgLink

type Props = StackScreenProps<
  MainStackParamList,
  'AccountRecoveryInstructions' | 'AccountRecoveryInstructionsAuthenticated'
> & {
  isFromDuplicate?: boolean
  phoneNumber: string
  phoneNumberFormatted: string
  Logout: () => Promise<void>
} & WithTranslation<'AccountRecovery', 'Common'>

const AccountRecoveryInstructions: React.FC<Props> = ({
  isFromDuplicate,
  phoneNumber,
  phoneNumberFormatted,
  Logout,
  navigation,
  t,
}: Props & PropsFromRedux & WithTranslation) => {
  const [isBusy, setIsBusy] = useState(false)

  const handleOnResend = async (): Promise<void> => {
    setIsBusy(true)
    TrackAppEvent(AppEvents.Name.account_recovery_resend_selected, AppEvents.Category.Login)

    try {
      const {errors} = await userAccountRecovery(phoneNumber)

      if (errors) {
        Snackbar.error({
          title: errors[0].message,
          duration: Snackbar.LENGTH_LONG,
        })
        setIsBusy(false)
        return
      }

      Snackbar.success({
        title: t('InstructionsResent'),
        duration: Snackbar.LENGTH_LONG,
      })
    } catch (e) {
      void logErrorAndShowException(e, 'AccountRecoveryInstructions, handleOnResend:')
    }

    setIsBusy(false)
  }

  const onPress = (): void => {
    if (isFromDuplicate) {
      void Logout(false)
      return
    }

    navigation.reset({routes: [{name: 'Login'}]})
  }

  const handleOnContactUs = (): void => {
    TrackAppEvent(
      AppEvents.Name.account_recovery_instructions_contact_us_selected,
      AppEvents.Category.Login,
    )
    openContactUsForm(navigation)
  }

  const action = {
    onPress,
    text: t('GoToLoginScreen'),
    disable: isBusy,
    testID: 'Account-Recovery-Instructions-CTA',
  }

  const title = (
    <Box paddingTop={'medium'}>
      <PFText variant={'h1'} textAlign={'center'}>
        {t('InstructionsTitle')}
      </PFText>
    </Box>
  )

  const description = (
    <PFText variant={'p'} textAlign={'center'}>
      {t('PhoneMatch')}
    </PFText>
  )

  const mainBody = (
    <Box align={'center'} gap={'medium'} marginTop={-56}>
      <SvgLink
        onPress={handleOnResend}
        linkType={'single'}
        linkText={t('ResendTo', {phoneNumber: phoneNumberFormatted})}
        linkIcon={'internal'}
        linkIconSize={'little'}
      />
      <PFText variant={'p'} textAlign={'center'}>
        <Trans i18nKey="DidNotReceiveTextMessage" t={t}>
          If you have an existing account and don’t receive a text within five minutes, you can
          <SvgLink
            onPress={handleOnContactUs}
            linkType={'inline'}
            linkText={t('Common:ContactUsLowercase')}
          />
          .
        </Trans>
      </PFText>
    </Box>
  )

  return (
    <Page
      buttonProps={buttonLockupProperties(action)}
      variant={'generic'}
      title={title}
      description={description}
      smallTopGap
    >
      {mainBody}
      <PageViewedAnalyticsProvider
        eventName={AppEvents.Name.account_recovery_instructions_viewed}
        eventCategory={AppEvents.Category.Login}
      />
      <ActivityIndicator animating={isBusy} style={styles.activityIndicator} size="large" />
    </Page>
  )
}
const mapDispatchToProps = {
  Logout,
}

const connector = connect(null, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const NavigationAccountRecoveryInstructions = withForwardedNavigationParams<Props>()(
  AccountRecoveryInstructions,
)
const TranslatedAccountRecoveryInstructions = withTranslation(['AccountRecovery', 'Common'])(
  NavigationAccountRecoveryInstructions,
)
const ConnectedAccountRecoveryInstructions = connector(TranslatedAccountRecoveryInstructions)

export {ConnectedAccountRecoveryInstructions as AccountRecoveryInstructions}

const styles = StyleSheet.create({
  activityIndicator: {
    margin: 20,
  },
})
