import {Consumer} from '@possible/cassandra'
import {StackScreenProps} from '@react-navigation/stack'
import React, {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {Loading} from 'src/designSystem/components/atoms/Loading/Loading'
import {
  AddressForm,
  RequiredAddressFields,
} from 'src/designSystem/components/organisms/AddressForm/AddressForm'
import Log from 'src/lib/loggingUtil'
import {getHasErrorsOrMissingValues} from 'src/lib/utils/formValidationUtil'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PopPage} from 'src/navigation/NavHelper'
import {useUpdateUserAddress} from 'src/products/MCU/Address/address.utils'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {AddressUpdateErrorModal} from 'src/products/general/AddressUpdates/AddressUpdateErrorModal'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AdminEvents, AppEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'

type Props = StackScreenProps<MainStackParamList, 'AddressUpdate'> & {
  address?: Consumer.types.Address
  onComplete?: () => void
}

const AddressUpdate: FC<Props> = (props) => {
  const {address, onComplete, route, navigation} = props
  const {t} = useTranslation(['PersonalInformation', 'Common'])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  const addressFromProps = address ?? route?.params?.address

  const {data: meData, loading: isMeDataLoading} = Consumer.hooks.useMeAddressQuery()

  const [updateUserAddress, {loading: isUpdateUserAddressLoading}] = useUpdateUserAddress()

  const defaultAddress = addressFromProps ??
    meData?.me.profile?.home?.address ?? {
      __typename: 'Address',
      verified: false,
    }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    reset,
  } = useForm<Consumer.types.ApplicantPhysicalAddressComponentsInput>({
    mode: 'all',
    defaultValues: Consumer.adapters.AddressToAddressComponents(defaultAddress),
  })

  usePageViewedAnalytics({
    eventName: AdminEvents.address_update_viewed,
    eventCategory: AppEvents.Category.Admin,
  })

  useEffect(() => {
    if (meData?.me.profile?.home?.address) {
      reset(
        Consumer.adapters.AddressToAddressComponents({
          ...meData?.me.profile?.home?.address,
        }),
      )
    }
  }, [reset, meData?.me.profile?.home?.address])

  const complete = (addressToConfirm: Consumer.types.Address): void => {
    PopPage(navigation)
    if (route.params?.onComplete) {
      route.params.onComplete(addressToConfirm)
    } else {
      onComplete?.()
    }
  }

  const onSubmit = async (
    data: Consumer.types.ApplicantPhysicalAddressComponentsInput,
  ): Promise<void> => {
    setIsSubmitting(true)

    try {
      data.country = 'US'

      const resAddress = await updateUserAddress(data)
      complete(resAddress)
      TrackAppEvent(AdminEvents.address_update_completed, AppEvents.Category.Admin)
    } catch (e) {
      Log.error(e, 'AddressUpdate, onSubmit: ')
      setShowErrorModal(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const isDisabled =
    getHasErrorsOrMissingValues(errors, watch, RequiredAddressFields) ||
    isSubmitting ||
    isUpdateUserAddressLoading

  const isLoading = isSubmitting || isUpdateUserAddressLoading

  const primaryAction = {
    text: t('SaveAddress'),
    onPress: handleSubmit(onSubmit),
    disabled: isDisabled,
    loading: isLoading,
    testID: 'Save-Address-CTA',
  }

  if (isMeDataLoading) {
    return <Loading type="loader0" size="large" />
  }

  return (
    <Page
      title={route.params.title}
      buttonProps={buttonLockupProperties(primaryAction)}
      variant={'generic'}
      smallTopGap={true}
      testID="Address-Update-Screen"
    >
      <Box direction="column" justify="between" fill="vertical">
        <Box>
          <AddressForm control={control} errors={errors} />
          <Box gap={'small'} direction={'row'} margin={'tiny'}>
            <Box align={'center'} flex={1} paddingTop={6}>
              <SvgIcon name={'info'} colorVariant={'success'} size={'large'} />
            </Box>
            <Box flex={9}>
              <PFText variant="p_sm">{t('AddressEditFooter')}</PFText>
            </Box>
          </Box>
        </Box>
      </Box>
      <AddressUpdateErrorModal
        canUpdateAddress={true}
        showModal={showErrorModal}
        hideModal={() => setShowErrorModal(false)}
      />
    </Page>
  )
}

export {AddressUpdate}
