/**
 * Importing `expo-constants` in web results in an unrecoverable
 * error for webpack. So, for now, we're no-oping these functions
 * on web.
 */

import {
  BinaryBuildNumberMethod,
  BinaryVersionNumberMethod,
  CompoundVersionMethod,
  UIVersionMethod,
} from 'src/lib/expo/versioning.types'

/**
 * Get the binary version of the app; semver style
 * @returns 'N/A' on web
 */
const BinaryVersionNumber: BinaryVersionNumberMethod = (): string | undefined => {
  return 'N/A'
}

/**
 * Get the build number of the binary. This used to be based on the
 * unix epoch so the number is very large, but now it is incremented
 * with each build by expo. We truncate the number to the last 4 digits
 * to make it more readable.
 * @returns 'N/A' on web
 */
const BinaryBuildNumber: BinaryBuildNumberMethod = (): string | undefined => {
  return 'N/A'
}

/**
 * Get the UI version string. This used to be an incrementing number
 * but now it is an id for the update. We truncate it to the first 8
 * characters to make it more readable and to line up with how expo
 * displays it on their dashboard.
 * @returns 'N/A' on web
 */
const UIVersion: UIVersionMethod = (): string | undefined => {
  return 'N/A'
}

/**
 * Get an ugly compound version string that is used to report metadata
 * to various services. This is in the format of:
 * `<binary version>:<last 4 digits of build number>-<first 8 characters of update id>`.
 * Example: `2.3.0:1234-abcdef12`
 * @returns An ugly compound version string. Format: '2.3.0:1234-abcdef12'
 */
const CompoundVersion: CompoundVersionMethod = (): string => {
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  return (process.env.UI_VERSION as string | undefined) ?? 'N/A'
}

export {BinaryBuildNumber, BinaryVersionNumber, CompoundVersion, UIVersion}
