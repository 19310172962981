import {LoanTypeVariant} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {UseIsAdvanceQueryDocument} from 'src/lib/advance/useIsAdvance/useIsAdvanceQuery.gqls'
import Log from 'src/lib/loggingUtil'

/**
 * custom hook that queries the BE and verifies the loanTypeVariant for Cash Advance
 * @returns true if the loanTypeVariant is NonRecourseInstallment
 */

type UseIsAdvanceReturnType = {
  loading: boolean
  isAdvance: boolean
}

const useIsAdvance = (): UseIsAdvanceReturnType => {
  const {data: loanTypeData, loading: isLoading} = useCassandraQuery(UseIsAdvanceQueryDocument, {
    fetchPolicy: 'cache-first',
    onError: (error) => {
      Log.error('Unable to determine if isAdvance: ', error.message)
    },
  })

  const offerVariant: LoanTypeVariant | null | undefined =
    loanTypeData?.me?.products?.eligible?.all?.find((offer) => offer.__typename === 'LoanOfferInfo')
      ?.offers?.[0]?.loanTypeVariant

  const variant: LoanTypeVariant | undefined =
    loanTypeData?.me?.loans?.latestActionableLoan?.loanTypeInfo?.variant

  if (
    offerVariant === LoanTypeVariant.NonRecourseInstallment ||
    variant === LoanTypeVariant.NonRecourseInstallment
  ) {
    return {loading: isLoading, isAdvance: true}
  }

  return {loading: isLoading, isAdvance: false}
}

export {useIsAdvance}
