import * as Types from '../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type ContUwPreQualAmountQueryVariables = Types.Exact<{
  assessmentDate: Types.Scalars['DateTime']['input']
  product: Types.PrequalificationProduct
}>

export type ContUwPreQualAmountQuery = {
  __typename?: 'Query'
  loanGetPrequalification: {
    __typename?: 'LoanPrequalification'
    amount: string
    status: Types.LoanPrequalificationStatus
  }
}

export const ContUwPreQualAmountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'ContUwPreQualAmount'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'assessmentDate'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'product'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'PrequalificationProduct'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanGetPrequalification'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'assessmentDate'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'assessmentDate'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'product'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'product'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContUwPreQualAmountQuery, ContUwPreQualAmountQueryVariables>
