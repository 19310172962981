/*
  Filter Console Logs for warnings and errors that our out of our control and or planning to fix shortly.
  This mechanism should be used sparingly. 
*/

export const setupConsoleFilter = (): void => {
  if (!__DEV__) {
    return
  }

  // eslint-disable-next-line no-console
  const originalConsoleWarn = console.warn

  // Create a list of patterns to filter out specific warnings
  const filteredWarningPatterns: RegExp[] = [
    // Add patterns to filter out specific warnings
    // For example: /Warning: componentWillMount has been renamed/

    /*
        React Navigation v6 warnings :
        We are on v6 of the library, it is no longer being maintained. We would need to upgrade to v7 or 
        switch to the Expo Navigation. That latter is what we plan to do, once the Expo binary goes live
        We will migrate the navigation stack by Q2 2025.
    */
    /"shadow\*" style props are deprecated\. Use "boxShadow"/,
    /props\.pointerEvents is deprecated\. Use style\.pointerEvents/,
  ]

  // Replace the global console.warn function with our custom implementation
  // eslint-disable-next-line no-console, @typescript-eslint/no-explicit-any
  console.warn = (...args: any[]): void => {
    // Check if the warning message matches any of our filtered patterns
    const warningMessage = args.join(' ')
    const shouldFilter = filteredWarningPatterns.some((pattern) => pattern.test(warningMessage))

    // If the warning doesn't match any filtered patterns, pass it to the original handler
    if (!shouldFilter) {
      originalConsoleWarn.apply(console, args)
    }
  }
}
