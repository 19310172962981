/**
 * If this is true, redux store actions will be logged
 */
const isReduxStoreLoggingEnabled = false //do not check in with true

/**
 * If this is true, the APIClient will log requests and responses
 */
const isApiClientLoggingEnabled = false //do not check in with true

/**
 * If this is true, various services will log events
 */
const isEventServiceLoggingEnabled: boolean = false //do not check in with true

/**
 * If this is true Workflows navigators will display a debug UI about the current prereq.
 */
const isWorkflowsDebugPanelEnabled: boolean = false //do not check in with true

enum LogLevels {
  Dir, //Dir is not a convetional log level, it's used by JS DevTools to display objects in the console.
  Log,
  Debug,
  Info,
  Warn,
  Error,
  None,
}

/**
 * All log messages of severity equal to or greater than logLevel will be logged to the console
 * All log messages of lower severity will be ignored.
 * You can silence the application log messages in the console by setting logLevel = LogLevels.ERROR
 */
const localLogLevel = (): LogLevels => (__DEV__ ? LogLevels.Dir : LogLevels.None)
const remoteLogLevel = (): LogLevels => (__DEV__ ? LogLevels.None : LogLevels.Log)

export {
  isReduxStoreLoggingEnabled as REDUX_STORE_LOGGING,
  isApiClientLoggingEnabled as API_CLIENT_LOGGING,
  isEventServiceLoggingEnabled as EVENT_SERVICE_LOGGING,
  isWorkflowsDebugPanelEnabled as WORKFLOWS_DEBUG_PANEL,
  LogLevels,
  localLogLevel,
  remoteLogLevel,
}
