import React from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {useGetLinkedAccounts} from 'src/lib/bank/useGetLinkedAccounts'
import {usePromise} from 'src/lib/usePromise/usePromise'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {
  logAddPaymentMethodError,
  logAddPaymentMethodErrorAndShowException,
} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {ReapplicationBankTemplate} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/ReapplicationBank/ReapplicationBankTemplate'
import {useAccountManagementBankLinking} from 'src/products/MCU/AccountManagementV2/useAccountManagementBankLinking'

type Props = {
  onContinue: (accountId: string) => Promise<void>
  bankLinkAnalyticEvent: () => void
}

export const ReapplicationBankGQLContainer: React.FC<Props> = (props) => {
  const {onContinue, bankLinkAnalyticEvent} = props

  const {linkedAccountsData, isLoadingLinkedAccounts, linkedAccountsError, linkedAccountsRefetch} =
    useGetLinkedAccounts()

  const {addOrRelinkAccountForPrimary} = useAccountManagementBankLinking()

  const preferredAccount = linkedAccountsData?.preferredAccount
  const latestAccount = linkedAccountsData?.latestAccount

  const [onContinueWithLoading, {isLoading: isLoadingOnContinue}] = usePromise(
    async (): Promise<void> => {
      TrackAppEvent(
        AppEvents.Name.link_bank_account_current_account_selected,
        AppEvents.Category.Application,
      )
      bankLinkAnalyticEvent()
      if (preferredAccount) {
        await onContinue(preferredAccount.id)
      } else if (latestAccount) {
        await onContinue(latestAccount.id)
      } else {
        throw new Error('No preferred or latest account found')
      }
    },
    {
      catch: (error) =>
        logAddPaymentMethodError(error, 'Error while continuing with current account'),
    },
  )

  /* This function is necessary because the types for `ReapplicationBankTemplate` do not allow
   * passing `onContinueWithLoading` directly and updating it doesn't feel right at this time.
   */
  const handleOnContinue = async (): Promise<void> => {
    await onContinueWithLoading()
  }

  const handleContinueRelink = async (): Promise<void> => {
    bankLinkAnalyticEvent()
    try {
      const result = await linkedAccountsRefetch()

      if (result.errors) {
        logAddPaymentMethodError(new Error('refetch error'))
        return
      }
      if (result.selectedData?.preferredAccount) {
        await onContinue(result.selectedData?.preferredAccount.id)
      } else if (result.selectedData?.latestAccount) {
        await onContinue(result.selectedData?.latestAccount.id)
      } else {
        throw new Error('refetched but no preferred account found')
      }
    } catch (e) {
      logAddPaymentMethodError(
        e,
        'ReapplicationBankGQLContainer handleRelinkContinue() refetch error',
      )
    }
  }

  const handleOnRelinkAccount = (): void => {
    TrackAppEvent(
      AppEvents.Name.link_bank_account_relink_account_selected,
      AppEvents.Category.Application,
    )

    if (!preferredAccount?.id) {
      logAddPaymentMethodErrorAndShowException(
        new Error(
          'ReapplicationBankGQLContainer handleOnRelinkAccount() no preferred account found',
        ),
      )
      return
    }

    addOrRelinkAccountForPrimary({
      accountToRelink: {
        id: preferredAccount?.id,
      },
      onChoosePrimaryAccountComplete: handleContinueRelink,
    })
    bankLinkAnalyticEvent()
  }

  const handleShouldLinkNewAccount = (): void => {
    TrackAppEvent(
      AppEvents.Name.link_bank_account_new_account_selected,
      AppEvents.Category.Application,
    )
    addOrRelinkAccountForPrimary({
      onChoosePrimaryAccountComplete: handleContinueRelink,
    })
    bankLinkAnalyticEvent()
  }

  return (
    <BaseTemplate
      isLoading={isLoadingLinkedAccounts && !linkedAccountsData}
      isError={!!linkedAccountsError}
    >
      <ReapplicationBankTemplate
        {...props}
        isPreferredAccount={preferredAccount}
        isLatestAccount={latestAccount}
        isLoading={isLoadingOnContinue}
        onContinue={handleOnContinue}
        onLinkNewAccount={handleShouldLinkNewAccount}
        onRelinkAccount={handleOnRelinkAccount}
      />
    </BaseTemplate>
  )
}
