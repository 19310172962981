import {LinkedAccountType} from '@possible/cassandra/src/types/types.mobile.generated'
import React, {ReactElement, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {BottomSheet} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {Loading} from 'src/designSystem/components/atoms/Loading/Loading'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {
  RadioButtonList,
  RadioButtonListOption,
} from 'src/designSystem/components/molecules/RadioButtonList/RadioButtonList'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {maskPrefixNonBreaking} from 'src/lib/user/utils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {BankAggregatorAccountSelectionTemplateProps} from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/BankAggregatorAccountSelection.types'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'

/**
 * UI template for a screen that displays a list of bank accounts from a bank aggregator (LinkedAccounts)
 * and allows the user to select one of them.
 */
const BankAggregatorAccountSelectionTemplate: React.FC<
  BankAggregatorAccountSelectionTemplateProps
> = (props: BankAggregatorAccountSelectionTemplateProps) => {
  const {
    accountsFromBankAggregator,
    continueBtn,
    failedVerifyBtn,
    title,
    description,
    testID,
    syncingOverlay,
    onSelectAccount,
    // by default it does not show the "New Account" indicator next to accounts with isNewAccount: true
    showNewAccountIndicator = false,
  } = props
  const {t} = useTranslation(['BankAggregatorAccountSelection', 'Common'])

  // convert accounts to radio options
  const accountsRadioOptions: RadioButtonListOption[] = accountsFromBankAggregator.map(
    (account) => {
      let accountTypeLabel = ''
      switch (account.type) {
        case LinkedAccountType.Checking:
          accountTypeLabel = t('AccountChecking')
          break
        case LinkedAccountType.Savings:
          accountTypeLabel = t('AccountSavings')
          break
        case LinkedAccountType.Other:
        default:
          accountTypeLabel = t('AccountOther')
          break
      }
      const name = account.institution?.name
        ? `${account.institution.name} ${accountTypeLabel}`
        : account.name

      const labelText = `${name} (${maskPrefixNonBreaking} ${account.mask})`
      let labelElement: ReactElement = <PFText variant="p">{labelText}</PFText>
      if (account.preferredFundingSource) {
        labelElement = (
          <Box marginBottom={'tiny'}>
            <PFStatusPill
              text={t('Primary')}
              color={NamedColors.PRODUCT_BLUE}
              fontColor={NamedColors.WHITE}
            />
            <PFText variant="p">{labelText}</PFText>
          </Box>
        )
      } else if (account.isNewAccount && showNewAccountIndicator) {
        labelElement = (
          <Box marginBottom={'tiny'}>
            <PFStatusPill
              text={t('NewAccount')}
              color={NamedColors.PRODUCT_BLUE}
              variant="outline"
              shape="rectangle"
              testID="BankAggregatorAccountSelection-RadioButtonListOption-NewAccountPill"
            />
            <PFText variant="p">{labelText}</PFText>
          </Box>
        )
      }

      return {
        id: account.id,
        text: labelElement,
        testID: `BankAggregatorAccountSelection-RadioButtonListOption`,
      }
    },
  )

  const [selectedAccountId, setSelectedAccountId] = useState<string>(
    // default to primary account if it's in the list
    accountsFromBankAggregator.find((account) => account.preferredFundingSource === true)?.id ??
      accountsFromBankAggregator[0]?.id,
  )

  const selectedAccount = accountsFromBankAggregator.find(
    (account) => account.id === selectedAccountId,
  )

  const handleOnSelectAccount = (selectedOptionId: string): void => {
    setSelectedAccountId(selectedOptionId)
    onSelectAccount?.(selectedOptionId)
  }
  const handleOnContinue = (): void => {
    if (selectedAccount) {
      void continueBtn.onContinue?.({
        selectedAccountId,
      })
    }
  }
  const handleOnVerifyAfterSyncingFailed = (): void => {
    if (selectedAccount) {
      void failedVerifyBtn?.onFailedVerifyAccount({
        selectedAccountId,
        selectedAccountNumberMask: selectedAccount.mask,
      })
    }
  }

  // we show a syncing overlay if it's provided with configurable title/description/graphic
  // but if syncing failed we change it to a default failed state
  const syncingOverlayTitle = syncingOverlay?.didSyncingFail
    ? t('SyncingPleaseVerify')
    : syncingOverlay?.title
  const syncingOverlayDescription = syncingOverlay?.didSyncingFail
    ? t('SyncingTakingTooLong')
    : syncingOverlay?.description
  const syncingOverlayGraphic = syncingOverlay?.didSyncingFail ? (
    <Box boxStyle={styles.syncingOverlayGraphic}>
      <SvgIcon name="warning" colorVariant="warning" isFilled={true} size="medium" />
    </Box>
  ) : (
    <Box boxStyle={styles.syncingOverlayGraphic}>
      <Loading type="loader0" size="large" />
    </Box>
  )

  return (
    <BaseTemplate onErrorBoundary={(e): void => logAddPaymentMethodError(e)}>
      <Page
        variant="generic"
        smallTopGap={true}
        title={title}
        description={description}
        testID={testID}
        buttonProps={
          // hide the button while syncing and overlay is shown
          syncingOverlay.isVisible
            ? undefined
            : {
                type: 'singleButton',
                primary: {
                  disabled: !selectedAccountId,
                  text: t('Common:Continue'),
                  testID: continueBtn.testID,
                  onPress: handleOnContinue,
                },
              }
        }
      >
        <PFText variant="h3">{t('Accounts')}</PFText>
        <Box paddingBottom="small">
          {/* NOTE: this can eventually be replaced with producst/general/ PaymentMethodList if it adds support for LinkedAccounts */}
          <RadioButtonList
            onPress={handleOnSelectAccount}
            options={accountsRadioOptions}
            selectedOption={selectedAccountId}
          ></RadioButtonList>
        </Box>
        {/* the "syncing overlay" is shown as bottom modal while it's submitting */}
        <BottomSheet
          visible={syncingOverlay.isVisible}
          title={syncingOverlayTitle}
          titleAlign="left"
          titleVariant="h3"
          graphic={syncingOverlayGraphic}
          onDismiss={(): void => {}}
          showDots={false}
        >
          <Box testID="BankAggregatorAccountSelection-SyncingOverlay" width="100%">
            <PFText
              variant="p_lg"
              textAlign="left"
              textProps={{
                style: styles.syncingOverlayText,
              }}
            >
              {syncingOverlayDescription}
            </PFText>
            {syncingOverlay?.didSyncingFail ? (
              <Box marginTop="medium">
                <Button
                  mode="primary"
                  size="large"
                  width="100%"
                  onPress={handleOnVerifyAfterSyncingFailed}
                  testID={failedVerifyBtn.testID}
                >
                  {t('VerifyAccountBtn')}
                </Button>
              </Box>
            ) : null}
          </Box>
        </BottomSheet>
      </Page>
    </BaseTemplate>
  )
}

export {BankAggregatorAccountSelectionTemplate}

const styles = StyleSheet.create({
  syncingOverlayGraphic: {
    alignSelf: 'flex-start',
    width: 30,
    height: 30,
  },
  syncingOverlayText: {
    width: '100%',
  },
})
