/* eslint-disable complexity */
import React, {PropsWithChildren, useCallback, useState} from 'react'
import {
  AccessibilityProps,
  AnimatableNumericValue,
  DimensionValue,
  LayoutChangeEvent,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {Loading, LoadingType} from 'src/designSystem/components/atoms/Loading/Loading'
import PFElevation from 'src/designSystem/components/atoms/PFElevation/PFElevation'
import {LevelVariants} from 'src/designSystem/components/atoms/PFElevation/styles'
import PFText, {PFTextProps} from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgIconSize, SvgIconSizeMap} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'
import withDoubleClickPrevention from 'src/designSystem/components/atoms/WithDoubleClickPrevention/WithDoubleClickPrevention'
import {buttonFocusBorderDistance, buttonFocusBorderWidth} from 'src/designSystem/guide'
import {
  disabledButtonNormalBackground,
  errorButtonFocusBorder,
  errorButtonNormalBackground,
  errorButtonNormalBorder,
  primaryButtonFocusBorder,
  primaryButtonNormalBackground,
  progressBarButtonBackground,
  progressiveButtonFocusBackground,
  progressiveButtonNormalBackground,
  secondaryButtonNormalBackground,
  transparent,
} from 'src/designSystem/semanticColors'
import {ButtonAction, Color} from 'src/designSystem/types'

export const LARGE_BUTTON_MAX_WIDTH = 400
export const MEDIUM_BUTTON_MAX_WIDTH = 350
export const SMALL_BUTTON_MAX_WIDTH = 350

export type ButtonSize = 'small' | 'medium' | 'large'
export type ButtonMode =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'progressive'
  | 'selectedTab'
  | 'landingScreenPrimary'
export type ButtonState = 'normal' | 'focus'
export type ButtonIconProps = {
  center?: boolean
  icon: SvgIconProps
  color?: Color
  iconSize?: SvgIconSize
  buttonSize?: string
  testID?: string
}
export type ButtonProps = React.PropsWithChildren &
  AccessibilityProps & {
    size: ButtonSize
    mode: ButtonMode
    /* Width:
      Each size has an associated default width. This can be overridden with the common
      view width controls, i.e. points or percentages.
   */
    width?: DimensionValue
    state?: ButtonState
    disabled?: boolean
    icon?: SvgIconProps
    progress?: number //having a number 0-100 will have the button in loading mode
    loading?: LoadingType | boolean //when in progress mode, this will show a spinner
    onPress?: ButtonAction
    testID?: string
  }

const LARGE_BUTTON_HEIGHT = 48
const MEDIUM_BUTTON_HEGHT = 32
const SMALL_BUTTON_HEIGHT = 19

const dimensionValueToAnimatableNumericValue = (
  value: DimensionValue | undefined,
): AnimatableNumericValue => {
  let out: AnimatableNumericValue = NaN
  if (typeof value === 'number') {
    out = value
  } else if (typeof value === 'string') {
    out = parseInt(value, 10)
  }

  if (Number.isNaN(out)) {
    out = 0
  }

  return out
}

const sizeToIconSize: {[key in ButtonSize]: SvgIconSize} = {
  small: 'little',
  medium: 'small',
  large: 'medium',
}

const computeIconOffset = (buttonSizeValue: number, buttonSize: ButtonSize) =>
  Math.max((buttonSizeValue - SvgIconSizeMap[sizeToIconSize[buttonSize]]) / 2, 6)

export const iconLeftOffsetSizeMap = {
  large: computeIconOffset(LARGE_BUTTON_HEIGHT, 'large'),
  medium: computeIconOffset(MEDIUM_BUTTON_HEGHT, 'medium'),
  small: computeIconOffset(SMALL_BUTTON_HEIGHT, 'small'),
}

const computeTextIconPadding = (buttonSize: ButtonSize) =>
  SvgIconSizeMap[sizeToIconSize[buttonSize]] - 4
const iconMarginOffsetSizeMap = {
  large: computeTextIconPadding('large'),
  medium: computeTextIconPadding('medium'),
  small: computeTextIconPadding('small'),
}

const focusBorderMap = {
  primary: {
    borderColor: primaryButtonFocusBorder,
  },
  secondary: {
    borderColor: primaryButtonFocusBorder,
  },
  error: {
    borderColor: errorButtonFocusBorder,
  },
}

const containerModeStyleMap = {
  primary: {
    backgroundColor: primaryButtonNormalBackground,
  },
  secondary: {
    backgroundColor: secondaryButtonNormalBackground,
    borderWidth: 1,
  },
  disabled: {
    backgroundColor: disabledButtonNormalBackground,
  },
  error: {
    backgroundColor: errorButtonNormalBackground,
    borderWidth: 1,
    borderColor: errorButtonNormalBorder,
  },
  progressive: {
    normal: {
      backgroundColor: progressiveButtonNormalBackground,
    },
    focus: {
      backgroundColor: progressiveButtonFocusBackground,
    },
  },
  selectedTab: {
    backgroundColor: NamedColors.PRODUCT_BLUE,
  },
  landingScreenPrimary: {
    backgroundColor: NamedColors.PRODUCT_BLUE,
  },
}

const textModeStyleMap: {[key: string]: PFTextProps} = {
  primary: {
    color: 'white',
  },
  secondary: {
    color: 'textPrimary',
  },
  disabled: {
    color: 'textDisabled',
  },
  error: {
    color: 'error',
  },
  progressive: {
    color: 'white',
  },
  selectedTab: {
    color: 'white',
  },
  landingScreenPrimary: {
    color: 'white',
  },
}

const textSizeStyleMap: {[key: string]: PFTextProps} = {
  small: {
    variant: 'label_sm',
  },
  medium: {
    variant: 'label_sm',
  },
  large: {
    variant: 'label_md',
  },
}

type ContainerSizeType = Pick<ViewStyle, 'width' | 'height' | 'paddingHorizontal' | 'maxWidth'>
const containerSizeStyleMap: {[key: string]: ContainerSizeType} = {
  small: {
    width: 128,
    maxWidth: SMALL_BUTTON_MAX_WIDTH,
    height: SMALL_BUTTON_HEIGHT,
  },
  medium: {
    width: 128,
    maxWidth: MEDIUM_BUTTON_MAX_WIDTH,
    height: MEDIUM_BUTTON_HEGHT,
  },
  large: {
    width: 300,
    maxWidth: LARGE_BUTTON_MAX_WIDTH,
    height: LARGE_BUTTON_HEIGHT,
  },
}

const textModeStyle = (mode: ButtonMode, disabled: boolean): PFTextProps => {
  let style = textModeStyleMap.disabled
  if (!disabled) {
    style = textModeStyleMap[mode]
  }
  return style
}

const containerModeStyle = (
  mode: ButtonMode,
  disabled: boolean,
  state?: ButtonState,
): ViewStyle => {
  if (disabled) {
    return containerModeStyleMap.disabled
  }

  const defaultStyles = containerModeStyleMap[mode] || {}
  return {...defaultStyles, ...containerModeStyleMap[mode][state ?? 'normal']}
}

const textSizeStyle = (size: ButtonSize): PFTextProps => {
  return textSizeStyleMap[size]
}

const containerSizeStyle = (size: ButtonSize): ContainerSizeType => {
  return containerSizeStyleMap[size]
}
const ButtonIcon: React.FC<ButtonIconProps> = ({center, icon, color, iconSize, buttonSize}) => {
  const centerStyle: ViewStyle = {alignSelf: 'center'}
  const defaultStyle: ViewStyle = {
    position: 'absolute',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    left: buttonSize ? iconLeftOffsetSizeMap[buttonSize] : 0,
  }

  return (
    <View style={center ? centerStyle : defaultStyle}>
      <SvgIcon name={icon.name} colorVariant={'default'} size={iconSize} />
    </View>
  )
}

const ButtonLoadingIcon = (props) => {
  let {loading} = props
  if (loading === true) {
    loading = 'loader0'
  }

  return (
    <View
      style={
        props.center
          ? {alignSelf: 'center'}
          : {position: 'absolute', left: iconLeftOffsetSizeMap[props.size]}
      }
    >
      <Loading color={props.color} type={loading} size={props.size} />
    </View>
  )
}

/**
 * Generic button with branded styles applied.
 * @example <Button mode='primary' size='medium' onPress={() => foo()}>text</Button>
 * @example <Button mode='primary' loading={true} icon={{ category: 'socials', name: 'twitter' }}>text</Button>
 */
const Button = (props: PropsWithChildren<ButtonProps>) => {
  const {
    size,
    mode,
    disabled = false,
    state,
    children,
    icon,
    width,
    progress,
    loading = false,
    onPress,
    testID,
    ...accessibilityProps
  } = props

  const [inState, setInState] = useState<ButtonState>(state ?? 'normal')
  const [actualWidth, setAcualWidth] = useState<number>()

  const innerTextPropsStyle = icon ? {marginHorizontal: iconMarginOffsetSizeMap[size]} : undefined

  const elevationContainerProps = {...containerSizeStyle(size)}
  const outerContainerProps: ContainerSizeType = {...elevationContainerProps, width: '100%'}

  const borderRadius = dimensionValueToAnimatableNumericValue(elevationContainerProps.height)

  const containerProps: ViewStyle = {
    ...containerModeStyle(mode, disabled, inState),
    justifyContent: 'center',
    borderRadius,
    paddingHorizontal: 16,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  }

  if (width) elevationContainerProps.width = width

  const textProps: PFTextProps = {
    ...textSizeStyle(size),
    ...textModeStyle(mode, disabled),
    textAlign: 'center',
    textProps: {numberOfLines: 1, style: innerTextPropsStyle},
  }
  const focusBorderStyle = focusBorderMap[mode]

  //When the element is focused a larger border is shown. React Native has it's borders go inward. We need one
  //to go out. Because the border needs to go out, we needed a margin on the main container so the border
  //doesn't cause the button to shift around when focused.
  const focusBorder =
    focusBorderStyle && inState === 'focus' ? (
      <View
        style={{
          borderRadius: (containerProps.borderRadius as number) + buttonFocusBorderDistance * 2,
          position: 'absolute',
          left: buttonFocusBorderDistance * -1, //remove left and top if having the margin reserved
          top: buttonFocusBorderDistance * -1,
          bottom: buttonFocusBorderDistance * -1,
          right: buttonFocusBorderDistance * -1,
          borderWidth: buttonFocusBorderWidth,
          ...focusBorderStyle,
        }}
      />
    ) : undefined

  let iconElement = icon ? (
    <ButtonIcon
      center={children === undefined}
      icon={icon}
      iconSize={sizeToIconSize[size]}
      buttonSize={size}
      color={textProps.color}
    />
  ) : undefined

  const progressBar =
    progress && actualWidth ? (
      <View
        style={{
          position: 'absolute',
          backgroundColor: progressBarButtonBackground,
          right: 0,
          height: '100%',
          width: actualWidth * ((100 - progress) / 100),
        }}
      />
    ) : undefined

  if ((loading && !progress) || (loading && progress !== undefined && progress < 100)) {
    iconElement = (
      <ButtonLoadingIcon
        center={children === undefined}
        loading={loading}
        size={size}
        color={textProps.color}
      />
    )
  }

  const onPressIn = useCallback(() => {
    setInState('focus')
  }, [])

  const onPressOut = useCallback(() => {
    setInState('normal')
  }, [])

  const handlePress = useCallback(() => {
    onPress?.()
    setInState('normal')
  }, [onPress])

  let elevationLevel: LevelVariants = 2
  if (inState === 'focus') {
    elevationLevel = 1
  }
  if (disabled) {
    elevationLevel = 0
  }

  const onlayout = useCallback((event: LayoutChangeEvent) => {
    setAcualWidth(event.nativeEvent.layout.width)
  }, [])
  return (
    <PFElevation
      level={elevationLevel}
      backgroundColor={transparent}
      borderRadius={dimensionValueToAnimatableNumericValue(elevationContainerProps.height)}
      style={{
        width: elevationContainerProps.width,
        maxWidth: elevationContainerProps.maxWidth,
        height: elevationContainerProps.height,
      }}
      showOverflow
      otherProps={{accessible: false, testID: 'PFElevation'}}
    >
      <TouchableWithoutFeedback
        accessible={true}
        aria-busy={!!loading}
        aria-disabled={disabled}
        accessibilityRole={'button'}
        {...accessibilityProps}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        onPress={handlePress}
        disabled={disabled}
        testID={testID}
      >
        <View style={outerContainerProps}>
          {focusBorder}
          <View style={containerProps} onLayout={onlayout}>
            {progressBar}
            {iconElement}
            {children ? <PFText {...textProps}>{children}</PFText> : undefined}
          </View>
        </View>
      </TouchableWithoutFeedback>
    </PFElevation>
  )
}

export default withDoubleClickPrevention(Button)
