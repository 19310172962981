import {StackScreenProps} from '@react-navigation/stack'

import {
  DefaultPaymentMethodCode,
  LoanCustomPaymentIntention,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {getPaymentMethodTypeForAnalytics} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {
  LoanPayConfirmMakeCustomPaymentMutation,
  LoanPayConfirmMakeCustomPaymentMutationVariables,
} from 'src/products/loans/MakePayment/LoanPayConfirm/LoanPayConfirm.gqls'
import {
  LoanPayConfirmAch,
  LoanPayConfirmPaymentMethod,
  LoanPayConfirmDebitCard,
  LoanPayConfirmEligiblePaymentMethod,
} from 'src/products/loans/MakePayment/LoanPayConfirm/LoanPayConfirm.types'
import {LoanPayNavigatorStack} from 'src/products/loans/MakePayment/LoanPayNavigatorStack'
import {loanMakePaymentLogError} from 'src/products/loans/MakePayment/LoanPay.utils'

export const trackChangePaymentMethodCompleted = (args: {
  /**
   * The new payment method to track. We only care about __typename, or for AchPaymentMethod whether or not it has a LinkedAccount
   * */
  newPaymentMethod:
    | (Pick<LoanPayConfirmAch, '__typename'> & {
        account?: null | {
          id?: null | NonNullable<LoanPayConfirmAch['account']>['id']
        }
      })
    | Pick<LoanPayConfirmDebitCard, '__typename'>
}): void => {
  const {newPaymentMethod} = args
  if (newPaymentMethod.__typename === 'AchPaymentMethod' && !newPaymentMethod.account?.id) {
    TrackAppEvent(
      ManageActiveLoanEvents.loans_adhoc_payment_methods_change_payment_method_completed,
      AppEvents.Category.ManageActiveLoan,
      {
        type: 'add_routing_account',
      },
    )
  } else if (newPaymentMethod.__typename === 'AchPaymentMethod' && newPaymentMethod.account) {
    TrackAppEvent(
      ManageActiveLoanEvents.loans_adhoc_payment_methods_change_payment_method_completed,
      AppEvents.Category.ManageActiveLoan,
      {
        type: 'add_link_with_plaid',
      },
    )
  } else if (newPaymentMethod.__typename === 'DebitCardPaymentMethod') {
    TrackAppEvent(
      ManageActiveLoanEvents.loans_adhoc_payment_methods_change_payment_method_completed,
      AppEvents.Category.ManageActiveLoan,
      {
        type: 'add_debit_card',
      },
    )
  }
}

/**
 * Get the selected payment method. If there is a selected payment instrument ID in route params that will be used,
 * otherwise it will use the default payment method.
 */
export const getSelectedLoanPaymentMethod = (args: {
  selectedPaymentMethodId?: string
  paymentMethods: LoanPayConfirmPaymentMethod[]
}): {
  selectedPaymentMethod: LoanPayConfirmEligiblePaymentMethod
} => {
  const {paymentMethods, selectedPaymentMethodId} = args
  // select a default payment instrument
  let defaultPaymentMethod: LoanPayConfirmEligiblePaymentMethod = undefined
  for (const paymentMethod of paymentMethods) {
    if (
      (paymentMethod.__typename === 'AchPaymentMethod' ||
        paymentMethod.__typename === 'DebitCardPaymentMethod') &&
      paymentMethod.defaultStatus.includes(DefaultPaymentMethodCode.PaymentDefault)
    ) {
      defaultPaymentMethod = paymentMethod
      break
    }
  }
  const selectedPaymentInstrumentId = selectedPaymentMethodId ?? defaultPaymentMethod?.id

  // find the payment method they've selected
  let selectedPaymentMethod: LoanPayConfirmEligiblePaymentMethod = undefined
  if (paymentMethods?.length) {
    for (let i = 0; i < paymentMethods?.length; i++) {
      const paymentMethod = paymentMethods[i]
      if (
        'bankingPaymentInstrumentId' in paymentMethod &&
        paymentMethod.bankingPaymentInstrumentId === selectedPaymentInstrumentId
      ) {
        selectedPaymentMethod = paymentMethod
        break
      }
    }
  }
  return {
    selectedPaymentMethod,
  }
}

/**
 * Send user to the "Payment methods" screen when they tap on their payment method.
 */
export const goToPaymentMethods = (args: {
  navigation: StackScreenProps<LoanPayNavigatorStack, 'LoanPayConfirm'>['navigation']
}): void => {
  const {navigation} = args
  TrackAppEvent(
    ManageActiveLoanEvents.loans_adhoc_payment_review_view_payment_methods,
    AppEvents.Category.ManageActiveLoan,
  )

  navigation.navigate('LoanPayMethods')
}

/**
 * Submits the loan payment and navigates to the success or failure screen depending on the result.
 */
export const submitLoanPaymentAndNavigate = async (args: {
  loanId: string
  paymentInstrumentId: string
  paymentOptionIntention: LoanCustomPaymentIntention
  paymentMethod: NonNullable<LoanPayConfirmEligiblePaymentMethod>
  submitPayment: ReturnType<
    typeof useCassandraMutation<
      LoanPayConfirmMakeCustomPaymentMutation,
      LoanPayConfirmMakeCustomPaymentMutationVariables
    >
  >[0]
  navigation: StackScreenProps<LoanPayNavigatorStack, 'LoanPayConfirm'>['navigation']
}): Promise<void> => {
  const {
    loanId,
    paymentInstrumentId,
    paymentOptionIntention,
    paymentMethod,
    submitPayment,
    navigation,
  } = args

  const variables: LoanPayConfirmMakeCustomPaymentMutationVariables = {
    customPaymentInput: {
      loanId,
      instrumentId: paymentInstrumentId,
      type: paymentOptionIntention,
    },
  }
  const paymentMethodTypeForAnalytics = getPaymentMethodTypeForAnalytics(paymentMethod)
  try {
    const result = await submitPayment({variables})

    TrackAppEvent(
      ManageActiveLoanEvents.loans_adhoc_payment_review_cta,
      AppEvents.Category.ManageActiveLoan,
      {
        payment_method_type: paymentMethodTypeForAnalytics,
        adhoc_payment_type: paymentOptionIntention,
      },
    )
    if (
      result.errors === undefined ||
      result.errors === null ||
      result.errors.length === 0 ||
      isNaN(result.errors.length)
    ) {
      if (!result.data?.loanMakeCustomPayment) {
        throw new Error('No data returned from loanMakeCustomPayment mutation')
      }
      navigation.navigate('LoanPaySuccess', {
        paymentMethodTypeForAnalytics,
        paymentAmount: result.data.loanMakeCustomPayment.amount,
        initiationDate: result.data.loanMakeCustomPayment.initiationDate,
      })
    } else {
      throw new Error(result.errors[0].message)
    }
  } catch (e) {
    let errorToLog: Error
    if (e instanceof Error) {
      errorToLog = e
    } else {
      try {
        // if this is a GraphQLError it comes as an object and we want to log the message and extensions
        errorToLog = new Error(JSON.stringify(e))
      } catch (e) {
        errorToLog = new Error(String(e))
      }
    }

    loanMakePaymentLogError(errorToLog, 'failed to submit custom payment option')
    navigation.navigate('LoanPayFailure', {
      paymentMethodTypeForAnalytics,
    })
  }
}
