import React, {FC} from 'react'

import {LoanAmountSelectionGQLContainer} from 'src/products/loans/LoanAmountSelection/LoanAmountSelectionGQLContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export type LoanAmountSelectionWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container to use the LoanAmountSelection screen within Workflows.
 * @param props
 * @returns
 */

const LoanAmountSelectionWorkflowContainer: FC<LoanAmountSelectionWorkflowContainerProps> = (
  props,
) => {
  const {onPreReqFulfilled: handleOnPreReqFulfilled} = props

  return <LoanAmountSelectionGQLContainer onPreReqFulfilled={handleOnPreReqFulfilled} />
}

export {LoanAmountSelectionWorkflowContainer}
