import {Consumer} from '@possible/cassandra'
import React, {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Trans, useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'
import {isEqual} from 'lodash'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {Loading} from 'src/designSystem/components/atoms/Loading/Loading'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  AddressForm,
  RequiredAddressFields,
} from 'src/designSystem/components/organisms/AddressForm/AddressForm'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {isStringValueEmpty} from 'src/designSystem/lib/dataUtil'
import {UpdateAddressMethod} from 'src/products/MCU/Address/address.utils'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {getHasErrorsOrMissingValues} from 'src/lib/utils/formValidationUtil'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {openContactUsForm} from 'src/lib/contactUs'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import Log from 'src/lib/loggingUtil'

export type AddressCompletionMethod = (
  address: Consumer.types.Address,
  userState: string,
) => Promise<void> | void

type Props = {
  onComplete: AddressCompletionMethod
  updateAddress: UpdateAddressMethod
  selectedState?: string | null
  isForReviewAndEdit?: boolean
  onError?: () => void
  isAppRevampFunctionalUpdatesEnabled?: boolean
}

const AddressCollectionTemplate: FC<Props> = (props) => {
  const {
    onComplete,
    updateAddress,
    selectedState,
    isForReviewAndEdit = false,
    onError,
    isAppRevampFunctionalUpdatesEnabled = false,
  } = props
  const navigation: StackNavigationProp<MainStackParamList> = useNavigation()

  const {t} = useTranslation(['PersonalInformation', 'Common'])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const {data: meData, loading: isMeDataLoading} = Consumer.hooks.useMeAddressQuery()
  const existingAddress = meData?.me.profile?.home?.address

  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    reset,
  } = useForm<Consumer.types.ApplicantPhysicalAddressComponentsInput>({
    mode: 'all',
    defaultValues: Consumer.adapters.AddressToAddressComponents({
      ...existingAddress,
      verified: existingAddress?.verified ?? false,
      state: existingAddress?.state ?? selectedState,
    }),
  })

  usePageViewedAnalytics({
    eventName: AppEvents.Name.personal_details_address_screen_viewed,
    eventCategory: AppEvents.Category.Application,
  })

  // if the me profile query finishes loading late we want to reset
  // the address form's default values
  useEffect(() => {
    if (existingAddress) {
      reset(
        Consumer.adapters.AddressToAddressComponents({
          ...existingAddress,
        }),
      )
    }
  }, [reset, existingAddress])

  /* This is a temporary solution until Product has a look at this flow again.
   * Right now the server returns address_verified= true and no error if it finds an address
   * close to the one entered. We aren't letting the user know that we found a different address.
   * This will use the server data to fix the state and zip code and force the user to submit again
   * We should probably tell the user what's going on here.
   */
  const fixAddressWithApiResponse = (
    address: Consumer.types.ApplicantPhysicalAddressComponentsInput,
    addressFromApi?: Consumer.types.Maybe<Consumer.types.Address>,
  ): boolean => {
    let isFixed = false

    if (!addressFromApi) {
      return false
    }

    if (!isStringValueEmpty(addressFromApi?.state) && address.state !== addressFromApi.state) {
      setValue('state', addressFromApi.state ?? '')
      isFixed = true
    }

    if (!!addressFromApi.postalCode?.trim() && address.postalCode !== addressFromApi.postalCode) {
      setValue('postalCode', addressFromApi.postalCode)
      isFixed = true
    }
    return isFixed
  }

  const onSubmit = async (
    data: Consumer.types.ApplicantPhysicalAddressComponentsInput,
  ): Promise<void> => {
    TrackAppEvent(
      AppEvents.Name.personal_details_address_screen_completed,
      AppEvents.Category.Application,
    )

    setIsSubmitting(true)

    try {
      const resAddress = await updateAddress(data)
      const userState = resAddress.state
      if (!userState) {
        throw new Error('We are unable to verify your address.')
      }

      const didChangeAddress = fixAddressWithApiResponse(data, resAddress)

      // Track when SmartyStreet auto-corrects an address -- ENG-22845 for context
      const didAddressChangeToOriginal = isEqual(existingAddress, resAddress)
      if (didChangeAddress && !didAddressChangeToOriginal) {
        Log.warn('AddressCollectionTemplate - SmartyStreet changed address')
      }

      if (!resAddress.verified || didChangeAddress) {
        throw new Error()
      }

      await onComplete(resAddress, userState)
    } catch {
      onError?.()
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isMeDataLoading) {
    return <Loading type="loader0" size="large" />
  }

  const handleOnNavigateToContactUs = (): void => {
    openContactUsForm(navigation)
  }

  const isDisabled =
    getHasErrorsOrMissingValues(errors, watch, RequiredAddressFields) || isSubmitting

  return (
    <Page
      title={isForReviewAndEdit ? t('EditYourLocation') : t('YourLocation')}
      variant={'generic'}
      smallTopGap={true}
      testID={'Address-Collection-Screen'}
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: isForReviewAndEdit ? t('UpdateAddress') : t('Common:Next'),
          onPress: handleSubmit(onSubmit),
          disabled: isDisabled,
          loading: isSubmitting,
          testID: 'address-collection-submit-button',
        },
      }}
    >
      <Box direction="column" justify="between" fill="vertical">
        <Box>
          {isForReviewAndEdit ? (
            <Box marginBottom={'medium'}>
              <PFText variant="p_sm">{t('NoteUpdateState')}</PFText>
            </Box>
          ) : null}
          <AddressForm
            control={control}
            errors={errors}
            hasSelectedState={selectedState ? true : false}
            /**
             * Prevent a user that signed up from a non coastal state from changing to a costal state at loan submission.
             * By default, Non-Coastal allows Layer to pre-populate the user's information, but if they change to a costal state
             * we want to prevent that change from being accepted.
             */
            canUpdateState={false}
          />
          <Box gap={'small'} direction={'row'} margin={'tiny'}>
            <Box align={'center'} flex={1} paddingTop={6}>
              <SvgIcon name={'info'} colorVariant={'success'} size={'large'} />
            </Box>
            <Box flex={9}>
              <PFText variant="p_sm">
                <Trans
                  t={t}
                  i18nKey={
                    isAppRevampFunctionalUpdatesEnabled
                      ? 'PersonalInformation:YourLocationBottom'
                      : 'PersonalInformation:YourLocationBottomWithState'
                  }
                  components={{
                    bold: <PFText variant="p_sm_semibold" />,
                    blue: (
                      <SvgLink
                        onPress={handleOnNavigateToContactUs}
                        linkType={'inline'}
                        linkText={t('Common:ContactUsLowercase')}
                        textVariant="p_sm_semibold"
                      />
                    ),
                  }}
                />
              </PFText>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  )
}

export {AddressCollectionTemplate}
