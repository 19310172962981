import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {merge} from 'lodash'
import {Optional} from 'utility-types'

import {getUserProperty, setUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'
import {ShowException} from 'src/lib/errors'
import {userIdSelector} from 'src/lib/user/selector'
import {PfReduxState} from 'src/reducers/types'
import {OneTimeOnlyFrontEndPreReqs} from 'src/workflows/constants'
import {wfLog, wfWarn} from 'src/workflows/logging'
import {FrontEndPreReqType, WorkflowsState, WorkflowsUserMiscPropPayload} from 'src/workflows/types'
import {CLEAR_USER_ACTION} from 'src/api/actions/actionsNames'

const initialState: WorkflowsState = {
  fetched: false,
  metOneTimeFrontEndPreReqs: [],
  selectedOffer: undefined,
}

/**
 * Fetch the workflow state from the backend and update the redux store.
 * This should be called when the app starts up to ensure the user's
 * workflow state is up to date. If called again, it will only return
 * local state.
 */
export const FetchWorkflowStateAction = createAsyncThunk<
  WorkflowsState,
  void,
  {state: PfReduxState}
>('Workflows/Fetch', async (_, {getState}) => {
  const state = getState()
  if (state.workflows.fetched) {
    return state.workflows
  }

  const userId = userIdSelector(state)
  if (!userId) {
    wfLog('UserMiscProp Fetch. No user ID found. Returning initial state')
    return initialState
  }

  const result = await getUserProperty('workflowState')
  if (result.error) {
    throw result.error
  }

  return {
    fetched: true,
    ...result.value,
    metOneTimeFrontEndPreReqs: result.value?.metOneTimeFrontEndPreReqs ?? [],
  }
})

/**
 * Update the workflow state in redux and on the backend.
 * This is a relatively high risk operation as it can affect
 * the user's ability to apply for products.
 * So we should be careful when using this action.
 */
export const SetWorkflowStateAction = createAsyncThunk<
  WorkflowsState,
  DeepPartial<WorkflowsUserMiscPropPayload>,
  {state: PfReduxState}
>('Workflows/Set', async (wfPayload, {getState}) => {
  const state = getState()

  const miscPropPayload: Optional<WorkflowsState, 'fetched'> = merge({}, state.workflows, wfPayload)
  delete miscPropPayload.fetched

  try {
    await setUserProperty('workflowState', miscPropPayload)
    return merge({}, state.workflows, wfPayload)
  } catch (e) {
    wfWarn('SetWorkflowStateAction failed!', e)
    throw e
  }
})

/**
 * Clear the workflow state in redux and on the backend.
 * This clears all workflow state (selected offer, met pre-reqs, etc.)
 * EXCEPT for the one-time front-end pre-reqs as the user should only
 * have to meet those once.
 * This should be called before starting any new product application
 * except the initial one.
 */
export const ClearWorkflowStateAction = createAsyncThunk<
  WorkflowsState,
  void,
  {state: PfReduxState}
>('Workflows/Clear', async (_, {getState}) => {
  const state = getState()
  const userId = userIdSelector(state)

  if (userId) {
    await setUserProperty('workflowState', {
      metOneTimeFrontEndPreReqs: state.workflows.metOneTimeFrontEndPreReqs,
    })
  }

  return {...initialState, metOneTimeFrontEndPreReqs: state.workflows.metOneTimeFrontEndPreReqs}
})

/**
 * Complete a single front-end pre-req for the selected offer.
 * @param preReq The pre-req to mark complete
 * @returns The updated workflow state
 */
export const CompleteFrontEndPreReqAction = createAsyncThunk<
  WorkflowsState,
  FrontEndPreReqType,
  {state: PfReduxState}
>('Workflows/CompleteFrontEndPreReq', async (preReq, {getState, dispatch}) => {
  const state = getState()
  const selectedOffer = state.workflows.selectedOffer

  if (OneTimeOnlyFrontEndPreReqs.includes(preReq)) {
    if (state.workflows.metOneTimeFrontEndPreReqs.includes(preReq)) {
      return state.workflows
    }

    const updatedOneTimePreReqs = [...state.workflows.metOneTimeFrontEndPreReqs, preReq]
    await dispatch(SetWorkflowStateAction({metOneTimeFrontEndPreReqs: updatedOneTimePreReqs}))
    return getState().workflows
  } else {
    if (!selectedOffer) {
      throw new Error('No selected offer')
    }
    const metPreReqs = selectedOffer.metFrontEndPreReqs
    if (metPreReqs.includes(preReq)) {
      return state.workflows
    }

    const updatedPreReqs = [...metPreReqs, preReq]
    const updatedOffer = {...selectedOffer, metFrontEndPreReqs: updatedPreReqs}

    await dispatch(SetWorkflowStateAction({selectedOffer: updatedOffer}))
    return getState().workflows
  }
})

/**
 * Clear the selected offer from the workflow state.
 * This should be called when the user has completed an application
 * or backed out of an application
 */
export const ClearSelectedOfferAction = createAsyncThunk<
  WorkflowsState,
  void,
  {state: PfReduxState}
>('Workflows/ClearSelectedOffer', async (_, {getState}) => {
  const state = getState()
  const selectedOffer = state.workflows.selectedOffer
  if (!selectedOffer) {
    return state.workflows
  }

  const updatedState = merge({}, state.workflows)
  delete updatedState.selectedOffer

  try {
    await setUserProperty('workflowState', updatedState)
    return updatedState
  } catch (e) {
    wfWarn('ClearSelectedOffer failed!', e)
    throw e
  }
})

const WorkflowsSlice = createSlice({
  name: 'Workflows',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(CLEAR_USER_ACTION, (state) => {
      Object.assign(state, initialState)
    })
    builder.addCase(FetchWorkflowStateAction.pending, () => {
      wfLog('Fetching state...')
    })
    builder.addCase(FetchWorkflowStateAction.fulfilled, (state, action) => {
      Object.assign(state, action.payload)
      wfLog(`Fetch complete. New state: ${JSON.stringify(state)}`)
    })
    builder.addCase(FetchWorkflowStateAction.rejected, (state, action) => {
      wfLog(`Unable to fetch state. Error: ${action.error.message}`)
    })

    builder.addCase(SetWorkflowStateAction.pending, (_state, action) => {
      wfLog(`Updating state to: ${JSON.stringify(action.meta.arg)}...`)
    })
    builder.addCase(SetWorkflowStateAction.fulfilled, (state, action) => {
      Object.assign(state, action.payload)
      wfLog('Update complete')
    })
    builder.addCase(SetWorkflowStateAction.rejected, (_state, action) => {
      wfWarn('Unable to update state')
      ShowException(action.error)
    })

    builder.addCase(ClearWorkflowStateAction.pending, () => {
      wfLog('Clearing state...')
    })
    builder.addCase(ClearWorkflowStateAction.fulfilled, (state, action) => {
      Object.assign(state, action.payload)
      wfLog('State cleared')
    })
    builder.addCase(ClearWorkflowStateAction.rejected, (state, action) => {
      // this is a warn instead of a log as failing to clear
      // state _could_ lead to trying to apply for an
      // ineligible product in the future
      Object.assign(state, initialState)
      wfWarn('Unable to clear state')
      ShowException(action.error)
    })

    builder.addCase(ClearSelectedOfferAction.pending, () => {
      wfLog('Clearing selected offer...')
    })
    builder.addCase(ClearSelectedOfferAction.fulfilled, (state, action) => {
      wfLog('Selected offer cleared')
      return action.payload
    })
    builder.addCase(ClearSelectedOfferAction.rejected, (_state, action) => {
      wfWarn('Unable to clear selected offer')
      ShowException(action.error)
    })

    builder.addCase(CompleteFrontEndPreReqAction.pending, (_state, action) => {
      wfLog(`Completing front-end pre-req: ${action.meta.arg}`)
    })
    builder.addCase(CompleteFrontEndPreReqAction.fulfilled, (state, action) => {
      Object.assign(state, action.payload)
      wfLog('Pre-req complete')
    })
    builder.addCase(CompleteFrontEndPreReqAction.rejected, (_state, action) => {
      wfWarn('Unable to complete pre-req')
      ShowException(action.error)
    })
  },
})

export const {actions} = WorkflowsSlice
export const {reducer} = WorkflowsSlice

export const workflowsStateFetchedSelector = (state: PfReduxState): boolean =>
  state.workflows.fetched
export const workflowsSelectedOfferSelector = (
  state: PfReduxState,
): WorkflowsState['selectedOffer'] => {
  return state.workflows.selectedOffer
}
export const workflowsSelectedOfferIdSelector = (state: PfReduxState): string | undefined =>
  state.workflows.selectedOffer?.offerId ?? undefined
export const workflowsMetOneTimeFrontEndPreReqsSelector = (
  state: PfReduxState,
): FrontEndPreReqType[] => {
  return state.workflows.metOneTimeFrontEndPreReqs
}

export const workflowsSelectedOfferMetFrontEndPreReqsSelector = (
  state: PfReduxState,
): FrontEndPreReqType[] => {
  return state.workflows.selectedOffer?.metFrontEndPreReqs ?? []
}
