import React from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {ProgressBar} from 'src/designSystem/components/molecules/ProgressBar/ProgressBar'
import {
  LedgerItem,
  LedgerItemProps,
} from 'src/products/card/components/molecules/LedgerItem/LedgerItem'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'

export type PayOverTimeHeaderTileProps = {
  onPressPendingPayment: () => void
  pendingAmount: string
  remainingAmount: string
  totalDue: string
  totalOverdueAmount: string
  totalPaid: string
}

const PayOverTimeHeaderTile = ({
  onPressPendingPayment,
  pendingAmount,
  remainingAmount,
  totalDue,
  totalOverdueAmount,
  totalPaid,
}: PayOverTimeHeaderTileProps): JSX.Element => {
  const {t} = useTranslation('CardPayOverTimeScheduleTemplate')

  const ledgerItems: LedgerItemProps[] = [
    {
      title: t('Paid'),
      amount: convertToDollarAmt(totalPaid),
      titleAndIconColorVariant: 'default',
      amountColor: NamedColors.BLACK,
    },
    {
      title: t('paymentPending'),
      onPress: onPressPendingPayment,
      amount: convertToDollarAmt(Number(pendingAmount) * -1),
    },
    {
      title: t('amountRemaining'),
      amount: convertToDollarAmt(remainingAmount),
      titleAndIconColorVariant: 'default',
      amountColor: NamedColors.BLACK,
    },
  ]

  return (
    <Box {...boxProps} testID={'PayOverTimeHeaderTile'}>
      <Box padding={'small'} align="center">
        <PFText variant="p_sm">
          <PFText variant="label_lg">{convertToDollarAmt(totalPaid, true)}</PFText> paid of{' '}
          {convertToDollarAmt(totalDue, true)}
        </PFText>
      </Box>
      <Box paddingHorizontal={'small'}>
        <ProgressBar
          amountSuccess={Number(totalPaid) / Number(totalDue)}
          amountInProgress={Number(pendingAmount) / Number(totalDue)}
          amountWarning={Number(totalOverdueAmount) / Number(totalDue)}
          numberOfDelimiters={3}
        />
        {ledgerItems
          .filter((item) => item.amount !== '$0.00')
          .map(
            ({title, ...props}): JSX.Element => (
              <LedgerItem
                title={title}
                key={title}
                titleTextVariant={'p'}
                amountVariant={'p_semibold'}
                testIDPrefix={`PayOverTimeHeaderTile-${title}`}
                {...props}
              />
            ),
          )}
      </Box>
    </Box>
  )
}
const boxProps: BoxProps = {
  elevation: 4,
  radius: 'little',
  background: 'white',
  padding: 'small',
  boxStyle: {overflow: 'hidden'},
  gap: 'none',
}

export {PayOverTimeHeaderTile}
