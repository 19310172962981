import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'

import {GetMeAction} from '@possible/cassandra'

import AddBankLinkTemplate, {
  AddBankLinkTemplateProps,
} from 'src/products/general/AddBankLink/AddBankLinkTemplate'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {badConnectionSelector} from 'src/api/selectors/selectors'
import {ContextualizedLogException, ShowException} from 'src/lib/errors'
import {callbackStatus} from 'src/products/general/GeneralPaymentMethods/types'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {
  AddBankLinkedAccountSubset,
  OnContinueAfterAddBankLinkComplete,
} from 'src/products/general/AddBankLink/AddBankLink.types'
import AggregatorPlaidOAuthRelink, {
  OnOAuthRelinkComplete,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaidOAuthRelink'
import {
  logOfferApplicationError,
  logOfferApplicationErrorAndShowException,
} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {useBankAggregator} from 'src/products/general/GeneralPaymentMethods/useBankAggregator'

import {useCassandraLazyQuery} from '@possible/cassandra/src/utils/hooks'
import {filterLinkedAccountsInUse} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'
import {BankAccountsDocument} from 'src/products/general/AddBankLink/queries/BankAccounts.gqls'
import {BankAccountsQuery} from '@possible/cassandra/src/types/types.mobile.generated'

export type AddBankLinkGQLContainerProps = Pick<
  AddBankLinkTemplateProps,
  'pageTitle' | 'onCheckBankSupport'
> & {
  navigation: StackScreenProps<MainStackParamList>['navigation']
  onContinueAfterBankLinkComplete: OnContinueAfterAddBankLinkComplete
}

const linkedAccountsSelector = (
  data: BankAccountsQuery,
): BankAccountsQuery['me']['bankAccounts']['all'] | undefined => {
  if (!data?.me?.bankAccounts?.all) {
    return undefined
  }
  return filterLinkedAccountsInUse<AddBankLinkedAccountSubset>(data?.me?.bankAccounts?.all)
}

/**
 * Container for the AddBankLink screen that launches banking aggregatorgets and saves any data using GQL APIs.
 */
const AddBankLinkGQLContainer: React.FC<AddBankLinkGQLContainerProps> = (
  props: AddBankLinkGQLContainerProps,
) => {
  const {
    navigation,
    pageTitle,
    onContinueAfterBankLinkComplete,
    onCheckBankSupport: handleOnCheckBankSupport,
  } = props

  const {t} = useTranslation('AddBankLink')
  const {openBankAggregator} = useBankAggregator(navigation)

  const [fetchLinkedAccounts] = useCassandraLazyQuery(
    BankAccountsDocument,
    {},
    linkedAccountsSelector,
  )
  const dispatch = usePfDispatch()
  const hasBadConnection = usePfSelector(badConnectionSelector)

  const [bankNameIfUnsupported, setBankNameIfUnsupported] = useState<string | undefined>(undefined)
  const [isOkButtonLoading, setIsOkButtonLoading] = useState<boolean>(false)

  /**
   * Launch the banking aggregator to let the user link their bank account.
   */
  const handleOnAddBank: AddBankLinkTemplateProps['onAddBank'] = async (): Promise<void> => {
    if (hasBadConnection) {
      logOfferApplicationError(
        new Error('Error while trying to add a bank on AddBankLink'),
        'Error while trying to add a bank',
      )
      ShowException(t('NetworkRequestFailed'))
    } else {
      await openBankAggregator({
        onBankLinkComplete: async ({bankName, linkedAccounts}) => {
          await onDoneWithBankingAggregator(callbackStatus.SUCCESS, bankName, linkedAccounts)
        },
      })
    }
  }

  /**
   * Handle when the user is done with the banking aggregator.
   */
  const onDoneWithBankingAggregator = useCallback(
    async (
      status: string,
      bankName: string,
      linkedAccounts?: AddBankLinkedAccountSubset[],
    ): Promise<void> => {
      if (status === callbackStatus.UNSUPPORTED_BANK) {
        setBankNameIfUnsupported(bankName)
      } else if (status === callbackStatus.EXITED_SUCCESS) {
        setIsOkButtonLoading(true)
        setBankNameIfUnsupported(undefined)
      } else {
        setIsOkButtonLoading(true)
        setBankNameIfUnsupported(undefined)
        try {
          const accounts = linkedAccounts ?? (await fetchLinkedAccounts())?.selectedData

          if (accounts) {
            await dispatch(GetMeAction())
            await onContinueAfterBankLinkComplete({bankName, accounts})
          }
        } catch (e) {
          logOfferApplicationErrorAndShowException(
            e,
            'AddBankLink onDoneWithBankingAggregator bankAggregatorCallback failed to confirm bank account linking',
          )
        } finally {
          setIsOkButtonLoading(false)
        }
      }
    },
    [dispatch, fetchLinkedAccounts, onContinueAfterBankLinkComplete],
  )

  const handleRelinkComplete = useCallback<OnOAuthRelinkComplete>(
    (institutionName) => {
      onDoneWithBankingAggregator(callbackStatus.SUCCESS, institutionName).catch(
        ContextualizedLogException('Failed to complete bank relink'),
      )
    },
    [onDoneWithBankingAggregator],
  )

  const handleRelinkProcessing = useCallback(() => {
    setIsOkButtonLoading(true)
  }, [])

  return (
    <>
      <AggregatorPlaidOAuthRelink
        onRelinkComplete={handleRelinkComplete}
        onRelinkProcessing={handleRelinkProcessing}
      />
      <AddBankLinkTemplate
        pageTitle={pageTitle}
        isOkButtonLoading={isOkButtonLoading}
        onAddBank={handleOnAddBank}
        onCheckBankSupport={handleOnCheckBankSupport}
        bankNameIfUnsupported={bankNameIfUnsupported ?? undefined}
      />
    </>
  )
}

export {AddBankLinkGQLContainer}
