import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from 'react-navigation-props-mapper'
import {LogException, ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {
  OnBankSelectedForPrimaryAccountSelection,
  OnContinueAfterPrimaryAccountSelected,
  OnPrimaryAccountSelectionSavingFailed,
} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelection.types'
import {
  trackBankAccountSelectedBeforeSave,
  trackContinueCTAButtonTapped,
} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelection.utils'
import {PrimaryAccountSelectionGQLContainer} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelectionGQLContainer'

export type PrimaryAccountSelectionLegacyContainerProps = ForwardedStackScreenProps<
  MainStackParamList,
  'PrimaryAccountSelection'
>

/**
 * Container to integrate the PrimaryAccountSelection screen into navigation when using
 * navigation.navigate() to this screen. Calls route params onContinue() when done.
 */
const PrimaryAccountSelectionRouteContainer: React.FC<
  PrimaryAccountSelectionLegacyContainerProps
> = (props: PrimaryAccountSelectionLegacyContainerProps) => {
  const {navigation, onContinue, isFromOnboarding = false} = props
  const {t} = useTranslation(['AccountManagement', 'Common'])

  const handleOnBankAccountSelected: OnBankSelectedForPrimaryAccountSelection = () => {
    trackBankAccountSelectedBeforeSave({isFromOnboarding})
  }
  const handleOnContinueButtonTapped: OnContinueAfterPrimaryAccountSelected = (): void => {
    trackContinueCTAButtonTapped({isFromOnboarding})
  }

  const handleOnContinueFromRouteParams: OnContinueAfterPrimaryAccountSelected = (args: {
    accountId: string
    accountMask?: string
  }): void => {
    const {accountId, accountMask} = args
    try {
      Log.debug('Successfully set bank account as primary in legacy container')
      onContinue(accountId, accountMask ?? '')
    } catch (e) {
      LogException(e, 'Error setting bank account as primary in legacy container')
      ShowException(t('ErrorSettingPrimaryAccount'))
    }
  }

  const handleOnSavingPrimaryAccountFailed: OnPrimaryAccountSelectionSavingFailed = (e: Error) => {
    LogException(e, 'Error setting bank account as primary when not in onboarding')
    ShowException(t('ErrorSettingPrimaryAccount'))
  }

  return (
    <PrimaryAccountSelectionGQLContainer
      navigation={navigation}
      onBankAccountSelected={handleOnBankAccountSelected}
      onContinueAfterPrimaryAccountSelected={handleOnContinueButtonTapped}
      onContinueAfterPrimaryAccountSelectionSaved={handleOnContinueFromRouteParams}
      onSavingPrimaryAccountFailed={handleOnSavingPrimaryAccountFailed}
      isFromOnboarding={isFromOnboarding}
    />
  )
}

const wrappedContainer =
  withForwardedNavigationParams<PrimaryAccountSelectionLegacyContainerProps>()(
    PrimaryAccountSelectionRouteContainer,
  )
export {wrappedContainer as PrimaryAccountSelectionRouteContainer}
