import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>}
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never}
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string}
  String: {input: string; output: string}
  Boolean: {input: boolean; output: boolean}
  Int: {input: number; output: number}
  Float: {input: number; output: number}
  /**
   * A base64url binary encoded string (see: RFC 4648, https://datatracker.ietf.org/doc/html/rfc4648#section-5).
   *
   * Example value: "SSdtIGEgbGl0dGxlIHRlYXBvdA=="
   */
  Base64Binary: {input: string; output: string}
  /**
   * A zoned date with no time information.
   *
   * Example value: "1983-10-01"
   */
  Date: {input: string; output: string}
  /**
   * A date with zoned date time information.
   *
   * Example value: "1983-10-01T23:59:00.000-10:00[Pacific/Honolulu]"
   */
  DateTime: {input: string; output: string}
  /**
   * An arbitrary precision signed decimal number. This value is serialized as a string to prevent rounding errors.
   *
   * Example value: "29.99"
   */
  Decimal: {input: string; output: string}
  /**
   * A local date with no time information.
   *
   * Example value: "1983-10-01"
   */
  LocalDate: {input: string; output: string}
  /**
   * A UUID
   *
   * Example value: "00000fed-ac32-9641-0000-000000000000"
   */
  UUID: {input: string; output: string}
  /**
   * A time zone id.
   *
   * Example value: America/Los_Angeles
   */
  ZoneId: {input: string; output: string}
}

/** Medium to deliver the message */
export enum AccountDeliveryMedium {
  Email = 'EMAIL',
  Sms = 'SMS',
  Voice = 'VOICE',
}

/** Type of the user identifier */
export enum AccountUsernameType {
  Phone = 'PHONE',
}

/** User app platform identifier */
export enum AppPlatform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

/** Device details stored in the users_sessions / users_login_history table */
export type DeviceDetailsInput = {
  carrier?: InputMaybe<Scalars['String']['input']>
  clientType?: InputMaybe<Scalars['String']['input']>
  deviceId?: InputMaybe<Scalars['String']['input']>
  deviceManufacturer?: InputMaybe<Scalars['String']['input']>
  operatingSystem?: InputMaybe<Scalars['String']['input']>
}

/** Donkey UI branch information */
export type DonkeyUiBranch = {
  __typename?: 'DonkeyUiBranch'
  /** App deployment version key */
  deploymentKey: Scalars['String']['output']
  /** App deployment version name */
  name: Scalars['String']['output']
}

export type DonkeyUiBranchesInput = {
  /** Limit how many versions can be returned */
  count: Scalars['Int']['input']
  /** User app platform of choice (ios, android, web) */
  platform: AppPlatform
}

/** Response for successful login */
export type LoginResult = {
  __typename?: 'LoginResult'
  /** If set, it means that MFA login is required to continue */
  mfaDeliveryMedium?: Maybe<UserMfaDeliveryMedium>
  /** The token to be used by authenticated requests */
  token?: Maybe<Scalars['String']['output']>
  /** Logged user ID */
  userId?: Maybe<Scalars['UUID']['output']>
}

export type PublicMutation = {
  __typename?: 'PublicMutation'
  /**
   * Selects specific offer from partners.
   * Triggered when user clicks on deep link from a partner website and install/open our app.
   */
  partnerSelectOffer: Scalars['Boolean']['output']
  /** Sends forgot login account notification to the user registered with the given phone number */
  userAccountRecovery: Scalars['Boolean']['output']
  /** Exchanges the temporary token with the login data required to make authenticated calls */
  userExchangeTemporaryToken: LoginResult
  /** Change the user password after receiving the verification code */
  userForgotPasswordReset: LoginResult
  /** Send code when the user tries to recover their password */
  userForgotPasswordSendCode: Scalars['Boolean']['output']
  /** Callback after identity provider (Google, Apple) login */
  userIdentityProviderCallback: LoginResult
  /** User standard login with username and password */
  userLogin: LoginResult
  /** User login with MFA */
  userLoginWithMfa: LoginResult
  /** Allows users to register accounts in Possible Finance */
  userRegister: UserRegisterResponse
  /** Resends login with MFA request for user */
  userResendLoginWithMfa: Scalars['Boolean']['output']
  /** Resets the password using the temporary password sent by agents */
  userResetTemporaryPassword: LoginResult
  /** Validates the verification code for a specific operation type */
  userValidateVerificationCode: UserValidateVerificationCodeResponse
  /** Verifies the user email. Used when the user receives the email "Click to verify your email". */
  userVerifyEmailConfirm: Scalars['Boolean']['output']
}

export type PublicMutationPartnerSelectOfferArgs = {
  offerId: Scalars['UUID']['input']
}

export type PublicMutationUserAccountRecoveryArgs = {
  phoneNumber: Scalars['String']['input']
}

export type PublicMutationUserExchangeTemporaryTokenArgs = {
  input: UserExchangeTempTokenInput
}

export type PublicMutationUserForgotPasswordResetArgs = {
  input: UserForgotPasswordResetInput
}

export type PublicMutationUserForgotPasswordSendCodeArgs = {
  input: UserForgotPasswordSendCodeInput
}

export type PublicMutationUserIdentityProviderCallbackArgs = {
  input: UserIdentityProviderCallbackInput
}

export type PublicMutationUserLoginArgs = {
  input: UserLoginInput
}

export type PublicMutationUserLoginWithMfaArgs = {
  input: UserLoginWithMfaInput
}

export type PublicMutationUserRegisterArgs = {
  input: UserRegisterInput
}

export type PublicMutationUserResendLoginWithMfaArgs = {
  input: UserResendLoginWithMfaInput
}

export type PublicMutationUserResetTemporaryPasswordArgs = {
  input: UserTemporaryPasswordResetInput
}

export type PublicMutationUserValidateVerificationCodeArgs = {
  input: UserValidateVerificationCodeInput
}

export type PublicMutationUserVerifyEmailConfirmArgs = {
  input: UserVerifyEmailInput
}

export type PublicQuery = {
  __typename?: 'PublicQuery'
  getDonkeyUiBranches: Array<Maybe<DonkeyUiBranch>>
  getStates?: Maybe<Array<State>>
  getUserAccountMigrationStatus: Scalars['Boolean']['output']
}

export type PublicQueryGetDonkeyUiBranchesArgs = {
  input: DonkeyUiBranchesInput
}

export type PublicQueryGetUserAccountMigrationStatusArgs = {
  email: Scalars['String']['input']
}

export type State = {
  __typename?: 'State'
  code: StateCode
  name: Scalars['String']['output']
}

export enum StateCode {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

/** Input for temporary token exchange. Used by hand offs between app and web */
export type UserExchangeTempTokenInput = {
  /** Device details for history */
  deviceDetails?: InputMaybe<DeviceDetailsInput>
  /** The token to be exchanged */
  tempToken: Scalars['String']['input']
  /** The applicant's ID */
  userId: Scalars['UUID']['input']
}

/** Input to reset the password after receiving the verification code */
export type UserForgotPasswordResetInput = {
  password: Scalars['String']['input']
  username: Scalars['String']['input']
  usernameType: AccountUsernameType
  verificationCode: Scalars['String']['input']
  verificationCodeType?: InputMaybe<VerificationCodeType>
}

/** Input for forgot password request to get a new token */
export type UserForgotPasswordSendCodeInput = {
  deliveryMedium: AccountDeliveryMedium
  /** A user identifier, usually the phone number */
  username: Scalars['String']['input']
  usernameType: AccountUsernameType
}

/** Input for identity provider callback */
export type UserIdentityProviderCallbackInput = {
  /** Code returned by the identity provider */
  code: Scalars['String']['input']
  /** Device details for history */
  deviceDetails?: InputMaybe<DeviceDetailsInput>
  /** The identity provider */
  provider: UserIdentityProviderCallbackProvider
  /** Log in source */
  source: UserIdentityProviderCallbackSource
}

export enum UserIdentityProviderCallbackProvider {
  Apple = 'APPLE',
  Google = 'GOOGLE',
}

export enum UserIdentityProviderCallbackSource {
  App = 'APP',
}

/** Input for standard username and password login */
export type UserLoginInput = {
  /** Device details for history */
  deviceDetails?: InputMaybe<DeviceDetailsInput>
  /** The user's email address. */
  email: Scalars['String']['input']
  /** The user's password. */
  password: Scalars['String']['input']
}

/** Input for MFA login */
export type UserLoginWithMfaInput = {
  /** Device details for history */
  deviceDetails?: InputMaybe<DeviceDetailsInput>
  /** The user's email address. */
  email: Scalars['String']['input']
  /** The MFA type */
  mfaDeliveryMedium: UserMfaDeliveryMedium
  /** The verification code (one time password) */
  otp: Scalars['String']['input']
  /** The user's password. */
  password: Scalars['String']['input']
}

export enum UserMfaDeliveryMedium {
  Email = 'EMAIL',
  Sms = 'SMS',
  Voice = 'VOICE',
}

/** Input for account registration */
export type UserRegisterInput = {
  /** Device details for history */
  deviceDetails?: InputMaybe<DeviceDetailsInput>
  /** The applicant's email address. */
  email: Scalars['String']['input']
  /** The applicant's first name (optional) */
  firstName?: InputMaybe<Scalars['String']['input']>
  /** The applicant's last name (optional) */
  lastName?: InputMaybe<Scalars['String']['input']>
  /** The applicant's password. */
  password: Scalars['String']['input']
  /** The applicant's state (optional) */
  stateSelected?: InputMaybe<Scalars['String']['input']>
}

/** Response for account registration */
export type UserRegisterResponse = {
  __typename?: 'UserRegisterResponse'
  /** Authentication token to be used in the next requests */
  token: Scalars['String']['output']
  /** Registered user ID */
  userId: Scalars['UUID']['output']
}

/** Input for MFA login */
export type UserResendLoginWithMfaInput = {
  /** The user's email address. */
  email: Scalars['String']['input']
  /** The MFA type */
  mfaDeliveryMedium: UserMfaDeliveryMedium
  /** The user's password. */
  password: Scalars['String']['input']
}

export type UserTemporaryPasswordResetInput = {
  /** Device details for history */
  deviceDetails?: InputMaybe<DeviceDetailsInput>
  /** The user's email address. */
  email: Scalars['String']['input']
  /** The proposed new password */
  proposedPassword: Scalars['String']['input']
  /** The temporary password sent to the user */
  temporaryPassword: Scalars['String']['input']
}

export type UserValidateVerificationCodeInput = {
  username: Scalars['String']['input']
  usernameType: AccountUsernameType
  verificationCode: Scalars['String']['input']
  verificationCodeType?: InputMaybe<VerificationCodeType>
}

/** Response for verification code validation */
export type UserValidateVerificationCodeResponse = {
  __typename?: 'UserValidateVerificationCodeResponse'
  /** User obfustcated email */
  obfuscatedEmail: Scalars['String']['output']
}

export type UserVerifyEmailInput = {
  /** User email address */
  email: Scalars['String']['input']
  /** Verification code sent in the previous step */
  verificationCode: Scalars['String']['input']
}

/** Type of the verification code */
export enum VerificationCodeType {
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export type PartnerSelectOfferMutationVariables = Exact<{
  offerId: Scalars['UUID']['input']
}>

export type PartnerSelectOfferMutation = {
  __typename?: 'PublicMutation'
  partnerSelectOffer: boolean
}

export type UserForgotPasswordSendCodeMutationVariables = Exact<{
  input: UserForgotPasswordSendCodeInput
}>

export type UserForgotPasswordSendCodeMutation = {
  __typename?: 'PublicMutation'
  userForgotPasswordSendCode: boolean
}

export type UserForgotPasswordResetMutationVariables = Exact<{
  input: UserForgotPasswordResetInput
}>

export type UserForgotPasswordResetMutation = {
  __typename?: 'PublicMutation'
  userForgotPasswordReset: {
    __typename?: 'LoginResult'
    userId?: string | null
    token?: string | null
    mfaDeliveryMedium?: UserMfaDeliveryMedium | null
  }
}

export type UserValidateVerificationCodeMutationVariables = Exact<{
  input: UserValidateVerificationCodeInput
}>

export type UserValidateVerificationCodeMutation = {
  __typename?: 'PublicMutation'
  userValidateVerificationCode: {
    __typename?: 'UserValidateVerificationCodeResponse'
    obfuscatedEmail: string
  }
}

export type UserExchangeTemporaryTokenMutationVariables = Exact<{
  input: UserExchangeTempTokenInput
}>

export type UserExchangeTemporaryTokenMutation = {
  __typename?: 'PublicMutation'
  userExchangeTemporaryToken: {
    __typename?: 'LoginResult'
    userId?: string | null
    token?: string | null
    mfaDeliveryMedium?: UserMfaDeliveryMedium | null
  }
}

export type UserLoginMutationVariables = Exact<{
  input: UserLoginInput
}>

export type UserLoginMutation = {
  __typename?: 'PublicMutation'
  userLogin: {
    __typename?: 'LoginResult'
    userId?: string | null
    token?: string | null
    mfaDeliveryMedium?: UserMfaDeliveryMedium | null
  }
}

export type UserRegisterMutationVariables = Exact<{
  input: UserRegisterInput
}>

export type UserRegisterMutation = {
  __typename?: 'PublicMutation'
  userRegister: {__typename?: 'UserRegisterResponse'; token: string; userId: string}
}

export type UserLoginWithMfaMutationVariables = Exact<{
  input: UserLoginWithMfaInput
}>

export type UserLoginWithMfaMutation = {
  __typename?: 'PublicMutation'
  userLoginWithMfa: {
    __typename?: 'LoginResult'
    userId?: string | null
    token?: string | null
    mfaDeliveryMedium?: UserMfaDeliveryMedium | null
  }
}

export type UserVerifyEmailConfirmMutationVariables = Exact<{
  input: UserVerifyEmailInput
}>

export type UserVerifyEmailConfirmMutation = {
  __typename?: 'PublicMutation'
  userVerifyEmailConfirm: boolean
}

export type UserResetTemporaryPasswordMutationVariables = Exact<{
  input: UserTemporaryPasswordResetInput
}>

export type UserResetTemporaryPasswordMutation = {
  __typename?: 'PublicMutation'
  userResetTemporaryPassword: {
    __typename?: 'LoginResult'
    userId?: string | null
    token?: string | null
    mfaDeliveryMedium?: UserMfaDeliveryMedium | null
  }
}

export type UserIdentityProviderCallbackMutationVariables = Exact<{
  input: UserIdentityProviderCallbackInput
}>

export type UserIdentityProviderCallbackMutation = {
  __typename?: 'PublicMutation'
  userIdentityProviderCallback: {
    __typename?: 'LoginResult'
    userId?: string | null
    token?: string | null
    mfaDeliveryMedium?: UserMfaDeliveryMedium | null
  }
}

export type UserResendLoginWithMfaMutationVariables = Exact<{
  input: UserResendLoginWithMfaInput
}>

export type UserResendLoginWithMfaMutation = {
  __typename?: 'PublicMutation'
  userResendLoginWithMfa: boolean
}

export type AccountRecoveryMutationVariables = Exact<{
  phoneNumber: Scalars['String']['input']
}>

export type AccountRecoveryMutation = {__typename?: 'PublicMutation'; userAccountRecovery: boolean}

export type GetDonkeyUiBranchesQueryVariables = Exact<{
  input: DonkeyUiBranchesInput
}>

export type GetDonkeyUiBranchesQuery = {
  __typename?: 'PublicQuery'
  getDonkeyUiBranches: Array<{
    __typename?: 'DonkeyUiBranch'
    deploymentKey: string
    name: string
  } | null>
}

export type GetStatesQueryVariables = Exact<{[key: string]: never}>

export type GetStatesQuery = {
  __typename?: 'PublicQuery'
  getStates?: Array<{__typename: 'State'; code: StateCode; name: string}> | null
}

export const PartnerSelectOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'PartnerSelectOffer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'offerId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'partnerSelectOffer'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'offerId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'offerId'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PartnerSelectOfferMutation, PartnerSelectOfferMutationVariables>
export const UserForgotPasswordSendCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserForgotPasswordSendCode'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'UserForgotPasswordSendCodeInput'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userForgotPasswordSendCode'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserForgotPasswordSendCodeMutation,
  UserForgotPasswordSendCodeMutationVariables
>
export const UserForgotPasswordResetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserForgotPasswordReset'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserForgotPasswordResetInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userForgotPasswordReset'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'token'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mfaDeliveryMedium'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserForgotPasswordResetMutation,
  UserForgotPasswordResetMutationVariables
>
export const UserValidateVerificationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserValidateVerificationCode'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'UserValidateVerificationCodeInput'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userValidateVerificationCode'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'obfuscatedEmail'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserValidateVerificationCodeMutation,
  UserValidateVerificationCodeMutationVariables
>
export const UserExchangeTemporaryTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserExchangeTemporaryToken'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserExchangeTempTokenInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userExchangeTemporaryToken'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'token'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mfaDeliveryMedium'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserExchangeTemporaryTokenMutation,
  UserExchangeTemporaryTokenMutationVariables
>
export const UserLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserLogin'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserLoginInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userLogin'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'token'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mfaDeliveryMedium'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserLoginMutation, UserLoginMutationVariables>
export const UserRegisterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserRegister'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserRegisterInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userRegister'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'token'}},
                {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserRegisterMutation, UserRegisterMutationVariables>
export const UserLoginWithMfaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserLoginWithMfa'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserLoginWithMfaInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userLoginWithMfa'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'token'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mfaDeliveryMedium'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserLoginWithMfaMutation, UserLoginWithMfaMutationVariables>
export const UserVerifyEmailConfirmDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserVerifyEmailConfirm'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserVerifyEmailInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userVerifyEmailConfirm'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserVerifyEmailConfirmMutation,
  UserVerifyEmailConfirmMutationVariables
>
export const UserResetTemporaryPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserResetTemporaryPassword'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'UserTemporaryPasswordResetInput'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userResetTemporaryPassword'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'token'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mfaDeliveryMedium'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserResetTemporaryPasswordMutation,
  UserResetTemporaryPasswordMutationVariables
>
export const UserIdentityProviderCallbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserIdentityProviderCallback'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'UserIdentityProviderCallbackInput'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userIdentityProviderCallback'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'token'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mfaDeliveryMedium'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserIdentityProviderCallbackMutation,
  UserIdentityProviderCallbackMutationVariables
>
export const UserResendLoginWithMfaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserResendLoginWithMfa'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserResendLoginWithMfaInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userResendLoginWithMfa'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserResendLoginWithMfaMutation,
  UserResendLoginWithMfaMutationVariables
>
export const AccountRecoveryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AccountRecovery'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'phoneNumber'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userAccountRecovery'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'phoneNumber'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'phoneNumber'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountRecoveryMutation, AccountRecoveryMutationVariables>
export const GetDonkeyUiBranchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getDonkeyUiBranches'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DonkeyUiBranchesInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getDonkeyUiBranches'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'deploymentKey'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDonkeyUiBranchesQuery, GetDonkeyUiBranchesQueryVariables>
export const GetStatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetStates'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getStates'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStatesQuery, GetStatesQueryVariables>
